.team-report {
    padding: 2em;
    max-width: 1400px;
    margin: 0 auto;
}
.team-report h1 {
    margin: 0;
}

.team-report .row.control-row {
    margin: 0;
    align-items: center;
    position: relative;
    justify-content: flex-end;
}

.team-report .row.control-row .filter-button {
    height: 100%;
    font-size: 1.2em;
    color: var(--text-blue);
    box-shadow: 0 1px 5px 0 #ccc;
    background: none;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em;
    cursor: pointer;
    position: relative
}

.team-report h6 {
    font-weight: 500;
    font-size: 0.8em;
    margin: 0;
}
.team-report .action-button {
    max-width: 120px;
    font-size: 0.9em;
    margin-top: 0;
}

.team-report .row {
    margin: 2em auto;
    justify-content: space-evenly;
}

.team-report .row > * {
    flex: 1 1 15%;
}

.team-report h4 {
    font-weight: 600;
    font-size: 1.2em;
}

.team-report table {
    margin-bottom: 2em;
}

.team-report table b {
    font-weight: 600;
}

.team-report .filters {
    position: absolute;
}
