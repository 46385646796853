.modal-container.IMAGE_GENERATOR {
  background: white;
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(100, 255, 218, 0.1);
  min-width: 600px;
  animation: modalFadeIn 0.5s ease;
}
.image-generator-modal {
  max-width: 90vw;
  max-height: 90vh;
  min-width: 600px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem !important;

  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(100, 255, 218, 0.1);
  animation: modalFadeIn 0.5s ease;
}

.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-title h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
}

.image-generator-modal .modal-close {
  cursor: pointer;
  font-size: 1.2rem;
  color: #666;
  transition: all 0.2s ease;
  padding: 0.5rem;
  border-radius: 6px;

  &:hover {
    color: var(--text-black);
    background: #f5f5f5;
  }
}

.image-generator-modal .modal-middle {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  flex: 1;

  justify-content: center;
}

.image-generator-modal #konvaContainerBox {
  border-radius: 12px;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 400px;
    height: 400px;
  }
}

.image-generator-modal .result-image {
  width: 500px;
  height: 500px;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 400px;
    height: 400px;
  }
}

.image-generator-modal .result-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .image-generator-modal {
    min-width: 95vw;
    min-height: auto;
    padding: 0.75rem;
    gap: 1rem;
  }

  .image-generator-modal .modal-middle {
    padding: 0.75rem 0;
    gap: 1rem;
  }
}

.loading-wrapper {
  position: relative;
  width: 500px;
  height: 500px;

  @media (max-width: 1024px) {
    width: 400px;
    height: 400px;
  }
}

.loading-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden; /* Shine effect'i sınırla */
  background: rgba(10, 25, 47, 0.7);
  border: 1px solid rgba(100, 255, 218, 0.2);
  box-shadow: 0 0 20px rgba(100, 255, 218, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: var(--preview-image);
  background-size: cover;
  background-position: center;
  filter: blur(8px) brightness(0.4);
  z-index: 0;
}

.loading-progress-infinite {
  position: absolute;
  inset: 0;
  border: 4px solid transparent;
  border-radius: 12px;
  background: linear-gradient(
      90deg,
      var(--second-green),
      #2cc389,
      #00ffff,
      #7b68ee,
      #ff69b4,
      #ff8c00,
      var(--second-green)
    )
    border-box;
  background-size: 300% 100%;
  mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  animation:
    rotateBorder 4s linear infinite,
    rainbowMove 6s linear infinite;
  z-index: 1;
}

.loading-shine-effect {
  position: absolute;
  top: 0;
  left: -100%;
  width: 150%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.1),
    rgba(44, 195, 137, 0.2),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05),
    transparent
  );
  animation: shineEffect 4s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  z-index: 2;
  transform: skewX(-20deg);
}

/* Loading bars container */
.loading-bars {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 0.5rem;
}

.loading-bar {
  width: 3px;
  height: 32px;
  background: var(--second-green);
  animation: pulseBar 1s ease-in-out infinite;
  box-shadow: 0 0 8px rgba(44, 195, 137, 0.5);
}

.loading-bar:nth-child(1) {
  animation-delay: 0s;
}
.loading-bar:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-bar:nth-child(3) {
  animation-delay: 0.4s;
}
.loading-bar:nth-child(4) {
  animation-delay: 0.6s;
}
.loading-bar:nth-child(5) {
  animation-delay: 0.8s;
}

.loading-text {
  position: absolute;
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -80px;
  width: 100%;
}

@keyframes rotateBorder {
  0% {
    clip-path: inset(0 0 98% 0);
  }
  25% {
    clip-path: inset(0 0 0 98%);
  }
  50% {
    clip-path: inset(98% 0 0 0);
  }
  75% {
    clip-path: inset(0 98% 0 0);
  }
  100% {
    clip-path: inset(0 0 98% 0);
  }
}

@keyframes pulseBar {
  0%,
  100% {
    transform: scaleY(0.3);
    opacity: 0.2;
  }
  50% {
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes shineEffect {
  0% {
    left: -150%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    left: 150%;
    opacity: 0;
  }
}

.ai-processing {
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 16px;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  justify-items: center;
}

.ai-processing-dots {
  margin-top: 1.5rem;
}

.ai-processing-dot {
  width: 10px;
  height: 10px;
  background: var(--second-green);
  box-shadow: 0 0 10px rgba(44, 195, 137, 0.5);
}

.ai-processing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.ai-processing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes moveGradient {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.result-image {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Shine effect'i sadece loading durumunda göster */
.loading-container .result-image::after {
  display: none;
}

/* Normal durumdaki result-image'dan shine effect'i kaldır */
.result-image::after {
  display: none;
}

@keyframes shineEffect {
  0% {
    left: -150%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    left: 150%;
    opacity: 0;
  }
}

.loading-container {
  position: relative;
}

.loading-container::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 2px;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(44, 195, 137, 0.3),
    transparent
  );
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  pointer-events: none;
  animation: borderGlow 4s linear infinite;
}

@keyframes borderGlow {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* Rainbow animasyonu için yeni keyframe */
@keyframes rainbowMove {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 300% 0;
  }
}

.result-container {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 12px;
  overflow: visible;
  background: rgba(10, 25, 47, 0.7);

  box-shadow: 0 0 20px rgba(100, 255, 218, 0.1);
}

.result-container::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: var(--preview-image);
  background-size: cover;
  background-position: center;
  filter: blur(8px) brightness(0.4);
  z-index: 0;
}

.result-image {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.result-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.loading-content {
  background: rgba(0, 0, 0, 0.85);
  padding: 2rem 2.5rem;
  border-radius: 14px;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  z-index: 3;
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  position: relative;
}

.loading-text {
  position: static;
  margin: 0;
  transform: none;
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.loading-bars {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 0.5rem;
}

.history-container {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  padding: 6px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  border: 1px solid rgba(100, 255, 218, 0.2);
  z-index: 100;
  animation: slideUp 0.3s ease;
}

.history-item {
  width: 55px;
  height: 55px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid transparent;
  opacity: 0.7;
  animation: fadeInScale 0.3s ease forwards;
}

.history-item:hover {
  opacity: 1;
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0 4px 12px rgba(100, 255, 218, 0.3);
}

.history-item.active {
  border-color: #64ffda;
  opacity: 1;
  box-shadow: 0 0 20px rgba(100, 255, 218, 0.4);
  animation: activePulse 2s infinite;
}

.history-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Animasyon için */
.history-item {
  animation: fadeInUp 0.3s ease-out forwards;
  opacity: 0;
  transform: translateY(10px);
}

.history-item:nth-child(1) {
  animation-delay: 0s;
}
.history-item:nth-child(2) {
  animation-delay: 0.1s;
}
.history-item:nth-child(3) {
  animation-delay: 0.2s;
}
.history-item:nth-child(4) {
  animation-delay: 0.3s;
}
.history-item:nth-child(5) {
  animation-delay: 0.4s;
}

@keyframes fadeInUp {
  to {
    opacity: 0.7;
    transform: translateY(0);
  }
}

.history-item::before {
  content: attr(data-index);
  position: absolute;
  top: 4px;
  left: 4px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 4px;
  z-index: 1;
}

.history-item.active {
  border-color: var(--second-green);
  opacity: 1;
  box-shadow: 0 0 20px rgba(44, 195, 137, 0.4);
  transform: scale(1.05);
}

.history-item.active::after {
  content: '';
  position: absolute;
  inset: 0;
  border: 2px solid var(--second-green);
  border-radius: 6px;
  animation: pulseActive 2s infinite;
}

@keyframes pulseActive {
  0% {
    box-shadow: 0 0 0 0 rgba(44, 195, 137, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(44, 195, 137, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(44, 195, 137, 0);
  }
}

.right-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 500px;
  justify-content: space-between;

  @media (max-width: 1024px) {
    width: 400px;
  }
}

.result-container {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 8px;
  overflow: visible;
  background: rgba(0, 0, 0, 0.85);
}

.result-container::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: var(--preview-image);
  background-size: cover;
  background-position: center;
  filter: blur(8px) brightness(0.4);
  z-index: 0;
}

.result-image {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.result-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.history-container {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.98);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 0.7;
    transform: scale(1);
  }
}

@keyframes activePulse {
  0%,
  100% {
    box-shadow: 0 0 20px rgba(100, 255, 218, 0.4);
  }
  50% {
    box-shadow: 0 0 30px rgba(100, 255, 218, 0.6);
  }
}

.action-buttons-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;
}

.regenerate-button-overlay,
.continue-button-overlay,
.generate-button-overlay {
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* Loading durumunda history container için yeni stiller */
.loading-wrapper .history-container {
  pointer-events: none;
  opacity: 0.5;
  filter: grayscale(50%);
  transition: all 0.3s ease;
}

/* Loading durumunda history item için yeni stiller */
.loading-wrapper .history-item {
  cursor: not-allowed;
}

/* Loading durumunda active history item için yeni stiller */
.loading-wrapper .history-item.active {
  border-color: rgba(100, 255, 218, 0.3);
  box-shadow: 0 0 20px rgba(100, 255, 218, 0.2);
}

/* History container'ı loading-wrapper'a göre konumlandır */
.loading-wrapper .history-container {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}
