.modal-container.BOOK_A_DEMO_WITH_US {
  width: 90vw;
  max-width: 1100px;
  position: relative;
  border-radius: 1em;
}

.checkup-meeting {
  border-radius: 1em;
  height: 100%;
}

.checkupMeeting .close-button {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
  color: var(--text-gray);
  font-size: 1.8em;
}

.checkupMeeting .close-button:hover {
  transform: translateY(-1px);
  color: var(--text-gray);
}

.checkupMeeting .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 2em;
  width: 100%;
}
.checkupMeeting .middle-text {
  width: 70%;
  font-size: var(--font-24);
  color: var(--text-gray);
  font-weight: 500;
  text-align: left;
  margin: 1em 0;
  display: flex;
  align-items: center;
  gap: 1em;
}

.checkupMeeting .middle-text .icon {
  color: var(--main-green);
  font-size: 1.3em;
}

.modal-container.BOOK_A_DEMO_WITH_US .subscription-header {
  width: 80%;
  max-width: 800px;
  margin: 0 auto 1em;
  color: var(--text-gray);
  font-weight: 600;
  font-size: var(--font-28);
  text-align: left;
}

.basic-user .modal-container.BOOK_A_DEMO_WITH_US .subscription-header {
  margin-top: 1em;
}
.modal-container.BOOK_A_DEMO_WITH_US .subscription-header .green {
  color: var(--text-green);
}

.modal-container.BOOK_A_DEMO_WITH_US .feature-points {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 800px;
  margin: 0 auto;
  gap: 2em;
  font-weight: 500;
}

.modal-container.BOOK_A_DEMO_WITH_US .feature-points > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-16);
  color: rgb(114, 114, 114);
  gap: 0.8em;
}

.modal-container.BOOK_A_DEMO_WITH_US .feature-points > div .green {
  color: var(--text-green);
}

.modal-container.BOOK_A_DEMO_WITH_US .feature-points > div .icon {
  color: var(--main-green);
  font-size: 4em;
}

.modal-container.BOOK_A_DEMO_WITH_US .calendly-inline-widget {
  height: 700px;
  max-height: 65vh;
}
.modal-container .connect-page-button {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.modal-container .connect-page-button .button {
  background: linear-gradient(
    294deg,
    var(--primary-green),
    var(--second-green) 35%
  );
  /* width: 30%; */
  color: var(--main-white);
  padding: 1em;
  border-radius: 7px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  flex-direction: row;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modal-container .connect-page-button .button:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.modal-container .connect-page-button .button-row {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.modal-container .connect-page-button .button-row img {
  width: 1.5em;
}

.modal-container .connect-page-button .button-row .icon {
  font-size: 1.3em;
}

@media (max-width: 1200px) {
  .BOOK_A_DEMO_WITH_US .middle-text {
    width: 100%;
  }
  .BOOK_A_DEMO_WITH_US .middle-text .icon {
    font-size: 2em;
  }
}
