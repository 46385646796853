.ad-health-score.content-box {
  gap: 0 !important;
}
.ad-health-score {
  .row {
    gap: 4em;
    align-items: flex-start !important;
  }
  .low {
    color: #e54343;
  }
  .middle {
    color: #e5ae21;
  }
  .high {
    color: var(--second-green);
  }

  .graph {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    transform: translateY(-10%);

    .your-score {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.3em;
      font-weight: 700;
      font-size: 1.5em;
      transform: translateY(-300%);
    }
    .classified {
      display: flex;
      flex-direction: row;
      gap: 2em;
      align-items: center;
      justify-content: center;
      transform: translateY(-300%);
    }
    .classified .column {
      align-items: center !important;
      justify-content: center;

      .secondary-text.optimized {
        color: #1a932e;
      }

      .secondary-text.needs-improvement {
        color: #dfa510;
      }

      .secondary-text.critical {
        color: #e65f2b;
      }
    }
    @media (max-width: 1200px) {
      .graph {
        transform: translateY(0%);
      }
      .your-score {
        transform: translateY(-10%);
      }
      .classified {
        transform: translateY(-10%);
      }
    }
  }
  @media (max-width: 1200px) {
    .graph {
      transform: translateY(0%);
    }
  }

  .missing-components {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-height: 450px;
      overflow-y: scroll;
    }

    .content::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    .content:hover::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    .content::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.25);
    }

    .content-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 0em 0.5em;
      gap: 0.5em;
    }

    .content-row .icon {
      display: flex;
    }

    .content-row.critical {
      .icon {
        color: #e65f2b;
      }
    }

    .content-row.needsImprovement {
      .icon {
        color: #dfa510;
      }
    }

    .content-row.optimized {
      .icon {
        color: #1a932e;
      }
    }
  }

  .footer-text {
    font-size: 1.5em;
    color: #0d2330;
    font-weight: 600;
  }

  @media (max-width: 1200px) {
    .missing-components {
      margin-top: 0.5em;
    }
  }
  @media (max-width: 1000px) {
    /* .row {
      flex-direction: column !important;
      gap: 0 !important;
    } */
    .secondary-text {
      font-size: 1em !important;
    }
    .graph {
      width: 30%;
      height: 50%;
    }

    .missing-components .content {
      gap: 0.5em;
      font-size: 0.7em;
    }
    .footer-text {
      font-size: 1.2em;
      transform: translateY(0);
      margin-top: 1em;
    }
  }
}
