.special-user-card {
  font-size: 14px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  max-height: min-content;
  .checkbox {
    font-size: 13px;
    span {
      font-size: 11px;
    }
  }

  p {
    margin: 0;
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    /* background: rgba(103, 103, 103, 1); */
    background: var(--text-blue-hover);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
    padding: 0.7em 1em;
    font-size: 0.92em;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .card-header.admin,
  .card-header.admin-panel-manager {
    background: #bc0f0f;
  }
  .card-header.developer {
    background: #d59cc6;
  }
  .card-header.investor {
    background: #f7b500;
  }
  .card-header.accountant {
    background: #f78000;
  }
  .card-header.sdr {
    background: #528a7cfc;
  }
  .card-header.sales-person {
    background: #69a056;
  }
  .card-header.account-manager {
    background: #3b4caa;
  }
  .card-header.performance-manager {
    background: #832598;
  }
  .card-header.partner {
    background: #357d90;
  }
  .card-header.basic {
    background: #4a4a4a;
  }

  .section .username {
    font-weight: 600;
  }
  .section .user-role {
    cursor: pointer;
  }
  
  .section .user-role:hover {
    background: rgb(233, 233, 233);
  }

  .section .role {
    font-weight: 800;
  }
  
  .section .role-selection {
    margin-left: 0.3em;
  }
  
  .section-header {
    font-size: 1.1em;
    font-weight: 600;
    color: var(--text-blue-hover);
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .section-header .icon{
    transition: .3s;
  }

  .section-header .icon.rotated{
    transform: rotate(180deg);
  }

  .card-content {
    display: flex;
    flex-direction: column;
    padding: 0.7em 1em;
    max-height: 500px;
    overflow: scroll;
  }

  .card-header {
    gap: 0.5em;
  }

  .card-header .section {
    display: flex;
    align-items: center;
    gap: 0.75em;
  }

  .card-header .section .username {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .card-content .section {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex: 1;
    position: relative;
    padding: 0.8em 0em;
  }
  .card-content .section.permissions {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    align-items: flex-start;
  }
  .card-content .permissions-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5em;
    width: 100%;
  }

  .card-content .section:not(:last-child)::after {
    content: '';
    left: 0;
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #f5f5f5;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15) inset;
    z-index: 3;
  }

  .section.contact-info .phone,
  .section.contact-info .email {
    display: flex;
    align-items: center;
    color: #444849;
    font-weight: 300;
    font-size: 1em;
  }

  .section.contact-info .phone .helper-text,
  .section.contact-info .email .helper-text {
    font-size: 0.9em;
    color: rgb(81, 81, 81);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .section.contact-info .phone:not(.not-available):hover,
  .section.contact-info .email:not(.not-available):hover {
    color: #303233;
    font-weight: 400;
    text-decoration: none;
  }

  .section.contact-info .phone:not(.not-available):hover .icon,
  .section.contact-info .email:not(.not-available):hover .icon {
    display: inline-block;
  }

  .card-content .section .role-text {
    font-size: 1.1em;
    font-weight: 600;
    color: var(--text-blue-hover);
  }

  .card-content .section .options-dropdown {
    display: flex;
    gap: 0.25em;
  }

  .card-content .section .options-dropdown select {
    width: 100px;
    border: 1px solid #dedede;
    background: transparent;
    border-radius: 2px;
    font-size: 1em;
  }

  .card-content .section .options-dropdown select:focus {
    outline: 1px solid #dedede;
  }

  .card-content .section .save-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 2px;
    background-color:#1f82a5;
    color: #fff;
    outline: 1px solid #dedede;
    font-weight: 600;
    transition: all 100ms;
    border-radius: 5px;
    padding: 0.5em 1em;
    gap: 0.25em;
  }

  .card-content .section .save-btn:hover {
    background-color: var(--text-blue-hover);
  }

  .card-content .section .save-btn .icon {
    font-size: 0.9em;
  }

  .card-content .section .cancel-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 2px;
    background-color: #d6d6d6;
    color: #fff;
    font-weight: 600;
    transition: all 100ms;
    outline: 1px solid #dedede;
  }

  .card-content .section .options-dropdown .cancel-btn:hover {
    background-color: #b6b6b6;
  }
}
