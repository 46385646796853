.stripe-payment-page .header-row {
  width: 90%;
  max-width: 800px;
  margin: 1em auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stripe-payment {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

