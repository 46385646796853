.region-popover {
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 12px;
  background: #fff;
  border: 1px solid #e0e0e0;
  width: 550px;
  height: 65vh;
  position: relative;
}

.region-popover .header-section {
  flex-shrink: 0;
  background: #f9f9f9;
  padding: 1.25rem 1.25rem 0.75rem 1.25rem;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 12px 12px 0 0;
}

.region-popover .content-section {
  flex: 1;
  overflow-y: auto;
  padding: 0 1rem 1rem;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.region-popover .close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.region-popover .close-button:hover {
  background: rgba(0, 0, 0, 0.05);
}

.region-popover .close-button .icon {
  color: #666;
  font-size: 0.8rem;
}

.region-popover h5 {
  margin: 0;
  padding: 0;
  font-size: var(--font-14);
  font-weight: 600;
  color: #2c2c2c;
}

.region-popover .selected-countries {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.region-popover .all-countries {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.region-popover .search-bar {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease;
}

.region-popover .search-bar:focus-within {
  background: #fff;
  border-color: var(--primary-green);
  box-shadow: 0 0 0 4px rgba(16, 185, 129, 0.1);
}

.region-popover .search-input {
  background: transparent;
  outline: none;
  color: #2c2c2c;
  width: 100%;
  font-size: var(--font-12);
  border: none;
}

.region-popover .search-bar svg {
  color: #666;
  font-size: var(--font-12);
}

.region-popover .clear-search-btn {
  cursor: pointer;
  color: #999;
  transition: color 0.2s ease;
}

.region-popover .clear-search-btn:hover {
  color: #666;
}

.region-popover .list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem 0;
  overflow-y: auto;
}

.region-popover .list span {
  font-size: var(--font-12);
  color: #2c2c2c;
  background: #f5f5f5;
  padding: 0.5rem calc(1.25rem + 12px) 0.5rem 0.75rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid transparent;
  flex-grow: 1; /* Her kutu aynı alanı paylaşacak şekilde büyür */
  min-width: 120px; /* Kutuların çok dar olmaması için minimum genişlik */
  max-width: 390px; /* Kutuların çok geniş olmaması için maksimum genişlik */
  justify-content: center;
}
.region-popover .list span:hover {
  transition: all 0.2s ease;
}

.region-popover .list span .icon {
  font-size: var(--font-10);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.region-popover .list span:hover {
  background: #f0f0f0;
  border-color: #e0e0e0;
}

.region-popover .list span:hover .icon {
  opacity: 1;
}

.region-popover .list span.selected {
  background: var(--primary-green);
  color: white;
  font-weight: 500;
}

.region-popover .list span.selected:hover {
  background: var(--main-green-hover);
}

.region-popover .list span.selected .icon {
  opacity: 1;
}

.region-popover .list span.cpm-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #f5f5f5bb;
  position: relative;
}

.region-popover .list span.cpm-disabled:hover::after {
  content: 'Different CPM Cluster';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
}

.region-popover .list span.cpm-disabled:hover {
  background: #f0f0f0;
  border-color: transparent;
}

.region-popover .list span.cpm-disabled:hover .icon {
  opacity: 0;
}

.region-popover .divider {
  width: 100%;
  height: 1px;
  background: #eaeaea;
  margin: 0.5rem 0;
}

.region-popover .limit-error-text {
  font-size: var(--font-12);
  color: #666;
  padding: 0.75rem;
  background: #fff5f5;
  border-radius: 8px;
  border: 1px solid #fee2e2;
}

.region-popover .limit-error-text b {
  color: #dc2626;
  font-weight: 500;
  display: block;
  margin-bottom: 0.25rem;
}

.region-popover .info-text {
  font-size: var(--font-12);
  color: #666;
  padding: 0.75rem;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .region-popover {
    height: 80vh;
  }

  .region-popover .header-section {
    padding: 1.25rem;
  }

  .region-popover .content-section {
    padding: 0 1.25rem 1.25rem;
  }
}

@media (max-width: 480px) {
  .region-popover {
    width: 350px;
    transform: translateX(15%);
  }
  .region-popover .header-section {
    padding: 1rem;
  }
  .region-popover .content-section {
    padding: 0 1rem 1rem;
  }
}
