.audience-settings .filter-bundles h5 {
  font-size: 1.3em;
  font-weight: 500;
}

.audience-settings .filter-bundles .bundle-container {
  background: rgb(245 245 245);
  border-radius: 0.5em;
  margin-left: 0px;
  margin-bottom: 0.5em;
  font-size: 1.1em;
}

.audience-settings .filter-bundles .bundle-container .header {
  padding: 0.5em 1em;
  margin-left: 0.4em;
  font-weight: 500;
  font-size: 1.1em;
}

.audience-settings .filter-bundles .bundle-container .header > .row {
  justify-content: space-between;
}

.audience-settings .filter-bundles .bundle-container .header > .row .actions {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.audience-settings
  .filter-bundles
  .bundle-container
  .header
  > .row
  .actions
  .button {
  font-size: 0.8em;
  padding: 3px 10px;
  margin: 0;
}

.audience-settings
  .filter-bundles
  .bundle-container
  .header
  > .row
  .actions
  .icon {
  font-size: 1.1em;
  cursor: pointer;
}

.audience-settings
  .filter-bundles
  .bundle-container
  .header
  > .row
  .actions
  .icon:hover {
  color: rgb(23, 43, 92);
}

.audience-settings .filter-bundles .bundle-container .content {
  max-height: 0;
  overflow: hidden;
  transition:
    max-height 0.3s,
    height 0.3s;
}

.audience-settings .filter-bundles .bundle-container .content.open {
  max-height: 100%;
  padding: 1em 1.5em;
  border-top: 1px solid gray;
}

.audience-settings .filter-bundles .bundle-container .content .attribute-name {
  font-weight: 500;
  margin: 1em 0;
}

.audience-settings .filter-bundles .bundle-container .content input {
  border-radius: 5px;
  border: none;
  box-shadow: inset 0 0px 3px #ccc;
  padding: 5px 10px;
}

.audience-settings .filter-bundles .bundle-container .content input:focus {
  box-shadow: inset 0 1px 4px #ccc;
  border: none;
  outline: none;
}

.audience-settings .filter-bundles .bundle-container .content label {
  margin: 0;
  font-size: 1em;
  font-weight: 500;
}

.audience-settings .filter-bundles .button {
  margin: 0.5em auto;
}

.audience-settings .categories-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  margin-bottom: 2em;
}

.audience-settings .category-box {
  padding: 2px 7px;
  box-shadow: 0 1px 3px 0px #ccc;
  border-radius: 5px;
  cursor: pointer;
  color: var(--text-gray);
  background: var(--main-white);
  font-size: 0.8em;
}

.audience-settings .category-box:hover {
  background: rgb(245, 245, 245);
}

.audience-settings .category-box.selected {
  background: var(--text-blue);
  color: var(--main-white);
}
