.modal-container .segment {
  width: 600px;
  padding: 0;
}

.modal-container .segment .header {
  background: var(--main-green);
  width: 100%;
  padding: 1em 5%;
  border-radius: 0.5em 0.5em 0 0;
  color: var(--main-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-container .segment .header .segment-name {
  font-size: 1.2em;
  font-weight: 500;
}

.modal-container .segment .header .conversion-container,
.modal-container .segment .footer .conversion-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 0.5em;
  font-size: 0.9em;
}

.modal-container .segment .header .conversion-container .number,
.modal-container .segment .footer .conversion-container .number {
  font-size: 1.5em;
}

.modal-container .segment .cont-box {
  margin: 1em auto;
  width: 90%;
}

.modal-container .segment .visitors-who {
  font-weight: 500;
  font-size: 1.2em;
  color: rgb(121, 121, 121);
}

.modal-container .segment .ruleset-row {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 1em;
  color: var(--text-gray);
  margin: 1.5em 0;
}

.modal-container .segment .ruleset-row .bullet {
  height: 0.6em;
  width: 0.6em;
  background: rgb(249 78 78);
  border-radius: 5em;
}

.modal-container .segment .footer {
  justify-content: space-between;
  width: 90%;
}
