.admin-panel-list-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.admin-panel-list-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.admin-panel-list-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.admin-panel-list-wrapper::-webkit-scrollbar-thumb {
  background: var(--transparent-gray);
}

.admin-panel-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.25);
}

.admin-panel-customers-list {
  display: grid;
  flex-wrap: wrap;
  grid-gap: 1.5em;
  grid-template-columns: repeat(auto-fill, minmax(440px, 1fr));
  width: 100%;
  padding: 0 1em 1em;
  align-items: start;
}

.scroll-ending-box {
  padding: 1em;
  align-items: center;
  justify-content: center;
  display: flex;
}

@media (max-width: 468px) {
  .admin-panel-customers-list {
    padding: 0 0.2em 0em;
  }
  .admin-panel-customers-list {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
