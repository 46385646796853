.tickets-page-content {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .buttons {
    display: flex;
    justify-content: space-between;
    max-width: unset;
    width: 100%;
  }

  .tabs {
    display: flex;
    gap: 0.75em;
  }

  .tab {
    padding: 0.5em 1em;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 0.9em;
    color: #333;
    background-color: #f6f7f9;
    text-align: center;
    transition: background-color 0.2s;
  }

  .tab:hover {
    background-color: hsl(220, 25%, 97%);
  }

  .tab.active {
    font-weight: 500;
    background-color: #edf2fa;
  }

  .new-ticket-button {
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 1em;
    border-radius: 0.5em;
    font-size: 0.9em;
    border: none;
    outline: none;
    color: #fff;
    background-color: #6b6b6b;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;

    &:hover {
      background-color: #5a5a5a;
    }

    .icon {
      font-size: 0.9em;
    }
  }

  .no-tickets {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.2em;
    color: var(--disabled-gray);
  }

  --tickets-grid-spacing: 1.2em;

  .tickets-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: calc(
      -1 * var(--tickets-grid-spacing)
    ); /* gutter size offset */
    width: auto;
  }

  .tickets-grid_column {
    padding-left: var(--tickets-grid-spacing); /* gutter size */
    background-clip: padding-box;
  }

  .tickets-grid_column .ticket-card {
    margin-bottom: var(--tickets-grid-spacing);
  }
}
