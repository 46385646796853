@keyframes liquid {
  0% {
    transform: translateX(-100%) skewX(-15deg);
  }
  100% {
    transform: translateX(100%) skewX(-15deg);
  }
}
.process-bar-container {
  width: 100%;
  box-sizing: border-box;
  margin-top: 1em;

  .process-bar-wrapper {
    margin: 0 auto;
  }

  .process-bar-container .process-bar-segments {
    display: flex;
    gap: 2px;
    height: 26px;
    background: rgba(243, 244, 246, 0.7);
    border-radius: 18px;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 12px;
  }

  .process-bar-container .segment {
    flex: 1;
    position: relative;
    overflow: hidden;
  }

  .process-bar-container .segment.preparation .process-bar-fill {
    height: 100%;
    background: linear-gradient(90deg, #12445a 0%, #3792b8 50%, #4eab72 200%);
    position: relative;
    transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 14px 0 0 14px;
  }

  .process-bar-container .segment.optimization .process-bar-fill {
    height: 100%;
    background: linear-gradient(90deg, #12445a -100%, #3792b8 0%, #4eab72 100%);
    position: relative;
    transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 0 14px 14px 0;
  }

  .process-bar-container .animation-container {
    position: absolute;
    top: 0;
    left: -2px;
    right: -2px;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
  }

  .process-bar-container .liquid-animation {
    position: absolute;
    inset: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.15) 20%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.15) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: liquid 2s cubic-bezier(0.4, 0, 0.2, 1);
    filter: blur(3px);
  }

  .process-bar-container .day-marker {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    z-index: 2;
    color: #fff;
  }

  .process-bar-container .day-end {
    right: 4px;
    color: #fff;
    background-color: #4b4a4a5c;
    border-radius: 14px;
  }

  .process-bar-container .phase-labels {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
  }

  .process-bar-container .phase-label {
    font-size: 14px;
    color: #666;
    font-weight: 500;
  }

  .process-bar-container .current-day-marker {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 14px;
    background-color: #4b4a4a5c;
    z-index: 2;
  }

  .process-bar-container .day-markers {
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
    margin-bottom: 0px;
    margin-top: 3em;
    position: relative;
    height: 0px;
  }

  .process-bar-container .day-label {
    font-size: 13px;
    color: #666;
    font-weight: 500;
    position: absolute;
  }

  .process-bar-container .day-label.start {
    top: -20px;
    left: 4px;
  }

  .process-bar-container .day-label.middle {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .process-bar-container .day-label.end {
    top: -20px;
    right: 4px;
  }

  .process-bar-container .labels-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 4px;
  }

  .process-bar-container .phase-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .process-bar-container .phase-group:last-child {
    align-items: flex-end;
  }

  .process-bar-container .day-label {
    font-size: 13px;
    color: #666;
    font-weight: 500;
  }

  .process-bar-container .day-label.middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -20px;
  }

  .process-bar-container .phase-label {
    font-size: 14px;
    color: #666;
    font-weight: 500;
  }

  .process-bar-container .current-day-marker {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 14px;
    background-color: #4b4a4a00;
    z-index: 2;
  }

  /* Responsive tasarım */
  @media (max-width: 1540px) {
    .process-bar-container .process-bar-segments {
      height: 22px;
    }

    .process-bar-container .day-label,
    .process-bar-container .current-day-marker {
      font-size: 12px;
    }

    .process-bar-container .phase-label {
      font-size: 13px;
    }
  }

  @media (max-width: 768px) {
    .process-bar-container .process-bar-segments {
      height: 16px;
    }

    .process-bar-container .day-label,
    .process-bar-container .current-day-marker {
      font-size: 10px;
    }

    .process-bar-container .phase-label {
      font-size: 11px;
    }
  }

  @media (max-width: 480px) {
    .process-bar-container .process-bar-segments {
      height: 14px;
    }

    .process-bar-container .day-label,
    .process-bar-container .current-day-marker {
      font-size: 9px;
      padding: 2px 6px;
    }

    .process-bar-container .phase-label {
      font-size: 10px;
    }
  }
  .acsr-tables-wrapper td {
    position: relative;
    text-align: right;
    padding: 8px 8px 8px 24px; /* Sol padding'i artırdık para birimi için yer açmak adına */
  }

  .acsr-tables-wrapper .currency {
    position: absolute;
    left: -3px;
  }

  .acsr-tables-wrapper .percentage {
    margin-left: 2px;
  }
}
