.ADSET_AUDIENCES_CONFIG {
  min-width: 500px;
  max-width: 60vw;
}

.adset-audiences-config {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.adset-audiences-config h3 {
  font-weight: 500;
  color: var(--second-green);
  font-size: var(--font-22);
  margin-bottom: 0.5em;
}

.adset-audiences-config .audience-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  position: relative;
}

.adset-audiences-config .audience-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

.adset-audiences-config .audience-table tbody {
  max-height: 400px;
  overflow-y: auto;
  display: block;
}

.adset-audiences-config .audience-table thead tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.adset-audiences-config .audience-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.adset-audiences-config .audience-table th {
  height: 2em;
  padding: 1em;
  background: white;
  border-bottom: 2px solid #eee;
}

.adset-audiences-config .audience-table th:first-child {
  text-align: left;
  width: 60%;
}

.adset-audiences-config .audience-table th:not(:first-child) {
  width: 20%;
}

.adset-audiences-config .audience-table td {
  padding: 0.8em 1em;
  border-bottom: 1px solid #eee;
}

.adset-audiences-config .audience-table td:first-child {
  width: 60%;
}

.adset-audiences-config .audience-table td:not(:first-child) {
  width: 20%;
}

.adset-audiences-config .audience-row {
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.adset-audiences-config .audience-row:hover {
  background: #f0f0f0;
}

.adset-audiences-config .audience-checkbox-container {
  text-align: center;
}

.adset-audiences-config .audience-checkbox {
  width: 1.5em;
  height: 1.5em;
}

.adset-audiences-config .audience-checkbox:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.2s ease;
}

.adset-audiences-config .audience-checkbox:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.adset-audiences-config .audience-table tbody::-webkit-scrollbar {
  width: 8px;
}

.adset-audiences-config .audience-table tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.adset-audiences-config .audience-table tbody::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.adset-audiences-config .audience-table tbody::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

/* Save butonu için yeni stiller */
.adset-audiences-config .modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid #eee;
}

.adset-audiences-config button {
  min-width: 120px;
  padding: 0.7em 1.4em;
  border-radius: 6px;
  font-weight: 600;
  transition: all 0.2s ease;
  background: var(--second-green);
  color: white;
  border: none;
  cursor: pointer;
}

.adset-audiences-config .action-button {
  min-width: 120px;
  padding: 0.7em 1.4em;
  border-radius: 6px;
  font-weight: 600;
  transition: all 0.2s ease;
}

.adset-audiences-config .action-button.update {
  background: var(--second-green);
  color: white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.adset-audiences-config .action-button.update:hover {
  background: var(--second-green-hover);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.adset-audiences-config .action-button.update:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.adset-audiences-config button:hover {
  background: var(--second-green-hover);
}

.adset-audiences-config button:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}
