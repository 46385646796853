.chip-input-wrapper {
  display: flex;
  align-items: center;

  padding: .3em 1em;
  max-width: 600px;
  gap: .3em;

  border: none;
  outline: none;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px 0 #ccc;
}

.text-input {
  padding: 0;
  border: none;
  outline: none;
  font-size: 1em;
  width: auto;
  /* min-width: 1ch; */
}

.chip {
  background-color: #e6fee8;
  padding: 1px 8px;
  border-radius: 1em;
  font-size: .9em;
  cursor: default;
}