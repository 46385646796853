.productSelectionList {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 1rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 0.75rem;
  }
}

.leftSide,
.rightSide {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid var(--border-main);
}

.leftSide {
  flex: 2.85;
  height: calc(100vh - var(--topbar-height) - 4em);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1024px) {
    flex: none;
    height: 65vh;
    min-height: 450px;
  }
}

.mainColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.productsList {
  flex: 1;
  min-height: 0;
  overflow: hidden;
  position: relative;
}

.rightSide {
  flex: 1.55;
  padding: 1rem;
  height: calc(100vh - var(--topbar-height) - 4em);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #f9f9f9;

  @media (max-width: 1024px) {
    flex: none;
    height: 35vh;
    min-height: 250px;
    padding: 0.75rem;
  }
}

.leftSideHeader {
  display: flex;
  align-items: flex-start;
  margin: 0 0 0.75rem;
  gap: 0.75rem;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}

.row {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
  min-width: 250px;
  max-width: 300px;

  @media (max-width: 1024px) {
    flex: 1 1 100%;
    max-width: none;
    width: 100%;
  }
}

.label {
  font-size: 0.8em;
  white-space: nowrap;
  color: #666;
}

.searchBarContainer {
  flex: 0 1 auto;
  max-width: 300px;
  min-width: 180px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0.35rem 0.5rem;
  background: #f8f9fa;
  transition: all 0.2s ease;
  height: 30px;
  margin-left: auto;
  gap: 0.5rem;

  @media (max-width: 1024px) {
    max-width: none;
    width: 100%;
    margin-left: 0;
    margin-top: 0.75rem;
  }
}

.searchIcon {
  color: #666;
  font-size: 0.9rem;
  min-width: 14px;
}

.searchBar {
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
  background: transparent;
  font-size: 0.85rem;
  height: 24px;
}

.productsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 0.75rem;
  padding: 0.25rem 0.25rem 2rem 0.25rem;
  max-height: calc(100vh - 13rem);
  overflow-y: auto;
  scrollbar-width: thin;

  @media (max-width: 1024px) {
    max-height: calc(60vh - 8rem);
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 0.5rem;
    padding: 0.25rem 0.25rem 1rem 0.25rem;
  }
}

.productContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #ffffff;
  height: fit-content;

  @media (max-width: 1024px) {
    padding: 0.5rem;
  }
}

.productContainer:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-color: var(--second-green);
}

.selectCircle {
  position: absolute;
  z-index: 1;
  right: 0.35rem;
  top: 0.35rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1.5px solid var(--second-green);
  background: #ffffff;

  @media (max-width: 768px) {
    width: 1.1rem;
    height: 1.1rem;
    right: 0.25rem;
    top: 0.25rem;
  }
}

.icon {
  color: var(--second-green);
  font-size: 0.7rem;
}

.price {
  font-size: 0.85rem;
  font-weight: 600;
  color: var(--second-green);
  margin: 0.35rem 0;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin: 0.25rem 0;
  }
}

.productName {
  font-size: 0.8rem;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3;

  @media (max-width: 1024px) {
    font-size: 0.75rem;
    -webkit-line-clamp: 1;
  }
}

.productImage {
  aspect-ratio: 1;
  width: 100%;
  margin: 0 auto 0.5rem;
  border-radius: 4px;
  overflow: hidden;

  @media (max-width: 1024px) {
    margin-bottom: 0.35rem;
  }
}

.productImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.productContainer:hover .productImage img {
  transform: scale(1.05);
}

.productSelectionList :global(.custom-dropdown) {
  max-width: 300px;
  font-size: 0.85rem;
  border: 1px solid #e0e0e0 !important;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
}

.loadMoreButton {
  bottom: 0;
  width: 100%;
  padding: 0.5rem;
  background-color: var(--border-main);
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: 500;
  z-index: 2;
  color: var(--text-black);
  @media (max-width: 768px) {
    padding: 0.4rem;
    font-size: 0.8rem;
  }
}

.loadMoreButton:hover {
  background: var(--text-blue);
  color: white;
}

.loadMoreButton {
  animation: fadeIn 0.5s ease-in-out;
}

.loadMoreIcon {
  margin-right: 0.5em;
}

.productsPreviewer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.productsPreviewer h4 {
  margin-top: 0;
}

.productSelectionList :global(.tooltip-container):has(.nextButton) {
  width: 100%;
}

.nextButton {
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  font-size: 0.9rem;
  margin-top: auto;

  position: sticky;
  bottom: 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    padding: 0.6rem;
    font-size: 0.85rem;
  }
}

/* Scrollbar stilleri */
.productsGrid::-webkit-scrollbar,
.selectedProductsList::-webkit-scrollbar {
  width: 4px;

  @media (max-width: 768px) {
    width: 3px;
  }
}

.productsGrid::-webkit-scrollbar-track,
.selectedProductsList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.productsGrid::-webkit-scrollbar-thumb,
.selectedProductsList::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

/* Firefox için scrollbar */
.productsGrid,
.selectedProductsList {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

.selectedProductsList {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  margin-bottom: 1rem;
}

.selectedProductsList .productsGrid {
  max-height: none;
  height: auto;
}

.leftSideHeader :global(.dropdown) {
  max-width: 300px;
  font-size: 0.85rem;
  border: 0px solid #e0e0e0 !important;
  border-radius: 6px;
  background: #f8f9fa;
  padding: 0.35rem 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: var(--second-green) !important;
  }

  :global(.dropdown-toggle) {
    border: none;
    background: none;
    padding: 0;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :global(.dropdown-menu) {
    width: 100%;
    padding: 0.5rem 0;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin-top: 0.25rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
}

.dropdown {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  flex: 2;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}
