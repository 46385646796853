.performance-user-detail-layout .content-container-wrapper {
  display: grid;
  grid-template-columns: 1fr min(1700px, 90%) 1fr;
  padding-left: calc(var(--drawer-opener-width) - 2em);
}

.performance-user-detail-layout .content-container-wrapper > * {
  grid-column: 2;
}

.performance-user-detail-layout .content-container {
  display: grid;
  grid-template-columns: 5fr 2fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header header'
    'main side';
  gap: 40px;
}

.performance-user-detail-layout .content-container .header-content {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.performance-user-detail-layout .content-container .main-content {
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.performance-user-detail-layout .content-container .side-content {
  grid-area: side;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

@media (max-width: 1200px) {
  .performance-user-detail-layout .content-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'header'
      'main'
      'side';
  }

  .performance-user-detail-layout .content-container .header-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
}

@media (max-width: 1178px) {
  .performance-user-detail-layout .content-container .main-content {
    font-size: medium;
    min-width: 94vw;
  }
  .performance-user-detail-layout .content-container-wrapper {
    display: flex;
  }
}
@media (max-width: 855px) {
  .performance-user-detail-layout .content-container .main-content {
    font-size: medium;
    min-width: 92vw;
  }
  .performance-user-detail-layout .content-container-wrapper {
    display: flex;
  }
}
@media (max-width: 585px) {
  .performance-user-detail-layout .content-container .main-content {
    font-size: medium;
    min-width: 88vw;
  }
}
@media (max-width: 475px) {
  .performance-user-detail-layout .content-container .main-content {
    font-size: medium;
    min-width: 80vw;
  }
}
@media (max-width: 435px) {
  .performance-user-detail-layout .content-container .main-content {
    font-size: smaller;
    min-width: 80vw;
    margin-left: -10px;
  }
  .performance-user-detail-layout .content-container-wrapper {
    display: flex;
  }
  .performance-user-detail-layout .content-container .side-content {
    font-size: smaller;
    min-width: 80vw;
    margin-left: -10px;
    margin-top: -15px;
  }
}
