.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  margin-bottom: 0.5rem;
  font-size: 0.9em
}

.form-control input,
.form-control textarea,
.form-control select {
  width: 100%;
  height: 3em;
  border: none;
  outline: none;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px 0 #ccc;
  padding: 0.5em 1em;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
  /* padding: 3px 0.5em */
}

.form-control--invalid .error-text {
  font-size: 0.8em
}

.form-control .select-cont {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px 0 #ccc;
  padding: 0;
  cursor: pointer;
}

.form-control select {
  width: calc(100% - 1em);
  margin: 0 auto;
  box-shadow: none;
  cursor: pointer;
}

input.standart {
  border: 0;
  outline: 0;
  box-shadow: 0 1px 4px 0 #ccc;
  border-radius: 5px;
  font-size: 0.9em;
  padding: 5px 10px;
}

input.standart:focus {
  box-shadow: 0 2px 6px 0px #ccc;
}