.modal-container .generic-modal  {
  max-width: 600px;
  color: #3e3c3c;
}

.generic-modal .body {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 2em;
}
.generic-modal .body .image-icon{
  width: 150px;
}
.generic-modal .footer button{
  margin-top: 1em;
}
.generic-modal p {
  margin: 0;
  color: var(--text-gray);
}
.generic-modal .body img {
  max-width: 60px;
}
.modal-container .generic-modal .footer {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.generic-modal h2 {
  margin: 0 0 0.3em;
  color: #7f7f7f;
}
.generic-modal input {
  /* width: 40%; */
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 0.3em;
  margin-bottom: 1em;
}

.generic-modal .middle-component{
 margin-top: 0.5em;
}
.generic-modal .middle-component h3{
  margin: 0;
  color: #7f7f7f;
  font-size: 1em;
}