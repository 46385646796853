.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.productSectionBody {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 2em;
}

.backButton {
  display: flex;
  align-items: center;
  color: var(--second-green);
  background: transparent;
  border: 1px solid var(--second-green);
  font-size: 0.875em;
  font-weight: 500;
}

.backButton:hover {
  background: var(--second-green);
  border: 1px solid var(--second-green);
  color: #fff;
}

.catalogTopLogo {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
	pointer-events: none;
}

.catalogTopLogoBackButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 18px;
  color: #333;
  padding-left: 2em;
}

.catalogTopLogoBackIcon {
  cursor: pointer;
  margin-right: 10px;
  color: #13364a;
}

.catalogTopLogoText {
  font-weight: bold;
  margin-right: 10px;
  background: linear-gradient(to right, #13364a 0%, #58ba8b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.catalogTopLogoDeviceIcon {
  font-size: 24px;
  color: #58ba8b;
}

.productSelectionTitle {
  padding: 1em 2em;
  font-weight: 600;
  color: var(--second-green);
}
