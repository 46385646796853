/* Normal Layout - değişmeyecek */
.campaign-page-layout {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.empty-campaigns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-campaigns-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  margin-bottom: 5em;
  text-align: center;
  padding: 64px 32px;
  background: linear-gradient(
    to bottom,
    rgba(var(--main-green-rgb), 0.02),
    #ffffff
  );
  border-radius: 16px;
  border: 1px solid rgba(var(--main-green-rgb), 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.no-campaigns-text {
  font-size: 1.5em !important;
  color: var(--text-gray);
  text-align: center;
}

.create-campaign-button {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.8em 1.5em;
  border: none;
  border-radius: 0.5em;
  background-color: var(--main-blue);
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.2s ease;
}

.breadcrumb-container {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 20;
  align-content: center;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  height: var(--breadcrumb-height);
}
.basic-user .breadcrumb-container {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  z-index: 2000;
  align-content: center;
  border-bottom: 1px solid #f0f0f0;
  width: calc(100% + 2em);
  height: var(--breadcrumb-height);
  padding: 0 2em;
  margin-left: -2em;
}
.campaign-page-layout .content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 10em);
}
.basic-user .campaign-page-layout .content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - var(--breadcrumb-height));
}
.empty-campaigns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}

.no-campaigns-text {
  font-size: 1.1em;
  color: var(--text-gray);
  text-align: center;
}

.create-link {
  color: var(--main-blue);
  cursor: pointer;
  text-decoration: underline;
}

.create-link:hover {
  color: var(--main-blue-hover);
}

.ai-ads-full-funnel-campaigns-grid {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 1em;
  padding: 0.5em 2em 0.5em 0.5em;
  width: 100%;
  overflow-x: auto;
  cursor: default;
  user-select: none;
  -webkit-user-drag: none;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #666;
  }

  /* Firefox scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
.bottom-section {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 10;
  width: 100%;
  border-top: 1px solid #e3e3e3;
}
.bottom-section-header {
  display: flex;
  justify-content: flex-end;
  padding: 0em 2em 0.5em 2em;
}
.basic-user .bottom-section {
  position: sticky;
  bottom: 0;
  background: #fbfbfb;
  padding: 1em 0em 0em 0em;
  z-index: 10;
  margin-top: auto; /* Push to bottom */
  width: calc(100% + 2em);
  margin-left: -2em;
  padding-left: 2em;
  border-top: 1px solid #e3e3e3;
}

.basic-user .ai-ads-full-funnel-campaigns-grid-header {
  color: var(--second-green) !important;
  text-decoration: none !important;
  pointer-events: none;
  transition: all 100ms;
  line-height: 1;
  font-size: 1.35em;
  padding: 1em 0 1em 0;
}

.ai-ads-full-funnel-campaigns-grid.full {
  grid-template-columns: 1fr;
  height: -webkit-fill-available;
}

.ai-ads-full-funnel-campaigns-grid .no-campaigns-text {
  width: 100%;
}
.create-campaign-card {
  max-width: 12em;
  min-width: 12em;
  height: 100%;
  min-height: 12em;
  cursor: pointer;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  background: #fff;
  border: 2px dashed #ccc;
  border-radius: 0.7em;
  color: #666;
  font-size: 1em;
  transition: all 200ms;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px;
  display: flex;
  flex-direction: column;
}
.create-campaign-card {
  height: 100%;
  min-height: 12em;
  cursor: pointer;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  background: #fff;
  border: 2px dashed #ccc;
  border-radius: 0.7em;
  color: #666;
  font-size: 1em;
  transition: all 200ms;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px;
  width: 100%;
}

.create-campaign-card:hover {
  border-color: var(--second-green);
  color: var(--second-green);
  background: #f9f9f9;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 2px 8px;
}

.create-campaign-card:hover {
  border-color: var(--second-green);
  color: var(--second-green);
  background: #f9f9f9;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 2px 8px;
}

.create-campaign-card svg {
  font-size: 1.2em;
}

.create-campaign-card svg {
  font-size: 1.2em;
}

@media (max-width: 1400px) {
  .ai-ads-full-funnel-campaigns-grid {
    grid-template-columns: 1fr;
  }
}

.breadcrumb-actions .actions {
  display: flex;
  align-items: center;
  gap: 1em;
  display: none;
}

.basic-user.breadcrumb-actions .actions .button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
  height: 2em;
  background: #eeeeee;
  color: var(--text-gray);
  padding: 0.8em;
}

.basic-user .breadcrumb-actions .actions .button:hover {
  background: #d2d2d2;
}

.basic-user .breadcrumb-actions .actions .campaign-status {
  font-size: 0.8em;
  color: #959595;
}

.basic-user .breadcrumb-actions .actions .campaign-status .status {
  font-weight: 600;
}

.basic-user .breadcrumb-actions .actions .switch {
  background-color: rgb(238, 238, 238);
  box-shadow: unset;
  border-radius: 5px;
  font-size: 13px;
  min-width: 120px;
}
.ai-ads-full-funnel-campaigns-grid {
  padding: 0.5em 2em 1em 0.5em;
}
/* Mobil düzenlemeler */
@media (max-width: 768px) {
  .campaign-page-layout {
    min-height: 100dvh;
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .campaign-page-layout .content-wrapper {
    height: auto;
    min-height: calc(100dvh - var(--breadcrumb-height));
    padding-bottom: env(safe-area-inset-bottom, 20px);
  }

  .breadcrumb-container {
    position: -webkit-sticky;
    position: sticky;
    top: env(safe-area-inset-top, 0);
    padding-top: env(safe-area-inset-top, 0);
    background: white;
    z-index: 2000;
    width: 100%;
  }

  .basic-user .breadcrumb-container {
    width: 100%;
    margin-left: 0;
    padding: env(safe-area-inset-top, 0) 1em 0 1em;
    min-height: 60px;
  }

  .empty-campaigns-container {
    position: relative;
    height: calc(
      100dvh - var(--breadcrumb-height) - env(safe-area-inset-bottom, 0px)
    );
    transform: none;
    top: 0;
    left: 0;
  }

  .basic-user .bottom-section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: calc(1em + env(safe-area-inset-bottom, 0px));
    background: white;
  }
  .acsr-container {
    padding: 2em 0em 12em 1em;
  }

  .basic-user .bottom-section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fbfbfb;
    padding: 1em;
    padding-bottom: calc(1em + env(safe-area-inset-bottom, 0px));
    z-index: 1000;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    scroll-snap-type: none;
    overscroll-behavior-x: none;
  }

  .basic-user .bottom-section .ai-ads-full-funnel-campaigns-grid {
    scroll-snap-type: none;
    scroll-behavior: auto;
    overscroll-behavior-x: none;
    touch-action: pan-x;
    -webkit-overflow-scrolling: auto;
  }

  .basic-user .bottom-section::-webkit-scrollbar {
    display: none;
  }

  .basic-user .bottom-section {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ai-ads-full-funnel-campaigns-grid {
    padding: 0.5em 2em 2em 0.5em;
    gap: 0.75em;
    margin-bottom: env(safe-area-inset-bottom, 20px);
    overflow-x: auto;

    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }

  .create-campaign-card {
    min-width: 10em;
    max-width: 10em;
    min-height: 10em;
    height: 10em;
    scroll-snap-align: start;
    margin-bottom: 1em;
  }

  .ai-ads-full-funnel-campaigns-grid-header {
    padding: 1em 1em 0.5em 1em;
    font-size: 1.2em;
  }

  /* iOS için özel scroll davranışı */
  @supports (-webkit-touch-callout: none) {
    .ai-ads-full-funnel-campaigns-grid {
      padding-bottom: calc(1em + env(safe-area-inset-bottom, 20px));
    }
  }

  /* Tablo düzenlemeleri */
  .acsr-metric-table {
    flex: 0 0 18em; /* Masaüstünde 22em olan genişliği küçülttük */
    margin-right: 1em; /* Sağ margin'i azalttık */
    font-size: 0.9em; /* Font boyutunu küçülttük */
  }

  .acsr-metric-table th,
  .acsr-metric-table td {
    padding: 0.4em; /* Padding'i azalttık */
  }

  .acsr-campaign-column {
    max-width: 14em; /* Masaüstünde 17.6em olan genişliği küçülttük */
  }

  /* Chart düzenlemeleri */
  .acsr-chart {
    height: 180px; /* Chart yüksekliğini azalttık */
    padding: 0.5em; /* Padding'i azalttık */
    min-height: 6em; /* Minimum yüksekliği azalttık */
  }

  .acsr-report-card {
    min-height: 12em; /* Card yüksekliğini azalttık */
  }

  /* Metriklerin font boyutlarını küçült */
  .acsr-subtitle-value {
    font-size: 1.2em; /* Masaüstünde 1.5em */
  }

  .acsr-subtitle-text {
    font-size: 0.9em; /* Masaüstünde 1em */
  }

  /* Header boyutlarını küçült */
  .acsr-metric-header {
    padding: 0.4em;
    height: 2.5em; /* Masaüstünde 3em */
  }

  .acsr-campaign-header,
  .acsr-campaign-subheader {
    height: 2.2em; /* Masaüstünde 2.6em */
  }

  /* Tablo başlıklarını küçült */
  .acsr-metric-table th {
    padding-right: 1em; /* Masaüstünde 1.3em */
    font-size: 0.85em;
  }

  .bottom-section-header {
    padding: 1.5em 1em 0.5em 1em;
  }
}

/* Daha küçük ekranlar için ek optimizasyonlar */
@media (max-width: 375px) {
  .acsr-metric-table {
    flex: 0 0 16em;
    font-size: 0.85em;
  }

  .acsr-campaign-column {
    max-width: 12em;
  }

  .acsr-chart {
    height: 160px;
  }
}

/* iOS özel düzenlemeler */
@supports (-webkit-touch-callout: none) {
  .campaign-page-layout {
    min-height: -webkit-fill-available;
  }

  .campaign-page-layout .content-wrapper {
    min-height: calc(
      100vh - var(--breadcrumb-height) - env(safe-area-inset-top, 0px)
    );
  }

  .basic-user .campaign-page-layout .content-wrapper {
    height: calc(
      100vh - var(--breadcrumb-height) - env(safe-area-inset-top, 0px)
    );
  }

  .ai-ads-full-funnel-campaigns-grid {
    padding-bottom: env(safe-area-inset-bottom, 20px);
  }
}

/* Genel viewport height düzeltmeleri */
@supports (height: 100dvh) {
  .campaign-page-layout {
    height: 100dvh;
  }

  .campaign-page-layout .content-wrapper {
    height: calc(100dvh - var(--breadcrumb-height));
  }

  .empty-campaigns-container {
    height: calc(100dvh - var(--breadcrumb-height));
  }
}

.empty-state-icon-container {
  width: 120px;
  height: 120px;
  margin: 0 auto 32px;
  background: rgba(var(--main-green-rgb), 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.empty-state-icon {
  width: 200px;
  height: 190px;
}

.empty-state-title {
  color: #1a1a1a;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 24px;
}

.empty-state-description {
  color: #666;
  font-size: 18px;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto 32px;
}

.empty-state-button {
  padding: 18px 36px;
  background-color: var(--main-green);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  transition: all 0.2s ease;
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(var(--main-green-rgb), 0.1);
}

.empty-state-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(var(--main-green-rgb), 0.2);
  background-color: var(--main-green-dark, var(--main-green));
}

.empty-state-button .button-icon {
  font-size: 20px;
}

.filter-toggle-container {
  position: static;
  display: flex;
  gap: 1em;
  align-items: center;
}

.filter-toggle-container span {
  cursor: pointer;
  font-size: 0.95em;
  font-weight: 500;
  color: #333;
  transition: color 0.2s ease;
  user-select: none;
}

.filter-toggle-container span:first-child:after {
  content: '/';
  margin-left: 1em;
  color: #666;
  font-weight: 300;
}

.filter-toggle-container span.active {
  color: var(--main-green);
  font-weight: 600;
}

/* Mobil için düzenleme */
@media (max-width: 768px) {
  .filter-toggle-container {
    right: 1em;
  }
}

.breadcrumb-container span.clickable {
  cursor: pointer;
}

.breadcrumb-container span:not(.clickable) {
  cursor: default;
}
