.testimonials-carousel {
  width: 100%;
  margin: 2em auto 0;
  padding: 1em 0;
  position: relative;
  /* display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; */

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 0.5em;
  }
  .header span {
    background: linear-gradient(
      90deg,
      var(--second-green) 0%,
      var(--primary-green) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h1 {
    color: #13364a !important;
  }

  .testimonials-container .overlayFirst {
    position: absolute;
    background: linear-gradient(to right, #091923, transparent);
    z-index: 10;
  }

  .testimonials-container .overlayLast {
    position: absolute;
    background: linear-gradient(to left, #091923, transparent);
    z-index: 10;
  }

  .testimonials-container .section-header {
    margin: 0 auto 1em;
  }

  .testimonials-container .decor {
    position: absolute;
    top: 3em;
    left: 5.5em;
    z-index: 0;
    opacity: 0;
  }

  .testimonials {
    margin: 1em auto;
    color: #202327;
    padding: 3em;
  }

  .flag {
    max-width: 1.5em;
  }

  .testimonials-card .logo {
    max-width: 3em;
    height: 2em;
    filter: grayscale(100%);
  }

  .logo.muyu {
    filter: brightness(1) invert(1);
    max-width: 4em;
    height: 1em;
    margin: 0.7em 0 0.3em 0;
  }

  .logo.andiis-treasures {
    max-width: 4em;
    height: 0.5em;
    margin: 0.5em 0;
  }

  .logo.sui {
    max-width: 2em;
  }

  .logo.manzara {
    max-width: 5em;
  }

  .logo.anthos {
    max-width: 6em;
    height: 1.5em;
    margin: 0.2em -1em 0.2em -1em;
    /* margin-right: -1em; */
  }

  .logo.swoon-london {
    max-width: 4em;
  }

  .testimonials-card {
    position: relative;
    padding: 1em 1.5em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 15em;
    max-width: 1100px;
    margin: 0 auto;
    border-radius: 1.4em;
    box-shadow: 0px 3px 15px #0000351a;
    background-color: var(--main-white);
    transition: 0.3s;
    gap: 1em;
    max-width: 500px;
  }

  .testimonials .react-multi-carousel-item {
    margin: 1em;
  }

  .owl-item.active.center .testimonials-card {
    color: #202327;
    box-shadow: 0px 3px 15px #0000351a;
    /* transform: scale(1.07); */
  }

  .testimonials-card .card-header {
    color: var(--text-black);
    text-align: left;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .name {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 0.5em;
    font-size: 18px;
  }

  .stars {
    width: 100%;
  }

  .testimonials h3 {
    font-size: 500;
  }

  .testimonials .text-part {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
  }

  .text-part img {
    width: 100%;
  }

  .text-part p {
    line-height: 1.5em;
    text-align: left;
    font-size: 16px;
    color: var(--text-purplish-gray);
  }
  .swiper {
    width: 100%;
  }
  @media (max-width: 2200px) {
    .testimonials-card {
      height: 20em;
    }
  }

  @media (max-width: 1400px) {
    .testimonials-card {
      max-width: 750px;
    }
  }

  @media (max-width: 1000px) {
    .testimonials-container {
      width: 100%;
      border-radius: 0;
    }

    .testimonials-container .testimonials {
      --items: 2;
    }

    .testimonials-card {
      max-width: 650px;
    }
  }

  @media (max-width: 800px) {
  }

  @media (max-width: 600px) {
    .testimonials-card {
      flex-direction: column;
      width: 90%;
      max-width: 85vw;
    }

    .testimonials .name {
      margin-bottom: 0.3em;
    }

    .stars {
      max-width: 90px;
    }
    .testimonials {
      padding: 1em;
    }
  }
  @media (max-width: 430px) {
    .text-part p {
      font-size: 13.6px;
    }
    .testimonials-card {
      width: 100%;
      max-width: 100vw;
    }
  }

  @media (max-width: 350px) {
    .stars {
      max-width: 70px;
    }
  }
}
