.toolbarBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.toolbarBoxTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}

.toolbarBoxTitleText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.toolbarBoxInside {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
  width: 100%;
}

.toolbarBoxInsideItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
}

.toolbarBoxInsideItemTitle {
  font-size: 14px;
}

.toolbarBoxInsideItemContent {
  display: flex;
  flex-direction: row;
}

.toolbarBoxInsideItemContentColor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background 0.1s;
}

.toolbarBoxInsideItemContentColorBox {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  margin-left: 10px;
}

.toolbarBoxInsideItemContentColorInput {
  width: 70px;
  height: 30px;
  border: none;
  padding: 5px 10px;
  text-align: right;
  font-size: 12px;
  cursor: pointer;
  background: none;
  align-content: center;
  align-self: center;
}

.toolbarBoxInsideItemContentButton {
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: background 0.1s;
}

.toolbarBoxInsideItemContentButton:hover,
.toolbarBoxInsideItemContentSelect:hover,
.toolbarBoxInsideItemContentColor:hover {
  background: #ececec;
}

.toolbarBoxInsideItemContentButtonIcon {
  margin-right: 4px;
}

.paletteBox {
  position: relative;
  box-shadow: var(--main-box-shadow);
  padding: 0.5em 0 0 0;
  border-radius: 0.5em;
  background: white;
}

.paletteBox h5 {
  margin: 0;
  text-align: center;
}

.palettes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  overflow-y: auto;
  padding: 0.5em 1em 1em;
  flex-wrap: wrap;
  flex-direction: row;
  width: 380px;
}

.paletteRow {
  display: flex;
  justify-content: center;
  cursor: pointer;
  gap: 5px;
}

.paletteRow:hover {
  box-shadow: var(--main-box-shadow);
  transform: scale(1.02);
}

.paletteRowSelected {
  border: 2px solid #282828;
}

.colorBox {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
}

.colorPicker {
  position: absolute;
  right: 13em;
}
