.topbar-container {
  height: var(--topbar-height);
  width: 100vw;
  background: linear-gradient(to right, #011520 0%, #13364a 100%);
  box-shadow: 0px 7px 7px #ccc;
  box-shadow: 0px 8px 7px #71717130;
  display: flex;
  position: relative;
  z-index: 80;
}

.topbar-container .topbar {
  width: 100%;
  height: 100%;
  /* max-width: 1300px; */
  padding: 1em 2em 1em 4em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topbar-container .topbar .logo-cont {
  height: 100%;
  max-width: calc(var(--topbar-height) * 2.5);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbar-container .topbar .logo-cont span {
  color: var(--main-white);
  font-size: 0.85em;
  float: right;
  margin-top: -0.3em;
}

.topbar-container .topbar .logo-cont img {
  width: 100%;
}

.topbar-container .customer-title {
  align-items: center;
  gap: 0.7em;
}

.topbar-container .customer-title .back-arrow {
  color: var(--main-white);
  padding: 5px;
  font-size: 1.7em;
  border-radius: 5px;
  cursor: pointer;
}

.topbar-container .customer-title .back-arrow:hover {
  background: rgba(0, 0, 0, 0.183);
}

.topbar-container .customer-title h3 {
  margin: 0;
}

.right-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
}

.checkup-container {
  align-items: center;
}
.ahcu-title {
  display: none;
}

.topbar-container .nav-profile-pic {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.topbar-container .open-menu {
  position: absolute;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  display: none;
}

.topbar-container .bar-button:hover .nav-profile-pic {
  opacity: 0.5;
}

.topbar-container .bar-button:hover .open-menu {
  display: inherit;
}

.topbar-container .nav-main .navigation {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex: 1 1 100%;
}

.topbar-container .nav-main .nav-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2em;
  color: #eaeaea;
  text-decoration: none;
  padding: 1em 0;
  cursor: pointer;
}

.topbar-container .profile-pic-container {
  width: 60px;
  height: 60px;
}

.topbar-container .profile-pic-container img {
  width: 100%;
  height: 100%;
  border-radius: 15%;
  object-fit: cover;
}

.topbar-container .profile-pic-container .icon {
  width: 80%;
  height: 80%;
  border-radius: 15%;
}

.topbar-container .profile-menu-cont {
  position: relative;
}

.topbar-container .profile-menu {
  /* display: none; */
  max-height: 0;
  position: absolute;
  background: linear-gradient(to right top, #01394f, 10%, #016750);
  color: var(--main-white);
  transition: 0.3s;
  right: 0;
  margin-top: 15px;
  z-index: 10;
  border-radius: 8px;
  overflow: hidden;
  z-index: 9999;
  min-width: 250px;
  max-width: 450px;
}

.topbar-container .profile-menu.open {
  /* display: inline-block; */
  max-height: 200px;
}

.topbar-container .profile-menu .menu-list {
  margin: 0;
  z-index: 9999;
}

.topbar-container .profile-menu .menu-list .menu-item .first-letter {
  font-size: 1.2em;
  width: 25px;
  display: flex;
  justify-content: center;
}

.topbar-container .profile-menu .menu-list .menu-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  color: var(--main-white);
  text-decoration: none;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.293);
  font-size: 0.9em;
  cursor: pointer;
  padding: 0.6em;
  z-index: 9999;
}

.topbar-container .profile-menu .menu-list .menu-item:last-child {
  border: none;
  font-weight: bold;
  color: rgb(254, 99, 99);
}

.topbar-container .profile-menu .menu-list .menu-item:first-child {
  /* font-weight: bold;
  max-height: 40px;
  box-shadow: 0 1px 5px 0 #00000036;
  background: rgba(0, 0, 0, 0.324);
  display: flex; */
}

.topbar-container .profile-menu .menu-list .menu-item:hover {
  background: rgba(0, 0, 0, 0.324);
  box-shadow: 0 1px 5px 0 #00000036;
}

.topbar-container .profile-menu .menu-list .menu-item.active {
  background: var(--second-green);
}

.topbar-container .profile-menu .menu-list .menu-item .icon-container {
  display: flex;
  position: relative;
}

.topbar-container .profile-menu .menu-list .menu-item .icon-container .badge {
  position: absolute;
  top: -0.1em;
  right: 0em;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #ff4500;
}

.topbar-container .profile-menu .menu-list .menu-item .icon {
  width: 25px;
}

@media (max-width: 1550px) {
  .topbar-container .topbar {
    padding: 1em 1em 1em 3.8em;
  }
}

@media (max-width: 1450px) {
  .topbar-container .topbar {
    padding: 1em 1em 1em 3em;
  }
}
@media (max-width: 1150px) {
  .topbar-container .topbar .logo-cont {
    max-width: 100px;
    margin-left: -30px;
  }
}

/* Hamburger Menu Additions */

.hamburger-menu {
  display: none;
  color: var(--main-white);
  cursor: pointer;
}

.menu-items {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu-items.show {
  display: flex;
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
  }
  .right-menu {
    z-index: 10;
  }

  .checkup-container {
    display: flex;
    margin-left: -4px;
    gap: 1.4em;
  }
  .ahcu-title {
    display: flex;
    font-size: 0.95em;
    font-weight: 500;
  }
  .topbar-container .topbar .logo-cont {
    margin-left: 0px;
  }

  .menu-items {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background: linear-gradient(to right, #011520 0%, #13364a 100%);
    width: 100vw;
    padding: 25px 0;
    place-items: start;
    font-size: medium;
    font-weight: 500;
    background-image: var(--main-gradient);
    opacity: 0.97;
  }

  .menu-items.show {
    display: flex;
    align-items: center;
    margin-top: -13px;
    background: linear-gradient(to right, #011520 0%, #13364a 100%);
  }

  .menu-items .bar-button {
    width: auto;
    text-align: left;
    padding: 25px;
    display: flex;
    min-width: 85vw;
    opacity: 1 !important;
    display: flex !important;
    justify-content: flex-start;
    gap: 1.5em;
  }

  .menu-items button {
    width: 85%;
    text-align: left;
    padding: 0;
    left: 15px;
  }

  .menu-items .bar-button .icon {
    scale: 1.5;
  }

  .topbar-container .profile-menu {
    position: relative;
    background: none;
    border: none;
    overflow: hidden;
    margin-top: 0;
  }

  .topbar-container .profile-menu-cont {
    width: 100%;
    text-align: -webkit-center;
    max-width: 85vw;
  }

  .topbar-container .profile-menu .menu-list .menu-item {
    width: 85vw;
  }

  .topbar-container .profile-menu .menu-list .menu-item.active {
    background: none;
  }

  .topbar-container .profile-menu .menu-list .menu-item:hover {
    background: none;
  }

  .topbar-container .topbar {
    padding: 1em 1em 1em 0.8em;
  }

  .topbar-container .topbar .logo-cont span {
    display: none;
  }

  .topbar-container .customer-title {
    align-items: center;
    gap: 0.7em;
    background: var(--main-gradient);
    font-size: 12px;
    width: 100vw;
    position: absolute;
    left: 0;
    padding: 0em 0em 0em 2em;
    top: 0;
    height: 50px;
  }

  .topbar-container .customer-title .back-arrow {
    color: var(--main-white);
    padding: 5px;
    font-size: 2em;
    border-radius: 5px;
    cursor: pointer;
  }

  .topbar-container .customer-title .back-arrow:hover {
    background: rgba(0, 0, 0, 0.183);
  }

  .topbar-container .customer-title h3 {
    margin: 0;
  }
}
