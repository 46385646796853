.actionButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  gap: 0.75rem;
}

.actionButton {
  min-height: 40px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actionButton:hover {
  background: var(--main-green-gradient-hover);
}

.applyButton {
  composes: actionButton;
  width: 100%;
  background: var(--second-green);
  color: #ffffff;
  border: 1px solid var(--second-green);

  &:hover {
    background: var(--second-green-hover);
  }
}

.savingButton,
.saveAndContinueButton,
.savedButton,
.applyToAllProductsButton {
  composes: actionButton;
  width: 50%;
  min-width: 150px;
}

.saveCheck {
  margin-left: 0.75rem;
  font-size: 1rem;
  color: var(--second-green);
}

.savedButton {
  opacity: 0.6;
  pointer-events: none;
  background: #f1f3f5;
  border: 1px solid var(--border-main);
  color: var(--text-black);
}

.applyToAllProductsButton {
  background: transparent;
  border: 1px solid var(--second-green);
  color: var(--second-green);

  &:hover {
    background: var(--second-green);
    color: #ffffff;
    border: 1px solid var(--second-green);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background: #f1f3f5;
    border-color: var(--border-main);
    color: var(--text-gray);
  }
}

@media (max-width: 768px) {
  .actionButtonsContainer {
    gap: 0.5rem;
    margin: 0.75rem auto;
  }

  .actionButton {
    min-height: 36px;
    font-size: 0.85rem;
  }

  .savingButton,
  .saveAndContinueButton,
  .savedButton,
  .applyToAllProductsButton {
    min-width: 120px;
  }
}
