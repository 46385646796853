.comparison-multiple-modal {
  padding: 1em;
  width: 80vw;
  min-height: 750px;
  margin: 0 auto;
}

.comparison-multiple-modal h1 {
  color: var(--main-green);
  margin: 0 0 0.1em;
  font-size: 1.5em;
}
