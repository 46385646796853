.info-badge-card {
  padding: 1em;
  border-radius: 5px;
  box-shadow: var(--card-shadow);
  width: min-content;
}

/* .info-badge-card.blue {
    background: blue;
} */

.info-badge-card .value {
  font-size: 1.8em;
  font-weight: 500;
  /* color: var(--text-blue) */
  color: var(--main-white);
}

.info-badge-card .name {
  /* color: var(--text-gray); */
  color: var(--main-white);
  white-space: nowrap;
}
