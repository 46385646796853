.filter-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
    border-bottom: 1px solid #ccc;
    padding: 0.5em 1em;
}

.filter-row:first-child {
    border-top: 1px solid #ccc;
}

.filter-row .filter-inner-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
    flex: 1 1 auto
}

.filters .remove-filter {
    cursor: pointer;
}

.filters .remove-filter:hover {
    color: var(--main-blue)
}

.filter-row .row p {
    font-size: 0.8em;
    margin: 0;
}

.filter-row .form-control {
    margin: 0
}

.filter-row .form-control label {
    font-size: 0.8em;
    margin-bottom: 0;
}

.filter-row .row input {
    background: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
    text-align: right;
}

.filter-row .select-cont {
    border: none;
    outline: none;
    border-radius: 0.5em;
    box-shadow: none;
    padding: 0;
    cursor: pointer;
}

.filter-row select {
    width: unset;
    margin: 0 auto;
    box-shadow: none;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    height: 2em;
}
