body:has(.start-screen) {
  #backdrop-hook .backdrop {
    background: url('../../assets/images/start-screen-bg.jpg');
    background-position: top center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    top: var(--topbar-height);
    z-index: 2;
  }

  #modal-hook .modal-overlay {
    background: transparent;
    top: var(--topbar-height);
    height: calc(100vh - var(--topbar-height));
    z-index: 3;

    .modal-container.START_SCREEN {
      border-radius: 24px;
      background: var(--main-white);
      box-shadow: 0px 2.172px 32.522px 0px rgba(0, 0, 0, 0.25);
      max-width: 950px;
      width: 90%;
    }

    .modal-container.START_SCREEN > div {
      padding: 0;
    }
  }

  @media (min-width: 1600px) {
    #backdrop-hook .backdrop {
      background-position: top center;
      background-size: 1550px auto;
    }
  }
}

.start-screen {
  display: flex;
  flex-direction: column;
  position: relative;
  .app-logos {
    position: absolute;
    top: -75px;
    right: -75px;
  }

  @media (min-width: 951px) {
    .app-logos {
      display: none;
    }

    .social-logos-animated {
      display: inline-block;
    }
  }

  @media (max-width: 950px) {
    .app-logos {
      display: inline-block;
    }

    .social-logos-animated {
      display: none;
    }
  }

  @media (max-height: 800px) {
    .social-logos-animated {
      transform: scale(0.8);
      top: -25px;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background: #dcdcdc;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 0.875em;
    max-width: 70%;
    padding: 3.75em 3.75em 2.5em 3.75em;

    h1,
    h2 {
      margin: 0;
    }

    .small-title {
      letter-spacing: 5.6px;
      font-size: 1em;
      font-weight: 500;
      background: linear-gradient(
        263deg,
        var(--second-green) 44.19%,
        var(--primary-green) 96.46%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .title {
      color: #0d2330;
      font-size: 2.125em;
      font-weight: 700;
      line-height: normal;

      .highlighted {
        background: linear-gradient(
          263deg,
          var(--second-green) 44.19%,
          var(--primary-green) 96.46%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media (max-width: 768px) {
        font-size: 1.6em;
      }
    }
    @media (max-width: 768px) {
      padding: 2em 2em 1em 2em;
    }
  }

  .content {
    padding: 1.875em 2.75em 3.75em 2.75em;
    display: flex;
    justify-content: space-between;
    gap: 0.9375em;

    .card {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      text-align: center;
      border-radius: 10px;
      box-shadow: 0px 4px 27px 0px var(--transparent-gray);
      color: #33383d;
      height: 265px;
      padding: 2em 1em 1em 1em;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow:
          rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
          rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

        transform: scale(1.03);
      }

      &:active {
        transform: scale(1);
      }

      .icon {
        flex: 1;
        width: 100px;
      }

      .title-box {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .title {
        margin: 0;
        width: 80%;
        font-size: 1.336875em;
        font-weight: 600;

        .highlighted {
          background: linear-gradient(
            263deg,
            #57ba8a 44.19%,
            #5aba87 47.58%,
            #74b751 89.69%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-transform: uppercase;
        }
        @media (max-width: 768px) {
          font-size: 1.2em;
        }
      }

      .helper-text {
        font-size: 0.8em;
        color: #f5f5f5;
      }

      &:hover .helper-text {
        display: inline-block;
      }
    }

    .card.colored {
      color: var(--main-white);
      background: linear-gradient(
        263deg,
        #57ba8a 44.19%,
        #5aba87 47.58%,
        #74b751 89.69%
      );

      &:hover {
        box-shadow:
          rgba(117, 183, 81, 0.458) 0px 10px 36px 0px,
          rgb(90, 186, 135) 0px 0px 0px 1px;
      }
    }
  }

  @media (max-width: 900px) {
    .app-logos {
      display: none;
    }

    .header {
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    .content {
      flex-direction: column;
      padding: 2em;
    }

    .content .card .icon {
      width: 50px;
    }
  }
}
