.filters {
  position: relative;
  z-index: 50;
}

.filters-box {
  position: absolute;
  top: calc(100% + 1em);
  left: -10vw;
  box-shadow: 0 1px 5px 0 #ccc;
  background: var(--main-white);
  border-radius: 5px;
  padding: 0;
  width: max-content;
}
.team-report .filters-box {
  width: auto;
}

.filters .row.head {
  align-items: center;
  justify-content: space-between;
  margin: 1em;
}

.filters h5 {
  margin: 0;
  font-size: 1.1em;
  color: var(--main-green);
}

.filters .apply-button {
  color: var(--main-green);
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;
}

.filters .apply-button:hover {
  color: var(--main-green-hover);
}

.filters .apply-button.red {
  color: rgb(150, 0, 0);
}

.filters .apply-button.red:hover {
  color: rgb(106, 0, 0);
}

.filters .add-filter-button {
  margin-top: 0.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3em 0 0.6em;
  font-size: 1em;
  gap: 0.5em;
  cursor: pointer;
}

.filters .add-filter-button:hover {
  color: var(--main-blue);
}

.filters .add-filter-button span {
  font-size: 0.8em;
}

.filters .row.small {
  flex-wrap: nowrap;
  align-items: center;
}

.filters .row .remove-button {
  cursor: pointer;
  width: 2em;
}

@media (max-width: 435px) {
  .filters-box {
    left: -35vw;
  }
}
