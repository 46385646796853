.campaign-setup-modal {
  width: 700px;
  border-radius: 16px;
  padding: 1.2em !important;
  background-color: var(--main-white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.campaign-setup-modal:has(.progress-wrapper) {
  min-height: 600px;
}

.campaign-setup-modal .modal-header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 24px;
  justify-content: space-between;
  flex-shrink: 0;
}

.campaign-setup-modal .modal-header h2 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--text-gray);
  margin: 0;
}

.campaign-setup-modal .close-button {
  background: transparent;
  border: none;
  color: #64748b;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  cursor: pointer;
}

.campaign-setup-modal .close-button:hover {
  background: #f1f5f9;
  color: #ef4444;
}

.campaign-setup-modal .modal-content {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.campaign-setup-modal .boost-campaign-wrapper,
.campaign-setup-modal .progress-wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

/* Animation styles */
.campaign-setup-modal .fade-enter {
  opacity: 0;
  transform: translateX(20px);
  position: absolute;
  width: 100%;
  height: 100%;
}

.campaign-setup-modal .fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.campaign-setup-modal .fade-exit {
  opacity: 1;
  transform: translateX(0);
  position: absolute;
  width: 100%;
  height: 100%;
}

.campaign-setup-modal .fade-exit-active {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media (max-width: 768px) {
  .modal-container.CAMPAIGN_SETUP {
    width: 90% !important;
  }

  .campaign-setup-modal {
    width: 100%;
    padding: 16px;
  }
  .campaign-setup-modal .modal-header h2 {
    font-size: 0.9em;
  }
}
