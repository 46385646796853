.description-card .input-row {
  width: 100%;
  margin: 0.4em 0;
}

.description-card .description-box p {
  font-size: 0.85em;
  margin: 0;
  padding: 0;
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
}