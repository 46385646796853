/* Card Header Start */
.campaign-detail .fluid-card-header {
  background: #fefefe;
  border-radius: 7px 7px 0 0;
  padding: 0.5em 1.5em;
  z-index: 1;
  color: #404040;
  box-shadow: 0 10px 8px -9px #ccc;
  max-width: 1500px;
}
/* Summary section animasyonu */
.campaign-summary-section {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  position: relative;
  background: white;
  z-index: 1;
}

.campaign-summary-section.expanded {
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}
.campaign-detail {
  position: relative;
  overflow: hidden;
}
.campaign-detail .fluid-card-header .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.campaign-detail .fluid-card-header .header-wrapper .options {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.campaign-detail .fluid-card-header .header-wrapper .options svg {
  flex-shrink: 0;
}

.campaign-detail .fluid-card-header .header-wrapper .options .icon {
  color: #bababa;
}

.campaign-detail .fluid-card-header .header-wrapper .options .menu-icon {
  display: flex;
  padding: 0.12em;
  font-size: 1.2em;
  cursor: pointer;
}

.campaign-detail .fluid-card-header .header-wrapper .actions {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.campaign-detail .fluid-card-header .header-wrapper .actions .button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.8rem;
  height: 34px;
  border-radius: 0.8em;
  font-size: var(--font-12);
  transition: all 0.2s ease;
  background: var(--main-white);
  color: var(--text-gray);
  border: 1px solid var(--border-main);
}

.campaign-detail .fluid-card-header .header-wrapper .actions .button:hover {
  background: var(--background-gray);
  border-color: var(--border-hover);
}

.campaign-detail .fluid-card-header .header-wrapper .actions .button.edit {
  color: var(--text-blue);
  border-color: var(--text-blue);
  background: white;
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .button.edit:hover {
  background: var(--text-blue);
  color: var(--main-white);
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .button.edit:disabled {
  color: var(--disabled-gray);
  border-color: var(--disabled-gray);
  background: var(--background-gray);
  cursor: not-allowed;
}

.campaign-detail .fluid-card-header .header-wrapper .actions .button.view {
  color: var(--main-green);
  border-color: var(--main-green);
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .button.view:hover {
  background: var(--main-green);
  color: var(--main-white);
}

.campaign-detail .fluid-card-header .header-wrapper .actions .button.danger {
  color: #ff6b6b;
  border-color: #ff6b6b;
  background: white;
  font-weight: 500;
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .button.danger:hover {
  background: #ff6b6b;
  color: var(--main-white);
  border-color: #ff6b6b;
}

.campaign-detail .fluid-card-header .header-wrapper .actions .button.publish {
  color: var(--main-green) !important;
  border-color: var(--main-green);
  background: white !important;
  font-weight: 500;
}
.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .button.publish:hover {
  color: white !important;
  border-color: var(--main-green);
  background: var(--main-green) !important;
  font-weight: 500;
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .button.publish:hover {
  background: var(--main-green);
  color: var(--main-white);
}

/* Cancel butonu - daha spesifik seçici */
.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  button.cancel-button {
  color: var(--text-blue) !important;
  background: var(--main-white) !important;
  border: 1px solid var(--text-blue) !important;
  font-weight: 500 !important;
  padding: 0.5rem 0.8rem !important;
  border-radius: 0.8em !important;
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  button.cancel-button:hover {
  background: var(--text-blue) !important;
  color: var(--main-white) !important;
  border-color: var(--text-blue) !important;
}

.campaign-detail .status-switch-wrapper {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  white-space: nowrap;
}

.campaign-detail .switch {
  background: var(--main-white);
  border-radius: 0.8em;
  border: 1px solid var(--border-main);
  height: 34px;
}

.campaign-detail .switch .selection {
  background: var(--main-green);
  border-radius: 4px;
}

.campaign-detail .content-wrapper {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transform: translateY(-20px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  z-index: 2;
}

.campaign-detail .content-wrapper.expanded {
  max-height: 100%;
  min-height: 490px;
  opacity: 1;
  transform: translateY(0);
}

.campaign-detail .toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  cursor: pointer;
  border-top: 1px solid #eee;
  background: #f5f5f5;
  transition: all 0.5s ease;
  border-radius: 0 0 7px 7px;
  position: relative;
  z-index: 3;
  max-width: 1500px;
}

.campaign-detail .toggle-button:hover {
  background: linear-gradient(to bottom, #e0e0e0, #f5f5f5);
}

.campaign-detail .toggle-button .icon-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

/* İkon animasyonu */
.campaign-detail .toggle-button svg {
  width: 1em;
  height: 1em;
  color: #666;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.campaign-detail .toggle-button.expanded svg {
  transform: rotate(180deg);
}

.campaign-detail .toggle-button .toggle-text {
  position: relative;
  height: 1.2em;
  min-width: 8em;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.campaign-detail .toggle-button .text-content {
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 0.9em;
  color: #666;
  font-weight: 500;
  user-select: none;
}
.campaign-header-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.campaign-detail .toggle-button .text-content.visible {
  opacity: 1;
  transform: translateY(0);
}

.campaign-detail .toggle-button .text-content:not(.visible) {
  opacity: 0;
  transform: translateY(-20px);
}
.campaign-detail .fluid-card-content {
  padding: 1em;
  width: 100% !important;
}

.campaign-detail .content-wrapper.expanded {
  box-shadow: none;
}
.campaign-detail .fluid-card-header .header-wrapper .actions .button:hover {
  background: #d2d2d2;
}

/* Campaign status stili */
.campaign-detail .fluid-card-header .header-wrapper .actions .campaign-status {
  font-size: var(--font-12);
  color: var(--text-gray);
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .campaign-status
  .status {
  font-weight: 600;
}

.campaign-detail .fluid-card-header .header-wrapper .actions .switch {
  background-color: rgb(238, 238, 238);
  box-shadow: unset;
  border-radius: 0.8em;
  font-size: 13px;
  /* height: 1.75em; */
  min-width: 120px;
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .switch.right
  .left-side,
.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .switch.left
  .right-side {
  color: #8c9092;
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .switch.right
  .right-side,
.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .switch.left
  .left-side {
  /* text-transform: uppercase; */
  font-weight: 600;
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .switch.left
  .left-side {
  color: #6c6c6c;
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .switch.left
  .left-side
  ~ .selection {
  background: #8c9092;
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .switch
  .selection {
  background: var(--second-green);
  width: 50%;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 0.8em;
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .actions
  .switch.right
  .selection {
  left: 50%;
}

.campaign-detail .fluid-card-content {
  padding: 0;
  max-width: 1500px;
}

.campaign-detail .fluid-card-content .campaign-content-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  gap: 2em;
  width: 100%;
}

.campaign-detail .fluid-card-content .campaign-content-wrapper .left-side {
  flex: 1 1 50%;
  max-width: 600px;
}

.campaign-detail .fluid-card-content .campaign-content-wrapper .details {
  display: flex;
  flex-direction: column;
  gap: 1.35em;
  flex: 1;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .details-toggler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  border-bottom: 1px solid rgb(218 218 218);
  color: #969696;
  font-size: 0.9em;
  cursor: pointer;
  max-width: 500px;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .details-toggler:hover {
  border-bottom: 1px solid rgb(191, 191, 191);
  color: #727272;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .details-toggler
  .icon {
  transition: 0.3s;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .details-toggler
  .icon.rotate {
  transform: rotateZ(180deg);
}

.campaign-detail .fluid-card-content .campaign-content-wrapper .divider {
  width: 100%;
  height: 3px;
  background: #eaeaea;
  margin: 1.75em 0;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .campaign-image
  img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
}

.campaign-detail .fluid-card-content .campaign-content-wrapper .details .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75em;
  color: #757878;
  min-width: 300px;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .adcampaign-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  max-width: 80%;
  margin: auto;
  text-align: center;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .adcampaign-error
  .adcampaign-error-title {
  font-weight: 600;
  font-size: 1.2em;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .adcampaign-error
  .adcampaign-error-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .adcampaign-error
  .adcampaign-error-description
  .adcampaign-error-subtitle {
  font-weight: 500;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .adcampaign-error
  .adcampaign-error-description
  .adcampaign-error-message {
  font-size: 0.85em;
  line-height: 1.5;
}

.campaign-detail .fluid-card-content .campaign-content-wrapper .errors {
  max-width: 500px;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .errors
  .campaign-error {
  border: 1px solid #dedede;
  background: #ffebe8;
  border-radius: 10px;
  padding: 0.5em;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .errors
  .campaign-error:not(:last-child) {
  margin-bottom: 0.75em;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .errors
  .campaign-error
  .campaign-error-title {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .errors
  .campaign-error
  .campaign-error-title
  .campaign-label {
  font-weight: 600;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .errors
  .campaign-error
  .campaign-error-title
  .error-message {
  font-size: 0.85em;
  font-weight: 400;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .errors
  .campaign-error
  .details-content {
  border-top: 1px solid #00000020;
  margin-top: 0.5em;
  font-size: 13px;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .errors
  .campaign-error
  .error-user-title {
  display: block;
  font-weight: 500;
  margin-top: 0.5em;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper
  .errors
  .campaign-error
  .error-user-msg {
  font-size: 0.85em;
  color: #5b5b5b;
  user-select: text;
}

@media (max-width: 992px) {
  .campaign-detail .fluid-card-content .campaign-content-wrapper .details .row {
    max-width: unset;
  }
}

/* Edit modunda preview'ı gizle */
.campaign-detail .fluid-card-content .campaign-content-wrapper.editing {
  display: flex;
  width: 100% !important;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper.editing
  .left-side {
  flex: 1;
  max-width: 800px;
  width: 100% !important;
}

.campaign-detail .editing > .adcr {
  display: none;
}

.campaign-detail .editing .previews-wrapper {
  margin-top: 24px;
}

.campaign-detail .editing .previews-wrapper .adcr {
  max-width: 500px;
  margin: 0 auto;
}

.campaign-creatives-section {
  margin-top: 1em;
}

.campaign-detail
  .editing
  .campaign-content
  .campaign-form[data-form-type='targeting'] {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px !important;
  background: #f9f9f9;
  border-radius: 16px;
  width: 100% !important;
  min-width: 100% !important;
}

.campaign-detail
  .editing
  .campaign-content
  .campaign-form[data-form-type='targeting']
  .row {
  max-width: 400px !important;
  width: 100% !important;
  margin: 0 !important;
}

.campaign-detail .editing .campaign-content .left-side > * {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.4s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.campaign-detail .editing > .previews-wrapper,
.campaign-detail .editing > .adcr {
  display: none !important;
}

.campaign-detail .editing .campaign-content-wrapper .previews-wrapper {
  display: block;
  margin-top: 24px;
}

.campaign-detail .editing .campaign-content-wrapper .previews-wrapper .adcr {
  max-width: 500px;
  margin: 0 auto;
}

.campaign-detail .fluid-card-content .campaign-content-wrapper.editing {
  display: flex;
  flex-direction: column;
}

.campaign-detail
  .fluid-card-content
  .campaign-content-wrapper.editing
  .left-side {
  flex: 1;
  max-width: 100%;
}

@media (max-width: 1024px) {
  .campaign-detail .fluid-card-header .header-wrapper .actions {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .campaign-detail .status-switch-wrapper {
    flex: 1;
    justify-content: flex-start;
  }
}
@media (max-width: 1024px) {
  .campaign-detail .fluid-card-header .header-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
@media (max-width: 768px) {
  .campaign-detail .fluid-card-header .header-wrapper .actions {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 0.5rem;
  }

  .campaign-detail
    .fluid-card-header
    .header-wrapper
    .actions
    .status-switch-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    grid-column: 1 / -1;
    justify-content: flex-start;
  }
}

@media (max-width: 480px) {
  .campaign-detail .fluid-card-header .header-wrapper .actions {
    grid-template-columns: 1fr;
  }

  .campaign-detail .fluid-card-header .header-wrapper .actions .button,
  .campaign-detail
    .fluid-card-header
    .header-wrapper
    .actions
    button.cancel-button {
    justify-content: center;
  }

  .campaign-detail .status-switch-wrapper {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}

.campaign-detail .fluid-card-header .header-wrapper .options {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.campaign-header-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.campaign-label {
  font-size: var(--font-14);
  font-weight: 500;
  color: var(--text-black);
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.campaign-label-date {
  font-size: var(--font-12);
  color: var(--text-gray);
}

.campaign-detail
  .fluid-card-header
  .header-wrapper
  .options
  .campaign-label-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.8rem;
  background: var(--background-gray);
  border-radius: 0.8em;
}

@media (max-width: 768px) {
  .campaign-label {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .campaign-label {
    max-width: 200px;
  }
}
