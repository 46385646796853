.ad-creative-library-box {
    
}

.ad-creative-library-box .all-set {
    display: flex;
    align-items: center;
    gap: .5em;
}

.ad-creative-library-box .all-set .icon {
    color: var(--main-green);
    font-size: 2em;
}