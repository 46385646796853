.basic-user .ai-ads .ai-ads-home-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'summary'
    'history'
    'comparison';
  gap: 2em;
  padding: 2em 1em 0em 0em;
}

.ai-ads .ai-ads-home-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'summary'
    'history'
    'comparison';
  gap: 2em;
  padding: 0 1em;
  max-width: 100%;
}

.ai-ads .ai-ads-home-grid .summary {
  grid-area: summary;
}

.ai-ads .ai-ads-home-grid .history {
  grid-area: history;
}

.ai-ads .ai-ads-home-grid .comparison {
  grid-area: comparison;
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 1400px) {
  .ai-ads .ai-ads-home-grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      'summary'
      'history'
      'comparison';
    width: 100%;
    padding: 0 1em;
  }

  .ai-ads .ai-ads-home-grid .comparison {
    width: 100%;
    overflow-x: hidden;
  }
}

@media (max-width: 768px) {
  .basic-user .ai-ads .ai-ads-home-grid {
    padding: 2em;
  }
}
