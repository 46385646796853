.container {
  display: flex;
  flex-direction: column;
  color: #33383d;
}

.catalogTopLogo {
  display: flex;
  align-items: center;
  width: 100%;
  pointer-events: none;
  padding-left: 2em;
}

.catalogTopLogoText {
  font-weight: bold;
  margin-right: 10px;
  background: linear-gradient(to right, #13364a 0%, #58ba8b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.catalogTopLogoDeviceIcon {
  font-size: 24px;
  color: #58ba8b;
}

.topbar {
  font-size: 1.75em;
  font-weight: 500;
  height: 2.857142857142857em;
  padding: 0.892857142857143em 1.428571428571429em;
  z-index: 2;
  background: #fff;
}

.topbarLine {
  content: '';
  display: block;
  width: 100%;
  height: 0.0625em;
  background-color: #cacaca;
}

.content {
  display: flex;
  background: #f9f9f9;
  height: calc(
    100vh - var(--breadcrumb-height) - 1px
  ); /* 1px is the border bottom of the breadcrumb */
  overflow: hidden;
}

.welcomingArea {
  padding: 1.5625em 2.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.875em;
  overflow-y: auto;
  height: 100%;
}

.welcomingAreaActions {
  display: flex;
  gap: 1.875em;
}

.row {
  margin-bottom: 1.25em;
}

.textsContainer {
  margin-bottom: 1.25em;
}

.textsContainer p {
  margin: 0;
}

.companyName {
  font-size: 1.5em;
  line-height: 1;
}

.greeting {
  font-size: 1.5em;
  font-weight: 500;
}

.previousCreativesContainer {
  display: grid;
  gap: 1.875em;
  grid-template-columns: repeat(auto-fill, minmax(18.75em, 1fr));
}

.secondaryTitle {
  font-size: 1.25em;
  font-weight: 500;
}

.backButton {
  display: flex;
  align-items: center;
  color: var(--second-green);
  background: transparent;
  border: 1px solid var(--second-green);
  font-size: 0.875em;
  font-weight: 500;
}

.backButton:hover {
  background: var(--second-green);
  border: 1px solid var(--second-green);
  color: #fff;
}

@media (max-width: 1200px) {
  .topbar {
    font-size: 1.5em;
  }

  .companyName {
    font-size: 1.25em;
  }

  .greeting {
    font-size: 1.25em;
  }

  .secondaryTitle {
    font-size: 1.2em;
  }
}

@media (max-width: 768px) {
  .welcomingAreaActions {
    flex-direction: column;
  }
}
