.initial-industry-container .logo-cont {
  height: 100%;
  max-width: calc(var(--topbar-height) * 2.5);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initial-industry-container .logo-cont span {
  color: var(--main-white);
  font-size: 0.85em;
  float: right;
  margin-top: -0.3em;
}

.initial-industry-container .logo-cont img {
  width: 100%;
}
