.datepicker-header {
    display: flex;
    justify-content: space-evenly;
    margin: 0;
}

.datepicker-header .form-control {
    background: lightgrey;
    border-radius: 5px;
    padding: 0 5px;
    margin: 0
}
.date-picker-cont .react-datepicker-popper{
    z-index: 2;
}

.date-picker-cont .react-datepicker__input-container input {
    max-width: 8em;
}

.date-picker-cont label {
    display: none;
}

.date-picker-cont .react-datepicker .select-cont {
    box-shadow: none;
}

.date-picker-cont .react-datepicker select {
    background: none !important;
    color: black;
    border: none;
    outline: none;
    border-radius: 0.5em;
    box-shadow: none;
    padding: 0;
    cursor: pointer;
    width: 100%;
    max-height: 2em;
}