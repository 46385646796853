.konvaArea {
  flex: 1 1 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 12px;
  border: 1px solid var(--border-main);
  background: #ffffff;

  @media (max-width: 1024px) {
    flex: 1 1 75%;
  }
}
