@media (max-width: 1550px) {
  .App main {
    padding: 1em;
  }

  :root {
    --topbar-height: 60px;
  }
}

@media (max-width: 1150px) {
  .App.has-navbar main {
    margin-left: var(--collapsed-navbar-width);
  }
}

main.basic-user {
  padding: 20px;
}
@media screen and (max-width: 1550px) {
  .App.full-screen-noscroll-padding main {
    padding: 2em 0em 0em 1em;
    overflow: unset;
  }
}

@media (max-width: 1400px) {
  :root {
    --enh-catalog-navbar-width: 310px;
  }
}

@media (max-width: 1300px) {
  :root {
    --enh-catalog-navbar-width: 300px;
  }
}

@media (max-width: 1300px) {
  :root {
    --enh-catalog-navbar-width: 280px;
  }
}

@media (max-width: 1100px) {
  :root {
  }
}

@media (max-width: 768px) {
  .App {
    position: relative;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    min-height: -webkit-fill-available;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .App main {
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(
      var(--mobile-nav-height) + env(safe-area-inset-bottom, 0px)
    );
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2em 1em 1em 1em;
  }

  .main-cont {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px)
      env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px);
  }

  .ai-ads-breadcrumb-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: env(safe-area-inset-top, 0px);
    z-index: 100;
    background: white;
    width: 100%;
    margin: 0;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }

  @supports (-webkit-touch-callout: none) {
    .App {
      min-height: -webkit-fill-available;
    }

    .App main {
      height: -webkit-fill-available;
    }

    .main-cont {
      min-height: -webkit-fill-available;
    }
  }

  :root {
    --topbar-height: 50px;
  }
}
@media (max-width: 1600px) {
  :root {
    --font-32: 32px;
    --font-30: 30px;
    --font-28: 28px;
    --font-26: 26px;
    --font-24: 24px;
    --font-22: 22px;
    --font-20: 20px;
    --font-18: 18px;
    --font-16: 16px;
    --font-14: 14px;
    --font-12: 12px;
    --font-10: 10px;
    --font-8: 8px;
    --navbar-height: 70px;
  }
}
@media (max-width: 1400px) {
  :root {
    --font-32: calc(32px * 0.95);
    --font-30: calc(30px * 0.95);
    --font-28: calc(28px * 0.95);
    --font-26: calc(26px * 0.95);
    --font-24: calc(24px * 0.95);
    --font-22: calc(22px * 0.95);
    --font-20: calc(20px * 0.95);
    --font-18: calc(18px * 0.95);
    --font-16: calc(16px * 0.95);
    --font-14: calc(14px * 0.95);
    --font-12: calc(12px * 0.95);
    --font-10: calc(10px * 0.95);
    --font-8: calc(8px * 0.95);
  }
}
@media (max-width: 1200px) {
  :root {
    --font-32: calc(32px * 0.9);
    --font-30: calc(30px * 0.9);
    --font-28: calc(28px * 0.9);
    --font-26: calc(26px * 0.9);
    --font-24: calc(24px * 0.9);
    --font-22: calc(22px * 0.9);
    --font-20: calc(20px * 0.9);
    --font-18: calc(18px * 0.9);
    --font-16: calc(16px * 0.9);
    --font-14: calc(14px * 0.9);
    --font-12: calc(12px * 0.9);
    --font-10: calc(10px * 0.9);
    --font-8: calc(8px * 0.9);
  }
}
@media (max-width: 1000px) {
  :root {
    --font-32: calc(32px * 0.85);
    --font-30: calc(30px * 0.85);
    --font-28: calc(28px * 0.85);
    --font-26: calc(26px * 0.85);
    --font-24: calc(24px * 0.85);
    --font-22: calc(22px * 0.85);
    --font-20: calc(20px * 0.85);
    --font-18: calc(18px * 0.85);
    --font-16: calc(16px * 0.85);
    --font-14: calc(14px * 0.85);
    --font-12: calc(12px * 0.85);
    --font-10: calc(10px * 0.85);
    --font-8: calc(8px * 0.85);
  }
}
@media (max-width: 768px) {
  :root {
    --font-32: calc(32px * 0.8);
    --font-30: calc(30px * 0.8);
    --font-28: calc(28px * 0.8);
    --font-26: calc(26px * 0.8);
    --font-24: calc(24px * 0.8);
    --font-22: calc(22px * 0.8);
    --font-20: calc(20px * 0.8);
    --font-18: calc(18px * 0.8);
    --font-16: calc(16px * 0.8);
    --font-14: calc(14px * 0.8);
    --font-12: calc(12px * 0.8);
    --font-10: calc(10px * 0.8);
    --font-8: calc(8px * 0.8);
  }
}
@media (max-width: 480px) {
  :root {
    --font-32: calc(32px * 0.7);
    --font-30: calc(30px * 0.7);
    --font-28: calc(28px * 0.7);
    --font-26: calc(26px * 0.7);
    --font-24: calc(24px * 0.7);
    --font-22: calc(22px * 0.7);
    --font-20: calc(20px * 0.7);
    --font-18: calc(18px * 0.7);
    --font-16: calc(16px * 0.7);
    --font-14: calc(14px * 0.7);
    --font-12: calc(12px * 0.7);
    --font-10: calc(10px * 0.7);
  }
}

@media (max-height: 1200px) {
  :root {
    --font-32: calc(32px * 0.95);
    --font-30: calc(30px * 0.95);
    --font-28: calc(28px * 0.95);
    --font-26: calc(26px * 0.95);
    --font-24: calc(24px * 0.95);
    --font-22: calc(22px * 0.95);
    --font-20: calc(20px * 0.95);
    --font-18: calc(18px * 0.95);
    --font-16: calc(16px * 0.95);
    --font-14: calc(14px * 0.95);
    --font-12: calc(12px * 0.95);
    --font-10: calc(10px * 0.95);
  }
}

@media (max-height: 900px) {
  :root {
    --font-32: calc(32px * 0.9);
    --font-30: calc(30px * 0.9);
    --font-28: calc(28px * 0.9);
    --font-26: calc(26px * 0.9);
    --font-24: calc(24px * 0.9);
    --font-22: calc(22px * 0.9);
    --font-20: calc(20px * 0.9);
    --font-18: calc(18px * 0.9);
    --font-16: calc(16px * 0.9);
    --font-14: calc(14px * 0.9);
    --font-12: calc(12px * 0.9);
    --font-10: calc(10px * 0.9);
  }
}

@media (max-height: 700px) {
  :root {
    --font-32: calc(32px * 0.85);
    --font-30: calc(30px * 0.85);
    --font-28: calc(28px * 0.85);
    --font-26: calc(26px * 0.85);
    --font-24: calc(24px * 0.85);
    --font-22: calc(22px * 0.85);
    --font-20: calc(20px * 0.85);
    --font-18: calc(18px * 0.85);
    --font-16: calc(16px * 0.85);
    --font-14: calc(14px * 0.85);
    --font-12: calc(12px * 0.85);
    --font-10: calc(10px * 0.85);
  }
}

@media (max-width: 1200px) and (max-height: 900px) {
  :root {
    --font-32: calc(32px * 0.85);
    --font-30: calc(30px * 0.85);
    --font-28: calc(28px * 0.85);
    --font-26: calc(26px * 0.85);
    --font-24: calc(24px * 0.85);
    --font-22: calc(22px * 0.85);
    --font-20: calc(20px * 0.85);
    --font-18: calc(18px * 0.85);
    --font-16: calc(16px * 0.85);
    --font-14: calc(14px * 0.85);
    --font-12: calc(12px * 0.85);
    --font-10: calc(10px * 0.85);
    --font-8: calc(8px * 0.85);
  }
}

@media (max-width: 768px) and (max-height: 700px) {
  :root {
    --font-32: calc(32px * 0.75);
    --font-30: calc(30px * 0.75);
    --font-28: calc(28px * 0.75);
    --font-26: calc(26px * 0.75);
    --font-24: calc(24px * 0.75);
    --font-22: calc(22px * 0.75);
    --font-20: calc(20px * 0.75);
    --font-18: calc(18px * 0.75);
    --font-16: calc(16px * 0.75);
    --font-14: calc(14px * 0.75);
    --font-12: calc(12px * 0.75);
    --font-10: calc(10px * 0.75);
  }
}
