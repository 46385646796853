.integration-card .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3.5em;
  padding: 0.4em 0;
}

.integration-card .content-group {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.integration-card .content-group .content-group-title {
  display: flex;
  align-items: center;
  gap: 0.55em;
}

.integration-card .content-group .content-group-title h3 {
  margin: 0;
  font-weight: 500;
  font-size: 0.9em;
  color: #000;
}

.integration-card .divider {
  width: 100%;
  height: 1px;
  background-color: #000;
}

.integration-card .content-group .lines {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.integration-card .content-group .lines.inputs {
  gap: 0.75em;
}

.integration-card .line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.integration-card .line span {
  max-width: unset;
  display: flex;
  align-items: center;
  color: #454545;
  font-size: 0.8em;
}

.integration-card .line span.link {
  color: #1a9ddd;
  text-decoration-line: underline;
  text-decoration-color: #1a9ddd;
  cursor: pointer;
  transition: color 75ms;
}

.integration-card .line span.link:hover {
  color: #0b649f;
}

.integration-card span.italic-text {
  font-style: italic;
}

.integration-card .segment-selection-percentage {
  display: flex;
  align-items: center;
  gap: 1em;
}

.integration-card .line .status-icon {
  margin-left: 0.5em;
}

.integration-card .line .switch {
  height: 1.8em;
  font-size: 0.9em;
}

/* Resets Start */
.integration-card .line .form-control {
  display: flex;
  margin: 0;
  height: 1.8em;
}

.integration-card .line .form-control input {
  box-shadow: unset;
  height: 100%;
}

.integration-card .line .form-control label {
  display: none;
}

.integration-card .line input {
  flex: 1;
  height: 100%;
  max-width: 13em;
  border-radius: 5px;
  border: 0;
  background: var(--transparent-gray);
  font-size: 0.75em;
}
/* Resets End */
