.pricing-compare-main {
    max-width: var(--content-box-width);
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-top: 6em;
  }
  .pricing-compare-main .small-text{
    color: var(--text-main);
    font-size: 0.9em;
    font-weight: 600;
  }
  
  .pricing-compare-main .header-title {
    font-size: 1.6em;
    font-weight: 600;
    background: var(--main-green-gradient);
    -webkit-background-clip: text;
    color: transparent;
  }
  .pricing-compare-main .chevron-icon {
    font-size: 1.3em;
    color: #bbbcbc;
   margin-top: 0.5em;
  }
  
  .pricing-compare-main .header-subtitle {
    font-size: 0.9em;
    color: #66707a;
    /* transform: translateY(-1.8em); */
    font-weight: 600;
  }
  
  .pricing-compare-main .pricing-table {
    width: 100%;
    border-collapse: collapse; /* Ensures no column borders */
    border-spacing: 0;
    max-width: 100%;
  }
  
  .pricing-compare-main .pricing-table th,
  .pricing-compare-main .pricing-table td {
    padding: 20px;
    text-align: center;
    font-weight: 400;
    font-size: var(--font-16);
    vertical-align: middle;
    color: #333;
    border: none; 
    border-bottom: 1px solid #e6e6e6; 
  }
  .pricing-compare-main .pricing-table tr {
    border: none;
    border-bottom: 1px solid #e6e6e6;
  }

  .pricing-compare-main .pricing-table tr:last-child{
    border-bottom: none;
  }
  .pricing-compare-main .pricing-table tr th {
    padding: 30px 30px 15px 30px;
  }
  .pricing-compare-main .pricing-table th:first-child {
    text-align: left;
  }
  
  .pricing-compare-main .pricing-table td:first-child {
    text-align: left;
  }
  
  .pricing-compare-main .pricing-table th {
    background-color: rgba(255, 255, 255, 0);
    font-weight: bold;
    color: #555555;
    padding: 30px;
    border-bottom: 1px solid #e6e6e6; /* Light gray line under the header */
  }
  
  .pricing-compare-main .pricing-table tr:last-child th,
  .pricing-compare-main .pricing-table tr:last-child td {
    border-bottom: none; /* Remove border from the last row */
  }
  
  /* Responsive Design */
  @media (max-width: 900px) {
    .pricing-compare-main .cross-icon {
      width: 10px;
      height: 10px;
    }
    .pricing-compare-main .check-icon {
      width: 16px;
      height: 16px;
    }
    .pricing-compare-main .pricing-table th,
    .pricing-compare-main .pricing-table td {
      padding: 15px;
    }
    .pricing-compare-main .pricing-table tr th {
      padding: 15px 15px 7px 15px;
    }
  }
  @media (max-width: 768px) {
    .pricing-compare-main {
      padding: 20px;
    }
  
    .pricing-compare-main h2 {
      font-size: 24px;
    }
  
    .pricing-compare-main p {
      font-size: 14px;
    }
  
    .pricing-compare-main .pricing-table th,
    .pricing-compare-main .pricing-table td {
      padding: 10px;
      font-size: 12px;
    }
  }
  @media (max-width: 676px) {
    .pricing-compare-main {
      padding: 5px;
    }
    .pricing-compare-main .pricing-table th,
    .pricing-compare-main .pricing-table td {
      padding: 10px 0px;
      font-size: 12px;
    }
    .pricing-compare-main .pricing-table th,
    .pricing-compare-main .pricing-table td {
      padding: 7px;
    }
    .pricing-compare-main .pricing-table tr th {
      padding: 7px 7px 3px 7px;
    }
  }
  @media (max-width: 550px) {
    .pricing-compare-main {
      padding: 0px;
    }
    .pricing-compare-main .pricing-table th,
    .pricing-compare-main .pricing-table td {
      padding: 10px 0px;
      font-size: 11px;
    }
    .pricing-compare-main .pricing-table th,
    .pricing-compare-main .pricing-table td {
      padding: 8px 2px;
    }
    .pricing-compare-main .pricing-table tr th {
      padding: 8px 8px 2px 8px;
    }
    .pricing-compare-main .cross-icon {
      width: 7px;
      height: 7px;
    }
    .pricing-compare-main .check-icon {
      width: 11px;
      height: 11px;
    }
  }
  .pricing-compare-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:0.em;
    cursor: pointer;
    padding: 2em;
    margin: 2em 0;
    border-radius: 16px;
    transition: all 0.3s ease;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    /* box-shadow: 5px 5px 10px #e6e6e6,
               -5px -5px 10px #ffffff; */
    border: 1px solid #e6e6e6;
  }

  .pricing-compare-cont:hover {
    transform: translateY(-3px);
    /* box-shadow: 7px 7px 15px #e6e6e6,
               -7px -7px 15px #ffffff; */
  }

  .pricing-compare-cont.collapsed .chevron-icon {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }

  .pricing-compare-cont.show-more .chevron-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }

  .pricing-compare-cont .header-title {
    font-size: 1.8em;
    font-weight: 700;
    background: var(--main-green-gradient);
    -webkit-background-clip: text;
    color: transparent;
    margin-bottom: 0.3em;
  }

  .pricing-compare-cont .header-subtitle {
    font-size: 1em;
    color: #66707a;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  .pricing-compare-cont .chevron-icon {
    font-size: 1.2em;
    color: #66707a;
    margin-top: 0.5em;
  }
  .pricing-table-cont{
    max-height: 0;
    opacity: 0;
    transition: all 0.5s ease;
    overflow: hidden;
    visibility: hidden;
    margin-top: 1em;
  }

  .pricing-table-cont.show-more{
    max-height: 2000px;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease;
  }


  /* Responsive tasarım için eklemeler */
  @media (max-width: 768px) {
    .pricing-compare-cont {
      padding: 1.5em;
      margin: 1em 0;
    }

    .pricing-compare-cont .header-title {
      font-size: 1.4em;
    }

    .pricing-compare-cont .header-subtitle {
      font-size: 0.9em;
    }
  }

  @media (max-width: 550px) {
    .pricing-compare-cont {
      padding: 1em;
    }

    .pricing-compare-cont .header-title {
      font-size: 1.2em;
    }

    .pricing-compare-cont .header-subtitle {
      font-size: 0.8em;
    }
  }
  