.connection-required {
  max-width: 80ch;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.connection-required .title {
  margin: 0;
  color: var(--text-black);
  text-wrap: nowrap;
  font-size: 1.3em
}

.connection-required .title .page-name {
  color: var(--main-green-hover);
  font-weight: 900;
}

.connection-required p {
  margin: 0.5em 0;
  color: var(--text-black);
  font-size: 1.1em;
}

.connection-required .description span.only-a-min-text {
  font-weight: 600;
}

.connection-required .manual-redirection {
  font-size: 0.95em;
}

.connection-required .manual-redirection span.click-here-text {
  color: var(--text-blue);
  cursor: pointer;
  transition: all 100ms;
}

.connection-required .manual-redirection span.click-here-text:hover {
  color: var(--text-blue-hover);
}

.modal-container .connection-required .action-bar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: .7em
}

@media (max-width: 767px) {
  .connection-required .title {
    text-wrap: unset;
  }
}
