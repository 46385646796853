.pricing-page {
  .pricing-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    max-width: 1650px;
    margin: 0 auto;
  }
  .pricing-page-back-button {
    height: 3em;
    font-size: 1em;
    padding: 0 1em;
    font-weight: 600;
    background: linear-gradient(90deg, var(--text-green), #5fb97d, #57ba8a);
    -webkit-background-clip: text;
    color: transparent;
    border: 2px solid var(--main-green);
    border-radius: 0.5em;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 2px;
  }
  .pricing-page-header {
    padding: 2em;
    align-items: normal;
  }
  .button-container {
    display: flex;
    gap: 1em;
  }
  .pricing-page-home-button {
    height: 3em;
    font-size: 1em;
    padding: 0 1em;
    font-weight: 600;
    background: var(--main-green-gradient);
    color: var(--main-white);
    transition: 0.3s ease-in;
    border: 2px solid var(--main-white);
    border-radius: 0.5em;
  }
  .pricing-page-home-button:hover {
    background: var(--main-white);
    color: var(--main-green);
    border: 2px solid var(--main-green);
  }
  .pricing-page-back-button:hover {
    background: var(--main-green-gradient);
    color: white;
  }
  @media (max-width: 1000px) {
    .pricing-page-header {
      padding: 1em;
    }
    .button-container {
      font-size: 0.8em;
    }
  }
  @media (max-width: 650px) {
    .pricing-page-header {
      flex-direction: column;
      align-items: center;
      gap: 3em;
    }
    .button-container {
      margin-bottom: 2em;
    }
  }
  @media (min-width: 1165px) and (max-width: 1500px) {
    .main-pricing-cards-box .small-row {
      justify-content: space-evenly;
      gap: 2.5em;
    }
    .main-pricing-cards-box {
      gap: 0em;
    }
  }
  @media (min-width: 1100px) and (max-width: 1280px) {
    .main-pricing-cards-box .small-row {
      gap: 1.5em;
    }
  }
}
