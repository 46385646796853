.demo-meeting-card {
  font-size: 14px;
  width: 100%;
  border-radius: 10px;
  background: var(--main-white);
  border: 2px solid #e5e5e5;
  overflow: hidden;
  max-height: min-content;

  p {
    margin: 0;
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--main-white);
    background: rgba(103, 103, 103, 1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
    padding: 0.7em 1em;
    font-size: 0.92em;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    padding: 0.7em 1em;
  }

  .card-header {
    gap: 0.5em;
  }

  .card-header .section {
    display: flex;
    align-items: center;
    gap: 0.75em;
  }

  .card-header .section .username {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .card-content .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    position: relative;
    padding: 0.8em 0em;
  }

  .card-content .section:not(:last-child)::after {
    content: '';
    left: 0;
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #f5f5f5;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15) inset;
    z-index: 3;
  }

  .section.meeting-datetime {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5em;
  }

  .section.meeting-datetime .meeting-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .section.meeting-datetime .meeting-created-at {
    color: #8e8e8e;
  }

  .section.meeting-datetime .meeting-date .date {
    font-size: 1.4em;
    font-weight: 600;
    cursor: pointer;
  }
  .section.meeting-datetime .meeting-date .date:hover {
    background: #ededed;
  }

  .section.meeting-datetime .meeting-date .date.overdue {
    font-size: 1.4em;
    font-weight: 600;
    color: #f73c3c;
  }

  .section.customer-info {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: flex-start;
  }

  .section.customer-info .customer-note {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .section.customer-info .customer-note:hover {
    background-color: whitesmoke;
  }

  .section.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .actionbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    color: rgb(96, 96, 96);
    border: solid 1.5px rgb(96, 96, 96);
    gap: 0.2em;
    padding: 8px 0px;
    width: 95px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
  }

  .actionbutton:hover {
    background: #e7e7e7;
  }

  .add-note {
    max-width: 100%;
  }

  .add-note .actionbutton {
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
    justify-content: start;
    padding: 8px;
    width: 100%;
  }

  .add-note .note-box {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .section.actions .buttons .actionbutton.destructive {
    background: #f73c3c;
    color: white;
    border: none;
  }

  .section.actions .buttons .actionbutton.destructive:hover {
    background: #d23333;
  }

  .section.actions .buttons .actionbutton.constructive {
    background: #4caf50;
    color: var(--main-white);
    border: none;
  }

  .section.actions .buttons .actionbutton.constructive:hover {
    background: #408741;
  }

  .section.contact-info .phone,
  .section.contact-info .email {
    display: flex;
    align-items: center;
    color: #444849;
    font-weight: 300;
    font-size: 1em;
  }

  .section.contact-info .phone .helper-text,
  .section.contact-info .email .helper-text {
    font-size: 0.9em;
    color: rgb(81, 81, 81);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .section.contact-info .phone .clock-icon {
    width: 1.1em;
    transform: translateY(2px);
  }

  .section.contact-info .phone:not(.not-available) {
    cursor: pointer;
  }

  .section.contact-info .phone:not(.not-available):hover,
  .section.contact-info .email:not(.not-available):hover {
    color: #303233;
    font-weight: 400;
    text-decoration: none;
  }

  .section.contact-info .phone .icon {
    display: none;
    margin-left: 5px;
  }

  .section.contact-info .email .icon {
    display: none;
    margin-right: 5px;
  }

  .section.contact-info .phone:not(.not-available):hover .icon,
  .section.contact-info .email:not(.not-available):hover .icon {
    display: inline-block;
  }

  .card-content .status {
    cursor: pointer;
    white-space: nowrap;
    font-size: 1.1em;
    font-weight: 600;
    color: #676767;
  }
  .card-content .status:hover {
    background: #ededed;
  }

  .card-content .status.lost {
    color: #f73c3c;
  }

  .card-content .status.won {
    color: #4caf50;
  }

  .card-content .status.meeting-done {
    color: rgb(3, 155, 161);
  }

  .card-content .status.low-budget {
    color: rgb(88, 52, 22);
  }

  .card-content .status.rescheduling {
    color: rgb(80, 117, 138);
  }

  .card-content .status.not-applicable {
    color: rgb(24, 24, 24);
  }

  .card-content .section.crm-info .account-manager:has(.name-text) {
    color: #444849;
    /* font-weight: 600; */
    cursor: pointer;
  }

  .card-content .section.crm-info .account-manager:has(.name-text.not-set) {
    color: #f73c3c;
    font-weight: 600;
    cursor: pointer;
  }

  .card-content .section.crm-info .account-manager:has(.name-text):hover {
    background: #44484910;
    border-radius: 2px;
  }

  .card-content .section.crm-info .account-manager:has(.name-text.disabled) {
    color: #5b5e5f;
    font-weight: 600;
    cursor: not-allowed;
  }

  .card-content .section.customer-info .lead-type:has(.name-text) {
    color: #444849;
    /* font-weight: 600; */
    cursor: pointer;
  }

  .card-content .section.customer-info .lead-type:has(.name-text):hover {
    background: #44484910;
    border-radius: 2px;
  }

  .card-content .section .options-dropdown {
    display: flex;
    gap: 0.25em;
  }

  .card-content .section .options-dropdown select {
    width: 100px;
    border: 1px solid #dedede;
    background: transparent;
    border-radius: 2px;
    font-size: 1em;
  }

  .card-content .section .options-dropdown select:focus {
    outline: 1px solid #dedede;
  }

  .card-content .section .options-dropdown .save-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 2px;
    background-color: #1f82a5;
    color: var(--main-white);
    font-weight: 600;
    transition: all 100ms;
    outline: 1px solid #dedede;
  }

  .card-content .section .options-dropdown .save-btn:hover {
    background-color: #1b6078;
  }

  .card-content .section .options-dropdown .save-btn .icon {
    font-size: 0.9em;
  }

  .card-content .section .options-dropdown .cancel-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 2px;
    background-color: #d6d6d6;
    color: var(--main-white);
    font-weight: 600;
    transition: all 100ms;
    outline: 1px solid #dedede;
  }

  .card-content .section .options-dropdown .cancel-btn:hover {
    background-color: #b6b6b6;
  }
}

.demo-meeting-card.lost .card-header {
  background: #f73c3c;
}

.demo-meeting-card.won .card-header {
  background: #4caf50;
}

.demo-meeting-card.meeting-done .card-header {
  background: rgb(3, 155, 161);
}

.demo-meeting-card.low-budget .card-header {
  background: rgb(88, 52, 22);
}

.demo-meeting-card.rescheduling .card-header {
  background: rgb(80, 117, 138);
}

.demo-meeting-card.not-applicable .card-header {
  background: rgb(24, 24, 24);
}
