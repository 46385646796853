.viewing-as-user-warning-container {
  width: 300px;
  height: 35px;
  background-color: var(--main-yellow);
  position: absolute;
  top: 0;
  right: 45%;
  z-index: 10000;
  padding: 0.5em 0.5em;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-black);
  text-align: center;
  padding: 0.5em 0.1em;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  transform: 0;
  transition: transform 0.3s ease;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10%;
}

.viewing-as-user-warning-container.hide {
  transform: translateY(-74%);
}

.viewing-as-user-warning-container:hover,
.viewing-as-user-warning-container.hide:hover {
  transform: translateY(0);
}

.viewing-as-user-warning-container .back-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5%;
  margin-left: 20px;
}

@media (max-width: 2300px) {
  .viewing-as-user-warning-container {
    right: 40%;
  }
}

@media (max-width: 1600px) {
  .viewing-as-user-warning-container {
    right: 30%;
  }
}
