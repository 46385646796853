.basic-input {}

.basic-input label,
.basic-input input,
.basic-input textarea {
  display: block;
}

.basic-input input,
.basic-input textarea,
.basic-input select {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 0.5em;
  padding: 0.5em;
}

.basic-input input:focus,
.basic-input textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.basic-input--invalid label,
.basic-input--invalid p {
  color: red;
}

.basic-input--invalid input,
.basic-input--invalid textarea {
  border-color: red;
  background: #ffd1d1;
  /* padding: 3px 0.5em */
}

.basic-input--invalid .error-text {
  font-size: 0.8em
}

.basic-input .select-cont {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px 0 #ccc;
  padding: 0;
  cursor: pointer;
}

.basic-input select {
  width: calc(100% - 1em);
  margin: 0 auto;
  box-shadow: none;
  cursor: pointer;
}