@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bayon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');

body {
  margin: 0;
  padding: 0;
  /* font-family: "Poppins" !important; */
  font-family: 'Inter' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* this may break some parts of the old UI */
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-white: #fff;
  --text-gray: #4c4c53;
  --main-gray: #e0e0e0;
  --light-gray: #7c8284;
  --text-black: #4f4f4f;
  --text-purplish-gray: #737395;
  --transparent-gray: rgba(0, 0, 0, 0.1);
  --transparent-black: rgba(0, 0, 0, 0.248);
  --transparent-black-hover: rgba(0, 0, 0, 0.425);
  --text-green: #7ebf59;
  --dark-green: #327c58;
  --main-green: #85ca6e;
  --light-green: #42bd2f;
  --second-green: #46b680;
  --primary-green: #8bc45d;
  --main-green-hover: #51b33f;
  --second-green-hover: #359064;
  --main-turquoise: #1297a9;
  --second-turquoise: #20a19e;
  --border-main: #dfeaf2;
  --border-hover: #a2c4dd;
  --main-blue: #0cb1ed;
  --dark-blue: #1c798c;
  --text-blue: #006b92;
  --text-blue-hover: #004a64;
  --main-blue-hover: #0a96c9;
  --main-gradient: linear-gradient(to right, #01394f 85%, #016750);
  --blue-gradient: linear-gradient(90deg, #0064a1 50%, #1a7a99);
  --blue-gradient-hover: linear-gradient(90deg, #014f7f, rgb(23, 67, 81));
  --main-red: #e40000;
  --main-yellow: #f8b724;
  --disabled-gray: #d9d9d9;
  --main-box-shadow: 0px 6px 16px hsla(240, 14%, 81%, 0.302);
  --card-shadow: 0px 2px 9px #00003e1a;
  --card-shadow-darker: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
  --background-gray: #f8f9fb;
  --button-gradient: linear-gradient(
    294deg,
    var(--primary-green),
    var(--second-green) 35%
  );
  --main-green-gradient: linear-gradient(
    90deg,
    var(--text-green),
    #5fb97d,
    #57ba8a
  );
  --main-green-gradient-transparent: linear-gradient(
    to right,
    rgba(139, 195, 74, 0.25),
    rgba(76, 175, 80, 0.25)
  );
  --main-green-gradient-hover: linear-gradient(
    90deg,
    #78bb51,
    #4fa26b,
    #46a778
  );
  --font-32: 32px;
  --font-30: 30px;
  --font-28: 28px;
  --font-26: 26px;
  --font-24: 24px;
  --font-22: 22px;
  --font-20: 20px;
  --font-18: 18px;
  --font-16: 16px;
  --font-14: 14px;
  --font-12: 12px;
  --font-10: 10px;

  --topbar-height: 70px;

  /* new navbar widths */

  --lg-navbar-width: 18em;
  --locked-navbar-width: 5em;
  --sidebar-width: 18em;
  --navbar-sidebar-width: calc(
    var(--sidebar-width) + var(--locked-navbar-width)
  );

  body.navbar-locked .basic-user .main-cont {
    margin-left: var(--lg-navbar-width);
    width: calc(100% - var(--lg-navbar-width));
    transition: all var(--transition-timing) cubic-bezier(0.4, 0, 0.2, 1);
  }

  body.navbar-locked .basic-user.ad-health-checkup .main-cont {
    margin-left: 0em !important;
  }

  body:not(.navbar-locked) .basic-user .main-cont {
    margin-left: var(--locked-navbar-width);
    width: calc(100% - var(--locked-navbar-width));
    transition: all var(--transition-timing) cubic-bezier(0.4, 0, 0.2, 1);
  }

  body:not(.navbar-locked) .basic-user .main-cont.sidebar-open {
    margin-left: var(--navbar-sidebar-width);
    width: calc(100% - var(--navbar-sidebar-width));
    transition: all var(--transition-timing) cubic-bezier(0.4, 0, 0.2, 1);
  }

  @media (max-width: 1470px), (max-height: 1030px) {
    :root {
      --sidebar-width: 14.4em;
    }

    body.navbar-locked .basic-user .main-cont {
      margin-left: 14.6em;
      width: calc(100% - 15em);
      height: 100vh;
    }
    body.navbar-locked .basic-user.ad-health-checkup .main-cont {
      margin-left: 0em !important;
    }
    .App.full-screen-noscroll-padding main {
      padding: 2em 0em 0em 2em;
      overflow: unset;
    }

    body:not(.navbar-locked) .basic-user .main-cont {
      margin-left: 4em;
      width: calc(100% - 4em);
      height: 100vh;
    }

    body:not(.navbar-locked) .basic-user .main-cont.sidebar-open {
      margin-left: calc(var(--navbar-sidebar-width) - 4.6em);
      width: calc(100% - var(--navbar-sidebar-width));
    }
  }
  /* new navbar widths */
  --breadcrumb-height: 60px;
  --collapsed-navbar-width: 40px;
  --drawer-opener-width: 50px;

  --enh-catalog-preview-ratio: 0.5;
  --enh-catalog-preview-size: calc(1080px / var(--enh-catalog-preview-ratio));
  --enh-catalog-navbar-width: 330px;
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
  /* background: rgb(224, 224, 224); */
  background: var(--main-white);
}

a:not(.button) {
  text-decoration: none;
  color: #016b92;
}

a:not(.button):hover {
  color: #015370;
  /* font-weight: bold; */
  text-decoration: underline;
}

body {
  margin: 0;
  /* background: #4d4d4d; */
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Slider - Start */

#sliderContainer {
  display: none;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  max-width: 750px;
  margin-top: 1em;
  height: 10px;
  border-radius: 10px;
  outline: none;
  transition: opacity 0.2s;
  background: transparent;
  box-shadow: 0 0 5px #00000020 inset;
}

.slider:hover {
  opacity: 1;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 20px;
  cursor: pointer;
  border-radius: 15px;
  background: var(--main-green);
  box-shadow: var(--main-box-shadow);
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  cursor: pointer;
  border-radius: 15px;
  background: var(--main-green);
  box-shadow: var(--main-box-shadow);
}

.slider::-webkit-slider-thumb:hover {
  background: var(--main-green-hover);
}

.slider::-moz-range-thumb:hover {
  background: var(--main-green-hover);
}

/* Slider - End */

/* App Container - Start */

.App {
  height: 100vh;
  width: 100vw;
  background: #fefefe;
}

.App .main-cont {
  display: flex;
  height: calc(100% - var(--topbar-height));
}

.App.ad-health-checkup .main-cont {
  height: 100%;
}

.App.full-screen .main-cont,
.App.full-screen-noscroll .main-cont {
  height: 100%;
}

.basic-user {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.basic-user .main-cont {
  flex: 1;
  overflow-y: auto;
  transition: margin-left 0.3s ease;
  height: 100%;
}

.basic-user .main-cont main {
  padding: 0;
}

.padding-0 {
  padding: 0;
}

.App .full-container {
  width: 100%;
  height: 100%;
}

.App.audiences main {
  max-width: none;
  padding: 0 3em 2em;
}

.App main {
  flex: 1 1 80%;
  padding: 2em;
  width: 100%;
  color: var(--text-gray);
  /* max-width: 1400px; */
  margin: 0 auto;
  height: 100%;
  overflow: hidden overlay;
}

.App.ad-health-checkup main {
  padding: 0 !important;
}

.App.full-screen-noscroll main {
  padding: 0;
  overflow: unset;
}

.App.full-screen-noscroll-padding main {
  padding: 2em 0em 0em 2em;
  overflow: unset;
}

.App.full-screen-scroll main {
  height: 100vh;
  overflow-y: scroll;
  padding: 0em 1em 1em 1em;
}

.App.full-screen-scroll-no-padding main {
  height: 100vh;
  overflow-y: scroll;
  padding: 0em;
}

.App main h1 {
  color: var(--main-green);
  font-weight: 600;
  font-size: 1.7em;
  /* margin: 1em auto;
  width: 90%; */
}

@media (max-width: 1550px) {
  .App main {
    padding: 1em;
  }
}

@media (max-width: 450px) {
  .App main h1 {
    font-size: 1.3em;
  }
}

/* App Container - End */

/* Scrollbar - Start */

*::-webkit-scrollbar {
  display: block;
  width: 12px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-track {
  background-color: #00000000;
}

*::-webkit-scrollbar-track-piece {
  background-color: #00000000;
}

*::-webkit-scrollbar-thumb {
  background-color: #00000000;
  border: 3px solid transparent;
  border-radius: 24px;
  box-shadow: 4px 0px 0px 4px #00000000 inset;
}

/* *::-webkit-scrollbar-thumb:hover {
  background-color: #00000040;
  border: 0px solid transparent;
  box-shadow: none;
} */

.scrolling::-webkit-scrollbar-thumb {
  box-shadow: 4px 0px 0px 4px #00000040 inset;
}

.scrolling::-webkit-scrollbar-thumb:hover {
  background-color: #00000040;
}

/* Scrollbar - End */

.loading-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.397);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Image overlay - Start */

#imageOverlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.48);
  z-index: 20000;
  align-items: center;
  justify-content: center;
}

#imageOverlay img {
  max-width: 80vw;
  max-height: 80vh;
}

#imageOverlay .close-button {
  background: none;
  border: none;
  font-size: 2em;
  color: var(--main-white);
  position: absolute;
  top: 2em;
  right: 1em;
  display: flex;
}

@media (max-width: 500px) {
  #imageOverlay img {
    max-width: 100vw !important;
    max-height: 80vh !important;
  }
}

/* Image overlay - End */

.row {
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
}

.error-text {
  color: rgb(209, 3, 3);
  font-size: 0.8em;
  margin: 0.5em auto;
}

.link-text {
  color: var(--text-blue);
  cursor: pointer;
}

.link-text:hover {
  text-decoration: underline;
}

.react-tiny-popover-container {
  z-index: 100000;
  top: -5px;
}

.react-tiny-popover-container .popover-arrow {
  /* border-bottom: 10px solid var(--main-white) !important; */
}

.adminsr #tidio-chat {
  display: none;
}

.test {
  height: 300px;
  width: 300px;
  border: 1px solid black;
  font-size: 30px;
  background: linear-gradient(130deg, #f1f1f1, #e2e2e2);
  background-size: 200% 200%;

  -webkit-animation: gradientAnimation 2s ease-in-out infinite;
  -moz-animation: gradientAnimation 2s ease-in-out infinite;
  animation: gradientAnimation 2s ease-in-out infinite;
}

/* Ripple animate */
.ripple-animate {
  background: #c7ffd1
    radial-gradient(circle, transparent -200%, var(--main-white) 100%)
    center/15000%;
  background-size: 200% 200%;
  -webkit-animation: gradientAnimation 2s ease-in-out forwards;
  -moz-animation: gradientAnimation 2s ease-in-out forwards;
  animation: gradientAnimation 2s ease-in-out forwards;
}

/* Blink animate */
.blink-animate {
  -webkit-animation: textBlinkAnimation 1s linear 2 forwards;
  -moz-animation: textBlinkAnimation 1s linear 2 forwards;
  animation: textBlinkAnimation 1s linear 2 forwards;
}

@-webkit-keyframes gradientAnimation {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 90% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

@-moz-keyframes gradientAnimation {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 90% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 90% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

@-webkit-keyframes dashLine {
  to {
    stroke-dashoffset: 0;
  }

  from {
    stroke-dashoffset: 3000;
  }
}

@-moz-keyframes dashLine {
  to {
    stroke-dashoffset: 0;
  }

  from {
    stroke-dashoffset: 3000;
  }
}

@keyframes dashLine {
  0% {
    stroke-dashoffset: 3000;
    /*not visible */
  }

  10% {
    stroke-dashoffset: 1500;
  }

  20% {
    stroke-dashoffset: 0;
  }

  30% {
    stroke-dashoffset: 0;
    /*  visible */
  }

  40% {
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dashoffset: -1500;
  }

  60% {
    stroke-dashoffset: -3000;
    /*  not visible */
  }

  70% {
    stroke-dashoffset: -3000;
  }

  80% {
    stroke-dashoffset: -3000;
  }

  90% {
    stroke-dashoffset: -1500;
  }

  100% {
    stroke-dashoffset: 0;
    /*  visible */
  }
}

@keyframes dashLineReverse {
  0% {
    stroke-dashoffset: -3000;
    /*not visible */
  }

  10% {
    stroke-dashoffset: -1500;
  }

  20% {
    stroke-dashoffset: 0;
  }

  30% {
    stroke-dashoffset: 0;
    /*  visible */
  }

  40% {
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dashoffset: 1500;
  }

  60% {
    stroke-dashoffset: 3000;
  }

  70% {
    stroke-dashoffset: 3000;
    /*  not visible */
  }

  80% {
    stroke-dashoffset: 3000;
  }

  90% {
    stroke-dashoffset: 1500;
  }

  100% {
    stroke-dashoffset: 0;
    /*  visible */
  }
}

@keyframes dashLineSingle {
  to {
    stroke-dashoffset: 0;
  }

  from {
    stroke-dashoffset: 3000;
  }
}

@keyframes textBlinkAnimation {
  0% {
    transform: scale(1) rotate(0deg);
  }

  25% {
    transform: scale(1.05) rotate(0deg);
  }

  45% {
    transform: scale(1.09) rotate(3deg);
  }

  50% {
    transform: scale(1.1) rotate(0deg);
  }

  55% {
    transform: scale(1.09) rotate(-3deg);
  }

  75% {
    transform: scale(1.005) rotate(0deg);
  }

  100% {
    transform: scale(1) rotate(0deg);
  }
}

/* Calendly popup height override */
.calendly-overlay .calendly-popup {
  max-height: 800px !important;
}
