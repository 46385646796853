.summary-of-audiences > .fluid-card-header {
    box-shadow: unset;
    background: var(--main-turquoise);
  }
  
  .summary-of-audiences > .fluid-card-header .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .summary-of-audiences > .fluid-card-header .header-wrapper .dropdown select {
    all: unset;
    appearance: auto;
  }
  
  .summary-of-audiences > .fluid-card-header .header-wrapper .dropdown option {
    background: #fafafa;
    color: #555555;
    font-size: 0.875em;
  }
  
  .summary-of-audiences > .fluid-card-content {
    padding: 1.5em 4em;
    border-radius: 0 0 10px 10px;
  }
  
  .summary-of-audiences > .fluid-card-content .charts-container {
    display: flex;
    justify-content: center;
    gap: 2em;
  }
  
  .summary-of-audiences > .fluid-card-content .charts-container .card-container {
    flex: 3 1;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .fluid-card-header {
    font-size: 0.8125em;
    text-align: center;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-header {
    background: var(--second-green);
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .b-audience
    .fluid-card-header {
    background: var(--dark-blue);
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .fluid-card-content {
    box-shadow: unset;
    display: flex;
    gap: 1em;
    flex: 1 1 80%;
    padding: 0;
    padding-top: 1em;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .fluid-card-content {
    flex-direction: column;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-content
    .content-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .b-audience
    .fluid-card-content
    .content-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-content
    .switch-container {
    display: flex;
    margin: auto;
    font-size: 0.8em;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-content
    .switch-container
    .switch {
    background-color: var(--disabled-gray);
    box-shadow: unset;
    height: 1.5em;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-content
    .switch-container
    .switch.right
    .left-side {
    color: #767171;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-content
    .switch-container
    .switch.left
    .right-side {
    color: #767171;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-content
    .switch-container
    .switch.right
    .right-side {
    font-weight: 600;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-content
    .switch-container
    .switch.left
    .left-side {
    font-weight: 600;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-content
    .switch-container
    .switch
    .selection {
    background: var(--second-green);
    width: 50%;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-content
    .switch-container
    .switch.right
    .selection {
    left: 50%;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .b-audience
    .fluid-card-content {
    justify-content: flex-start;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .fluid-card-content
    canvas {
    flex: 1;
    max-width: 80%;
    min-height: 135px;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .fluid-card-content
    .stats {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.25em;
    color: #6a6b6c;
    font-weight: 600;
    font-size: 1.1em;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .fluid-card-content
    .stats
    .symbol {
    font-size: 0.75em;
    margin-left: 0.2em;
    font-weight: 400;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .enhencer-audience
    .fluid-card-content
    .stats {
    position: relative;
    left: 1.5em;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .card-container
    .b-audience
    .fluid-card-content
    .stats {
    position: relative;
    right: 1.5em;
  }
  
  .summary-of-audiences > .fluid-card-content .charts-container .chart-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: 5em;
    padding-bottom: 2.5em;
    font-size: 0.875em;
    flex: 1;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .chart-options
    .option {
    color: #757878;
    cursor: pointer;
    transition: all 200ms;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .chart-options
    .option:not(.disabled):not(.active):hover {
    color: #474747;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .chart-options
    .option.active {
    font-weight: 600;
    font-size: 1.1em;
    background: #efefef;
    border-radius: 8px;
    padding: 0.15em 0.35em;
  }
  
  .summary-of-audiences
    > .fluid-card-content
    .charts-container
    .chart-options
    .option.disabled {
    cursor: default;
  }