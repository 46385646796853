@keyframes intro-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(139, 196, 93, 0.7);
  }
  70% {
    box-shadow: 0 0 0 0.6em rgba(139, 196, 93, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(139, 196, 93, 0);
  }
}
.intro-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .intro-content-wrapper {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 0 1.3em;
    align-items: center;
  }

  .intro-text-content {
    flex: 1;
    padding-right: 2.5em;
  }

  .intro-start-button {
    padding: 0.9em 1.9em;
    font-size: var(--font-20);
    font-weight: 600;
    background: linear-gradient(90deg, #8bc45d, #46b680);
    color: #ffffff;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1em;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0.3em 0.9em rgba(0, 0, 0, 0.2);
  }

  .intro-start-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    transition: all 0.5s;
  }

  .intro-start-button:hover {
    transform: translateY(-0.2em);
    box-shadow: 0 0.4em 1.3em rgba(0, 0, 0, 0.3);
    background: linear-gradient(
      32deg,
      var(--primary-green),
      var(--second-green) 52%
    );
  }

  .intro-start-button:hover::before {
    left: 100%;
  }

  .intro-start-button:active {
    transform: translateY(0.1em);
    box-shadow: 0 0.1em 0.6em rgba(0, 0, 0, 0.2);
  }

  .intro-start-button-pulse {
    animation: intro-pulse 2s infinite;
  }

  .intro-rating {
    margin-top: 1.3em;
    display: inline-flex;
  }

  .intro-star {
    font-size: 2em;
    color: #e0e0e0;
    position: relative;
    margin-left: 0.2em;
  }

  .intro-star.filled {
    color: #ffa500;
  }

  .intro-star.half-filled::before {
    content: '★';
    position: absolute;
    left: 0;
    top: 0;
    width: 60%;
    overflow: hidden;
    color: #ffa500;
  }

  @media (max-width: 1000px) {
    .intro-content-wrapper {
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    .intro-text-content {
      padding-right: 0;
      margin-bottom: 2.5em;
      text-align: -webkit-center;
    }
    .intro-text-content .intro-headline {
      text-align: center;
    }
  }
}
