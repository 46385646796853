.people-selector {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.attached-users {
  display: flex;
  gap: 5px;
  padding-left: 1.2em;
}

.attached-users.hidden {
  display: none;
}

.default-user-avatar {
  background-color: gray;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.attached-user {
  cursor: pointer;
  position: relative;
  height: 30px;
}

.attached-user:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.attached-user:hover::after {
  content: '×';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.user-list-overlay {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0px #0000004d;
}

.user-list-item {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.user-list-item-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.small-user-avatar {
  background-color: gray;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.small-user-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.add-user-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: var(--main-green);
  color: white;
  border-radius: 50%;
}

.add-user-button:hover {
  background-color: var(--main-green-hover);
}
