.support-tickets-list-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.support-tickets-list-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.support-tickets-list-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.support-tickets-list-wrapper::-webkit-scrollbar-thumb {
  background: var(--transparent-gray);
}

.support-tickets-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.25);
}

.support-tickets-list {
  display: grid;
  flex-wrap: wrap;
  grid-gap: 1.5em;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  width: 100%;
  padding: 0 1em 1em;
  align-items: start;
}

.scroll-ending-box {
  padding: 1em;
  align-items: center;
  justify-content: center;
  display: flex;
}
