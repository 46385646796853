.collapsibleTemplateSidebar {
  font-size: 1.75em;
  position: relative;
  display: flex;
  width: 30%;
  border-left: 1px solid #cacaca;
  z-index: 1;
  transition: width 0.3s ease-in-out;
}

.open {
  width: 30%;
}

.collapsed {
  width: 50px;
}

.collapser {
  position: absolute;
  top: calc(50vh - 80px);
  left: 0;
  transform: translate(-50%, calc(-50% + 40px));
  width: 60px;
  height: 60px;
  z-index: 2;
}

.collapserButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  border: 1px solid var(--second-green);
  cursor: pointer;
}

.content {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.collapsedContent {
  padding: 1em 2em;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  max-width: 14em;
  margin: 0 auto;
  height: 100%;
  padding: 1em 2em;
}

.header {
  display: flex;
  align-items: center;
}

.header ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header li {
  cursor: pointer;
  font-weight: 600;
  font-size: 0.875em;
  color: #abb1b8;
}

.active {
  background: linear-gradient(
    90deg,
    var(--primary-green) 0%,
    var(--second-green) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.body {
  overflow-y: auto;
}

.templateCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
}

.templateCard {
  overflow: hidden;
  width: 100%;
  min-height: 150px;
  aspect-ratio: 1;
  border-radius: 0.5em;
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}

.templateCard:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.templateCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .collapsibleTemplateSidebar {
    display: none;
  }
}
