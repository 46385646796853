.sign-up-card {
  font-size: 14px;
  width: 100%;
  border-radius: 10px;
  background: var(--main-white);
  border: 2px solid #e5e5e5;
  overflow: hidden;
  max-height: min-content;
}

.sign-up-card p {
  margin: 0;
}

.sign-up-card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--main-white);
  background: rgba(103, 103, 103, 1);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
  padding: 0.7em 1em;
  font-size: 0.92em;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.sign-up-card.touch .card-header {
  background: #43900f;
}

.sign-up-card.no-touch .card-header {
  background: rgb(24, 24, 24);
}

.sign-up-card.fast-churn .card-header {
  background: rgb(179, 49, 49);
}

.sign-up-card .card-content {
  display: flex;
  flex-direction: column;
  padding: 0.7em 1em;
  position: relative;
}

.sign-up-card .card-header .section {
  display: flex;
  align-items: center;
  gap: 0.75em;
}

.sign-up-card .card-content .section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  position: relative;
  padding: 0.8em 0em;
}

.sign-up-card .card-content .section.statuses {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.sign-up-card .card-content .section.statuses .touch {
  color: #43900f;
  font-weight: 600;
  font-size: 1.1em;
}

.sign-up-card .card-content .section.statuses .no-touch {
  color: rgb(24, 24, 24);
  font-weight: 600;
  font-size: 1.1em;
}

.sign-up-card .card-content .section.statuses .fast-churn {
  color: rgb(179, 49, 49);
  font-weight: 600;
  font-size: 1.1em;
}

.sign-up-card .card-content .signed-up-date {
  display: flex;
  align-self: end;
}

.sign-up-card .card-content .section.statuses .status-dropdowns {
  display: flex;
  align-self: end;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-size: 1.2em;
  font-weight: 600;
}

.sign-up-card .card-content .section:not(:last-child)::after {
  content: '';
  left: 0;
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #f5f5f5;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15) inset;
  z-index: 3;
}

.sign-up-card .section.meeting-datetime {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5em;
}

.sign-up-card .section.meeting-datetime .meeting-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sign-up-card .section.meeting-datetime .meeting-date .date {
  font-size: 1.4em;
  font-weight: 600;
}

.sign-up-card .section.customer-info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5em;
}

.sign-up-card .section.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sign-up-card .actionbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(96, 96, 96);
  border: solid 1.5px rgb(96, 96, 96);
  gap: 0.2em;
  padding: 8px 0px;
  width: 95px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;
}

.sign-up-card .actionbutton:hover {
  background: #e7e7e7;
}

.sign-up-card .add-note {
  max-width: 100%;
}

.sign-up-card .add-note .actionbutton {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  justify-content: start;
  padding: 8px;
  width: 100%;
}

.sign-up-card .add-note .note-box {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sign-up-card .section.actions .buttons .actionbutton.destructive {
  background: #f73c3c;
  color: var(--main-white);
  border: none;
}

.sign-up-card .section.actions .buttons .actionbutton.destructive:hover {
  background: #d23333;
}

.sign-up-card .section.actions .buttons .actionbutton.constructive {
  background: #76b537;
  color: var(--main-white);
  border: none;
}

.sign-up-card .section.actions .buttons .actionbutton.constructive:hover {
  background: #669b30;
}

.sign-up-card .section.contact-info {
  font-size: 0.9em;
}

.sign-up-card .section.contact-info .right-side {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.2em;
}

.sign-up-card .section.contact-info .phone,
.sign-up-card .section.contact-info .email {
  display: flex;
  align-items: center;
  color: #444849;
  font-weight: 300;
  font-size: 1em;
}

.sign-up-card .section.contact-info .phone .helper-text,
.sign-up-card .section.contact-info .email .helper-text {
  font-size: 0.9em;
  color: rgb(81, 81, 81);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sign-up-card .section.contact-info .phone .clock-icon {
  width: 1.1em;
  transform: translateY(2px);
}

.sign-up-card .section.contact-info .phone:not(.not-available) {
  cursor: pointer;
}

.sign-up-card .section.contact-info .phone:not(.not-available):hover,
.sign-up-card .section.contact-info .email:not(.not-available):hover {
  color: #303233;
  font-weight: 400;
  text-decoration: none;
}

.sign-up-card .section.contact-info .phone .icon {
  display: none;
  margin-left: 5px;
}

.sign-up-card .section.contact-info .email .icon {
  display: none;
  margin-right: 5px;
}

.sign-up-card .section.contact-info .phone:not(.not-available):hover .icon,
.sign-up-card .section.contact-info .email:not(.not-available):hover .icon {
  display: inline-block;
}

.sign-up-card .card-header .signup-status:has(.name-text) {
  cursor: pointer;
}

.sign-up-card .card-content .section.contact .inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8em;
}

.sign-up-card .card-content .section.contact .inputs .line {
  display: flex;
  align-items: baseline;
  gap: 0.5em;
}

.sign-up-card .card-content .section.contact .inputs .line > * {
  flex: 1 1 50%;
}

.sign-up-card .card-content .section.contact .inputs .line .input {
  display: flex;
  gap: 0.2em;
  font-size: 0.9em;
}

.sign-up-card .card-content .section.contact .inputs .line .input label {
  font-size: 0.9em;
}

.sign-up-card .card-content .section.contact .inputs .line .input input {
  text-align: center;
  font-weight: 400;
  width: 48px;
  border: none;
  border-radius: 2px;
  background: #f5f5f5;
}

.sign-up-card .card-content .section.contact .inputs .line .input-checkbox {
  text-align: center;
  font-weight: 400;
  font-size: 0.9em;
  display: flex;
  gap: 0.3em;
  align-items: center;
}

.sign-up-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox
  label {
  font-size: 0.9em;
}

.sign-up-card .card-content .section.contact .inputs .line .input .save-btn,
.sign-up-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox
  .save-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 2px;
  background-color: #1f82a5;
  color: var(--main-white);
  font-weight: 600;
  transition: all 100ms;
  height: 1.5em;
}

.sign-up-card
  .card-content
  .section.contact
  .inputs
  .line
  .input
  .save-btn:disabled,
.sign-up-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox
  .save-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.sign-up-card
  .card-content
  .section.contact
  .inputs
  .line
  .input
  .save-btn:hover,
.sign-up-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox
  .save-btn:hover {
  background-color: #1b6078;
}

.sign-up-card
  .card-content
  .section.contact
  .inputs
  .line
  .input
  .save-btn
  .icon,
.sign-up-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox
  .save-btn
  .icon {
  font-size: 0.9em;
}

.sign-up-card .card-content .section.contact .inputs .line .description {
  color: #444849;
  font-size: 0.875em;
  font-weight: 300;
  margin: 0;
  display: flex;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
}

.sign-up-card .card-content .section.contact .inputs .line .input input:focus {
  outline: 1px solid #c3bdbd;
}

.sign-up-card .switch-container {
  width: 140px;
  height: 36px;
  position: relative;
  cursor: pointer;
}

.sign-up-card .switch-background {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  position: absolute;
  top: 0;
  border-radius: 5px;
  transition: background-color 0.3s;
  box-shadow: inset 0 0 6px #0e0d0da3;
}

.sign-up-card .switch-handle {
  width: 70px;
  height: 36px;
  background-color: var(--main-white);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.85em;
}

.sign-up-card .switch-container.off .switch-background {
  background-color: #f73c3caf;
}

.sign-up-card .switch-container.on .switch-background {
  background-color: #4caf50;
}

.sign-up-card .switch-container.off .switch-handle {
  left: 0;
  color: #f73c3c;
}

.sign-up-card .switch-container.on .switch-handle {
  left: 70px;
  color: #4caf50;
}

.sign-up-card .card-content .section .checkup-meeting-status {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.sign-up-card .card-content .section .signup-status:has(.name-text) {
  cursor: pointer;
}

.sign-up-card .card-content .section .checkup-meeting-status:has(.name-text),
.sign-up-card
  .card-content
  .section.crm-info
  .performance-manager:has(.name-text),
.sign-up-card .card-content .section.crm-info .account-manager:has(.name-text) {
  color: #444849;
  /* font-weight: 600; */
  cursor: pointer;
}

.sign-up-card
  .card-content
  .section.crm-info
  .performance-manager:has(.name-text.not-set),
.sign-up-card
  .card-content
  .section.crm-info
  .account-manager:has(.name-text.not-set) {
  color: #f73c3c;
  font-weight: 600;
  cursor: pointer;
}

.sign-up-card .card-content .section .signup-status:has(.name-text):hover,
.sign-up-card
  .card-content
  .section
  .checkup-meeting-status:has(.name-text):hover,
.sign-up-card
  .card-content
  .section.crm-info
  .performance-manager:has(.name-text):hover,
.sign-up-card
  .card-content
  .section.crm-info
  .account-manager:has(.name-text):hover {
  background: #44484910;
  border-radius: 2px;
}

.sign-up-card
  .card-content
  .section.crm-info
  .performance-manager:has(.name-text.disabled),
.sign-up-card
  .card-content
  .section.crm-info
  .account-manager:has(.name-text.disabled) {
  color: #5b5e5f;
  font-weight: 600;
  cursor: not-allowed;
}

.sign-up-card .card-content .section .options-dropdown {
  display: flex;
  gap: 0.25em;
}

.sign-up-card .card-content .section .options-dropdown select {
  width: 100px;
  border: 1px solid #dedede;
  background: transparent;
  border-radius: 2px;
  font-size: 1em;
}

.sign-up-card .card-content .section .options-dropdown select:focus {
  outline: 1px solid #dedede;
}

.sign-up-card .card-content .section .options-dropdown .save-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 2px;
  background-color: #1f82a5;
  color: var(--main-white);
  font-weight: 600;
  transition: all 100ms;
  outline: 1px solid #dedede;
}

.sign-up-card .card-content .section .options-dropdown .save-btn:hover {
  background-color: #1b6078;
}

.sign-up-card .card-content .section .options-dropdown .save-btn .icon {
  font-size: 0.9em;
}

.sign-up-card .card-content .section .options-dropdown .cancel-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 2px;
  background-color: #d6d6d6;
  color: var(--main-white);
  font-weight: 600;
  transition: all 100ms;
  outline: 1px solid #dedede;
}

.sign-up-card .card-content .section .options-dropdown .cancel-btn:hover {
  background-color: #b6b6b6;
}
