.hour-range-picker {
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0px 5px;
}

.hour-range-picker .selectable {
    padding: 3px 5px;
    font-size: 1.1em;
    border-radius: 4px;
    border: none;
    display: flex;
    color: var(--text-gray);
    cursor: pointer;
    background: buttonface;
}

.hour-range-picker .clear-button {
    margin-left: 5px;
    padding: 3px 5px;
    border-radius: 4px;
    border: none;
    display: flex;
    gap: 0.5em;
    color: var(--text-gray);
    cursor: pointer;
}

.toggle-button:hover,
.clear-button:hover {
    background-color: #f6f6f6;
}