.start-your-journey-main {
  .column {
    gap: 1em;
    align-items: center !important;
  }
  .header {
    text-align: center;
  }
  .secondary-text {
    font-weight: 800 !important;
  }
  .column.left {
    width: 60%;
    max-width: 800px;
  }
  .column.right {
    width: 40%;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
    width: 85%;
    border: 1px solid var(--second-green);
    border-radius: 10px;
    padding: 1em;
    border: 1px solid var(--second-green);
  }
  .box.gradient {
    background: linear-gradient(
      90deg,
      var(--primary-green) 0%,
      var(--second-green) 70%
    );
    color: var(--main-white);
  }
  .box.gradient .button {
    background: var(--main-white);
    color: var(--second-green);
  }

  .box.gradient .secondary-text,
  .box.gradient .smaller-text {
    color: var(--main-white);
  }

  .button {
    background: var(--second-green);
    color: var(--main-white);
    padding: 0.5em 1em;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    flex-direction: row;
    font-weight: 500;
  }
  .start-your-journey-slider {
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
    overflow-x: auto;
    padding: 1em;
  }
  .success-card {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .success-card li {
    width: 100%;
  }
  .success-card:hover {
    cursor: pointer;
  }
  .success-card .logo {
    width: 150px;
    z-index: 10;
    position: absolute;
    bottom: 5%;
    left: 5%;
    filter: brightness(0) invert(1) opacity(1);
  }
  .success-card .logo.sui {
    width: 80px;
  }
  .success-card img {
    border-radius: 10px;
    width: 100%;
  }
  .swiper {
    overflow-y: visible;
    overflow-x: hidden;
    width: 100%;
    /* padding: 2em; */
  }
  .swiper-wrapper {
    height: 100%;
  }
  /* .swiper-button-next{
    right: -1%;
  }
  .swiper-button-prev{
    left: -1%;
  } */
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1.5em;
    font-weight: 800;
  }
  .swiper-button-prev,
  .swiper-button-next {
    transform: translateY(-50%);
    bottom: 0;
    color: var(--second-green);
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid var(--second-green);
  }

  .swiper-button-prev {
    transform: translateY(-50%) translateX(-20%);
  }

  .swiper-button-next {
    transform: translateY(-50%) translateX(20%);
  }

  @media (min-width: 1000px) and (max-width: 1500px) {
    .swiper {
      width: 90%;
    }
  }
  @media (max-width: 1000px) {
    .box {
      gap: 0.4em;
    }
    .secondary-text {
      font-size: 1em !important;
    }
  }
  @media (max-width: 1200px) {
    .box {
      width: 100%;
    }
  }

  @media (max-width: 700px) {
    .column.left {
      width: 100%;
    }
    .column.right {
      width: 100%;
    }
  }
}
