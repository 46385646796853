.backgroundRemoving {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.productList,
.konvaArea {
  border-radius: 12px;
  border: 1px solid var(--border-main);
  background: #ffffff;
}

.productList {
  flex: 0 0 15%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  transition: all 0.2s ease;

  @media (max-width: 1024px) {
    flex: 0 0 20%;
    min-height: 150px;
  }
}

.konvaArea {
  flex: 1 1 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0.5rem;

  @media (max-width: 1024px) {
    flex: 1 1 75%;
  }
}
