.ADSET_BUDGETS_CONFIG {
  min-width: 500px;
  max-width: 90vw;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}

.adset-budgets-config {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0;
  position: relative;
  max-height: 85vh;
  overflow: hidden;
}

.adset-budgets-config h3 {
  font-weight: 600;
  color: var(--second-green);
  font-size: 1.5rem;
  margin: 0;
  padding: 1em;
  text-align: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  border-bottom: 1px solid #eee;
}

.adset-budgets-config .budget-rows-container {
  overflow-y: auto;
  padding: 0 1em;
  max-height: calc(85vh - 180px);
  scrollbar-width: thin;
}

.adset-budgets-config .budget-rows-container::-webkit-scrollbar {
  width: 6px;
}

.adset-budgets-config .budget-rows-container::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}

.adset-budgets-config .budget-rows-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

.adset-budgets-config .budget-rows-container::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.adset-budgets-config .budget-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8em;
  margin-bottom: 0.8em;
  background: #fff;

  border-bottom: 1px solid #eee;
  transition: all 0.2s ease;
}

.adset-budgets-config .budget-row:hover {
}

.adset-budgets-config .adset-name {
  font-weight: 500;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1em;
  max-width: 60%;
}

.adset-budgets-config .audience-checkbox-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5em;
  flex-direction: row-reverse;
}

.adset-budgets-config .original-budget {
  color: #777;
  font-size: 0.85em;
  font-style: italic;
  white-space: nowrap;
}

.adset-budgets-config .daily-budget-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.adset-budgets-config .currency-symbol {
  position: absolute;
  left: 10px;
  color: #555;
  font-weight: 500;
}

.adset-budgets-config .budget-input {
  padding: 0.6em 0.6em 0.6em 1.8em;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 100px;
  font-size: 0.95em;
  transition: all 0.2s ease;
}

.adset-budgets-config .budget-input:focus {
  outline: none;
  border-color: var(--second-green);
  box-shadow: 0 0 0 2px rgba(0, 150, 136, 0.1);
}

.adset-budgets-config .undo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  color: #666;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.adset-budgets-config .undo-button:hover {
  background-color: #f8f8f8;
  color: var(--second-green);
  border-color: var(--second-green);
}

.adset-budgets-config .sticky-footer {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 2;
  padding: 1em;
  border-top: 1px solid #eee;
  margin-top: auto;
}

.adset-budgets-config .total-budget-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8em;
  margin-bottom: 1em;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid var(--second-green);
}

.adset-budgets-config .total-budget-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.adset-budgets-config .total-budget-container b {
  font-size: 1.1em;
  color: #333;
}

.adset-budgets-config .total-budget-difference {
  font-size: 0.85em;
  font-weight: 500;
  padding: 0.3em 0.6em;
  border-radius: 4px;
  margin-top: 0.3em;
}

.adset-budgets-config .total-budget-difference.positive {
  color: #00963e;
  background: rgba(0, 150, 62, 0.1);
}

.adset-budgets-config .total-budget-difference.negative {
  color: #d32f2f;
  background: rgba(211, 47, 47, 0.1);
}

.adset-budgets-config .modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
}

.adset-budgets-config .action-button {
  min-width: 120px;
  padding: 0.7em 1.4em;
  border-radius: 6px;
  font-weight: 600;
  transition: all 0.2s ease;
}

.adset-budgets-config .action-button.update {
  background: var(--second-green);
  color: white;
}

.adset-budgets-config .action-button.update:hover {
  background: var(--second-green-hover);
}

.adset-budgets-config .action-button.update:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .ADSET_BUDGETS_CONFIG {
    min-width: 90vw;
  }

  .adset-budgets-config .budget-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8em;
  }

  .adset-budgets-config .adset-name {
    max-width: 100%;
    padding-right: 0;
  }

  .adset-budgets-config .audience-checkbox-container {
    width: 100%;
    justify-content: space-between;
  }

  .adset-budgets-config .budget-input {
    width: 120px;
  }
}
