/* Loading Spinner - Start */

.spinner-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #00000073;
  top: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(3px);
}

.spinner-overlay.without-topbar {
  top: var(--topbar-height);
}

.inline-spinner-box {
  margin: 5px auto;
}

.inline-spinner-box.center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.small-spinner-cont {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto
}


.spinner-cont {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: calc(50vh - 25px);
  left: calc(50vw - 25px);
  z-index: 9999;
}

.spinner-message {
  width: 90vw;
  position: fixed;
  top: calc(50vh + 50px);
  left: 5vw;
  text-align: center;
  color: rgb(123, 123, 123);
  animation-timing-function: ease-in-out;
  animation: flash 3s infinite;
  animation-delay: 2s;
}

.spinner-overlay .spinner-message {
  color: white;
}

.center .spinner-message {
  width: auto;
  position: initial;
  text-align: center;
  color: rgb(123, 123, 123)
}

.inline-spinner-box .spinner-message {
  width: auto;
  position: relative;
  top: unset;
  left: unset;
  margin-top: 0.5em;
  text-align: center;
  color: rgb(123, 123, 123)
}

.spinner-cont .bar, .small-spinner-cont .bar {
  width: 7px;
  height: 15px;
  background: rgb(161, 223, 16);
  border-radius: 5px;
  animation-timing-function: ease-in-out;
  animation: dance 0.7s infinite;
}

.small-spinner-cont .bar {
  width: 5px;
  margin: 0 1px
}

.small-spinner-cont.xs {
  height: 20px;
}


.small-spinner-cont.xs .bar {
  width: 2px;
  margin: 0 1px 0 0
}

.small-spinner-cont.xs+.spinner-message {
  font-size: .8em;
}

.spinner-cont .bar-1, .spinner-cont .bar-5,
.small-spinner-cont .bar-1, .small-spinner-cont .bar-5 {
  background: rgb(195, 210, 0);
  animation-delay: 0;
}

.spinner-cont .bar-2, .spinner-cont .bar-4,
.small-spinner-cont .bar-2, .small-spinner-cont .bar-4 {
  background: rgb(179, 218, 5);
  animation-delay: 0.1s;
}

.spinner-cont .bar-3, .small-spinner-cont .bar-3 {
  background: rgb(161, 223, 16);
  animation-delay: 0.2s;
}

@keyframes dance {
  0% {
    height: 100%
  }

  50% {
    height: 10%
  }

  100% {
    height: 100%
  }
}

@keyframes flash {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

/* Loading Spinner - End */