.subscribed {
  color: #909090;
}
.subscribed .subscribe-container {
  display: flex;
  flex-direction: row;
  gap: 2em;
}
.subscribed .page-header {
  font-size: var(--font-24);
  align-items: center;
  margin-bottom: 1em;
  font-weight: 600;
}
.subscribed .left-part {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  background: var(--main-white);
}

.subscribed .plan-info-card {
  display: flex;
  flex-direction: row;
  gap: 1em;
  width: 100%;
  background: var(--main-white);
  padding: 2em;
  border: 1px solid rgb(223, 234, 242);
  border-radius: 0.75em;
  margin-top: 1em;
}
.subscribed .plan-info-card .plan-column {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 60%;
  justify-content: space-between;
}
.subscribed .plan-info-card .plan-features-column {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 40%;
  font-size: var(--font-20);
}
.subscribed .plan-column .plan-name {
  font-size: var(--font-24);
  font-weight: 600;
}
.subscribed .plan-column .renew-date {
  font-size: var(--font-20);
  font-weight: 400;
}
.subscribed .green-text {
  color: var(--second-green);
  text-transform: capitalize;
}
.subscribed .plan-column .buttons-row {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: flex-start;
  width: 90%;
  margin-top: 2em;
}
.subscribed .plan-column button {
  padding: 10px 1em;
  border-radius: 6px;
  cursor: pointer;
  font-size: var(--font-16);
  font-weight: 500;
  flex: 1;
  background: var(--main-white);
  color: #848383;
  border: 1px solid #bfbebe;
  max-width: 50%;
}
.subscribed .plan-column button:hover {
  background: #cecbcb;
}
.subscribed .plan-column .cancel-subs-button {
  background: #b64646;
  color: var(--main-white);
  border: none;
}
.subscribed .payment-channel {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.subscribed .plan-features-box {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.8fr 1fr;
  gap: 0.5em 0.5em;
  margin-top: 1em;
}
.subscribed .plan-features-box .grid-item {
  position: relative;
  text-align: left;
  white-space: wrap;
  font-size: var(--font-14);
}
.subscribed .plan-features-box .grid-item .icon {
  max-width: 20px;
  margin-right: 5px;
}

.subscribed .plan-features-box .grid-item.unchecked .icon {
  opacity: 0;
}
.subscribed .plan-features-box .unchecked {
  color: #d4d4d4;
}

.subscribed .left-part .visitor-count-part {
  display: flex;
  flex-direction: column;
  font-size: var(--font-20);
  font-weight: 600;
  gap: 1em;
  width: 100%;
  background: var(--main-white);
  padding: 2em;
  border: 1px solid rgb(223, 234, 242);
  border-radius: 0.75em;
}

.visitor-count-part .visitor-count-slider .slider {
  -webkit-appearance: progress-bar;
  -webkit-opacity: 0.4;
  width: 100%;
  height: 5px;
  background: #30e27d85;
  outline: none;
  /* opacity: 0.7; */
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.visitor-count-part .visitor-count-slider .slider::-webkit-slider-thumb {
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--second-green);
}
.visitor-count-part .additional-fee {
  font-size: 0.8em;
  font-weight: 500;
  color: #848383a8;
  margin-top: 1.5em;
}

.visitor-count-slider .number-range {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.6em;
  width: 100%;
  color: #848383a8;
}
.subscribed .left-part .bottom-payment-info {
  display: flex;
  flex-direction: column;
  font-size: var(--font-16);
  font-weight: 500;
  gap: 1em;
  width: 100%;
  padding: 2em;
  border: 1px solid rgb(223, 234, 242);
  border-radius: 0.75em;
}

.bottom-payment-info h4 {
  margin: 0;
  font-weight: 600;
  font-size: var(--font-22);
  color: var(--main-green);
}

.bottom-payment-info .plan-info-part {
  width: 20%;
  align-items: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1em;
}
.bottom-payment-info .error {
  color: #d04848;
}

.bottom-payment-info .payment-info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-16);
  font-weight: 500;
  width: 100%;
  color: var(--main-white);
  padding: 1em;
  border-radius: 0.75em;
  gap: 1em;
}
.bottom-payment-info .header {
  background: linear-gradient(
    90deg,
    rgba(139, 196, 93, 1) 0%,
    var(--second-green) 80%
  );
}
.bottom-payment-info .body {
  background: var(--main-white);
  color: #909090;
}
.bottom-payment-info .body .subscription-status {
  color: #b6b046;
}

.bottom-payment-info .body .active {
  color: var(--second-green);
}

.bottom-payment-info .body .churn {
  color: #d04848;
}

.bottom-payment-info .body button {
  background: var(--main-white);
  color: #848383;
  border: 1px solid #909090;
  padding: 5px 5px;
  border-radius: 6px;
  cursor: pointer;
  font-size: var(--font-16);
  font-weight: 400;
  width: 50%;
}
.subscribed .right-part {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  background: var(--main-white);
  padding: 1em;
  align-items: center;
  border-radius: 0.75em;
  border: 2px solid var(--second-green);
}
.subscribed .right-part .header-column {
  padding: 25% 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subscribed .right-part .upgrade-image {
  max-width: 250px;
}
.subscribed .right-part .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}
.right-part .header .big-gradient-text {
  font-size: var(--font-24);
  font-weight: 600;
  background: linear-gradient(
    90deg,
    rgba(139, 196, 93, 1) 0%,
    rgba(70, 182, 128, 1) 80%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.right-part .header .small-gradient-text {
  font-size: var(--font-16);
  font-weight: 600;
  background: linear-gradient(
    90deg,
    rgba(139, 196, 93, 0.907) 60%,
    rgba(70, 182, 128, 0.797) 80%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.subscribed .right-part .next-plan-info {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
}
.subscribed .upgrade-plan-button {
  align-items: center;
}
.subscribed .upgrade-plan-button .upgrade-plan {
  background: linear-gradient(
    90deg,
    rgba(139, 196, 93, 1) 0%,
    rgba(70, 182, 128, 1) 60%
  );
  color: var(--main-white);
  border: none;
  padding: 10px 1em;
  border-radius: 6px;
  cursor: pointer;
  font-size: var(--font-16);
  font-weight: 500;
  width: 100%;
}
.upgrade-plan-button .upgrade-plan:hover {
  border: #c5c05dcb 1px solid;
}

.subscribed .channel-icon {
  max-height: 1.4em;
  display: inline;
  margin-left: 0.5em;
}

.usage-charges .payment-info-row .plan-info-part:nth-of-type(1),
.usage-charges .payment-info-row .plan-info-part:nth-of-type(1) {
  width: 30%;
  flex: 1 1 40%;
}

.usage-charges .payment-info-row.total {
  border-top: 1px solid rgb(158, 158, 158);
  border-radius: 0;
}

@media (max-width: 1150px) {
  .subscribed .right-part .upgrade-image {
    max-width: 150px;
  }
}

@media (max-width: 900px) {
  .subscribed .subscribe-container {
    flex-direction: column;
  }
  .subscribed .left-part {
    width: 100%;
  }
  .subscribed .right-part {
    width: 100%;
  }
  .subscribed .right-part .header-column {
    padding: 2em 0 0 0;
  }
}

@media (max-width: 1200px) {
  .subscribed .plan-info-card {
    flex-direction: column;
    gap: 2em;
  }
  .subscribed .plan-info-card .plan-column {
    width: 100%;
  }
  .subscribed .plan-info-card .plan-features-column {
    width: 100%;
  }
}
