.custom-subscription-plan.subscription-section {
  margin-top: 3em;
}

.custom-subscription-plan.subscription-section .header-row { 
  margin: 1em auto;
  border-bottom: 1px solid #9b9b9b;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.7em;
}

.custom-subscription-plan.subscription-section .header-row .icon {
  display: none;
  cursor: pointer;
  translate: 0.3s
}

.custom-subscription-plan.subscription-section:hover .header-row .icon {
  display: inline;
}

.custom-subscription-plan.subscription-section .header-row .icon:hover {
  transform: scale(1.1)
}

.custom-subscription-plan.subscription-section h4 {
  margin: 0 0 0.5em;
  font-size: 1em;
  font-weight: 600;
}

.custom-subscription-plan.subscription-section .icon {
  margin-right: 10px;
}

.custom-subscription-plan.subscription-section .subscription-details {
  justify-content: space-between;
}

.custom-subscription-plan.subscription-section .subscription-details p {
  font-size: .9em;
}