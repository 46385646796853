.social-logos-animated {
  position: absolute;
  position: absolute;
  top: -75px;
  right: -75px;
}

#socialContainer {
  position: relative;
}

#socialContainer .logo {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0,0,0);
}

#socialContainer .logo.google {
  top: 140px;
}

#socialContainer .logo.tiktok {
  right: 130px;
  top: 230px;
}

#socialContainer .logo.youtube {
  top: 120px;
  right: 120px;
}

#socialContainer .logo.facebook {}

#socialContainer .logo.instagram {
  top: 20px;
  right: 125px;
}