.plan-select-text {
  font-size: 1.5em;
  text-align: center;
}

.main-pricing-cards-box {
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 3em auto;
  gap: 2em;
  flex-wrap: wrap;
}
.update-plan .main-pricing-cards-box {
  max-width: 1050px;
}

.main-pricing-cards-box .small-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2em;
  flex: 1 1 auto;
}

.main-pricing-cards-box .small-row > .card {
  flex: 1 1 auto;
}

/* Users not from Shopify, single row cards */
.main-pricing-cards-box .pricing-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2em;
  width: 100%;
}

.main-pricing-cards-box .pricing-row > .pricing-card {
  flex: 1;
  max-width: 300px;
}

.custom-plan-card {
  background: #f6f5f8;
  border: 1px solid #e6e6e6;
  border-radius: 1em;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2em;
  display: flex;
  align-items: baseline;
  position: relative;
  overflow: hidden;
  transition: 0.1s;
}

.custom-plan-card .price-container button {
  width: min-content;
  margin: 0.5em 0 0;
  padding: 0.5em 2em;
  font-size: 1.1em;
}

.price-container .active-plan-button {
  background: linear-gradient(87.8deg, #2980a4 10%, #8bc260 160%);
  color: var(--main-white);
  border: var(--main-green) 1px solid;
}

.price-container .active-plan-button:hover {
  background: linear-gradient(87.8deg, #2980a4 10%, #8bc260 150%);
  color: var(--main-white);
  border: var(--main-green) 1px solid;
}

.extra-info {
  color: rgb(170, 0, 0);
  margin: 4em auto 2em;
}

.not-visible {
  opacity: 0;
}
.plans-green-header {
  color: var(--main-green);
  font-size: 1.3em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.plans-green-header .small-text {
  font-size: 0.5em;
  font-weight: 400;
  margin-left: 0.5em;
  color: var(--text-gray);
}
.plans-green-header .gradient-text {
  background: linear-gradient(
    87.8deg,
    var(--primary-green) 0%,
    var(--second-green) 70%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bg-cont {
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.pricing-cards-cont .update-plan-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  margin: 3em auto;
  margin-bottom: 0 !important;
  align-items: center;
}

.pricing-cards-cont .update-plan-header .back-button {
  display: flex;
  background-color: transparent;
  cursor: pointer;
  color: rgb(180, 177, 177);
  font-size: 0.7em;
  border-radius: 5px;
  border: rgb(180, 177, 177) 1px solid;
  align-items: center;
  justify-content: center;
  padding: 0 2em;
  height: 2em;
}

.pricing-cards-cont h1.intro {
  color: var(--text-gray);
  font-size: 1.6em;
  text-align: center;
  font-weight: 600;
}

.pricing-cards-cont h4.explanation-text {
  color: var(--text-purplish-gray);
  text-align: center;
  font-weight: 500;
  font-size: 1em;
  margin: 2em auto 0;
  max-width: 760px;
}

.pricing-cards-cont h4.explanation-text .green {
  color: var(--second-green);
}

.pricing-cards-cont .switch-cont {
  max-width: 300px;
  margin: 0 auto;
  width: 90%;
  position: relative;
}

/* .pricing-cards-cont .switch-cont .yearly-discount-text {
  position: absolute;
  z-index: 1;
  right: -10%;
  top: 20%;
  box-shadow: 0 1px 5px 1px #ccc;
  padding: 3px 10px;
  border-radius: 20px;
  background: var(--second-green);
  font-weight: 600;
  color: var(--main-green);
  font-size: 0.75em;
  transform: rotate(20deg) skew(-25deg);
} */

.pricing-cards-cont .save-text {
  color: var(--main-green);
  font-size: 0.8em;
  font-weight: 700;
  margin: 0.5em 0;
}
.pricing-cards-cont.YEARLY .save-text {
  display: none;
}

/* .pricing-cards-cont.YEARLY .yearly-discount-text {
  display: none;
} */

/* .pricing-cards-cont .switch-cont .switch.right .selection {
  background: #949494;
} */

/* Enterprise kartı yatay formatta olmayacağı için bu stil gereksiz
.pricing-cards-cont .enterprise-card-container {
  width: 100%;
}
*/

.pricing-cards-cont .ad-health-checkup-container {
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .main-pricing-cards-box .small-row,
  .main-pricing-cards-box .pricing-row {
    flex-wrap: wrap;
  }

  .pricing-card {
    flex: 1 1 calc(50% - 1em);
    min-width: 280px;
  }
}

@media (max-width: 768px) {
  .pricing-card {
    flex: 1 1 100%;
  }

  .enterprise-card .features {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1100px) {
  .pricing-cards-cont .switch-cont {
    max-width: 210px;
  }

  .pricing-cards-cont .switch-cont .switch {
    font-size: 0.8em;
  }
}

@media (max-width: 1164px) {
  .main-pricing-cards-box {
    flex-direction: column;
  }

  .main-pricing-cards-box .small-row,
  .main-pricing-cards-box .pricing-row {
    width: 100%;
  }
}

@media (max-width: 485px) {
  .main-pricing-cards-box {
    flex-direction: column;
  }
  .plan-name-row {
    margin-top: 0em;
  }
  .pageview-container {
    margin-top: 0em;
    height: 4em;
    margin-bottom: 3em;
  }
  .features {
    height: 7em;
  }
  .price-container {
    margin-top: 0em;
  }
}
