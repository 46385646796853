.success-stories-slider {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 20em;
  min-width: 20em;
  max-width: 20em;
  align-items: flex-start;
}

.success-stories-slider .title {
  font-size: 1.5em;
  font-weight: 500;
  color: #1a1a1a;
  margin: 0 0 0.75em 0;
}

.success-stories-slider .content .company {
  background: white;
  border-radius: 1.25em;
  padding: 1em;
  box-shadow: 0 0.125em 0.5em rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;
  height: 21em;
  border: 0.0625em solid #dfeaf2;
}

.success-stories-slider .content .company .main {
  display: flex;
  position: relative;
  min-height: 7.5em;
  height: 100%;
  width: 100%;
  min-width: 17.5em;
}

.success-stories-slider .content .company .main .texts {
  max-width: 65%;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.success-stories-slider .content .company .main .texts .title {
  display: flex;
  gap: 0.3125em;
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 0.25em;
}

.success-stories-slider .content .company .main .texts .title .value {
  color: #4caf50;
}

.success-stories-slider .content .company .main .texts .description {
  font-size: 1em;
  color: #94959b;
  margin: 0;
  line-height: 1.4;
}

.success-stories-slider .content .company .main .texts .metrics {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 0.5em;
}

.success-stories-slider .content .company .main .texts .metric {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  padding: 0.5em 0;
}

.success-stories-slider .content .company .main .texts .metric .value {
  color: #4caf50;
  font-size: 1em;
  font-weight: 600;
}

.success-stories-slider .content .company .main .texts .metric .name {
  color: #94959b;
  font-size: 0.9em;
  font-weight: 500;
}

.success-stories-slider .content .company .main .image {
  width: 14.25em;
  height: 14.25em;
  border-radius: 50%;
  position: absolute;
  right: -40%;
  top: 20%;
  overflow: hidden;
}

.success-stories-slider .content .company .main .image .background-image {
  width: 62%;
  height: 100%;
  object-fit: cover;
}

.success-stories-slider .slider-indicators {
  display: flex;
  gap: 0.25em;
  margin-bottom: 3em;
}

.success-stories-slider .slider-indicator {
  width: 1.5em;
  height: 0.35em;
  background: #e0e0e0;
  border-radius: 0.125em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.success-stories-slider .slider-indicator.active {
  background: #4caf50;
  width: 2em;
}

.success-stories-slider .controller {
  display: none;
}

.success-stories-slider .controller.prev {
  left: -0.75em;
}

.success-stories-slider .controller.next {
  right: -0.75em;
}

.success-stories-slider .controller .icon {
  color: #666;
  font-size: 0.75em;
}

.success-stories-slider .controller.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 1240px) {
  .success-stories-slider {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .success-stories-slider .content {
    width: 100%;
  }
  .success-stories-slider .content .company .main .image {
    left: 85%;
  }
}
