.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: var(--main-white);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  padding: 1em;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #2a006e;
  color: var(--main-white);
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

/* .modal__footer {
  padding: 1rem 0.5rem;
} */

.footer-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
}

.extra {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

/* Modal - Start */
.modal-hook {
  pointer-events: none;
}

.modal-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  pointer-events: none;
}
.modal-overlay:has(.USE_DESKTOP) {
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}

.modal-container {
  background: var(--main-white);
  border-radius: 0.5em;
  box-shadow: 0px 1px 2px #aaa;
  min-width: 300px;
  pointer-events: all;
}
.modal-container.USE_DESKTOP {
  background: transparent;
  box-shadow: none;
  max-width: 370px;
  z-index: 99999;
  pointer-events: none;
}
.adHealthCheckupWaiting {
  border-radius: none;
  box-shadow: none;
  min-width: 300px;
  pointer-events: all;
  background: transparent;
}

.paymentRequired {
  border-radius: 1em;
  box-shadow: none;
  max-height: 600px;
}
.demoSession {
  box-shadow: none;
  width: 50%;
  max-width: 1500px;
  width: 80%;
  height: 75%;
}
.beforeYouCancel {
  width: 80%;
  max-width: 800px;
}

.modal-container .header-title {
  color: var(--main-green);
  font-weight: 600;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.modal-container > div {
  padding: 1.5em;
}
.modal-container.CHECKUP_MEETING > div {
  padding: 2em;
}

.modal-container .content-box {
  width: 95%;
  margin: 1em auto;
}

.modal-container .are-you-sure {
  padding-top: 1em;
  padding-bottom: 0em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  grid-gap: 1em;
  gap: 1em;
  max-width: 450px;
}

.modal-container .are-you-sure .action-bar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.modal-container .payment-required {
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 800px;
  /* align-items: flex-end; */
  justify-content: center;
}

.modal-container .payment-required .info-icon {
  font-size: 2.5em;
  color: #62b446;
  margin: -0.2em auto -0.5em;
}

.modal-container .payment-required ul {
  /* list-style-type: disc; */
  padding-left: 20px;
  margin-bottom: 2.5em;
}

.modal-container .payment-required ul li {
  margin: 0.5em auto;
}

.modal-container .payment-required ul li::before {
  content: '\2022';
  color: #62b446;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 1.2em;
  margin-left: -1em;
}

.modal-container .payment-required .schedule-a-demo-link {
  text-align: right;
  text-decoration: none;
  color: #016b92;
  transition: 0s !important;
  margin-top: -1em;
}

.modal-container .payment-required .schedule-a-demo-link:hover {
  color: #015370;
  font-weight: 500;
}

.modal-container .footer {
  margin: 0 auto;
  /* width: 90%; */
  width: 100%;
  /* border-top: 1px solid rgb(187, 187, 187); */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
  padding: 0 0 1em;
}

.modal-container .footer button {
  padding: 0.2rem 1rem;
  min-width: 100px;
  font-size: 0.85em;
  font-weight: 500;
}
@media (max-width: 1400px) {
  .demoSession {
    width: 80%;
  }
}
@media (max-width: 1100px) {
  .demoSession {
    width: 90%;
  }
}

/* Modal - End */
