.adcr {
  position: sticky;
  top: 20px;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #dadce0;
  max-width: 25em;
}

.adcr.no-border {
  border: none;
}

.adcr.enhenced-catalog {
  min-height: 450px;
}

.adcr.modal-preview {
  max-width: 90%;
  min-height: 390px;
  z-index: 5;
}

.adcr h3 {
  margin: 0;
  padding: 12px 16px;
  font-size: 14px;
  color: #5f6368;
  border-bottom: 1px solid #dadce0;
  background: #f8f9fa;
  border-radius: 8px 8px 0 0;
  text-align: left;
}

.adcr .helper-text {
  color: #5f6368;
  font-size: 13px;
  padding: 16px;
  text-align: center;
  margin: 0;
}

.ad-creatives-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: #fff;
  border-radius: 0 0 8px 8px;
}

.ad-creative-cont {
  position: relative;
  overflow: hidden;
  transform: scale(0.5) translate(-50%, -50%);
  background: #f8f9fa;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #dadce0;
  border-radius: 4px;
}

.ad-creative-cont iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 768px) {
  .adcr {
    justify-self: center;
  }
}

.preview-iframe-container {
  overflow: hidden;
}

.preview-iframe-container iframe {
  border: none;
}

.preview-error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border: 1px solid #dadce0;
  border-radius: 4px;
}

.preview-error p {
  margin: 0;
  color: #5f6368;
  font-size: 13px;
  text-align: center;
}

/* Iframe yüklenene kadar gösterilecek loading durumu için */
