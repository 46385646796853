.user-name-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user-name-area .domain {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-name-area .domain {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-name-area .domain .text {
  color: #94c878;
  font-size: 1.7em;
  font-weight: 600;
  cursor: pointer;
  line-height: 0.6em;
}

.user-name-area .domain .text:hover {
  text-decoration: underline;
}

.user-name-area .actions {
  display: flex;
  gap: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-name-area .actions .link {
  color: #1a9ddd;
  text-decoration-line: underline;
  text-decoration-color: #1a9ddd;
  cursor: pointer;
  transition: color 75ms;
  font-size: 0.9em;
}

.user-name-area .actions .link:hover {
  color: #0b649f;
}

.user-name-area .move-to-leads-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 1.8em;
  background: #081f1ae3;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  color: var(--main-white);
}
