.account-settings {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.account-settings .dates {
  display: flex;
  flex-direction: column;
  color: #909090;
}

.account-settings .cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
  max-width: 1200px;
}

.account-settings .cards-container .settings-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  border-radius: 0.75em;
  border: 1px solid #dfeaf2;
  padding: 1em;
  transition: all 150ms;
}

.account-settings .cards-container .settings-card .texts {
  display: flex;
  flex-direction: column;
}

.account-settings .cards-container .settings-card .texts .title,
.account-settings .cards-container .settings-card .content-wrapper > .title {
  color: #202327;
  font-weight: 500;
}

.account-settings .cards-container .settings-card .texts .description {
  color: #9099a2;
  font-size: 0.875em;
}

.account-settings .cards-container .settings-card .change-button {
  position: relative;
  background: white;
  background-clip: padding-box;
  border: none;
  isolation: isolate;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  color: var(--main-green);
}
.account-settings .cards-container .settings-card .change-button:hover {
  color: var(--main-green-hover);
}

.account-settings .cards-container .settings-card .change-button::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 2px;
  background: var(--main-green-gradient);
  border-radius: 0.5em;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.account-settings .cards-container .settings-card .change-button::after {
  content: '';
  position: absolute;
  inset: 0;
  background: white;
  border-radius: 0.8em;
  z-index: -1;
}

.account-settings .cards-container .settings-card .change-button:hover::before {
  opacity: 1;
  filter: drop-shadow(0 0 10px rgba(0, 194, 255, 0.5));
}

.account-settings .cards-container .settings-card .change-button:disabled {
  color: gray;
  opacity: 0.5;
}

.account-settings .cards-container .settings-card .content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  .current-address {
  display: flex;
  gap: 0.75em;
  font-size: 0.75em;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  .email-card-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  .password-card-content-wrapper {
  margin-top: 1em;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  .current-address
  .title {
  color: #202327;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  .current-address
  .email {
  color: #9099a2;
}

.account-settings .cards-container .settings-card .content-wrapper form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.account-settings .cards-container .settings-card .content-wrapper form input {
  padding: 0.75em;
  border-radius: 0.5em;
  border: 1px solid #f1f2f3;
  background: #fcfcfc;
  font-size: 16px;
  color: #202327;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  form
  input::placeholder {
  color: #9099a2;
  font-weight: 500;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  form
  input:focus {
  outline-color: #8a929b;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  form
  .buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 30px;
  gap: 0.5em;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  form
  .buttons
  .cancel-button {
  background: none;
  color: #c0c6cd;
  font-weight: 600;
  height: 100%;
  transition: all 100ms;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  form
  .buttons
  .cancel-button:hover {
  color: #8a929b;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  form
  .buttons
  .save-button {
  position: relative;
  background: white;
  background-clip: padding-box;
  border: none;
  isolation: isolate;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: 0.8em;
  color: #333;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  form
  .buttons
  .save-button::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 2px;
  background: var(--main-green-gradient);
  border-radius: 0.5em;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0.4;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  form
  .buttons
  .save-button::after {
  content: '';
  position: absolute;
  inset: 0;
  background: white;
  border-radius: 0.8em;
  z-index: -1;
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  form
  .buttons
  .save-button:hover::before {
  opacity: 1;
  filter: drop-shadow(0 0 10px rgba(0, 194, 255, 0.5));
}

.account-settings
  .cards-container
  .settings-card
  .content-wrapper
  form
  .buttons
  .save-button:disabled {
  color: gray;
}

@media (max-width: 1200px) {
  .account-settings .cards-container {
    grid-template-columns: repeat(1, 1fr);
    max-width: 1200px;
  }

  .account-settings .cards-container .settings-card {
    max-width: unset;
    width: 100%;
  }
}

@keyframes rotateBorderColors {
  0% {
    background: conic-gradient(from 0deg, #00c2ff, var(--main-green), #00c2ff);
  }
  100% {
    background: conic-gradient(
      from 360deg,
      #00c2ff,
      var(--main-green),
      #00c2ff
    );
  }
}
