.companyLogo {
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.fileInput {
  display: none;
}

.toolbarBoxInsideItemContentImage {
  width: 48px;
  height: 48px;
  object-fit: contain;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(1, 1, 1, 0.05);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinnerIcon {
  width: 24px;
  height: 24px;
  color: #4c4c53;
  animation: spin 750ms linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
