.create-full-funnel-from-existing-campaigns {

}

.create-full-funnel-from-existing-campaigns .ad-campaigns-container {
  margin: 2em auto
}

.create-full-funnel-from-existing-campaigns .add-campaign-button {
  margin-top: 1em;
}

.create-full-funnel-from-existing-campaigns .ad-campaign-row {
  margin-bottom: 1em;
}