.facebook-date-picker {
  position: relative;
}

.facebook-date-picker .time-range {
  position: relative;
}

.facebook-date-picker .time-range button {
  border: none;
  border-radius: 0.5em;
  background: rgb(240 240 240);
  padding: 5px 0.5em;
  transition: 0;
}

.facebook-date-picker .time-range button:hover {
  background: rgb(220, 220, 220);
}

.facebook-date-picker .date-selection-popover .react-datepicker {
  font-size: 0.7em;
}

.facebook-date-picker .date-selection-popover {
  /* display: none; */
  visibility: hidden;
  position: absolute;
  top: 3em;
  left: 0;
  background: var(--main-white);
  box-shadow: 0 1px 4px 1px #ccc;
  min-width: 300px;
  border-radius: 1em;
  padding: 1em;
  z-index: 100;
}

.facebook-date-picker .date-selection-popover.open {
  visibility: visible;
}

.date-selection-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2em;
}

.facebook-date-picker .react-datepicker__day-name,
.facebook-date-picker .react-datepicker__day,
.facebook-date-picker .react-datepicker__time-name {
  width: 1.2rem;
  line-height: 1.2rem;
}

.facebook-date-picker .react-datepicker__current-month {
  font-size: 0.8rem;
}

.facebook-date-picker .date-selection-popover h6 {
  margin: 0 auto 1em;
  font-weight: 500;
  font-size: 1em;
}

.facebook-date-picker .date-selection-popover ul {
  font-size: 0.8em;
  padding-left: 0;
  list-style-type: none;
}

.facebook-date-picker .date-selection-popover ul li {
  cursor: pointer;
}

.facebook-date-picker .date-selection-popover ul li:hover {
  color: rgb(0, 84, 140);
  font-weight: 500;
}

.facebook-date-picker .date-selection-popover ul li.active {
  color: var(--text-blue);
  font-weight: 500;
}

.facebook-date-picker .date-selection-popover .react-datepicker,
.facebook-date-picker .date-selection-popover .react-datepicker__header {
  border: none;
}

.facebook-date-picker .date-text {
  font-size: 0.8em;
  color: var(--text-gray);
  margin: 0 0 0.5em;
}

.facebook-date-picker .date-selection-popover .action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
}

.facebook-date-picker .date-selection-popover .action-buttons .button {
  padding: 3px 15px;
  font-size: 0.8em;
  transition: 0s;
}

.facebook-date-picker .date-selection-popover .action-buttons .update {
  color: var(--main-white);
}

.facebook-date-picker .toggle-button {
  padding: 3px 15px;
  font-size: 0.9em;
  border-radius: 4px;
  border: none;
  display: flex;
  gap: 0.5em;
  color: var(--text-gray);
  cursor: pointer;
}
