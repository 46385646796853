.complete-registration {
  display: flex;
}

.complete-registration .form-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.complete-registration .almost-done-section {
  flex: 1;
  position: relative;
  max-width: 85vh;
  align-self: center;
}
.complete-registration .almost-done-section img {
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}
.complete-registration .background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: auto;
}
.complete-registration .contact-number-wrapper .form-control label {
  display: block;
}
.complete-registration .error-text {
  margin-top: 5px !important;
}

.complete-registration .form-section .login-cont {
  max-width: 600px;
  width: 90%;
  margin: 2em auto;
}

.complete-registration .log-out-link {
  margin: 1em auto;
  cursor: pointer;
  color: var(--text-blue);
}

.complete-registration .log-out-link:hover {
  color: var(--main-blue);
}

.complete-registration .error-text {
  margin-top: -10px;
}

.complete-registration .error-text.country {
  margin-top: 5px;
  margin-bottom: 5px;
}

.complete-registration .error-text.verify {
  margin: unset;
  margin-top: 10px;
}

.complete-registration .action-button {
  height: 3em;
  font-size: 1em;
  padding: 0 1em;
  background: var(--main-green-gradient);
  font-weight: 600;
}
.complete-registration .action-button:hover {
  background: var(--main-green-gradient-hover);
}

.complete-registration .contact-number-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.complete-registration .contact-number-wrapper .label-row,
.complete-registration .contact-number-wrapper .input-row {
  display: grid;
  grid-template-columns: 1fr 2fr min(6em);
  gap: 1em;
}

.complete-registration .contact-number-wrapper .label-row label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9em;
}

.complete-registration .contact-number-wrapper .form-control {
  margin: 0;
}

.complete-registration .contact-number-wrapper .input-row .error-text {
  margin-top: 10px;
}

.complete-registration .contact-number-wrapper .verify-button {
  outline: none;
  border: none;
  border-radius: 0.5em;
  padding: 0.75em 0.5em;
  cursor: pointer;
  background-color: #85ca6e20;
  border: 1px solid #85ca6e30;
  transition: all 0.3s;
  color: #65a450;
  height: 3em;
  margin-top: 1.2em;
}

.complete-registration .contact-number-wrapper .verify-button:hover {
  background-color: #85ca6e30;
}

.complete-registration .contact-number-wrapper .verify-button.verified {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25em;
  background: var(--main-green);
  color: var(--main-white);
  font-weight: 600;
  cursor: default;
  height: 3.5em;
}

.complete-registration .contact-number-wrapper .verify-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 1200px) {
  .complete-registration {
    overflow-y: scroll;
  }
  .complete-registration .form-section {
    display: block;
  }
  .complete-registration .form-section .login-cont {
    max-width: 500px;
  }
  .complete-registration .almost-done-section {
    display: none;
  }
}
