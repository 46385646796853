.navbar-profile-menu-cont {
  position: relative;
}

.navbar-profile-menu {
  position: absolute;
  bottom: -12px;
  left: 101%;
  min-width: var(--lg-navbar-width);
  background: #011823;
  border-radius: 1.5em;
  box-shadow: 0 0.1875em 0.1875em -0.1875em rgba(0, 0, 0, 0.7);
  z-index: 20;
  font-size: 0.9em;
  overflow: hidden;
  max-width: 0;
  transition: max-width 0.3s ease-out;
  white-space: nowrap;
}

.navbar-profile-menu-cont:hover .navbar-profile-menu,
.navbar-profile-menu:hover,
.navbar-profile-menu.open {
  max-width: 12.5em;
  transition: max-width 0.3s ease-in;
}

.navbar-profile-menu-cont .navbar-profile-menu {
  transition: max-width 0.3s ease-out 0.5s;
}

.navbar-profile-menu .menu-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.navbar-profile-menu-cont .nav-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625em;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.navbar-profile-menu-cont .nav-item-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: left 0.3s ease;
}

.navbar-profile-menu-cont .nav-item-content:hover::before,
.navbar-profile-menu-cont .nav-item.menu-open .nav-item-content::before {
  left: 0;
}

.navbar-profile-menu-cont .nav-item.menu-open .nav-item-content::before {
  transition-delay: 0.3s;
}

.navbar-profile-menu-cont:not(:hover) .nav-item-content::before {
  transition-delay: 0s;
}

.navbar-profile-menu-cont .nav-item-left {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.navbar-profile-menu-cont .profile-letter {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: #4caf50;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 0.625em;
  position: relative;
  z-index: 1;
}

.navbar-profile-menu-cont .user-name,
.navbar-profile-menu-cont .user-email {
  position: relative;
  z-index: 1;
}

.navbar-profile-menu-cont .user-name {
  font-weight: bold;
}

.navbar-profile-menu-cont .user-email {
  font-size: 0.8em;
  color: #ccc;
}

.navbar-profile-menu .menu-item {
  display: flex;
  align-items: center;
  padding: 1em 1.5em;
  color: white;
  text-decoration: none;
  transition:
    background-color 0.3s,
    opacity 0.3s ease-in-out 0.2s; /* Gecikmeli opacity geçişi */
  opacity: 0;
  gap: 0.5em;
}
.navbar-profile-menu-cont:hover .menu-item,
.navbar-profile-menu:hover .menu-item,
.navbar-profile-menu.open .menu-item {
  opacity: 1;
  transition:
    background-color 0.3s,
    opacity 0.3s ease-in-out 0.2s; /* Gecikmeli opacity geçişi */
}
.navbar-profile-menu .menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.navbar-profile-menu .menu-item .icon {
  margin-right: 0.625em;
}

.navbar-profile-menu .menu-item .badge {
  width: 0.5em;
  height: 0.5em;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.icon-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.icon-container .badge {
  position: absolute;
  top: -0.25em;
  right: -0.25em;
  width: 0.5em;
  height: 0.5em;
  background-color: red;
  border-radius: 50%;
}

@media (max-width: 1470px), (max-height: 1030px) {
  .navbar-profile-menu {
    font-size: 0.9em;
  }
  .navbar-profile-menu {
    min-width: var(--lg-navbar-width);
  }
}

@media (max-width: 768px) {
  .navbar-profile-menu {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    min-width: 100%;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.05);
    margin-top: 0.3rem;
  }

  .navbar-profile-menu .menu-item {
    padding: 0.8rem 1rem;
  }

  .navbar-profile-menu-cont .nav-item-content {
    padding: 0.7rem;
  }

  .navbar-profile-menu-cont .profile-letter {
    width: 2rem;
    height: 2rem;
  }
}
