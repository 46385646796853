.fluid-card-container {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  width: 100%;
}
.fluid-card-container.campaign-detail {
}

.fluid-card-container .fluid-card-header {
  background: #146681;
  border-radius: 7px 7px 0 0;
  padding: 0.5em 1em;
  z-index: 0;
  color: var(--main-white);
}

.fluid-card-container .fluid-card-content {
  background: var(--main-white);
  border-radius: 0.7em;

  padding: 0.5em 1em;

  height: 100%;
}
