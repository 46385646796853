.checklist-card {
  border-radius: 16px;
  background: #ffffff;
  overflow: hidden;
  font-size: var(--font-14);
  flex: 1 1 50%;
  position: relative;
  border: 1px solid #e5e9f0;
}

.checklist-card.is-open {
  border: 1px solid #e5e9f0;
}

.checklist-card .header {
  padding: 0.5em 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-16);
  /* cursor: pointer; */
  background: #fafbfc;
  border-bottom: 1px solid #e5e9f0;
  transition: all 0.2s ease;
  font-weight: 500;
}

.checklist-card .header:hover {
  background: #fafbfc;
}

.checklist-card .header.completed {
  background: #ebfaf3;
  border-color: #d1f2e5;
  color: #0f172a;
}

.checklist-card.is-open:has(.completed) {
  border: 1px solid var(--second-green);
}

.checklist-card .header .name-row {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 0.3em;
}

.checklist-card .header .name-row .number {
  font-size: var(--font-18);
  font-weight: 600;
}

.checklist-card .header .name-row .name {
}

.checklist-card .header .status-row {
  display: flex;
  align-items: center;
  gap: 1em;
}

.checklist-card .header .status-row .icon {
  color: #10b981;
  font-size: var(--font-18);
}

.checklist-card .content {
  display: none;
  padding: 1.5em;
  width: 100%;
}

.checklist-card.is-open .content {
  display: inline-block;
}

.checklist-card .corner-logo {
  position: absolute;
  bottom: -4em;
  right: -4em;
  opacity: 0.5;
  /* width: 25%; */
  height: 100%;
  max-height: 200px;
  z-index: -1;
}

@media (max-width: 468px) {
  .checklist-card .content {
    padding: 1.5em 0.5em;
  }
}
