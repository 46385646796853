.revenue-graph-main {
  .column {
    gap: 0.5em;
    align-items: flex-start;
  }

  .row {
    justify-content: space-between !important;
  }
  .graph {
    justify-content: center;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .top-bottom-revenue {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 3em;
  }
  .average-revenue {
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px var(--transparent-gray);
    background: linear-gradient(
      90deg,
      var(--primary-green) 0%,
      var(--second-green) 90%
    );
    color: var(--main-white);
    padding: 1em;
  }
  .top-revenue,
  .bottom-revenue {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2em;
  }

  .average-revenue .small-text {
    font-size: 1em;
  }
  .average-revenue .small-text,
  .average-revenue .big-text,
  .average-revenue .secondary-text {
    color: var(--main-white);
  }
  @media (max-width: 700px) {
    .second-big-text img {
      max-width: 40px;
    }
    .row {
      justify-content: flex-start !important;
    }
    .average-revenue {
      padding: 0.5em;
    }
  }
}
