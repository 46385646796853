.filter-container {
  .filters .filter-group {
    padding: 0 1em 1em;
    border-radius: 11px;
  }

  .filters .filter-group.performance {
    grid-row: 3 / 5;
  }

  .filters .filter-group:first-child {
    grid-column: 1 / 2 span;
    display: flex;
    justify-content: stretch;
    padding-bottom: 0;
    padding-top: 0;
  }

  .filters .filter-group:first-child .date-range {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-content: center;
    align-items: center;
  }

  .filters .filter-group h2 {
    font-size: 1.1em;
    font-weight: 700;
    margin-bottom: 0.7em;
    margin-top: 1.3em;
    color: #464a4b;
  }

  .filters .filter-group.is-active {
    background: #f0fff8;
  }

  .filters .filter-group .checkboxes {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .filters .filter-group .range .inputs-wrapper {
    display: flex;
    gap: 1em;
  }

  .filters .filter-group .range .inputs-wrapper .input {
    width: min-content;
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .filters .filter-group .range .inputs-wrapper .input input {
    width: 100%;
    background-color: #95919138;
    border: 0.01px solid #0000003b;
    border-radius: 0.15em;
    font: inherit;
    color: currentColor;
    min-width: 30px;
  }

  .filters .filter-group .range .inputs-wrapper .input input:focus {
    outline: 1px solid #0000004a;
  }
}

.filter-container.collapsed .filters .filter-group {
  display: none;
}

.filter-container.customers .filters .filter-group:nth-child(2) {
  grid-column: 1 / 2 span;
  display: flex;
  justify-content: stretch;
  padding-bottom: 0;
  padding-top: 0;
}

.filter-group-collapsible {
  position: relative;
}

.filter-search {
  margin-bottom: 12px;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.filter-search-input {
  width: 100%;
  padding: 8px;
  padding-right: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.filter-search-input:focus {
  border-color: var(--main-green);
}

.clear-search-button {
  position: absolute;
  right: 8px;
  background: none;
  border: none;
  color: #666;
  font-size: 20px;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 0.2s;
}

.clear-search-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
}

.show-more-button {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  text-align: left;
  transition: opacity 0.2s;
}

.show-more-button:hover {
  opacity: 0.8;
}