.modal-container.GOOGLE_ADS_SUPPORT {
  background: var(--main-white);
  border-radius: 20px;
  box-shadow: 0px 5.731px 85.828px 0px rgba(0, 0, 0, 0.25);
  width: 40vw;
  min-width: 700px;
  height: 55vh;
  max-height: 400px;
  overflow: hidden;
}

.google-ads-support {
  position: relative;
  height: 100%;
}

.google-ads-support header {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 90%;
  z-index: 2;
}

.google-ads-support header .title {
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 2em;
}

.google-ads-support header .title span.highlight {
  color: #74b751;
}

.google-ads-support .icon-bubble {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 19.237px 46.17px rgba(0, 0, 0, 0.12);
  width: 150px;
  height: 150px;
  background-color: var(--main-white);
  z-index: 2;
}

.google-ads-support .icon-bubble img.icon {
  width: 82px;
}

.google-ads-support .icon-bubble.google {
  left: -10px;
  bottom: -25px;
}

.google-ads-support .icon-bubble.youtube {
  right: 100px;
  top: 190px;
  width: 96px;
  height: 96px;
  box-shadow: 0px 9.256px 22.213px rgba(0, 0, 0, 0.12);
}

.google-ads-support .icon-bubble.youtube img.icon {
  width: 48px;
}

.google-ads-support .buttons {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  display: flex;
  gap: 1.5em;
  justify-content: center;
  z-index: 2;
  margin-top: auto;
  width: 100%;
}

.google-ads-support .buttons .book-a-call-btn {
  border-radius: 8px;
  border: none;
  padding: 1em 1.5em;
  font-size: 1.1em;
  color: #fcfcfc;
  font-weight: 500;
  background: linear-gradient(
    263deg,
    #57ba8a 44.19%,
    #5aba87 47.58%,
    #74b751 89.69%
  );
  box-shadow: 0px 2px 10px 0px rgba(126, 191, 89, 0.35);
  transition: all 200ms;
}

.google-ads-support .buttons .book-a-call-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0px 2px 10px 0px rgba(126, 191, 89, 0.5);
}

.google-ads-support .buttons .book-a-call-btn:active {
  transform: translateY(0);
}

.google-ads-support .buttons .customer-support-btn {
  border-radius: 8px;
  color: var(--text-green);
  font-weight: 500;
  border: 1px solid var(--second-green);
  background: #fcfcfc;
  box-shadow: 0px 2px 10px 0px rgba(126, 191, 89, 0.35);
  transition: all 200ms;
}

.google-ads-support .buttons .customer-support-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0px 2px 10px 0px rgba(126, 191, 89, 0.5);
}

.google-ads-support .buttons .customer-support-btn:active {
  transform: translateY(0);
}

.google-ads-support .background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
}

.google-ads-support .background .bg-bubble {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: var(--main-white);
  box-shadow: 0px 9.256px 22.213px rgba(0, 0, 0, 0.12);
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(50%);
}

.google-ads-support .background .bg-bubble.xl {
  width: 1000px;
  height: 1000px;
}

.google-ads-support .background .bg-bubble.lg {
  width: 700px;
  height: 700px;
}

.google-ads-support .background .bg-bubble.md {
  width: 400px;
  height: 400px;
}

@media (max-width: 1400px) {
  .google-ads-support .icon-bubble.google {
    bottom: 80px;
  }
}

@media (max-width: 800px) {
  .modal-container.GOOGLE_ADS_SUPPORT {
    min-width: 450px;
  }
}

@media (max-width: 435px) {
  .modal-container.GOOGLE_ADS_SUPPORT {
    min-width: 300px;
    font-size: medium;
  }
  .google-ads-support .icon-bubble.google {
    scale: 0.7;
    bottom: 110px;
    left: 50px;
  }
  .google-ads-support .icon-bubble.youtube {
    scale: 0.7;
    right: 55px;
    top: 180px;
  }
  .google-ads-support .buttons {
    font-size: 20px;
  }
  .google-ads-support header .title {
    font-size: 1.5em;
  }
}
