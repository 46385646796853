.new-navbar.collapsed .navbar-account-manager-card {
  width: 5em;
  opacity: 0;
  padding: 15px 0;
  pointer-events: none;
  transition: none;
}

.navbar-account-manager-card {
  background: none;
  transition:
    width 0.3s ease,
    opacity 0.3s ease 0.1s,
    padding 0.3s ease;
  animation-delay: 2s;
  padding: 0.5em;
  color: white;
  width: 16em;
  position: relative;
  font-size: 1em;
  z-index: 80;
  opacity: 1;
}

.navbar-account-manager-card h3 {
  margin: 0 0 15px;
  font-size: 1em;
  font-weight: 600;
}

.navbar-account-manager-card .expert-info {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  justify-content: center;
}

.navbar-account-manager-card .expert-image {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  margin-right: 15px;
  max-width: 2.5em;
  max-height: 2.5em;
}

.navbar-account-manager-card .expert-details {
  display: flex;
  flex-direction: column;
}

.navbar-account-manager-card .expert-name {
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
}

.navbar-account-manager-card .expert-email {
  font-size: 14px;
  color: #b2dafd;
}

.navbar-account-manager-card .action-buttons {
  display: flex;
  justify-content: space-around;
}

.navbar-account-manager-card .action-buttons svg {
  width: 1em;
  height: 1em;
  margin: 0 8px;
}

.navbar-account-manager-card .action-button {
  width: 4em;
  height: 2.5em;
  padding: 0.3em;
  border: none;
  border-radius: 0.8em;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1em;
  text-align: center;
  box-shadow: none;
}

.navbar-account-manager-card .action-button.get-support {
  width: 90%;
  font-size: 0.9em;
}

.navbar-account-manager-card .action-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.navbar-account-manager-card .expert-email a {
  color: inherit;
  text-decoration: none;
}

.navbar-account-manager-card .expert-email a:hover {
  text-decoration: underline;
}

@media (max-width: 1470px),
(max-height: 1030px) {
  .navbar-account-manager-card {
    padding: 6px;
    font-size: 0.9em;
  }
}