.create-campaign-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  width: 100%;
  padding: 2em;
  color: var(--main-white);
  border-radius: 7px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.25em;
  transition: all 100ms;
  background: linear-gradient(
    32deg,
    var(--primary-green),
    var(--second-green) 42%
  );
}

.create-campaign-button:hover {
  background: linear-gradient(
    32deg,
    var(--primary-green),
    var(--second-green) 52%
  );
}

.create-campaign .fluid-card-header {
  padding: 0.5em 1em;
  background: var(--main-white);
  border-radius: 7px 7px 0 0;
  z-index: 10;
  color: #404040;
  border-bottom: 1px solid #f0f0f0;
  max-width: 1500px;
  position: sticky;
  top: -6px;

  background: rgba(255, 255, 255, 0.98);
}

.create-campaign .fluid-card-header .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-campaign
  .fluid-card-header
  .header-wrapper
  .options
  .campaign-label-wrapper {
  display: flex;
  align-items: center;
  gap: 0.75em;
  border-radius: 4px;
  padding: 0.1em 0.5em;
  transition: all 100ms;
  cursor: pointer;
  font-weight: 600;
}

.create-campaign
  .fluid-card-header
  .header-wrapper
  .options
  .campaign-label-wrapper:hover {
  background: #d1d1d140;
}

.create-campaign
  .fluid-card-header
  .header-wrapper
  .options
  .campaign-label-wrapper:has(.label-error) {
  background: rgb(209 3 3 / 8%);
  border: 1px solid rgb(209 3 3 / 12%);
}

.create-campaign
  .fluid-card-header
  .header-wrapper
  .options
  .campaign-label-wrapper
  .label-error {
  font-size: 0.75em;
  font-weight: 400;
  color: rgb(209 3 3);
}

.create-campaign .fluid-card-header .header-wrapper .actions {
  display: flex;
  align-items: center;
  gap: 1em;
}

.create-campaign .fluid-card-content {
  padding: 0;
  max-width: 1500px;
  height: calc(100vh - 120px);
}

.create-campaign .fluid-card-content .create-campaign-content-wrapper {
  position: relative;
}

.create-campaign .fluid-card-content .create-campaign-content-wrapper .overlay {
  position: absolute;
  height: calc(100% + 2em);
  width: calc(100% + 2em);
  transform: translate(-1em, -1em);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(6px);
  z-index: 100;
}

.create-campaign .campaign-created-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create-campaign .campaign-created-message .icon-wrapper img {
  width: 100%;
  max-width: 70px;
  height: auto;
}

.float-publish-button {
  position: fixed !important;
  bottom: 20px !important;
  right: 55px !important;
  z-index: 1000 !important;
  /* Diğer stil tanımlamalarını buraya ekleyin */
}

.float-publish-button .fa-cloud-upload-alt {
  font-size: 24px; /* Burada istediğiniz boyutu belirleyebilirsiniz */
  margin-right: 5px; /* İkon ve metin arasında boşluk eklemek için */
}

.create-campaign .tab-buttons {
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
}

.create-campaign .tab-buttons button {
  padding: 0.5em 1em;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.create-campaign .tab-buttons button.active {
  background-color: #45b67f;
  color: white;
}

.create-campaign .tab-buttons button:hover {
  background-color: #e0e0e0;
}

.campaign-sections {
  display: flex;
  flex-direction: column;
  gap: 0em;
  width: 100%;
  align-items: center;
  margin-top: 2em;
}

.campaign-tabs {
  display: grid;
  align-items: center;
  gap: 0.5em;
  grid-template-columns: 1fr 1fr;
  padding: 4px;
  background: #eef0f2;
  border-radius: 12px;
  width: 100%;
  position: relative;
  /* box-shadow: 0 3px 15px rgba(0, 0, 0, 0.08); */
  margin: 0 auto;
  margin-bottom: 1em;
}

.tab-error-icon {
  color: #d32f2f;
  font-size: 0.9em;
  margin-left: 1em;
}
.success-message {
  margin-top: 1em;
  color: var(--second-green);
  display: flex;
  align-items: center;
  gap: 0.3em;
  font-size: 1.1em;
  margin-left: 7.5em;
  margin-top: 1.5em;
}

.success-message .link {
  color: var(--second-green);
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

.success-message .link:hover {
  color: var(--second-green-hover);
}
.campaign-tabs::after {
  content: '';
  position: absolute;
  width: calc(50% - 8px);
  height: calc(100% - 8px);
  background: white;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 8px rgba(17, 20, 24, 0.15);
  z-index: 0;
  left: 4px;
  top: 4px;
  background: linear-gradient(to bottom, #ffffff, #fafbfc);
}

.campaign-tabs[data-active-tab='Creative']::after {
  transform: translateX(calc(100% + 8px));
}

.campaign-tab {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  user-select: none;
  position: relative;
  z-index: 1;
  background: transparent;
  color: #94979a;
  will-change: transform;
  transform: translateZ(0);
  padding: 0 10px;
  font-size: clamp(14px, 3.5vw, 16px);
}

.campaign-tab.active {
  color: #1a1d1f;
  font-weight: 600;
}

.campaign-tab:not(.active) {
  transition: color 0.3s ease;
}

.campaign-tab:hover:not(.active) {
  color: #1a1d1f;
}

.campaign-content {
  width: 100%;
  margin: 0 auto;
}

.left-panel {
  flex: 1;
  min-width: 0;
}

.right-panel {
  width: 400px;
  position: sticky;
  top: 1rem;
}

@media (max-width: 445px) {
  .campaign-tabs {
    width: 90vw;
    min-width: 280px;
  }

  .campaign-tab {
    height: 36px;
    padding: 0 8px;
  }

  .campaign-tabs::after {
    width: calc(50% - 6px);
    height: calc(100% - 6px);
    left: 3px;
    top: 3px;
  }
}

@media (max-width: 320px) {
  .campaign-tabs {
    min-width: 250px;
  }

  .campaign-tab {
    height: 32px;
    padding: 0 6px;
  }
}

@media (min-width: 768px) {
  /* .campaign-tabs {
    width: 400px;
  } */
}

.left-panel > * {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.4s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.targeting-section {
  position: relative;
}

.targeting-error-icon {
  position: absolute;
  top: 0;
  right: 0;
  color: #d32f2f;
  font-size: 1.2em;
}
