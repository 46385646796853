.textArea {
  width: 100% !important;
  height: auto !important;
  min-height: 80px;
  resize: vertical;
  font-family: inherit;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.2s;
  background-color: #fff;
}

.hasPlaceholders {
  border-color: var(--second-green, #58ba8b);
  background-color: rgba(88, 186, 139, 0.05);
}

.placeholderHelper {
  margin-top: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  font-size: 13px;
}

.placeholderHelperTitle {
  font-weight: 500;
  margin-bottom: 8px;
  color: #555;
}

.placeholderList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.placeholderTag {
  display: inline-block;
  padding: 4px 8px;
  background-color: rgba(88, 186, 139, 0.1);
  border: 1px solid rgba(88, 186, 139, 0.3);
  border-radius: 4px;
  color: var(--second-green, #58ba8b);
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
}

.placeholderTag:hover {
  background-color: var(--second-green, #58ba8b);
  color: white;
}

.textArea:focus {
  outline: none;
  border-color: var(--second-green, #58ba8b);
  box-shadow: 0 0 0 2px rgba(88, 186, 139, 0.1);
}

.textStyleControls {
  display: flex !important;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.styleButton {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.2s;
  padding: 0;
  flex-shrink: 0;
  color: #333;
}

.styleButton:hover {
  background-color: #e8e8e8;
  border-color: #d0d0d0;
}

.styleButtonActive {
  background-color: var(--second-green, #58ba8b);
  border-color: var(--second-green, #58ba8b);
  color: white;
}

.fontSizeControls {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.fontSizeInput {
  text-align: center !important;
  padding: 6px 8px !important;
  flex: 1;
  min-width: 0;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  height: 36px;
  transition: border-color 0.2s;
  background-color: #fff;
}

.fontSizeInput:focus {
  outline: none;
  border-color: var(--second-green, #58ba8b);
  box-shadow: 0 0 0 2px rgba(88, 186, 139, 0.1);
}

.fontSizeButton {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.2s;
  padding: 0;
  flex-shrink: 0;
  color: #333;
}

.fontSizeButton:hover {
  background-color: var(--second-green, #58ba8b);
  border-color: var(--second-green, #58ba8b);
  color: white;
}

.fontSizeButton:active {
  background-color: #4ca97a;
  transform: translateY(1px);
}

/* Enhanced container styles from Toolbar.module.css */
.elementControlsContainer {
  background: #f9f9f9;
  border: 1px solid rgba(224, 224, 224, 0.6);
  border-radius: 10px;
  padding: 0;
  width: 100%;
}

.elementControlsTitle {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid rgba(224, 224, 224, 0.6);
}

.elementControlsContent {
  margin-top: 0;
  padding: 10px;
}

.elementControlsItem {
  margin-bottom: 15px;
}

.elementControlsItem:last-child {
  margin-bottom: 0;
}

.colorPickerContainer {
  position: relative;
  margin-top: 10px;
  z-index: 10;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
}

.closePickerButton {
  display: block;
  width: 100%;
  margin-top: 15px;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s;
  color: #333;
}

.closePickerButton:hover {
  background-color: var(--second-green, #58ba8b);
  border-color: var(--second-green, #58ba8b);
  color: white;
}

.rangeInput {
  width: 70%;
  margin-right: 10px;
  height: 6px;
  -webkit-appearance: none;
  appearance: none;
  background: #e0e0e0;
  border-radius: 3px;
  outline: none;
}

.rangeInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--second-green, #58ba8b);
  cursor: pointer;
  border: 2px solid white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.rangeInput::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--second-green, #58ba8b);
  cursor: pointer;
  border: 2px solid white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.opacityValue {
  font-size: 14px;
  color: #333;
  min-width: 40px;
  text-align: right;
  font-weight: 500;
}

.valueDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 36px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  padding: 0 8px;
}

.clickable {
  cursor: pointer;
  transition: all 0.2s;
}

.clickable:hover {
  background-color: #e8e8e8;
  border-color: #d0d0d0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .colorPickerContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    max-width: 300px;
  }
}

.elementControls {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.removeElementContainer {
  background: #f9f9f9;
  border: 1px solid rgba(224, 224, 224, 0.6);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
}

.removeButton {
  width: 100% !important;
  height: auto !important;
  padding: 10px 15px;
  background-color: #f5f5f5;
  color: #d32f2f;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid rgba(211, 47, 47, 0.3);
  transition: all 0.2s ease;
}

.removeButton:hover {
  background-color: #ffebee;
  border-color: #d32f2f;
}

.removeButton .icon {
  font-size: 16px;
} 