.activity-log-table-header {
    font-size: var(--font-20);
    font-weight: 600;
    margin-bottom: 16px;
}

.activity-log-container {
    height: 600px;
    max-height: 70vh;
    overflow-y: auto;
    position: relative;
}

.facebook-activity-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
}

.facebook-activity-table th,
.facebook-activity-table td {
    border: 1px solid #ddd;
    max-width: 400px;
}

.facebook-activity-table th {
    position: sticky;
    top: 0;
    background-color: var(--second-green);
    color: white;
    padding: 12px;
    font-weight: 600;
    text-align: left;
    border-bottom: 2px solid #dee2e6;
    z-index: 1;
}

.facebook-activity-table td {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    padding: 8px;
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
}

.facebook-activity-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.facebook-activity-table tr:nth-child(odd) {
    background-color: #ffffff;
}

.facebook-activity-table tr:hover {
    background-color: #e9ecef;
}

.activity-log-next-button {
    margin: 1em auto;
}

.activity-log-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.activity-log-table-header {
    display: flex;
    align-items: center;
    gap: 8px;
}

.visibility-toggle-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    color: #666;
    transition: color 0.2s ease;
    font-size: var(--font-20);
}

.visibility-toggle-btn:hover {
    color: #333;
}