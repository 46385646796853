.notes-card .input-row {
  width: 100%;
  margin: 0.4em 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.notes-card .input-row.note-box {
  display: flex;
  flex-direction: column;
}

.notes-card .input-row.note-box .note-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ececec;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 0.5em 0.75em;
}

.notes-card .input-row.note-box .note-box-header .delete-note-icon {
  visibility: hidden;
  color: #c1c1c1;
  cursor: pointer;
}

.notes-card .input-row.note-box:hover .note-box-header .delete-note-icon {
  visibility: visible;
  transition: all 75ms;
}

.notes-card .input-row.note-box .note-box-header .delete-note-icon:hover {
  color: var(--main-red);
}

.notes-card .input-row.note-box .note-box-header .date-picker-cont input {
  font-family: inherit;
  font-style: italic;
  font-size: 0.75em;
  font-weight: 300;
  color: var(--text-gray);
  border: none;
  outline: none;
  margin: 0;
  padding: 0.2em;
  text-align: center;
  border-radius: 0.85em;
  max-width: 6.5em;
  vertical-align: middle;
  background: none;
  cursor: pointer;
  transition: all 100ms;
}

.notes-card .input-row.note-box .note-box-header .date-picker-cont input:hover {
  background: #bababa;
  color: var(--main-white);
}

.notes-card .input-row span {
  max-width: 12em;
}

.notes-card .input-row input:not(.date-picker-cont input) {
  flex: 1;
  max-width: 5em;
  border-radius: 5px;
  border: 0;
  background: var(--transparent-gray);
  margin-right: 10px;
}

.notes-card .input-row textarea {
  position: relative;
  width: 100%;
  background: #ececec;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border: none;
  outline: none;
  resize: vertical;
  font-family: inherit;
  padding: 0 1em 0.75em;
  font-size: 0.8em;
}

.notes-card .new-note-button {
  color: #0591a1;
  text-align: center;
  font-size: 0.9em;
  font-style: normal;
  font-weight: 600;
  margin: auto;
  padding: 0.5em 1em;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  transition: all 50ms ease-in;
}

.notes-card .new-note-button:hover {
  background-color: #0591a110;
  border-radius: 1em;
}

.notes-card .new-note-button:active {
  background-color: #0591a120;
}

.notes-card .new-note-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.notes-card .save-button-container {
  margin-top: 0.5em;
}
