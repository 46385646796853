.payments-card .input-row {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.payments-card .input-row:nth-child(2) {
  margin-bottom: 0;
}

.payments-card .input-row .input-wrapper {
  display: flex;
  width: 50%;
  align-items: center;
}

.payments-card .input-row .input-wrapper input {
  flex: 1;
  border-radius: 5px;
  border: 0;
  background: var(--transparent-gray);
  margin-right: 10px;
}

.payments-card .input-row .input-wrapper span {
  max-width: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 0.8em;
}

.payments-card .input-row span {
  max-width: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 0.8em;
}

.payments-card .input-row input {
  flex: 1;
  max-width: 6.7em;
  border-radius: 5px;
  border: 0;
  background: var(--transparent-gray);
  margin-right: 10px;
  height: 100%;
  font-size: 0.75em;
}

.payments-card .input-row .input-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  flex: 1;
}

.payments-card .input-row .input-cell .form-control {
  display: flex;
  margin: 0;
  height: 1.8em;
}

.payments-card .input-row .input-cell .form-control label {
  display: none;
}

.payments-card .input-row .input-cell input {
  box-shadow: unset;
  height: 100%;
}

@media (max-width: 768px) {
  .payments-card .input-row {
    width: 100%;
  }
}
@media (max-width: 589px) {
  .payments-card .input-row {
    width: 100%;
    gap: 10px;
    flex-direction: column;
    margin-bottom: 1.5em;
  }
}
