.sign-ups-container {
  height: 100%;
}

.sign-ups-container .layout {
  height: 100%;
  display: flex;
  gap: 1em;
}

.sign-ups-container .layout .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
}

.sign-ups-container .layout .content .heading {
  margin: 0;
  padding: 0 16px;
}

.sign-ups-container .layout .content .heading::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--main-green);
  margin-bottom: 16px;
}

.sign-ups-container .layout .content .not-found-text {
  margin: 0;
  color: #c4c4c4;
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 8em;
  font-size: 1.5em;
  font-weight: 600;
}

@media (max-width: 768px) {
  .sign-ups-list {
    padding: 0 0.2em 0em;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .sign-ups-container .layout {
    gap: 1.7em;
  }
  .sign-ups-container .layout .content {
    margin-right: -25px;
  }
}
