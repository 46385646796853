.boost-campaign {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1em 1.5em;
}

.boost-campaign .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 1em !important;
}

/* Header Styles */
.boost-campaign .header {
  display: flex;

  justify-content: space-between;
  gap: 16px;
}

.boost-campaign .header-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.boost-campaign .world-icon {
  border-radius: 8px;
}

.boost-campaign .title-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.boost-campaign .title-container h2 {
  font-size: var(--font-24);
  font-weight: 600;
  color: #666;
  margin: 0;
}

.boost-campaign .new-badge {
  background: #4ea8fd;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 8px;
  font-weight: 500;
  margin-top: -10px;
}

/* Description Styles */
.boost-campaign .description {
  color: #666;
  font-size: var(--font-16);
  line-height: 1.5;
  margin: 0;
}

/* Benefits Styles */
.boost-campaign .benefits {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 8px;
  max-width: 90%;
  height: 88px;
}

.boost-campaign .benefit-item {
  display: flex;

  gap: 12px;
}

.boost-campaign .check-icon {
  color: #4ea8fd;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  margin-top: 0;
}

.boost-campaign .benefit-item span {
  color: #666;
  font-size: var(--font-16);
  line-height: 1.5;
}

/* Input Styles */
.boost-campaign .boost-budget-selector {
  margin-top: 8px;
  max-width: 400px;
}

/* Footer Styles */
.boost-campaign .footer {
  margin-top: auto;
  padding-top: 24px;
  border-top: none;
  display: flex;
  justify-content: flex-start !important;
  gap: 12px;
  padding: 0em !important;
  align-items: normal !important;
}

.boost-campaign .cancel-button {
  padding: 0.8em 2em !important;
  border-radius: 8px;
  font-size: var(--font-16);
  font-weight: 500;
  color: #64748b;
  background: transparent;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
}

.boost-campaign .cancel-button:hover {
  background: #f1f5f9;
  border-color: #cbd5e1;
}

.boost-campaign .confirm-button {
  padding: 0.8em 2em !important;
  border-radius: 8px;
  font-size: var(--font-16);
  font-weight: 500;
  color: white;
  background: var(--second-green);
  border: 1px solid var(--second-green);
  transition: all 0.2s ease;
}

.boost-campaign .confirm-button:hover {
  background: var(--second-green-hover);
}

.boost-campaign .confirm-button:active {
  transform: translateY(0);
}

/* Switch özelleştirmeleri için CampaignSetupModal.css içinde: */
.modal-container.CAMPAIGN_SETUP_MODAL .switch-container {
  background: #f1f5f9;
  padding: 4px;
  border-radius: 20px;
  gap: 4px;
}

.modal-container.CAMPAIGN_SETUP_MODAL .switch-option {
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #64748b;
}

.modal-container.CAMPAIGN_SETUP_MODAL .switch-option.active {
  background: white;
  color: #1e293b;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .boost-campaign {
    padding: 0.8em 1em;
  }

  .boost-campaign .title-container h2 {
    font-size: var(--font-20);
  }

  .boost-campaign .description {
    font-size: var(--font-14);
  }

  .boost-campaign .benefits {
    flex-direction: column;
    max-width: 100%;
    height: auto;
    gap: 12px;
  }

  .boost-campaign .check-icon {
    width: 24px;
    height: 24px;
  }

  .boost-campaign .benefit-item span {
    font-size: var(--font-14);
  }

  .boost-campaign .footer .cancel-button,
  .boost-campaign .footer .confirm-button {
    padding: 0.6em 1.5em !important;
    font-size: var(--font-14);
  }
}
