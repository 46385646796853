.bar-button {
  border-radius: 8px;
  background: var(--transparent-black);
  color: var(--main-white);
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  text-decoration: none !important;
  color: var(--main-white) !important;
}

.bar-button.expandable {
  padding: 0 0.5em;
}

.bar-button.expandable:hover {
  max-width: 250px;
  gap: 0.5em;
}

.bar-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.bar-button.active {
  background-color: #13364a;
  position: relative;
}

.bar-button.active::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, var(--main-green), #085a9c);
  border-radius: 10px;
  z-index: -1;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.bar-button.adHealthCheckup,
.bar-button.bookADemoButton {
  background:
    linear-gradient(#043735 0 0) padding-box,
    linear-gradient(to right, var(--second-green), var(--primary-green))
      border-box;
  border: 1px solid transparent;
}
.bar-button.adHealthCheckup:hover,
.bar-button.bookADemoButton:hover {
  background:
    linear-gradient(#0d4d4b 0 0) padding-box,
    linear-gradient(to right, var(--second-green), var(--primary-green))
      border-box;
}
.bar-button.bookADemoButton {
  display: flex;
  gap: 0.5em;
  min-width: 130px;
  transition: 0.3s;
}
.bar-button.bookADemoButton .button-title {
  display: inline-block;
  max-width: 200px;
}

.profileicon {
  display: none;
}

.bar-button .name-first-letter {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: var(--main-white);
  font-size: 1.5em;
  font-weight: 600;
  height: 1.5em;
  text-align: center;
}

.bar-button.warning {
  background: var(--main-yellow);
}

.bar-button .button-title {
  max-width: 0;
  overflow: hidden;
  transition: 0.3s;
  font-size: 0.85em;
  text-decoration: none;
  text-overflow: clip;
  white-space: nowrap;
  display: none;
}

.bar-button.expandable:hover .button-title {
  max-width: 200px;
  display: none;
}
.right-menu .checkup-button {
  max-width: 40px;
}
.right-menu .checkup-button text {
  display: none;
}

.bar-button .img-icon {
  width: 20px;
}

.bar-button.show-title .button-title {
  max-width: 200px;
  display: inline-block;
}

@media (max-width: 1400px) {
  .bar-button {
    min-width: 35px;
    height: 35px;
  }

  .bar-button .icon {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .bar-button .img-icon {
    width: 12px;
    scale: 1.5;
  }

  .bar-button .button-title {
    max-width: 200px;
    display: inline-block;
  }
  .profileicon {
    display: block;
    margin-left: auto;
  }
  .bar-button {
    min-width: 85px;
  }

  .bar-button.bookADemoButton {
    gap: 1.5em;
    min-width: 85%;
  }
}
