.detail-card .ad-preview-container {
  position: relative;
  padding: 352px 254px;
  margin-right: -140px;
  margin-bottom: -350px;
  overflow: hidden;
  transform: scale(0.5) translate(-30%, -50%);
  border-radius: 10px;
  box-shadow: 0px 1.192px 11.024px 0px var(--transparent-gray);
  width: 100%;
  height: 100%;
  background: rgb(223, 223, 223);
  background: linear-gradient(130deg, #f1f1f1, #cdcdcd);
  background-size: 200% 200%;
  -webkit-animation: gradientAnimation 2s ease-in-out infinite;
  -moz-animation: gradientAnimation 2s ease-in-out infinite;
  animation: gradientAnimation 2s ease-in-out infinite;
  z-index: 5;
}

.detail-card .ad-preview-container iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  overflow: hidden;
}
