.table-cont table {
  border-collapse: collapse;
}

.table-cont table th {
  border: 1px solid gray;
  padding: 2px 10px;
  font-weight: 500;
  background: var(--main-green);
  color: var(--main-white);
}

.table-cont table td {
  border: 1px solid gray;
  padding: 2px 10px;
}
