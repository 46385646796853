.ai-audience-model-card .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    margin: 1em 0 0;
    border-bottom: 1px solid #9b9b9b;
}

.ai-audience-model-card .switch-container {
    max-width: 230px;
    width: 50%;
    font-size: 0.9em;
}

.ai-audience-model-card label {
    font-weight: 500;
    font-size: 0.9em;
}

.ai-audience-model-card .row {
    margin: 1em 0;
}

.ai-audience-model-card h4 {
    font-size: 1.2em;
    font-weight: 600;
    margin: 0;
}

.ai-audience-model-card .content-box.model-settings {
    background: #e4e4e4;
    padding: 1em;
    border-radius: 1em;
    margin-top: 1em;
    position: relative
}

.ai-audience-model-card .threshold-row {
    align-items: flex-end;
}

.section .attribute-name {
    color: var(--text-blue);
    font-weight: 500;
    font-size: 1.1em;
    margin: 2em 0 0.5em;
}

.ai-audience-model-card .content-box.model-settings .attribute-name {
    margin-top: 0;
}

.ai-audience-model-card .feature-tree-row {
    justify-content: space-between;
}

.ai-audience-model-card .feature-list .scrollable-list {
    max-height: 400px;
    overflow: scroll;
}

.ai-audience-model-card .feature-list .scrollable-list::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.ai-audience-model-card .feature-list .scrollable-list::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.ai-audience-model-card .feature-list .scrollable-list::-webkit-scrollbar-thumb {
    background: var(--transparent-gray);
}

.ai-audience-model-card .feature-list .scrollable-list::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.25);
}

.ai-audience-model-card .feature-list .switch-container {
    font-size: 0.75em;
    height: 1.8em;
    width: 130px;
}

.ai-audience-model-card .feature-list .switch-container .switch {
    width: 130px;
    min-width: 100px;
}

.ai-audience-model-card .ruleset-row {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.ai-audience-model-card .ruleset-row .icon {
    color: var(--main-green)
}

.ai-audience-model-card .ruleset-row .icon.red {
    color: var(--main-red)
}

.ai-audience-model-card .ruleset-row .table-name {
    color: rgb(116, 116, 116);
    font-size: 0.9em;
    font-style: italic;
}

.ai-audience-model-card .tree-settings .type-row {
    margin: 1em 0
}

.ai-audience-model-card .apply {
    height: 2em;
}

.ai-audience-model-card .action-row {
    display: flex;
    justify-content: space-between;
}

.ai-audience-model-card .action-row .button {
    min-width: 140px;
}

.ai-audience-model-card .audience-event-row {
    margin: 0 auto;
    justify-content: space-between;
}

.ai-audience-model-card .aud-event-name {
    font-size: 1.1em;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: .5em;
}

.ai-audience-model-card .aud-event-name img {
    max-width: 30px;
    max-height: 30px;
}

.ai-audience-model-card .top-visitors-slider-cont {

    align-items: center;
    max-width: 300px;
}

.ai-audience-model-card .top-visitors-slider-cont {
    gap: 1em;
    align-items: center;
}

.ai-audience-model-card .audience-metrics {
    justify-content: space-between;
    margin: 0;
    font-size: .9em;
}

.ai-audience-model-card .audience-metrics .value {
    font-weight: 500;
}

.ai-audience-model-card .left-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: .9em;
}

.ai-audience-model-card .events-action-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5em;
    flex: 0 0 40px;
}

.ai-audience-model-card .save-button {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding: .3em .6em;
    position: relative;
}


.ai-audience-model-card .delete-audience-event-button {
    display: none;
    align-items: center;
    justify-content: center;
    padding: .3em .6em;
}

.ai-audience-model-card .content-box.model-settings:hover .delete-audience-event-button {
    display: flex;
}

.ai-audience-model-card .add-new-event-button {
    margin: 0 auto;
}

.ai-audience-model-card .audience-settings-container {
    padding-left: 0;
    background: rgb(245 245 245);
    border-radius: .5em;
    font-size: .9em;
    margin-top: 1em;
}

.ai-audience-model-card .audience-settings-container .bundle-container .row {
    margin: 0
}

.ai-audience-model-card .audience-settings-container p {
    text-align: center;
    margin: 0;
}

.ai-audience-model-card .audience-settings-container .filter-bundles {
    padding: .5em 1em;    
}

.ai-audience-model-card .audience-settings-container .filter-bundles .bundle-container {
    background: rgb(228 228 228)
}

.ai-audience-model-card .audience-settings-container .filter-bundles .bundle-container .header {
    display: flex;
    justify-content: flex-start;
}

.ai-audience-model-card .audience-settings-container h4 {
    margin-bottom: .5em;
}