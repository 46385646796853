.toolbarBoxInsideItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 8px;

  transition: background-color 0.2s ease;
  min-height: 72px;

  overflow: hidden;
}

.toolbarBoxInsideItem:hover {
  background-color: rgba(88, 186, 139, 0.04);
}

.toolbarBoxInsideItemVertical {
  flex-direction: column;
  gap: 12px;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.toolbarBoxInsideItemTitle {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-gray);
  margin: 0 5px;
}

.toolbarBoxInsideItemContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.toolbarBoxInsideItemVertical .toolbarBoxInsideItemContent {
  width: 100%;
}

.toolbarBoxInsideItemContentSelect {
  width: 100%;
  height: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
  transition: background 0.1s;
}

.toolbarBoxInsideItemContentImage {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 4px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.toolbarBoxInsideItemContentImage:hover {
  border-color: #58ba8b;
  box-shadow: 0 2px 5px rgba(88, 186, 139, 0.2);
}

.toolbarBoxInsideItemContentCheckbox {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  color: #58ba8b;
  background: #58ba8b;
}

.toolbarBoxInsideItemContentColor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background 0.1s;
}

.toolbarBoxInsideItemContentColorBox {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  margin-left: 10px;
}

.toolbarBoxInsideItemContentColorInput {
  width: 70px;
  height: 30px;
  border: none;
  padding: 5px 10px;
  text-align: right;
  font-size: 12px;
  cursor: pointer;
  background: none;
  align-content: center;
  align-self: center;
}

.toolbarBoxInsideItemContentInput {
  width: 70px;
  height: 32px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 13px;
  color: #2c3e50;
  background: white;
  transition: all 0.2s ease;
}

.toolbarBoxInsideItemContentInput:focus {
  border-color: #58ba8b;
  outline: none;
  box-shadow: 0 0 0 2px rgba(88, 186, 139, 0.1);
}

.toolbarBoxInsideItemContentInputRightAligned {
  text-align: right;
}

.toolbarBoxInsideItemContentInputText {
  font-size: 13px;
  color: #6c757d;
  margin: 0 6px;
}

.toolbarBoxInsideItemContentButton {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 13px;
  color: #2c3e50;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.toolbarBoxInsideItemContentButton:hover {
  background: #f8f9fa;
  border-color: #58ba8b;
  color: #58ba8b;
}

.toolbarBoxInsideItemContentButton:active {
  transform: translateY(1px);
}

.toolbarBoxInsideItemContentButtonIcon {
  margin-right: 4px;
}

/* Refresh Button */
.toolbarBoxInsideItemContentButton svg {
  font-size: 14px;
  transition: transform 0.3s ease;
}

.toolbarBoxInsideItemContentButton:hover svg {
  transform: rotate(180deg);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to conditional content */
.toolbarBoxInsideItem:not(:first-child) {
  animation: fadeIn 0.3s ease;
}

.dividerLine {
  height: 1px;
  background: rgba(224, 224, 224, 0.6);

  width: 100%;

  animation: fadeIn 0.3s ease;
}

/* Slide animations */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
    max-height: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
    max-height: 250px;
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
    max-height: 250px;
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
    max-height: 0;
  }
}

/* Conditional content container */
.conditionalContent {
  animation: slideDown 0.25s ease-in-out forwards;
  width: 100%;
}

.conditionalContent.exit {
  animation: slideUp 0.25s ease-in-out forwards;
}

/* Özel stiller sadece CompanyTool için */
.enhencedToolItem {
  min-height: 72px !important;
  padding: 12px 8px !important;
  transition: background-color 0.2s ease !important;
  overflow: hidden !important;
}

.enhencedToolItem:hover {
  background-color: rgba(88, 186, 139, 0.04) !important;
}

.enhencedToolItem .toolbarBoxInsideItemTitle {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: var(--text-gray) !important;
  margin: 0 5px !important;
}

.enhencedToolItem .toolbarBoxInsideItemContent {
  gap: 12px !important;
}
