.meta-revenue-main {
  .column {
    gap: 1em;
    align-items: flex-start;
  }
  .row.cards {
    gap: 1.5em;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 2em;
    border-radius: 20px;
    box-shadow: 0 5px 10px var(--transparent-gray);
    width: 100%;

    background: linear-gradient(0deg, #f1f2f3 0%, var(--main-white) 100%);
    height: 100%;
    color: #0d2330;
  }
  .card img {
    width: 50px;
  }
  .card .graph img {
    width: 60px;
  }
  .card.gradient {
    background: linear-gradient(
      90deg,
      var(--primary-green) 0%,
      var(--second-green) 70%
    );
    color: var(--main-white);
  }
  .card.gradient .small-text {
    color: var(--main-white);
  }

  .card .header-text {
    font-size: 1.1em;
    font-weight: 600;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 0.5em;
  }
  .card .green-text {
    color: var(--second-green);
    font-size: 1.5em;
    font-weight: 600;
  }
  .card.gradient .green-text {
    color: var(--main-white);
  }
  .card .small-text {
    font-size: 1em;
  }

  @media (max-width: 900px) {
    .big-text img {
      max-width: 40px;
    }
    .card .green-text {
      font-size: 1.2em;
    }
    .card img {
      width: 40px;
    }
  }

  @media (max-width: 700px) {
    .row {
      flex-direction: column;
    }
    .row.cards {
      gap: 1em;
    }
    .card {
      width: 70%;
      padding: 1em;
    }
    .big-text img {
      max-width: 30px;
    }

    .card .green-text {
      font-size: 1em;
    }
    .card img {
      width: 30px;
    }
    .card .graph img {
      width: 40px;
    }
    .card .header-text {
      font-size: 1em;
    }
  }
}

@media (max-width: 700px) {
  .meta-revenue-main {
    gap: 2em !important;
  }
}
