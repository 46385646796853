.intro-logo {
  max-width: 12.5em;
  margin-bottom: 1em;
}

.intro-headline-container {
  height: 12em;
  margin-bottom: 0em;
  position: relative;
  overflow: hidden;

  .intro-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-backdrop-filter: blur(0px); /* Safari için */
  }

  .intro-headline-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .intro-headline {
    font-size: 3em !important;
    color: #abb1b8 !important;
    margin: 0;
    line-height: 1.2em;
    letter-spacing: -3px;
    word-wrap: break-word;
    hyphens: auto;
    width: 100%;
    text-align: left;
  }

  .intro-headline .highlight {
    background: linear-gradient(to right, #46b680, #8bc45d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    display: -webkit-inline-box;
  }

  @media (max-width: 1180px) {
    .intro-headline {
      font-size: 2.5em !important;
    }
  }

  @media (max-width: 468px) {
    .intro-headline {
      font-size: 2.2em !important;
    }

    .intro-logo {
      max-width: 10em;
    }
  }

  @media (max-width: 1200px) {
    .intro-headline {
      letter-spacing: -3px;
    }
  }
}
@media (max-width: 1180px) {
  .intro-headline-container {
    height: 10em;
  }
}
@media (max-width: 1000px) {
  .intro-headline {
    text-align: center;
  }
  .intro-headline-wrapper {
    justify-content: center;
  }
}
@media (max-width: 468px) {
  .intro-headline-container {
    height: 10em;
  }
}
