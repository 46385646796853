.stripe-update-card{
    display: flex;
    flex-direction: column;
    max-width: 600px;
    
}
.stripe-update-card .new-card-info{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1em;
    align-items: center;
    
}
.stripe-update-card button{
    display: flex;
    flex-direction: row;
    max-width: 100px;
    /* margin: 1em auto 0;  */
    align-items: right;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5em;
    margin-top: 1em; 
}
.stripe-update-card button:hover{
    background-color: #d9d8d8;
    cursor: pointer;
}
   