.subscription-status-slider {
  width: 20em;
  background: none;
  position: relative;
  margin-top: 2em;
}
.subscription-status-slider .subscription-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5em;
}

.subscription-status-slider .subscription-header .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.subscription-status-slider .subscription-header .subscription-header-text {
  font-size: 1.2em;
  font-weight: 500;
  text-transform: capitalize;
}

.subscription-status-slider .subscription-header .secondary-text {
  color: #94959b;
  font-size: 1em;
  font-weight: 500;
}

.subscription-status-slider .check-icon {
  font-size: 1.1em;
  color: var(--main-green);
}

.subscription-status-slider h2 {
  font-size: 1.5em;
  font-weight: 500;
  color: #1a1a1a;
  margin: 0 0 0.75em 0;
}

.subscription-status-slider .content .status-card {
  background: white;
  border-radius: 1em;
  padding: 1.25em;
  position: relative;
  box-shadow: 0 0.125em 0.5em rgba(0, 0, 0, 0.05);
  border: 0.0625em solid #dfeaf2;
}

.subscription-status-slider .status-text {
  font-size: 1em;
  color: #94959b;
  margin: 0;
  text-align: center;
}

.subscription-status-slider .slider-container {
  margin: 40px 0 20px 0px;
  position: relative;
}

.subscription-status-slider .slider-track {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.subscription-status-slider .slider-line {
  height: 4px;
  background-color: #e5e7eb;
  border-radius: 2px;
  position: relative;
}

.subscription-status-slider .slider-progress {
  height: 100%;
  background-color: #22c55e;
  border-radius: 2px;
  position: absolute;
  left: 0;
}

.subscription-status-slider .slider-value {
  position: absolute;
  font-size: 0.8em;
  color: #abb1b8;
  top: -25px;
  font-weight: 500;
}

.subscription-status-slider .slider-value.start {
  left: 0;
}

.subscription-status-slider .slider-value.current {
  left: 0;
  color: #666;
  font-size: 0.9em;
  font-weight: 600;
}

.subscription-status-slider .slider-value.end {
  right: 0;
}

.subscription-status-slider .slider-point {
  width: 8px;
  height: 8px;
  background: #e5e7eb;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  transform: translateX(-50%);
}

.subscription-status-slider .slider-point.active {
  background: #22c55e;
}
.subscription-status-slider .warning-icon {
  font-size: 1.2em;
  color: #f59e0b;
}

.subscription-status-slider .warning-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  /* color: #f59e0b; */
  font-size: 0.9em;
  font-weight: 500;
}

.subscription-status-slider .upgrade-plan {
  background: linear-gradient(
    90deg,
    rgba(139, 196, 93, 1) 0%,
    rgba(70, 182, 128, 1) 60%
  );
  color: var(--main-white);
  border: none;
  padding: 10px 1em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 500;
  width: 100%;
}
.subscription-status-slider .upgrade-plan:hover {
  border: #c5c05dcb 1px solid;
}

@media (max-width: 1240px) {
  .subscription-status-slider {
    margin-top: 2em;
    justify-content: start;
    width: 100%;
  }
}
