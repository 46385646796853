.bottom-notification-bar {
  background: var(--main-yellow);
  width: 100vw;
  font-size: 1em;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  color: var(--main-white);
  padding: 10px;
  z-index: 51;
}

.bottom-notification-bar.red {
  background: var(--main-red);
}

.bottom-notification-bar a {
  color: var(--main-white) !important;
  text-decoration: underline;
}

.bottom-notification-bar a:hover {
  color: rgb(230, 230, 230) !important;
  text-decoration: underline;
}

.bottom-notification-bar .close-button {
  position: absolute;
  right: 1em;
  bottom: 10px;
  font-size: 1em;
  color: var(--main-white);
  cursor: pointer;
  transition: 0.3s;
}

.bottom-notification-bar .close-button:hover {
  transform: scale(1.05);
  color: rgb(240, 240, 240);
}
