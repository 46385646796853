.modal-container.ADD_USER {
  width: 60%;
  max-width: 600px;
}

.add-user-modal {
  display: flex;
  flex-direction: column;
  color: var(--text-gray);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    font-size: 1.2em;
    font-weight: 500;
  }
  .close {
    cursor: pointer;
    color: var(--text-gray);
    &:hover {
      color: var(--text-purplish-gray);
    }
  }
  .buttons-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
    button {
      margin-left: 1em;
      background-color: var(--main-green);
      padding: 0.5em 1.5em;
      transition: background-color 0.3s;
      font-weight: 600;
      &:hover {
        background-color: var(--main-green-hover);
      }
    }
  }
}
