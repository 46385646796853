.konvaPreviewActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5em;
  width: 500px;
  margin: 0.5em auto 0;
  flex: 0 0 1em;
}

.leftSide {
  display: flex;
  gap: 0.5em;
}

.rightSide {
  display: flex;
  gap: 0.5em;
}

.actionBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.25em;
  border: none;
  background-color: transparent;
  font-size: 0.9em;
  color: #838383;
}

.actionBtn:not(.button--loading):disabled {
  cursor: default;
  background: transparent;
}

.actionBtn:not(.button--loading):not(:disabled):hover {
  background: #e0e0e0;
}

.actionBtn:not(.button--loading):disabled:hover {
  background: transparent;
}
