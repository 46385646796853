.productSelectionList {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 1rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 0.75rem;
  }
}

.mainHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: small;
  background: var(--main-white);
  color: var(--second-green);
}

.mainHeaderTitle {
  font-weight: 500;
  font-size: medium;
  margin-left: 10px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid var(--border-main);
  flex: 2.85;
  height: calc(100vh - var(--topbar-height) - 4em);
  overflow: hidden;

  @media (max-width: 1024px) {
    flex: none;

    min-height: 450px;
  }
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0.64;
  height: calc(100vh - var(--topbar-height) - 4em);
  position: sticky;
  padding-left: 2em;
}

.leftSideHeader {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.catalogDropdown {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  min-width: 200px;
  flex: 1;
}

.rightSideHeader h4 {
  margin-top: 0;
}

.searchBarContainer {
  flex: 0.5;
  min-width: 200px;
  max-width: 300px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0.35rem 0.5rem;
  background: #f8f9fa;
  transition: all 0.2s ease;
  height: 30px;
  gap: 0.5rem;
  max-height: 30px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
}

.searchIcon {
  color: #666;
  font-size: 0.9rem;
}

.searchBar {
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
  background: transparent;
  font-size: 0.85rem;
  height: 24px;
}

.productSelectionList :global(.catalog-row) {
  align-items: center;
  height: 100%;
  gap: 2em;
  flex: 1 1 auto;
  border: 1px solid red;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.label {
  font-size: var(--font-16);
  white-space: nowrap;
  color: #666;
  height: 30px;
  align-items: center;
  align-content: center;
  @media (max-width: 768px) {
    min-width: 80px;
  }
}

.productSelectionList :global(.custom-dropdown) {
  min-width: 200px;
  height: 30px;
  font-size: 0.9em;
}

.helperText {
  font-size: 0.9rem;
  color: var(--text-gray);
  text-align: center;
  padding: 2rem;
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin: 1rem auto;
  max-width: 800px;
  line-height: 1.5;
}

.helperIcon {
  margin-bottom: 0.5em;
  color: var(--text-gray);
}

.helperText a {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--main-green) !important;
  background-color: transparent;
  border: 1px solid var(--main-green);
  text-decoration: none;
  font-weight: 500;
  padding: 0.35rem 1rem;
  height: 30px;
  border-radius: 6px;
  transition: all 0.2s ease;
  margin: 0 0.5em;
}

.helperText a:hover {
  background-color: var(--main-green);
  color: white !important;
  transform: translateY(-1px);
}

.productsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 0.75rem;
  padding: 0.25rem 0.25rem 2rem 0.25rem;
  margin-top: 0.75rem;
  max-height: calc(100vh - 13rem);
  overflow-y: auto;
  scrollbar-width: thin;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 0.5rem;
    padding: 0.25rem 0.25rem 10rem 0.25rem;
  }
}

.productsList {
  padding: 0.5em 0em;
}

.selectedProductsList {
  max-height: 78vh;
  flex: 1;
  overflow-y: scroll;
}

.productContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #ffffff;
  height: fit-content;

  @media (max-width: 1024px) {
    padding: 0.5rem;
  }
}

.productContainer:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-color: var(--second-green);
}

.icon {
  color: var(--second-green);
}

.price {
  font-size: 0.85rem;
  font-weight: 600;
  color: var(--second-green);
  margin: 0.35rem 0;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin: 0.25rem 0;
  }
}

.nameCont {
  overflow: hidden;
}

.productName {
  font-size: 0.8rem;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3;

  @media (max-width: 1024px) {
    font-size: 0.75rem;
    -webkit-line-clamp: 1;
  }
}

.productImage {
  aspect-ratio: 1;
  width: 100%;
  margin: 0 auto 0.5rem;
  border-radius: 4px;
  overflow: hidden;

  @media (max-width: 1024px) {
    margin-bottom: 0.35rem;
  }
}

.productImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.productContainer:hover .productImage img {
  transform: scale(1.05);
}

.loadMoreButton {
  width: 100%;
  padding: 0.5rem;
  background: var(--border-main);
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--text-black);

  @media (max-width: 768px) {
    padding: 0.4rem;
    font-size: 0.8rem;
  }
}

.loadMoreButton:hover {
  background: var(--text-blue);
  color: white;
}

/* Scrollbar stilleri */
.productsGrid::-webkit-scrollbar {
  width: 4px;
}

.productsGrid::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.productsGrid::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

/* Firefox scrollbar */
.productsGrid {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex: 0 0 auto;
  padding: 0.35rem 1rem;
  height: 30px;
  min-width: 100px;
  width: 100%;
  position: relative;
  transition: all 0.3s ease;
}

.nextButtonIcon {
  font-size: 0.85rem;
  transform: translateX(0);
  transition: transform 0.3s ease;
  margin-left: 4px;
}

.nextButton:not(:disabled):hover .nextButtonIcon {
  transform: translateX(4px);
}

.nextButton:disabled .nextButtonIcon {
  opacity: 0.5;
}

.nextButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  align-self: self-end;
  min-width: 200px;

  @media (max-width: 998px) {
    width: 100%;
  }
}

.leftSideHeader :global(.custom-dropdown) {
  width: 100%;
}

.leftSideHeader :global(.custom-dropdown .dropdown) {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: #f8f9fa;
  padding: 0.35rem 0.5rem;
  height: 30px;
  font-size: 0.85rem;
  transition: all 0.2s ease;
  width: 100%;
  cursor: pointer;

  &:hover {
    border-color: var(--second-green);
  }

  .text-box {
    max-width: calc(100% - 20px);
    overflow: hidden;
  }

  .text-box .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
  }
}

.leftSideHeader :global(.custom-dropdown .options .option) {
  .text-box .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
}

.leftSideHeader :global(.dropdown) {
  :global(.dropdown-toggle) {
    border: none;
    background: none;
    padding: 0;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :global(.dropdown-menu) {
    width: 100%;
    padding: 0.5rem 0;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin-top: 0.25rem;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
}

/* Her bir label-dropdown çifti için kapsayıcı */
.dropdownWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1 1 300px;
  min-width: 250px;
  max-width: 400px;
}

.dropdownLabel {
  font-size: 0.8em;
  white-space: nowrap;
  color: #666;
  min-width: 80px;
}

.leftSideHeader :global(.custom-dropdown .dropdown .text-box) {
  max-width: calc(100% - 20px);
  overflow: hidden;
}

.leftSideHeader :global(.custom-dropdown .dropdown .text-box .name) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  cursor: pointer;
}

.leftSideHeader :global(.custom-dropdown .options .option .text-box .title) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
