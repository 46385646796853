.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: var(--font-18);
  position: sticky;
  top: 0;
  z-index: 1;
  flex: 3 1 auto;
}

.breadcrumb-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.breadcrumb-actions {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  max-width: 21em;
}

/* Tek başına olan breadcrumb item için */
.breadcrumb li:only-child {
  font-weight: 600 !important;
}

/* Tek başına olan breadcrumb item içindeki tüm elementler için */
.breadcrumb li:only-child > * {
  font-weight: 600 !important;
  color: #33383d !important;
  text-decoration: none !important;
  cursor: pointer;
  transition: all 100ms;
}

/* Alt başlıklı durumda ilk item için */
.breadcrumb li:first-child > * {
  font-weight: 600 !important;
  color: #33383d !important;
  text-decoration: none !important;
  cursor: pointer;
  transition: all 100ms;
}

/* Alt başlık için */
.breadcrumb-item.active > * {
  font-weight: 400 !important;
}

.breadcrumb-item > *:hover {
  opacity: 0.8;
}

.breadcrumb-separator {
  margin: 0 0.25em;
  color: #33383d;
  vertical-align: middle;
  scale: 1.4;
}

@media (max-width: 768px) {
  .breadcrumb-wrapper {
    flex-direction: column;
    gap: 0rem;
  }

  .breadcrumb-actions {
    width: 100%;
    justify-content: flex-end;
    align-self: self-end;
  }
}
