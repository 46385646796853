.react-joyride__tooltip {
  padding: 1.5em 1.5em 0 !important;
}

/* this is footer */
.react-joyride__tooltip>div:last-of-type {
  transform: translateY(-100%);
  margin-top: 0;
}

.react-joyride__tooltip>div:last-of-type>div {
  display: none;
}

.react-joyride__tooltip>div:last-of-type button {
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  float: none;
}


.intro {
  position: relative;
  margin: 0;
  overflow: hidden;

}

.intro .enh-logo {
  width: 130px;
  top: 0px;
  left: 0px;
  position: absolute;
}

.intro .content {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.intro .content .step-img {
  width: auto;
  height: auto;
  margin: auto;
  display: block;
}

.intro .content .progress {
  margin-top: 10px;
}

.intro .content .title {
  color: var(--main-green);
  font-weight: 500;
  font-size: 1.1em;
}

.react-joyride__tooltip button[data-action="close"] {
  padding: 1.5em !important;
}