.campaign-form {
  display: flex;
  flex-direction: column;
  gap: 1.35em;
  flex: 1 1 400px;
}
.campaign-form h3 {
  text-align: left;
}

/* Targeting form için özel max-width */
.campaign-form[data-form-type='targeting'] {
  max-width: 400px !important;
}

/* Diğer form tipleri için mevcut max-width korunacak */
.campaign-form .row {
  display: flex;
  align-items: center;
  gap: 0.75em;
  width: 100%;
  max-width: 800px;
  flex-flow: nowrap;
}
.campaign-form .row .col {
  display: flex;
  align-items: center;
  gap: 0.75em;
  width: 100%;
  max-width: 800px;
}

.campaign-form .row label {
  font-size: 1em;
  min-width: 7.5em;
  flex-shrink: 0;
}

.campaign-form .row .col label {
  min-width: 7.5em !important;
  position: relative;
  display: inline-block;
}

.campaign-form .row .form-control {
  all: unset;
  appearance: auto;
}

.campaign-form .row .form-control > * {
  all: unset;
  appearance: auto;
}

.campaign-form .row .form-control .select-cont {
  all: unset;
  appearance: auto;
}

.campaign-form .row .form-control .select-cont > * {
  all: unset;
  appearance: auto;
}

/* Make the field wrappers flex */
.campaign-form .row .countries-input,
.campaign-form .row .daily-budget-input,
.campaign-form .row .form-control,
.campaign-form .row .form-control .select-cont {
  display: flex;
  flex: 1;
  position: relative;
}

/* Remove label of the .form-control */
.campaign-form .row .form-control label {
  display: none;
}

/* Style all the fields */
.campaign-form .row .countries-input input,
.campaign-form .row .form-control input,
.campaign-form .row .form-control .select-cont select,
.campaign-form .row .form-control textarea {
  border: 1px solid #dedede;
  background: white;
  outline: none;
  color: var(--text-black);
  padding: 0.5em 0.8em;
  border-radius: 0.25em;
  font-size: 1em;
  width: 100%;
}

.campaign-form .row .form-control .select-cont select {
  -webkit-appearance: none;
  appearance: none;
}

/* Focus states */
.campaign-form .row input:focus,
.campaign-form .row .form-control .select-cont select:focus,
.campaign-form .row .form-control textarea:focus {
  outline: 1px solid #dedede;
}

/* Daily budget styling */

.campaign-form .row .daily-budget-input span {
  display: flex;
  align-items: center;
  height: 2.2em;
}

.campaign-form .row .daily-budget-input .form-control {
  flex: 1;
  margin: 0;
  padding: 0;
  max-width: 10em;
}

.campaign-form .row .daily-budget-input .form-control input {
  border: none;

  width: 100%;
}

.campaign-form .row .dash {
  margin: 0 1em;

  font-size: 1.3em;
}

/* Error styling */
.campaign-form .row.error,
.campaign-form .error.row {
  margin-top: -1em;
}

.campaign-form .row.error .error-text,
.campaign-form .error.row .error-text {
  margin: 0;
  flex: 1;
  color: #ff0000;
  font-size: 0.875em;
}

.campaign-form .error-text.dropdown {
  margin: 0;
  flex: 1;
  color: #7d7d7d;
}

/* Column layout adjustments */
.campaign-form .row.col-2:has(#ageMin) .dash {
  margin: 0 1em;
}

.campaign-form .row.col-2:has(.daily-budget-input) .col:nth-child(2),
.campaign-form .row.col-2:has(.text-field.daily-budget) .col:nth-child(2),
.campaign-form .row.col-2:has(#ageMin) .col:nth-child(2),
.campaign-form .row.col-2:has(.text-field.age) .col:nth-child(2),
.campaign-form
  .row.col-2:has(.daily-budget-input)
  + .error.row.col-2
  > .col:nth-child(2),
.campaign-form .row.col-2:has(#ageMin) + .error.row.col-2 > .col:nth-child(1) {
  display: none;
}

/* Daily budget container responsive adjustment */
.campaign-form .row:has(.daily-budget-input) {
  flex-direction: column;
  align-items: flex-start;
}

/* Gender input styling - daily budget ile aynı genişlikte */

/* Countries input max-width */
