.ad-success-main {
  .column {
    gap: 0.4em;
    align-items: flex-start;
    width: 100%;
  }
  .column.cards {
    transform: translateY(-10%);
  }
  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 0.5em;
    padding: 0em;
    background: #fcfcfc;
    border-radius: 10px;
    box-shadow: none;
  }
  .card.ai-card {
    background: linear-gradient(
      90deg,
      var(--second-green) 0%,
      var(--primary-green) 100%
    );
    color: var(--main-white);
  }

  .card-row {
    gap: 1em;
    align-items: flex-start;
  }
  .card img {
    width: 40px;
  }
  .content-part {
    width: 65%;
  }
  .row.numbers-part {
    justify-content: flex-end;
    width: 35%;
    height: 100%;
  }
  .row {
    margin: 1em;
  }
  .old-value,
  .new-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }
  .old-value {
    background-color: #f2f2f2;
  }
  .green-line {
    width: 100%;
    height: 2px;
    background-color: var(--second-green);
  }
  .top-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    gap: 3em;
    margin-bottom: 0.5em;
  }
  .top-row .part {
    display: flex;
    flex-direction: column;
    width: 13%;
  }
  .top-row .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10%;
    gap: 0.2em;
    color: var(--second-green);
    font-weight: 500;
  }
  .top-row .image-container img {
    width: 60px;
  }
}
