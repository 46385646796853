.campaign-creation-progress {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.campaign-creation-progress .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 450px;
}

.campaign-creation-progress .content.slide-up {
  gap: 12px;
}

.campaign-creation-progress .title {
  font-size: var(--font-20);
  font-weight: 600;
  color: #666;
  margin-bottom: 16px;
}

.campaign-creation-progress .title.fade-out {
  opacity: 0;
  transform: translateY(-20px);
  margin: 0;
  height: 0;
}

.campaign-creation-progress .subtitle {
  font-size: 16px;
  font-weight: 600;
  color: #475569;
  margin-top: 16px;
}

/* Campaign Row Styles */
.campaign-creation-progress .loading-row {
  border: 1px solid #e5e7eb;
  padding: 12px;
  border-radius: 8px;
}

.campaign-creation-progress .row {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  background: #fff;
  transition: all 0.2s ease;
}

.campaign-creation-progress .row.waiting {
  padding-bottom: 0 !important;
}

.campaign-creation-progress .loading-row .row {
  padding: 0 0 12px;
}

/* Campaign Text Styles */
.campaign-creation-progress .text {
  flex: 1;
  font-size: var(--font-14);
  color: #666;
  line-height: 1.4;
}

.campaign-creation-progress .text.success-text {
  color: var(--second-green);
  font-weight: 500;
}

/* Icon Styles */
.campaign-creation-progress .icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.campaign-creation-progress .icon.spinner-icon {
  animation: spinnerPulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.campaign-creation-progress .icon.check-icon {
  color: var(--second-green);
}

.campaign-creation-progress .icon.times-icon {
  color: #ef4444;
}

/* Loading Animation */
.campaign-creation-progress .inline-loading {
  width: 24px;
  height: 24px;
  border: 2px solid #e5e7eb;
  border-top-color: #4ea8fd;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.campaign-creation-progress .row .inline-spinner-box .small-spinner-cont {
  width: 24px;
  height: 24px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Spinner Animation */
@keyframes spinnerPulse {
  0% {
    transform: scale(0.95) rotate(0deg);
    opacity: 0.7;
    filter: blur(0px);
  }

  50% {
    transform: scale(1.05) rotate(180deg);
    opacity: 1;
    filter: blur(0.5px);
  }

  100% {
    transform: scale(0.95) rotate(360deg);
    opacity: 0.7;
    filter: blur(0px);
  }
}

/* Modern wave animation for success rows */
@keyframes successWave {
  0% {
    transform: perspective(800px) rotateX(10deg) scale(0.95);
    opacity: 0.8;
    filter: brightness(0.9);
  }

  25% {
    transform: perspective(800px) rotateX(-5deg) scale(1.02);
    opacity: 1;
    filter: brightness(1.05);
  }

  75% {
    transform: perspective(800px) rotateX(3deg) scale(0.98);
    opacity: 1;
    filter: brightness(1);
  }

  100% {
    transform: perspective(800px) rotateX(0) scale(1);
    opacity: 1;
    filter: brightness(1);
  }
}

/* Success Row Animation */
.campaign-creation-progress .row:has(.success-text) {
  background: rgba(22, 163, 74, 0.05);
  border-color: rgba(22, 163, 74, 0.1);
  animation: successPulse 0.5s ease-out;
}

@keyframes successPulse {
  0% {
    transform: scale(0.98);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Campaign Groups */
.campaign-creation-progress .campaign-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.campaign-creation-progress .campaign-group-title {
  font-size: 16px;
  font-weight: 600;
  color: #334155;
  padding: 0 8px;
}

/* Boost Campaigns Section */
.campaign-creation-progress .boost-campaigns {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e2e8f0;
}

.campaign-creation-progress .boost-campaigns-title {
  font-size: 18px;
  font-weight: 600;
  color: #334155;
  margin-bottom: 16px;
}

/* Scrollbar Styles */
.campaign-creation-progress .content::-webkit-scrollbar {
  width: 6px;
}

.campaign-creation-progress .content::-webkit-scrollbar-track {
  background: transparent;
}

.campaign-creation-progress .content::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 3px;
}

.campaign-creation-progress .content::-webkit-scrollbar-thumb:hover {
  background: #d1d5db;
}

/* Error State */
.campaign-creation-progress .row.error {
  border-color: #fecaca;
  background: #fef2f2;
}

.campaign-creation-progress .row .error-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
}

.campaign-creation-progress .row .error-content .error-header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.campaign-creation-progress .row.error .text {
  color: #ef4444;
}

.campaign-creation-progress .retry-button {
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #dc2626;
  color: white;
  font-size: 13px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.campaign-creation-progress .retry-button:hover {
  background-color: #b91c1c;
  transform: translateY(-1px);
}

.campaign-creation-progress .retry-button:active {
  transform: translateY(0);
}

/* Progress Indicator */
.campaign-creation-progress .progress-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.campaign-creation-progress .progress-indicator .step {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e2e8f0;
}

.campaign-creation-progress .progress-indicator .step.active {
  background: #16a34a;
  transform: scale(1.2);
}

.campaign-creation-progress .progress-indicator .step.completed {
  background: #16a34a;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .campaign-creation-progress {
    padding: 0 0.8em;
  }

  .campaign-creation-progress .content {
    gap: 10px;
    min-height: 400px;
  }

  .campaign-creation-progress .title {
    font-size: var(--font-18);
    margin-bottom: 12px;
  }

  .campaign-creation-progress .row {
    padding: 10px;
    gap: 10px;
  }

  .campaign-creation-progress .text {
    font-size: var(--font-13);
  }

  .campaign-creation-progress .icon {
    width: 18px;
    height: 18px;
  }

  .campaign-creation-progress .footer {
    padding-top: 12px;
    gap: 6px;
  }

  .campaign-creation-progress .footer .cancel-button,
  .campaign-creation-progress .support-button {
    padding: 0.5em 1.2em !important;
    font-size: var(--font-13);
    margin-top: 12px;
  }

  .campaign-creation-progress .success-footer {
    padding: 1.5em 0 0;
    gap: 10px;
  }

  .campaign-creation-progress .success-footer .success-content {
    font-size: var(--font-14);
  }

  .campaign-creation-progress .success-footer .button.done {
    padding: 0.5em 1.2em;
    font-size: var(--font-13);
  }
}

.campaign-creation-progress .error-message {
  font-size: 12px;
  color: #ef4444;
  margin-top: 4px;
}

.campaign-creation-progress .support-button-container {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: center;
}

.campaign-creation-progress .support-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.8em 2em !important;
  border-radius: 8px;
  font-size: var(--font-16);
  font-weight: 500;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.2s ease;
  margin-top: 20px;
}

.campaign-creation-progress .support-button:hover {
  background-color: #1d4ed8;
}

.campaign-creation-progress .support-button:active {
  transform: translateY(0);
}

.campaign-creation-progress .support-button .support-icon {
  width: 16px;
  height: 16px;
}

/* Footer styles */
.campaign-creation-progress .footer {
  margin-top: auto;
  padding-top: 16px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: normal !important;
}

.campaign-creation-progress .footer .cancel-button {
  padding: 0.6em 1.5em !important;
  border-radius: 6px;
  font-size: var(--font-14);
  font-weight: 500;
  color: #64748b;
  background: transparent;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
  margin-top: 16px;
}

.campaign-creation-progress .footer .cancel-button:hover {
  background: #f1f5f9;
  border-color: #cbd5e1;
}

/* Success footer styles and animations */
.campaign-creation-progress .success-footer {
  margin-top: auto;
  padding: 2em 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.campaign-creation-progress .success-footer .success-content {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: var(--font-18);
  font-weight: 500;
}

.campaign-creation-progress .success-footer .button.done {
  padding: 0.6em 1.5em;
  border-radius: 6px;
  font-size: var(--font-14);
  font-weight: 500;
  background: var(--second-green);
  color: white;
  border: none;
  transition: all 0.2s ease;
}

.campaign-creation-progress .footer .continue-button:hover,
.campaign-creation-progress .success-footer .button.done:hover {
  background: var(--second-green-hover);
}

.campaign-creation-progress .footer .continue-button {
  padding: 0.6em 1.5em !important;
  font-size: var(--font-14);
  font-weight: 500;
  color: white;
  background: var(--second-green);
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
  margin-top: 16px;
}

/* Success footer animation */
@keyframes successFooterAppear {
  0% {
    transform: scale(0.95);
    opacity: 0;
    filter: blur(4px);
  }

  100% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0);
  }
}

/* Success icon animation */
@keyframes successIconPop {
  0% {
    transform: scale(0) rotate(15deg);
    opacity: 0;
  }

  50% {
    transform: scale(1.2) rotate(-5deg);
  }

  75% {
    transform: scale(0.9) rotate(3deg);
  }

  100% {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
}

/* Row success animation class */
.campaign-creation-progress .row.animate-success {
  animation: successWave 1.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  background: linear-gradient(135deg, #f8fafc, #f1f5f9);
  border: 1px solid rgba(148, 163, 184, 0.2);
  box-shadow:
    0 4px 12px -2px rgba(148, 163, 184, 0.12),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.8);
  transform-style: preserve-3d;
}

.campaign-creation-progress .row.animate-success .text.success-text {
  color: #16a34a;
  font-weight: 500;
}

.campaign-creation-progress .row.animate-success .icon.check-icon {
  color: #16a34a;
}

/* Smooth height transition for title container */
.campaign-creation-progress .title-container {
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  height: 36px;
  /* title height + margin */
  overflow: hidden;
}

.campaign-creation-progress .title-container.collapsed {
  height: 0;
  margin: 0;
}

.retry-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  /* retry button genişliği kadar */
}

.retry-loading .inline-loading {
  width: 24px;
  height: 24px;
}

.campaign-creation-progress .progress-bar {
  width: 100%;
  height: 5px;
  min-height: 5px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 5px;
}

.campaign-creation-progress .progress-fill {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.3s ease-in-out;
}


.campaign-creation-progress .progress-details-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.campaign-creation-progress .progress-message {
  margin: 0;
  color: #333;
  font-size: var(--font-12);
  color: var(--text-gray);
  font-style: italic;
}

.campaign-creation-progress .progress-percentage-text {
  font-size: var(--font-12);
  color: var(--text-gray);
  font-style: italic;
}