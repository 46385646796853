.card-admin-user-data-variables .switch-container {
  display: flex;
  gap: 2em;
  margin: 1em auto;
}

.card-admin-user-data-variables .header-row {
  display: flex;
  gap: 2em;
  margin: 1em auto;
  border-bottom: 1px solid #9b9b9b;
}

.card-admin-user-data-variables h4 {
  font-size: 1.2em;
  font-weight: 600;
  margin: 1em 0 0;
}