.toolbarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.navigationButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25em;
}

.backButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.875em;
  font-weight: 500;
  color: var(--second-green);
  background: transparent;
  border: 1px solid var(--second-green);
  flex: 1;
  width: 25ch;
}

.backButton:hover {
  background: var(--second-green);
  border: 1px solid var(--second-green);
  color: #fff;
}

.catalogTopLogo {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  pointer-events: none;
}

.catalogTopLogoBackButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 18px;
  color: #333;
}

.catalogTopLogoText {
  font-weight: bold;
  margin-right: 10px;
  background: linear-gradient(to right, #13364a 0%, #58ba8b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.catalogTopLogoDeviceIcon {
  font-size: 24px;
  color: #58ba8b;
}

.toolbarInside {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 10px;
  gap: 2em;
}

.toolbarLeftInside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 10px;
  gap: 2em;
}

.mainToolbarItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1em;
}

.toolbarBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.toolbarBoxTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}

.toolbarBoxTitleText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.toolbarBoxInside {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
  width: 100%;
}

.textsToolContainer {
  gap: 0.5em;
}

.toolbarBoxInsideItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
}

.toolbarBoxInsideItemVertical {
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5px;
}

.toolbarBoxInsideItemTitle {
  font-size: 14px;
  white-space: nowrap;
}

.toolbarBoxInsideItemContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.toolbarBoxInsideItemContentColumn {
  display: flex;
  flex-direction: column;
}

.toolbarBoxInsideItemVertical .toolbarBoxInsideItemContent {
  width: 100%;
}

.toolbarBoxInsideItemContentSelect {
  width: 100%;
  height: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
  transition: background 0.1s;
}

.toolbarBoxInsideItemContentImage {
  width: 48px;
  height: 48px;
  object-fit: contain;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.toolbarBoxInsideItemContentCheckbox {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  color: #58ba8b;
  background: #58ba8b;
}

.toolbarBoxInsideItemContentColor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background 0.1s;
}

.toolbarBoxInsideItemContentColorBox {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  margin-left: 10px;
}

.toolbarBoxInsideItemContentColorInput {
  width: 70px;
  height: 30px;
  border: none;
  padding: 5px 10px;
  text-align: right;
  font-size: 12px;
  cursor: pointer;
  background: none;
  align-content: center;
  align-self: center;
}

.toolbarBoxInsideItemContentInput {
  width: 70px;
  height: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
}

.toolbarBoxInsideItemContentInputRightAligned {
  text-align: right;
}

.toolbarBoxInsideItemVertical .toolbarBoxInsideItemContentInput {
  width: 100%;
}

.toolbarBoxInsideItemContentInputText {
  font-size: 14px;
  align-self: center;
  margin-left: 6px;
  margin-right: 6px;
}

.toolbarBoxInsideItemContentButton {
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: background 0.1s;
}

.toolbarBoxInsideItemContentButton:hover,
.toolbarBoxInsideItemContentSelect:hover,
.toolbarBoxInsideItemContentColor:hover {
  background: #ececec;
}

.toolbarBoxInsideItemContentButtonIcon {
  margin-right: 4px;
}

.browseTemplatesButton {
  gap: 2em;
  font-weight: 600;
  margin-top: 1em;
  width: 100%;
  justify-content: flex-start;
}

.browseTemplatesIcon {
  width: 50px;
  height: 50px;
  border-radius: 0.2em;
  box-shadow: 0 0 0px 1px #ccc;
}

.enhencedToolBox {
  background: #f9f9f9;
  border: 1px solid rgba(224, 224, 224, 0.6);
  padding: 0;
}

.enhencedToolBox .toolbarBoxTitle {
  padding: 10px;
}

.enhencedToolBox .toolbarBoxInside {
  margin-top: 0;
}