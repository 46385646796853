.modal-container.CREATE_A_SUPPORT_TICKET {
  /* background: #f4f4f4; */
  box-shadow: 0px 5.731px 85.828px 0px rgba(0, 0, 0, 0.25);
  width: 40vw;
  min-width: 500px;
  height: 70vh;
  overflow-y: auto;
}

.create-a-support-ticket h2 {
  margin: 0;
}

.create-a-support-ticket {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.create-a-support-ticket .header {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.create-a-support-ticket .header .title {
  color: #292929;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.create-a-support-ticket .header .close {
  position: absolute;
  right: 1.5em;
  width: 28px;
  height: 28px;
  opacity: 0.5;
  transition: all 150ms;
  cursor: pointer;
}

.create-a-support-ticket .header .close:hover {
  opacity: 1;
}

.create-a-support-ticket .header .close:before,
.close:after {
  position: absolute;
  left: 14px;
  content: ' ';
  height: 29px;
  width: 1px;
  background-color: #000;
}

.create-a-support-ticket .header .close:before {
  transform: rotate(45deg);
}

.create-a-support-ticket .header .close:after {
  transform: rotate(-45deg);
}

.create-a-support-ticket .form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-width: 500px;
  width: 100%;
  margin: auto;
  padding: 1em 0;
}

.create-a-support-ticket .form-container .input-row {
  display: flex;
  flex-direction: column;
}

.create-a-support-ticket .form-container .inputs {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.create-a-support-ticket .form-container .input-row > label {
  font-size: 0.875em;
  font-weight: 500;
  color: #575757;
  margin-bottom: 0.5em;
}

.create-a-support-ticket .form-container .input-row .bug-type {
  display: flex;
  align-items: flex-end;
  gap: 1em;
}

.create-a-support-ticket .form-container .input-row .image-upload-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em;
  border: 1px solid #969696;
  background: var(--main-white);
  box-shadow: 0px 4.328px 39.385px 0px rgba(0, 0, 0, 0.07);
  height: 12vh;
  margin: 0;
  cursor: pointer;
}

.create-a-support-ticket .form-container .input-row .image-upload-area:active {
  border: 1px solid #535353;
  box-shadow: 0px 4.328px 39.385px 0px var(--transparent-gray);
}

.create-a-support-ticket
  .form-container
  .input-row
  .image-upload-area:has(.p-pic)
  .upload-text {
  display: none;
}

.create-a-support-ticket
  .form-container
  .input-row
  .image-upload-area:has(.error-text:empty)
  .error-text {
  display: none;
}

.create-a-support-ticket
  .form-container
  .input-row
  .image-upload-area
  .upload-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  color: #949494;
  transition: all 75ms;
}

.create-a-support-ticket
  .form-container
  .input-row
  .image-upload-area:hover
  .upload-text {
  color: #515151;
}

.create-a-support-ticket
  .form-container
  .input-row
  .image-upload-area
  .upload-text
  .icon {
  font-size: 2.5em;
}

.create-a-support-ticket
  .form-container
  .input-row
  .image-upload-area
  .p-pic-cont {
  display: flex;
}

.create-a-support-ticket .form-container .input-row .image-upload-area .p-pic {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 2px;
}

.create-a-support-ticket
  .form-container
  .input-row
  .image-upload-area
  #picInput,
.create-a-support-ticket
  .form-container
  .input-row
  .image-upload-area
  div:has(img[alt='vector']),
.create-a-support-ticket
  .form-container
  .input-row
  .image-upload-area
  .change-pic-button {
  display: none;
}

.create-a-support-ticket
  .form-container
  .input-row
  .bug-type
  .form-control:first-child {
  flex: 0 0 20%;
}

.create-a-support-ticket
  .form-container
  .input-row
  .bug-type
  .form-control:last-child {
  flex: 1;
}

.create-a-support-ticket .form-container .input-row .form-control {
  margin: 0;
  width: 100%;
}

.create-a-support-ticket .form-container .input-row .form-control label {
  display: none;
}

.create-a-support-ticket .form-container .input-row .form-control .select-cont {
  box-shadow: unset;
}

.create-a-support-ticket
  .form-container
  .input-row
  .form-control
  .select-cont
  select,
.create-a-support-ticket .form-container .input-row .form-control textarea,
.create-a-support-ticket .form-container .input-row .form-control input {
  width: 100%;
  border: 1px solid #969696;
  background: var(--main-white);
  box-shadow: 0px 4.328px 39.385px 0px rgba(0, 0, 0, 0.07);
  font: inherit;
  font-size: 0.875em;
}

.create-a-support-ticket
  .form-container
  .input-row
  .form-control
  .select-cont
  select:focus,
.create-a-support-ticket
  .form-container
  .input-row
  .form-control
  textarea:focus,
.create-a-support-ticket .form-container .input-row .form-control input:focus {
  border: 1px solid #535353;
  box-shadow: 0px 4.328px 39.385px 0px var(--transparent-gray);
}

.create-a-support-ticket .form-container .input-row .form-control textarea {
  min-height: 12vh;
}

.create-a-support-ticket .form-container .input-row .radio-group .items .radio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 7px;
  font-size: 1.1em;
  font-weight: 600;
  border: 1px solid #969696;
  background: var(--main-white);
  box-shadow: 0px 4.328px 39.385px 0px rgba(0, 0, 0, 0.07);
}

.create-a-support-ticket
  .form-container
  .input-row
  .radio-group
  .items
  .radio:hover {
  background: #f2f4f8;
}

.create-a-support-ticket
  .form-container
  .input-row
  .radio-group
  .items
  .radio:has(input[type='radio']:checked) {
  background: #edf2fa;
  border: 1px solid #9eaabd;
  box-shadow: 0 0px 6px 0 #dce3ef;
  color: #1b3f7d;
}

.create-a-support-ticket
  .form-container
  .input-row
  .radio-group
  .items
  .radio
  label {
  width: 100%;
  height: 100%;
  padding: 1.25em;
  display: flex;
  justify-content: center;
}

.create-a-support-ticket .form-container .button,
.create-a-support-ticket .completed-screen-container .button {
  margin: 0 auto;
  margin-top: 1em;
  padding: 1em 2em;
  background: var(--primary-green);
  border-radius: 0.5em;
  box-shadow:
    0px 8.22px 14.385px 0px rgba(41, 128, 164, 0.26),
    0px -8.22px 8.22px 0px rgba(105, 181, 90, 0.26);
  font-weight: 600;
  transition: all 200ms;
}

.create-a-support-ticket .form-container .button:hover,
.create-a-support-ticket .completed-screen-container .button:hover {
  transform: translateY(-2px);
  background: #8ec95e;
  box-shadow:
    0px 8.22px 14.385px 0px rgba(41, 128, 164, 0.3),
    0px -8.22px 8.22px 0px rgba(105, 181, 90, 0.3);
}

.create-a-support-ticket .form-container .button:disabled,
.create-a-support-ticket .completed-screen-container .button:disabled {
  background: #c1c1c1;
  box-shadow: unset;
  transform: unset;
}

.create-a-support-ticket .completed-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 65%;
  margin-top: auto;
}

.create-a-support-ticket .completed-screen-container .text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.create-a-support-ticket .completed-screen-container .text-wrapper .icon {
  font-size: 4em;
  color: var(--primary-green);
}

.create-a-support-ticket .completed-screen-container .text-wrapper .text {
  margin: 0;
  color: #575757;
  font-weight: 300;
  max-width: 50ch;
  text-align: center;
  line-height: 1.5;
}

.create-a-support-ticket .completed-screen-container .text-wrapper .all-support-tickets {
  padding: 0.5em 1em;
  margin-top: 1em;
  border-radius: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.10) 0px 1px 4px;
  color: #575757;
  font-weight: 500;
  transition: all 100ms;
}

.create-a-support-ticket .completed-screen-container .text-wrapper .all-support-tickets:hover {
  font-weight: 500;
  text-decoration: none;
  color: #292929;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media (max-width: 768px) {
  .create-a-support-ticket {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
