.creative-maker-form .ai-creative-images {
  display: flex;
  align-items: center;
  gap: 0.75em;
  max-width: 400px;
  min-width: 400px;
  overflow-x: auto;
}

.creative-maker-form .ai-creative-images-actions {
  display: flex;
  align-items: center;
  gap: 0.75em;
  transform: translateY(0.5em);
}

.creative-maker-form .ai-creative-images-action-button {
  background: linear-gradient(180deg, #85ca6e1c, white 20%);
  color: var(--main-green);
  border: 1px solid var(--main-green);
  transition: all 0.3s ease;
  margin-bottom: 1em;
}

.creative-maker-form .ai-creative-images-action-button:hover {
  background: linear-gradient(180deg, #85ca6e33, white 80%);
}

.creative-maker-form .ai-creative-images .ai-creative-image-preview {
  max-width: 80px;
  max-height: 80px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
}

.creative-maker-form .ai-creative-images .ai-creative-image-preview:hover {
  transform: scale(1.025);
  cursor: zoom-in;
}

/* Website URL input için özel stil */
.creative-maker-form #websiteUrl {
  width: calc(100% - 2.5em) !important;
}

@media (max-width: 468px) {
  .creative-maker-form #websiteUrl {
    width: 100% !important;
  }
}
