.recommended-shopify-apps-slider {
  width: 20em;
  background: none;
  position: relative;
  margin-top: 2em;
}

.recommended-shopify-apps-slider h2 {
  font-size: 1.5em;
  font-weight: 500;
  color: #1a1a1a;
  margin: 0 0 0.75em 0;
}

.recommended-shopify-apps-slider .slider-bottom-section {
  display: flex;
  justify-content: space-between;
  bottom: 0;
  margin-top: auto;
}

.recommended-shopify-apps-slider .content .company {
  background: white;
  border-radius: 1em;
  padding: 1.25em;
  position: relative;
  height: 13em;
  box-shadow: 0 0.125em 0.5em rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  border: 0.0625em solid #dfeaf2;
}

.recommended-shopify-apps-slider .content .company .main {
  display: flex;
  gap: 1em;
  position: relative;
}

.recommended-shopify-apps-slider .content .company .link {
  background: var(--main-green);
  color: white;
  padding: 0.2em 1.25em;
  border-radius: 0.375em;
  text-decoration: none;
  font-size: 0.875em;
  font-weight: 600;
  white-space: nowrap;
  transition: all 0.2s ease;
  border: none;
  box-shadow: 0 0.125em 0.25em rgba(76, 175, 80, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.recommended-shopify-apps-slider .content .company .link:hover {
  background: #45a049;
  transform: translateY(-0.125em);
  box-shadow: 0 0.25em 0.5em rgba(76, 175, 80, 0.3);
}

.recommended-shopify-apps-slider .content .company .link:active {
  transform: translateY(0);
  box-shadow: 0 0.125em 0.25em rgba(76, 175, 80, 0.2);
}

.recommended-shopify-apps-slider .content .company .main .logo {
  width: 2.5em;
  height: 2.5em;
  object-fit: contain;
}

.recommended-shopify-apps-slider .content .company .main .info {
  flex: 1;
}

.recommended-shopify-apps-slider .content .company .main .info h3 {
  font-size: 1em;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 0.25em 0;
  text-align: left;
}

.recommended-shopify-apps-slider .content .company .main .info .description {
  font-size: 0.875em;
  color: #94959b;
  line-height: 1.4;
  margin: 0;
}

.recommended-shopify-apps-slider .content .company .main .link {
  position: absolute;
  top: 0;
  right: 0;
  background: #4caf50;
  color: white;
  padding: 0.375em 1em;
  border-radius: 0.25em;
  text-decoration: none;
  font-size: 0.875em;
  font-weight: 500;
  white-space: nowrap;
}

.recommended-shopify-apps-slider .slider-indicators {
  display: flex;
  gap: 0.25em;
  margin-top: 0.75em;
}

.recommended-shopify-apps-slider .slider-indicator {
  width: 1.5em;
  height: 0.35em;
  background: #e0e0e0;
  border-radius: 0.125em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.recommended-shopify-apps-slider .slider-indicator.active {
  background: #4caf50;
  width: 2em;
}

.recommended-shopify-apps-slider .controller {
  display: none;
  width: 1.5em;
  height: 1.5em;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);
  border: 0.0625em solid #e0e0e0;
}

.recommended-shopify-apps-slider .controller.prev {
  left: -0.75em;
}

.recommended-shopify-apps-slider .controller.next {
  right: -0.75em;
}

.recommended-shopify-apps-slider .controller .icon {
  color: #666;
  font-size: 0.75em;
}

.recommended-shopify-apps-slider .controller.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 1240px) {
  .recommended-shopify-apps-slider {
    margin-top: 0em;
    width: 100%;
  }
}
