.crm-geochart .chart {
  width: 90%;
}

/*
  To apply custom CSS to the tooltip,
  include the 'tooltip: { isHtml: true }' object
  in the options prop of the <Chart /> component.
*/
.crm-geochart .google-visualization-tooltip {
  min-width: 200px;
  height: auto;
}

.crm-geochart
  .google-visualization-tooltip
  .google-visualization-tooltip-item-list {
  margin: 0.65em 0;
}

.crm-geochart
  .google-visualization-tooltip
  .google-visualization-tooltip-item-list
  .google-visualization-tooltip-item {
  display: flex;
  justify-content: space-between;
  margin: 0.35em 0;
  padding: 0 1em 0 1em;
}
