.one-alarm-container {
  position: relative;
  /* ... existing styles ... */
}

.validation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.validation-overlay p {
  margin: 5px 0;
  text-align: center;
}
