/* Mevcut CSS kuralları burada kalacak */

/* Basic user için yeni CSS kuralları */
.basic-user .profile {
  flex-direction: column;
  z-index: 0;
  top: 0;
  position: relative;
  width: 100%;
  margin-top: 0em;
  height: auto;
  padding: 0 0 0 2em;
  background-color: none;
}

.basic-user .profile-sidebar-wrapper {
  position: static;
  width: 100%;
  height: auto;
}

.basic-user .profile-sidebar-wrapper .sidebar {
  width: 100%;
  height: auto;
  margin-top: 0;
  position: inherit;
  padding: 0;
  background-color: #fcfcfc;
  box-shadow: none;
  border: none;
}

.basic-user .profile-sidebar-wrapper .sidebar-header {
  display: none;
}

.basic-user .profile-sidebar-wrapper .nav-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1em;
  padding: 0 1em;
  margin-top: 1em;
  width: 100%;
}

.basic-user .profile-sidebar-wrapper .nav-item {
  margin: 0;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.basic-user .profile-sidebar-wrapper .nav-item a,
.basic-user .profile-sidebar-wrapper .nav-item div {
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  border-radius: 20px;
  background-color: #ffffff;
  color: #333;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.basic-user .profile-sidebar-wrapper .nav-item a:hover,
.basic-user .profile-sidebar-wrapper .nav-item div:hover {
  background-color: #007bff;
  color: white;
}

.basic-user .profile-sidebar-wrapper .nav-item.active a,
.basic-user .profile-sidebar-wrapper .nav-item.active div {
  background-color: #007bff;
  color: white;
}

.basic-user .profile-sidebar-wrapper .nav-item svg {
  margin-right: 0;
}

.basic-user .profile-content-wrapper {
  padding: 0em 1em 2em 0em;
  margin-left: 0;
}

.basic-user .profile-header h1 {
  font-size: 1.5em;
}

.basic-user .profile-header .line {
  margin: 0.5em 0;
}

@media (max-width: 868px) {
  .basic-user .profile-sidebar-wrapper .nav-items {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5em;
  }

  .basic-user .profile-sidebar-wrapper .nav-item {
    margin: 0.25em 0;
    flex-direction: row;
    justify-content: center;
  }

  .basic-user .profile-content-wrapper {
    padding: 1em;
  }
}
.profile {
  display: flex;
  position: fixed;
  top: var(--topbar-height);
  left: 0;
  width: 100vw;
  height: calc(100vh - var(--topbar-height));
  background-color: #fcfcfc;
}

.profile-content-wrapper {
  flex: 1;
  overflow: auto;
  padding: 2em;
}

.profile-sidebar-wrapper {
  overflow: auto;
}

.profile-sidebar-wrapper .sidebar {
  width: 300px;
}

.profile .profile-content-wrapper .profile-header {
  display: flex;
  flex-direction: column;
}

.profile .profile-content-wrapper .profile-header h1 {
  margin: 0;
  color: #6b6b6b;
  font-weight: 700;
  margin: 0;
}

.profile .profile-content-wrapper .profile-header .line {
  height: 2px;
  width: 100%;
  background: var(--disabled-gray);
  margin: 1em 0;
}

@media (max-width: 1300px) {
  .profile-sidebar-wrapper .sidebar {
    width: 250px;
    font-size: 0.9em;
  }
}
@media (max-width: 768px) {
  .profile-content-wrapper {
    padding: 2em 2em 2em 2em;
  }
}

@media (max-width: 400px) {
  .profile-content-wrapper {
    padding: 2em 1.3em 2em 2em;
  }
}
@media (max-width: 1000px) {
  .profile-content .save-button {
    margin-top: 1em;
  }
}
