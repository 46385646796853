.ai-ads-ai-audience-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 2.25em;
  width: 96%;
  margin-top: 3rem;
  max-width: 1000px;
}

.ai-ads-ai-audience-grid > .fluid-card-container {
  border: 1px solid var(--border-main);
  border-radius: 12px;
}

@media (max-width: 1200px) {
  .ai-ads-ai-audience-grid {
    max-width: unset;
  }
}
