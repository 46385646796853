.hover-card-content:has(.product-set-hover-content) {
  background-color: var(--main-white);
  max-height: 400px;
  width: 100%;
  min-width: 300px;
  overflow-y: auto;
  padding: 8px 0;
  margin: 0 auto;
  left: calc(100% + 5px);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 2px;
}

.product-set-hover-content {
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 1em;
}

.product-set-hover-content .header {
  width: 100%;
  font-size: 0.8em;
  font-weight: 500;
  color: var(--text-black);
}

.product-set-hover-content .product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25em;
  padding: 0.5em;
  width: 60px;
  border-radius: 8px;
  transition: all 0.5s;
}

.product-set-hover-content .product-item:hover {
  box-shadow: rgba(0, 0, 0, 0.075) 0px 8px 18px 4px;
}

.product-set-hover-content .product-item .product-image-container {
  width: 60px;
  height: 60px;
}

.product-set-hover-content .product-item .product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-set-hover-content .product-item .product-name {
  color: var(--text-black);
  font-size: 0.6em;
  text-align: center;
  text-wrap: wrap;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.product-set-hover-content .helper-text {
  color: rgb(173, 173, 173);
}
