.existingSupplementaryWarning {
  background-color: #f8f9fa;
  border: 1px solid #e0e0e0;
  color: var(--text-gray);
  padding: 1.25rem;
  border-radius: 8px;
  margin: 1.5rem auto;
  width: 100%;
  max-width: 850px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.existingSupplementaryWarning p {
  margin: 0.75rem 0;
  line-height: 1.6;
  font-size: 0.95rem;
}

.existingSupplementaryWarning p:first-child {
  margin-top: 0;
}

.existingSupplementaryWarning p:last-child {
  margin-bottom: 0;
}

.existingSupplementaryWarning strong {
  color: var(--text-gray);
  font-weight: 600;
}

.existingSupplementaryWarning .tryAgain {
  color: #0d6efd;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  margin: 0 0.25rem;
  border-radius: 4px;
  background-color: rgba(13, 110, 253, 0.1);
  transition: all 0.2s ease;
}

.existingSupplementaryWarning .tryAgain:hover {
  background-color: rgba(13, 110, 253, 0.15);
  color: #0a58ca;
}

.existingSupplementaryWarning .disabledTryAgain {
  color: #6c757d;
  cursor: not-allowed;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  opacity: 0.7;
}

.existingSupplementaryWarning .note {
  font-size: 0.875rem;
  margin-top: 1rem;
  font-style: italic;
  color: var(--text-gray);
  padding: 0.75rem;
  background-color: #f0f0f0;
  border-radius: 6px;
}

@media (max-width: 768px) {
  .existingSupplementaryWarning {
    padding: 1rem;
    margin: 1rem auto;
  }

  .existingSupplementaryWarning p {
    font-size: 0.9rem;
  }

  .existingSupplementaryWarning .note {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}
