.audience-network-card .input-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.audience-network-card .no-category-text {
  font-size: 0.8em;
  display: block;
}

.audience-network-card .input-row span {
  max-width: 20em;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 0.8em;
  margin-right: 1em;
}

.audience-network-card .input-row input {
  flex: 1;
  max-width: 5em;
  border-radius: 5px;
  border: 0;
  background: var(--transparent-gray);
  margin-right: 45px;
}

.audience-network-card .checkbox-group-name {
  display: flex;
  flex-direction: row;
  gap: 1.7em;
}

.audience-network-card .input-row .user-selected {
  margin-left: 10px;
}

.audience-network-card .user-selected {
  font-size: 0.6em;
  text-align: center;
  margin-bottom: 0.5em;
}

.audience-network-card .first-part {
  display: flex;
  align-items: center;
  width: 26%;
}

.audience-network-card .first-part .switch {
  height: 1.8em;
  font-size: 0.9em;
}

.audience-network-card .map-categories-container {
  max-height: 20em;
  overflow: auto;
  border: 1px solid #c1c1c1;
  padding: 1em;
  border-radius: 1em;
  max-width: 600px;
}

.audience-network-card .input-row h3 {
  font-size: 0.9em;
  margin: 1em 0 0 0;
}

.audience-network-card .map-categories-container .input-row .divider {
  height: 1px;
  background-color: #000;
}

.audience-network-card .map-categories-container .input-row:first-child {
  display: flex;
  flex-direction: column;
}

.audience-network-card .map-categories-container .input-row {
  height: 1.8em;
}

.audience-network-card .categories-select {
  width: 23em;
  height: 100%;
  border: 0;
  border-radius: 5px;
  padding: 0.5em;
  background: var(--transparent-gray);
  font-size: 0.75em;
  color: #333;
  outline: none;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}

.audience-network-card .categories-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

.audience-network-card .categories-select::-ms-expand {
  display: none;
}

.audience-network-card .categories-select::before {
  content: '\25BC';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
  pointer-events: none;
}

.audience-network-card .categories-select option {
  font-size: 1em;
  color: #333;
}

.audience-network-card .save-button-container {
  margin-top: 1em;
}
@media (max-width: 615px) {
  .audience-network-card .map-categories-container {
    max-height: 30em;
    overflow-x: hidden;
  }
  .audience-network-card .map-categories-container .input-row {
    width: 100vw;
  }
  .audience-network-card .input-row input {
    margin-right: 25px;
  }
  .audience-network-card .input-row span {
    margin-left: 5px;
  }
  .audience-network-card .checkbox-group-name {
    gap: 0.6em;
  }
  .audience-network-card .input-row {
    margin-top: 1em;
  }
}
@media (max-width: 435px) {
  .audience-network-card .map-categories-container {
    max-height: 30em;
    overflow-x: hidden;
  }
  .audience-network-card .map-categories-container .input-row {
    width: 100vw;
  }
  .audience-network-card .input-row input {
    margin-right: 15px;
  }
  .audience-network-card .input-row span {
    margin-left: 5px;
  }
  .audience-network-card .checkbox-group-name {
    gap: 0.6em;
  }
  .audience-network-card .input-row {
    margin-top: 1em;
  }
}
