.support-tickets-container {
  height: 100%;
}

.support-tickets-container .layout {
  height: 100%;
  display: flex;
  gap: 1em;
}

.support-tickets-container .layout .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
}

.support-tickets-container .layout .content .heading {
  margin: 0;
  padding: 0 16px;
}

.support-tickets-container .layout .content .heading::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--main-green);
  margin-bottom: 16px;
}

.support-tickets-container .layout .content .not-found-text {
  margin: 0;
  color: #c4c4c4;
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 8em;
  font-size: 1.5em;
  font-weight: 600;
}
