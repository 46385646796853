.utms-popup {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  background: var(--main-white);
  padding: 10px;
  opacity: 1;
  border-radius: 0.7em;
  box-shadow: 0 0 10px 0px #0000004d;
}

.utms-popup .wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.5em;
}

.utms-popup .nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  font-weight: 500;
  background: var(--blue-gradient);
  border-radius: 5px;
  color: var(--main-white);
  padding: 1px 8px;
  height: 40px;
  cursor: pointer;
  transition: all 300ms;
}

.utms-popup .nav-button:hover {
  background: linear-gradient(90deg, #003b60 50%, #13607a);
}

.utms-popup-icon {
  display: flex;
  /* padding: 0.25em 0.75em; */
  cursor: pointer;
}

.utms-popup .note-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  max-width: 300px;
  border: solid 1px #ccc;
  border-radius: 10px;
  font-size: 0.9em;
}

.utms-popup .note-container .note-createdat {
  font-size: 0.8em;
  font-weight: bold;
}
