/* Layout Start */
.team-card .inputs-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1.25em 3em;
}

.team-card .inputs-wrapper .input-cell {
  display: flex;
  align-items: center;
}

.team-card .inputs-wrapper .input-cell span {
  max-width: 40%;
}

.team-card .inputs-wrapper .input-cell span,
.team-card .inputs-wrapper .input-cell .form-control {
  flex: 1;
}
/* Layout End */

/* Resets Start */
.team-card .inputs-wrapper .input-cell .form-control {
  display: flex;
  margin: 0;
  width: 100%;
  height: 1.8em;
}

.team-card .inputs-wrapper .input-cell .form-control .select-cont {
  box-shadow: unset;
  height: 100%;
}

.team-card .inputs-wrapper .input-cell .form-control select {
  width: 100%;
  height: 100%;
  padding: 0.5em;
  font-size: 0.75em;
  border-radius: 5px;
  background: var(--transparent-gray);
  color: #333;
  outline: none;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}

.team-card .inputs-wrapper .input-cell .form-control select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

.team-card .inputs-wrapper .input-cell .form-control select:-ms-expand {
  display: none;
}

.team-card .inputs-wrapper .input-cell .form-control select:before {
  content: '\25BC';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
  pointer-events: none;
}

.team-card .inputs-wrapper .input-cell .form-control select option {
  font-size: 1em;
  color: #333;
}

.team-card .inputs-wrapper input {
  flex: 1;
  height: 2.4em;
  border-radius: 5px;
  border: 0;
  background: var(--transparent-gray);
  margin-right: 10px;
}
/* Resets End */

.team-card .inputs-wrapper .input-cell span {
  color: #000;
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .team-card .inputs-wrapper {
    display: block;
  }
  .team-card .inputs-wrapper .input-cell .form-control .select-cont {
    padding: 2px;
  }
}
