.previousCreativeCard {
  flex: 1;
  border: 0.0625em solid #dfeaf2;
  background: #fff;
  border-radius: 0.75em;
  padding: 0.75em 1em;
  max-width: 31.25em;
}

.preview {
  width: 3.875em;
  height: 3.875em;
  border-radius: 0.3125em;
  border: 0.0625em solid #cbcbcb;
  background-color: #dfeaf2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.creativeCardTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.creativeCardDetailsBox {
  display: flex;
  flex-direction: column;
  gap: 0.3125em;
}

.creativeCardDetilsBoxTexts {
  display: flex;
  flex-direction: column;
  gap: 0.1875em;
}

.creativeIcon {
  width: 1em;
}

.creativeName {
  display: flex;
  align-items: center;
  gap: 0.1875em;
  font-size: 0.875em;
  font-weight: 500;
  color: #1a1b1c;
  position: relative;
}

.statusDot {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: background-color 0.3s ease;
}

.statusConnected {
  background-color: var(--main-green, #4cd995);
  box-shadow: 0 0 0.375em rgba(76, 217, 149, 0.4);
}

.statusChecking {
  background-color: #ffd700;
  box-shadow: 0 0 0.375em rgba(255, 215, 0, 0.4);
  animation: pulse 1.5s infinite;
}

.statusDisconnected {
  background-color: var(--main-red, #ff4d4f);
  box-shadow: 0 0 0.375em rgba(255, 77, 79, 0.4);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.creativeDate {
  font-size: 0.75em;
  color: #7d7d7d;
}

.creativeType {
  font-size: 0.75em;
  color: #7d7d7d;
}

.creativeCardDetailsBoxActions {
  display: flex;
  gap: 0.625em;
}

.launchAdButton {
  color: #fff;
  border-radius: 0.4em;
  padding: 0.6em 1.3em;
  font-size: 0.625em;
}

.editButton {
  background: #fff;
  color: #abb1b8;
  border-radius: 0.4em;
  border: 0.1em solid #4cd995;
  padding: 0.6em 1.3em;
  font-size: 0.625em;
}

.editButton:hover {
  background: #f7f7f7;
  border: 0.0625em solid #4cd995;
}

.enableFeedButton {
  background: #fff;
  color: #abb1b8;
  border-radius: 0.4em;
  border: 0.1em solid #4cd995;
  padding: 0.6em 1.3em;
  font-size: 0.625em;
}

.enableFeedButton:hover {
  background: #f7f7f7;
  border: 0.0625em solid #4cd995;
}

.creativeCardDivider {
  width: 100%;
  height: 0.0625em;
  background-color: #dfeaf2;
  margin: 0.625em 0;
}

.previews {
  display: flex;
  align-items: center;
  gap: 0.3125em;
}

.totalProducts {
  font-size: 0.75em;
  color: #7d7d7d;
}

.previewSmall {
  width: 1.875em;
  height: 1.875em;
  border-radius: 0.3125em;
  border: 0.0625em solid #cbcbcb;
  background-color: #dfeaf2;
  overflow: hidden;
}

.previewImageSmall {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
  transition: opacity 0.25s ease;
}

.previewImageSmall:hover {
  opacity: 1;
}

.connectionStatus {
  display: inline-block;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
}

.actionsContainer {
  position: relative;
}

.actionsDropdown {
  position: absolute;
  top: calc(100% + 0.3125em);
  left: 0;
  background: white;
  border: 0.0625em solid #dfeaf2;
  border-radius: 0.4em;
  box-shadow: 0 0.25em 1em rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 10em;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-0.625em);
  transition: opacity 0.2s ease-in-out,
              transform 0.2s ease-in-out,
              visibility 0.2s ease-in-out;
  padding: 0.3125em 0;
}

.actionsDropdown.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  pointer-events: all;
}

.dropdownItem {
  width: 100%;
  padding: 0.625em 1em;
  border: none;
  background: none;
  text-align: left;
  font-size: 0.75em;
  color: #1a1b1c;
  cursor: pointer;
  transition: all 0.15s ease;
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-weight: 500;
  opacity: 0;
  transform: translateY(-0.3125em);
  transition: opacity 0.2s ease-in-out,
              transform 0.2s ease-in-out,
              background-color 0.15s ease,
              color 0.15s ease;
}

.actionsDropdown.show .dropdownItem {
  opacity: 1;
  transform: translateY(0);
}

.actionsDropdown.show .dropdownItem:nth-child(1) {
  transition-delay: 0.05s;
}

.actionsDropdown.show .dropdownItem:nth-child(2) {
  transition-delay: 0.1s;
}

.dropdownItem:hover {
  background-color: #f7f7f7;
  color: #3dab75;
}

.dropdownItem + .dropdownItem {
  margin-top: 0.125em;
}

@media (max-width: 768px) {
  .previousCreativeCard {
    font-size: 0.85em;
  }
}
