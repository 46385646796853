/* Sidebar.css */
.sidebar {
  width: 350px;
  height: 100%;
  padding: 2em 1.5em;
  box-shadow: 2px 0 3px -2px #cccccc6e;
  display: flex;
  flex-direction: column;
  gap: 1.25em;
  border-right: 1px solid rgba(205, 202, 202, 0.2);
  transition:
    transform 0.3s ease,
    left 0.3s ease;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: var(--main-white);
  margin-top: 50px;
}

.sidebar.closed {
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar .sidebar-header {
  color: #5fb380;
  font-weight: 600;
  font-size: 1.2em;
}

.sidebar .nav-items {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.sidebar .nav-items .nav-item {
  display: flex;
  align-items: center;
  gap: 0.5em;
  border-radius: 0.75em;
  border: 1px solid #dfeaf2;
  padding: 0.8em;
  color: #6b6b6b;
  font-weight: 500;
}

.sidebar .nav-items .nav-item:hover {
  border: 1px solid #a2c4dd;
}

.sidebar .nav-items .nav-item.active {
  border: 1px solid var(--main-green);
  color: var(--main-green);
}

.sidebar .nav-items a {
  color: inherit;
}

.sidebar .nav-items a:hover {
  color: inherit;
  text-decoration: none;
}

.sidebar-toggle-button {
  position: absolute;
  top: 300px;
  left: 250px;
  z-index: 1100;
  background: linear-gradient(to bottom, #01394f, 70%, #016750);
  color: var(--main-white);
  border: none;
  cursor: pointer;

  padding: 7px;
  height: 200px;
  border-radius: 0 40px 40px 0;
  opacity: 0.9;
}

.sidebar-toggle-button svg {
  color: var(--main-white);
  height: 200px;
  width: 10px;
}

.profile-content-wrapper {
  margin-left: 350px;
  transition: margin-left 0.3s ease;
}

@media (max-width: 768px) {
  .profile-sidebar-wrapper {
    display: flex;
  }

  .profile-sidebar-wrapper.closed .sidebar {
    transform: translateX(-100%);
  }

  .profile-sidebar-wrapper.open .sidebar {
    transform: translateX(0);
  }

  .profile-content-wrapper {
    margin-left: 0;
  }

  .sidebar-toggle-button {
    display: block;
  }
}
@media (max-width: 1300px) {
  .profile-content-wrapper {
    margin-left: 250px;
  }
}
@media (max-width: 767px) {
  .profile-content-wrapper {
    margin-left: auto;
  }
  .sidebar .nav-items .nav-item {
    padding: 0.5em;
  }
}
@media (min-width: 768px) {
  .sidebar-toggle-button {
    display: none;
  }
}
