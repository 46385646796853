/* Admin Panel - Start */

.admin-panel-table {
  margin: 0em auto;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}

.admin-panel-table .control-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0;
  top: 0;
  z-index: 7;
}
.admin-panel-table .control-row .row {
  margin-right: 1.5em;
}
.username-cell {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-left: 0.5em;
  /* max-width: 300px; */
}

main.scrolled .admin-panel-table .control-row {
  background: var(--main-white);
  box-shadow: 0 0px 5px #05050575;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0.5em calc(25vw - 300px);
}

.admin-panel-table .control-row h2 {
  font-size: 1.3em;
  margin: 0;
  color: var(--text-gray);
}

.admin-panel-table .control-row .filter-button {
  height: 100%;
  font-size: 1.2em;
  color: var(--text-blue);
  box-shadow: 0 1px 5px 0 #ccc;
  background: none;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0 0.5em;
  cursor: pointer;
  position: relative;
}

.admin-panel-table .control-row .filter-button:hover,
.admin-panel-table .control-row .filter-button.active {
  background: rgb(242, 242, 242);
}

.admin-panel-table .control-row .filter-button .count-badge {
  position: absolute;
  right: -1em;
  top: -1em;
  font-size: 0.6em;
  width: 1.6em;
  height: 1.6em;
  background: green;
  color: var(--main-white);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-panel-table .search-bar,
.admin-panel-table .sort-bar,
.admin-panel-table .filters {
  background: var(--main-white);
}

.admin-panel-table .search-bar {
  border-radius: 0.5em;
  box-shadow: 0 1px 5px 0 #ccc;
  display: flex;
  align-items: center;
  padding: 3px 0.5em;
}

.admin-panel-table .search-bar input {
  border: none;
  outline: none;
}

.admin-panel-table .search-bar .icon {
  color: var(--text-blue);
}

.admin-panel-table table {
  border-collapse: collapse;
  position: relative;
}

.admin-panel-table table thead {
  background: orange;
  border-radius: 0.5em;
  position: sticky;
  top: 0;
  z-index: 2;
}

.admin-panel-table table .name-cell {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1em;
}

.admin-panel-table table .name-cell:hover {
  text-decoration: underline;
}

.admin-panel-table table .name-cell img {
  max-width: 1.5em;
}

.admin-panel-table table .actions-cell {
  cursor: pointer;
  display: flex;
  /* flex-flow: row nowrap; */
  align-items: center;
  gap: 0.2em;
}

.admin-panel-table .status-row {
  text-align: center;
  justify-content: space-between;
  max-width: 650px;
  margin: 1em auto;
}
.admin-panel-table table td {
  padding: 0.2em 0.1em;
  text-align: center;
  font-size: 0.8em;
  color: var(--text-gray);
  font-weight: 500;
  position: relative;
}

.admin-panel-table table td input {
  border: none;
  padding: 10px;
  margin: 0;
  text-align: center;
  background-color: transparent;
}

.admin-panel-table table td input:focus {
  outline: 2px solid #067760;
}
.admin-panel-table table td select {
  border: none;
  padding: 10px;
  margin: 0;
  text-align: center;
  background-color: transparent;
}
.admin-panel-table table td select:focus {
  outline: 2px solid #067760;
}
.admin-panel-table table td select option {
  padding: 20px;
  height: 100px;
}

.move-to-leads-button {
  position: relative;
  background-color: transparent;
  border: none;
  font-size: 0.85em;
  color: #838383;
}

.move-to-leads-tooltip-text {
  visibility: hidden;
  background-color: #ada7a7;
  color: var(--main-white);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  left: 270%;
  bottom: 4%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.8em;
}
.move-to-leads-button:hover {
  font-size: 1em;
}

.move-to-leads-button:hover .move-to-leads-tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Admin Panel - End */
