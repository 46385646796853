.generateButton {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  border-radius: 0.875em;
  border: 0.0625em solid #dfeaf2;
  background: #fff;
  padding: 1.25em 0.9375em;
  width: 13.75em;
  max-width: 31.25em;
  cursor: pointer;
  transition: all 0.3s ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.generateButton:hover {
  transform: translateY(-0.125em);
  border: 0.0625em solid #a2c4dd;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.375em;
  height: 2.375em;
  border-radius: 0.3125em;
  background-color: #ff8533;
  color: #fff;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.textsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.generateButtonTitle {
  font-size: 0.875em;
  font-weight: 500;
  color: #1a1b1c;
}

.generateButtonDescription {
  font-size: 0.75em;
  color: #7d7d7d;
}

@media (max-width: 1200px) {
  .generateButton {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .generateButton {
    font-size: 0.85em;
    flex: 1;
    width: 100%;
    max-width: 31.25em;
  }
}
