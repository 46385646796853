.acsr-tables-wrapper {
  margin-top: 5em;
  margin-bottom: 4em;
  display: flex;
  overflow-x: auto;
  padding-bottom: 1em;
  padding-right: 0.3em;
  /* scrollbar için boşluk */
  user-select: none;
  cursor: grab;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: rgba(136, 136, 136, 0.8) rgba(241, 241, 241, 0.4);
  scroll-behavior: smooth;
  /* Yumuşak kaydırma için */
  -webkit-overflow-scrolling: touch;
  /* iOS için düzgün scroll */
}

.acsr-tables-wrapper .sort-indicator {
  display: inline-block;
  width: 0.6em;
  margin-left: 0.3em;
}

.acsr-tables-wrapper .status-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.acsr-tables-wrapper .status-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.acsr-tables-wrapper .acsr-metric-table th .sort-indicator {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 0.8em;
}

/* Indicator styles */
.indicator {
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  background-color: #ccc;
  margin-left: 0.7em;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
}

.indicator.active {
  background-color: #4caf50;
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.7em;
}

.indicator.paused {
  background-color: white;
  color: #ff9800;
  margin-left: 0.2em;
  font-size: 1.7em;
}

.indicator.pending {
  background-color: rgba(255, 255, 255, 0);
  color: #2196f3;
  font-size: 1.4em;
  margin-left: 0.4em;
}

.indicator.issue {
  background-color: white;
  color: #6f6f6f;
  font-size: 1.7em;
  margin-left: 0.2em;
}

.indicator.info {
  background-color: white;
  color: #2196f3;
  font-size: 1.7em;
  margin-left: 0.2em;
}

.indicator.error {
  background-color: white;
  color: #f44336;
  font-size: 1.7em;
  margin-left: 0.2em;
}

.indicator.inactive {
  background-color: #ccc;
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.7em;
}

.indicator svg {
  width: 100%;
  height: 100%;
}

.acsr-metric-table th {
  cursor: pointer;
  position: relative;
  padding-right: 1.3em;
  user-select: none;
}

.acsr-metric-table .currency {
  font-size: 0.8em;
}

.acsr-tables-wrapper .acsr-metric-table th .sort-indicator {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 0.8em;
}

.acsr-metric-table th:hover {
}

.acsr-campaign-column {
  flex: 0 1 auto;
  position: sticky;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(255, 255, 255) 80%,
    rgba(255, 255, 255, 0.035) 100%
  );
  max-width: 19.6em;
  width: fit-content;
}

.acsr-campaign-header,
.acsr-tables-wrapper .acsr-campaign-subheader {
  height: 2.6em;
}

.acsr-campaign-name {
  padding: 0.8em 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.acsr-campaign-name.campaign {
  font-weight: 600;
  color: #495057;
  background-color: #f8f9fa;
  border-top: 2px solid #dee2e6;
}

.acsr-campaign-name.adset {
  color: #6c757d;
  font-size: 0.95em;
  padding-left: 2.5em;
  position: relative;
}

.acsr-campaign-name.adset::before {
  content: '';
  position: absolute;
  left: 1em;
  top: 50%;
  width: 0.8em;
  height: 2px;
  background-color: #ced4da;
}

.acsr-campaign-name.adset::after {
  content: '';
  position: absolute;
  left: 1em;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #e9ecef;
}

.acsr-metric-table {
  flex: 0 0 22em;
  margin-right: 1.5em;
  border-radius: 0;
  overflow: hidden;
  padding-top: 0.6em;
}

.acsr-tables-wrapper .acsr-campaign-name.clickable:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.acsr-tables-wrapper .acsr-campaign-name.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.acsr-metric-table th,
.acsr-metric-table td {
  padding: 0.6em;
  text-align: center;
  border-radius: 0.3em;
}

.acsr-tables-wrapper .acsr-metric-header {
  padding: 0.6em;
  text-align: center;
  font-weight: 700;
  color: #fff;
  height: 3em;
  align-content: center;
}

.acsr-tables-wrapper .acsr-metric-header .tooltip-container,
.acsr-tables-wrapper .acsr-metric-cell .tooltip-container {
  width: 100%;
}

.acsr-tables-wrapper .acsr-metric-table table {
  width: 100%;
  border-collapse: collapse;
}

.acsr-tables-wrapper .acsr-metric-table th,
.acsr-tables-wrapper .acsr-metric-table td {
  padding: 0.6em;
  text-align: center;
  border-bottom: 0.1em solid #e0e0e0;
}

.acsr-tables-wrapper .acsr-metric-table th {
  font-weight: bold;
}

/* Table Colors */
.acsr-tables-wrapper .acsr-metric-table.ad-spend .acsr-metric-header {
  background-color: #0585e0;
}

.acsr-tables-wrapper .acsr-metric-table.reach .acsr-metric-header {
  background-color: #00a090;
}

.acsr-tables-wrapper .acsr-metric-table.sales .acsr-metric-header {
  background-color: #00963e;
}

.acsr-tables-wrapper .acsr-metric-table.clicks .acsr-metric-header {
  background-color: #2fa700;
}

.acsr-tables-wrapper .acsr-metric-table.performance .acsr-metric-header {
  background-color: #00aee8;
}

.acsr-tables-wrapper .acsr-metric-table.ad-spend {
  background-color: rgba(5, 133, 224, 0.1);
}

.acsr-tables-wrapper .acsr-metric-table.reach {
  background-color: rgba(0, 160, 144, 0.1);
}

.acsr-tables-wrapper .acsr-metric-table.sales {
  background-color: rgba(0, 150, 62, 0.1);
}

.acsr-tables-wrapper .acsr-metric-table.clicks {
  background-color: rgba(47, 167, 0, 0.1);
}

.acsr-tables-wrapper .acsr-metric-table.performance {
  background-color: rgba(0, 174, 232, 0.1);
}

/* Mouse wheel event'ini y-ekseninde scroll için güncelle */
.acsr-tables-wrapper::-webkit-scrollbar {
  width: 0.3em;
  /* y-ekseni scrollbar genişliği */
  height: 0;
  /* x-ekseni scrollbar'ı gizle */
}

.acsr-tables-wrapper::-webkit-scrollbar-track {
  background: linear-gradient(
    to right,
    rgba(241, 241, 241, 0.4),
    rgba(241, 241, 241, 0.7)
  );
  border-radius: 0.15em;
}

.acsr-tables-wrapper::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to right,
    rgba(136, 136, 136, 0.8),
    rgba(136, 136, 136, 0.9)
  );
  border-radius: 0.15em;
  min-height: 3em;
  transition: all 0.2s ease-in-out;
}

.acsr-tables-wrapper::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    to right,
    rgba(102, 102, 102, 0.9),
    rgba(102, 102, 102, 1)
  );
}

.acsr-tables-wrapper:active {
  cursor: grabbing;
}

.acsr-tables-wrapper::-webkit-scrollbar {
  height: 0;
}

.acsr-table-container {
  margin-top: 5em;
  margin-bottom: 4em;
  position: relative;
}

.acsr-tables-wrapper {
  margin-top: 1em;
  /* 5em'den 1em'e düşürdük çünkü artık tab'lar var */
  /* ... existing styles ... */
}

.acsr-table-tabs {
  display: flex;
  gap: 1em;
  margin-bottom: 2em;
}

.acsr-tab {
  padding: 0.8em 1.6em;
  border: none;
  border-radius: 0.5em;
  background: #f5f5f5;
  color: #666;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.acsr-tab:hover {
  background: #ebebeb;
}

.acsr-tab.active {
  background: #0585e0;
  color: white;
}

.acsr-main-table {
  border-collapse: separate;
  border-spacing: 0;
  width: max-content;
}

.acsr-label-header {
  position: sticky;
  left: 0;
  background: white;
  z-index: 2;
  min-width: 250px;
  text-align: left;
  padding-left: 1em;
}

.acsr-metric-group-header {
  text-align: center;
  color: white;
  padding: 0.6em;
}

.acsr-metric-header {
  padding: 1em;
  color: white;
  border-right: 0px solid #fff;
}

/* Label header için özel genişlik */
.acsr-metric-header.label-header {
  position: sticky;
  left: 0;
  background: white;
  color: #495057;
  z-index: 3;
  text-align: left;
  padding-left: 1em;
  width: 25em;
  max-width: 25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

/* Diğer header'ların arka plan renkleri */
.acsr-metric-header.ad-spend {
  background-color: #0585e0;
}

.acsr-metric-header.reach {
  background-color: #00a090;
}

.acsr-metric-header.sales {
  background-color: #00963e;
}

.acsr-metric-header.clicks {
  background-color: #2fa700;
}

.acsr-metric-header.performance {
  background-color: #00aee8;
}

/* Label hücresi için özel genişlik */
.acsr-label-cell {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  padding: 0.8em 2em 0.8em 1em;
  border-right: 0px solid #f0f0f0;
  cursor: pointer;
}

/* Campaign satırları için özel renkler */
.ad-spend-group .acsr-table-row.campaign td {
  background-color: rgba(5, 133, 224, 0.233) !important;
  font-weight: 600 !important;
  z-index: 2;
}

.sales-group .acsr-table-row.campaign td {
  background-color: rgba(0, 150, 62, 0.193) !important;
  z-index: 2;
  font-weight: 600 !important;
}

.reach-group .acsr-table-row.campaign td {
  background-color: rgba(0, 160, 144, 0.208) !important;
  z-index: 2;
  font-weight: 600 !important;
}

.clicks-group .acsr-table-row.campaign td {
  background-color: rgba(47, 167, 0, 0.159) !important;
  z-index: 2;
  font-weight: 600 !important;
}

.performance-group .acsr-table-row.campaign td {
  background-color: rgba(0, 174, 232, 0.179) !important;
  z-index: 2;
  font-weight: 600 !important;
}

/* Campaign label hücresi için stil */
.acsr-table-row.campaign .acsr-label-cell {
  background-color: rgb(239, 239, 239) !important;
  z-index: 2;
}

/* Adset satırları için normal metrik renkleri devam etsin */
.acsr-table-row.adset .acsr-metric-cell.ad-spend {
  background-color: rgba(5, 133, 224, 0.1);
}

.acsr-table-row.adset .acsr-metric-cell.reach {
  background-color: rgba(0, 160, 144, 0.1);
}

.acsr-table-row.adset .acsr-metric-cell.sales {
  background-color: rgba(0, 150, 62, 0.1);
}

.acsr-table-row.adset .acsr-metric-cell.clicks {
  background-color: rgba(47, 167, 0, 0.1);
}

.acsr-table-row.adset .acsr-metric-cell.performance {
  background-color: rgba(0, 174, 232, 0.1);
}

/* Campaign satırları için border */
.acsr-table-row.campaign {
  border-top: 0px solid #dee2e6;
}

/* Campaign label hücresi için stil */
.acsr-table-row.campaign .acsr-label-cell {
  font-weight: 600;
  color: #2c2c2c;
  border-radius: 8px;
}

.acsr-table-row.adset .acsr-label-cell {
  padding-left: 2.5em;
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  border-radius: 8px;
}

.acsr-table-row.adset .acsr-label-cell::before {
  content: '';
  position: absolute;
  left: 1em;
  top: 50%;
  width: 1em;
  height: 2px;
  background-color: #ced4da;
  border-radius: 0 100% 100% 0;
}

.acsr-table-row.adset .acsr-label-cell::after {
  content: '';
  position: absolute;
  left: 1em;
  top: 0;
  width: 1.5px;
  height: 100%;
  transform: translateY(-50%);
  background-color: #ced4da;
  border-radius: 0 100% 100% 0;
}

.acsr-metric-cell {
  padding: 0.8em 1em;
  border-right: 0px solid #fff;
  /* Sütunlar arası beyaz çizgi */
}

/* Metric group colors */
.acsr-metric-group-header,
.acsr-metric-header {
  &.ad-spend {
    background-color: #0585e0;
  }

  &.reach {
    background-color: #00a090;
  }

  &.sales {
    background-color: #00963e;
  }

  &.clicks {
    background-color: #2fa700;
  }

  &.performance {
    background-color: #00aee8;
  }
}

/* Light backgrounds for cells */
.acsr-metric-cell {
  &.ad-spend {
    background-color: rgba(5, 133, 224, 0.1);
  }

  &.reach {
    background-color: rgba(0, 160, 144, 0.1);
  }

  &.sales {
    background-color: rgba(0, 150, 62, 0.1);
  }

  &.clicks {
    background-color: rgba(47, 167, 0, 0.1);
  }

  &.performance {
    background-color: rgba(0, 174, 232, 0.1);
  }
}

/* Diğer tüm header ve hücreler için sabit genişlik */
.acsr-metric-header:not(.label-header),
.acsr-metric-cell {
  width: 5em;
  min-width: 10em;
  max-width: 23em;
  padding: 0.6em;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Eski hover stilini kaldır */
.acsr-table-row:hover .acsr-metric-cell {
  filter: none;
}

/* Yeni hover stilleri */
.acsr-table-row.hover-effect,
.acsr-table-row.hover-effect .acsr-label-cell {
  background-color: rgb(242, 242, 242) !important;
}

/* Her grup için özel hover renkleri */
.ad-spend-group .acsr-table-row.hover-effect td,
.ad-spend-group .acsr-table-row.hover-effect .acsr-label-cell {
  background-color: rgba(5, 133, 224, 0.15) !important;
}

.sales-group .acsr-table-row.hover-effect td,
.sales-group .acsr-table-row.hover-effect .acsr-label-cell {
  background-color: rgba(0, 150, 62, 0.15) !important;
}

.reach-group .acsr-table-row.hover-effect td,
.reach-group .acsr-table-row.hover-effect .acsr-label-cell {
  background-color: rgba(0, 160, 144, 0.15) !important;
}

.clicks-group .acsr-table-row.hover-effect td,
.clicks-group .acsr-table-row.hover-effect .acsr-label-cell {
  background-color: rgba(47, 167, 0, 0.15) !important;
}

.performance-group .acsr-table-row.hover-effect td,
.performance-group .acsr-table-row.hover-effect .acsr-label-cell {
  background-color: rgba(0, 174, 232, 0.15) !important;
}

/* Scrollbar stilleri */
.acsr-tables-wrapper {
  overflow-x: auto;
  padding-bottom: 1em;
  margin: 1em 0;
}

.acsr-tables-wrapper::-webkit-scrollbar {
  height: 8px;
}

.acsr-tables-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.acsr-tables-wrapper::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.acsr-tables-wrapper::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

/* Tablo grupları için container */
.table-groups-container {
  display: flex;
  gap: 0;
}

/* Her tablo grubu için stil */
.acsr-metric-table {
  border-radius: 1em;
  overflow: hidden;
  margin-right: 0;
}

/* Ad Spend grubu için stiller */
.ad-spend-group .acsr-metric-header {
  background-color: #0585e0;
}

.ad-spend-group .acsr-metric-cell {
  background-color: rgba(5, 133, 224, 0.1);
}

/* Sales grubu için stiller */
.sales-group .acsr-metric-header {
  background-color: #00963e;
}

.sales-group .acsr-metric-cell {
  background-color: rgba(0, 150, 62, 0.1);
}

/* İsim sütunu için stil */
.acsr-name-table {
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;
  margin-right: 0;
  padding-top: 0.6em;
}

/* Tüm grup stilleri */
.reach-group .acsr-metric-header {
  background-color: #00a090;
}

.reach-group .acsr-metric-cell {
  background-color: rgba(0, 160, 144, 0.1);
}

.clicks-group .acsr-metric-header {
  background-color: #2fa700;
}

.clicks-group .acsr-metric-cell {
  background-color: rgba(47, 167, 0, 0.1);
}

.performance-group .acsr-metric-header {
  background-color: #00aee8;
}

.performance-group .acsr-metric-cell {
  background-color: rgba(0, 174, 232, 0.1);
}

/* Hover efekti için stil */
.table-groups-container tr[data-row-index] {
  position: relative;
}

/* Aynı satır indeksine sahip tüm satırlar için hover efekti */
.table-groups-container tr[data-row-index]:hover,
.table-groups-container tr[data-row-index]:hover ~ table tr[data-row-index],
.table-groups-container table:hover ~ table tr[data-row-index] {
}

/* Her grup için özel hover renkleri */
.ad-spend-group tr[data-row-index]:hover,
.ad-spend-group tr[data-row-index]:hover ~ table tr[data-row-index] {
  background-color: rgba(5, 133, 224, 0.15);
}

.sales-group tr[data-row-index]:hover,
.sales-group tr[data-row-index]:hover ~ table tr[data-row-index] {
  background-color: rgba(0, 150, 62, 0.15);
}

.reach-group tr[data-row-index]:hover,
.reach-group tr[data-row-index]:hover ~ table tr[data-row-index] {
  background-color: rgba(0, 160, 144, 0.15);
}

.clicks-group tr[data-row-index]:hover,
.clicks-group tr[data-row-index]:hover ~ table tr[data-row-index] {
  background-color: rgba(47, 167, 0, 0.15);
}

.performance-group tr[data-row-index]:hover,
.performance-group tr[data-row-index]:hover ~ table tr[data-row-index] {
}

/* Hover efekti için stil */
.acsr-table-row.hover-effect {
  background-color: rgba(0, 0, 0, 0.04);
  font-weight: 600 !important;
}

/* Her grup için özel hover renkleri */
.ad-spend-group .acsr-table-row.hover-effect {
  background-color: rgba(5, 133, 224, 0.15);
}

.sales-group .acsr-table-row.hover-effect {
  background-color: rgba(0, 150, 62, 0.15);
}

.reach-group .acsr-table-row.hover-effect {
  background-color: rgba(0, 160, 144, 0.15);
}

.clicks-group .acsr-table-row.hover-effect {
  background-color: rgba(47, 167, 0, 0.15);
}

.performance-group .acsr-table-row.hover-effect {
  background-color: rgba(0, 174, 232, 0.15);
}

/* Label hücresi içindeki düzen için */
.acsr-label-cell .label-with-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  width: 100%;
}

.acsr-label-cell .label-text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 19em;
  min-width: 17em;
}

/* Custom style for label-text inside tooltip */
.acsr-label-cell .tooltip-container {
  flex: 1;
  overflow: hidden;
}

.acsr-label-cell .tooltip-container .label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  min-width: auto;
  display: block;
}

.acsr-label-cell .indicator {
  margin: 0;
  flex-shrink: 0;
}

.acsr-metric-table.audiences {
}

.acsr-metric-table.audiences th {
  padding: 0.6em;
  background-color: #234a5e;
  color: white;
  font-weight: 500;
}

.acsr-metric-table.audiences td {
  padding: 0.6rem;
  vertical-align: middle;
}

.acsr-metric-table.audiences td:first-child {
  padding: 0.2rem 0.6rem;
}

.acsr-metric-table.audiences .audience-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.acsr-metric-table.audiences .switch {
  height: 20px !important;

  background: #f0f0f0;
  border: none;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.acsr-metric-table.audiences .switch .option {
  font-weight: 500;
  height: 26px !important;
  line-height: 26px !important;
  color: #666;
  z-index: 1;
  transition: color 0.2s ease;
}

.acsr-metric-table.audiences .switch .selection {
  background: var(--main-green);
  border-radius: 10px;
  height: 16px !important;
  top: 2px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.acsr-metric-table.audiences
  .switch[data-value='Yes']
  .option[data-value='Yes'],
.acsr-metric-table.audiences .switch[data-value='No'] .option[data-value='No'] {
  color: white;
}

.acsr-metric-table.audiences .switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.acsr-metric-table.audiences .switch:hover {
  background: #e8e8e8;
}

.acsr-metric-table.audiences .switch[data-value='Yes'] .selection {
  background: var(--main-green);
}

.acsr-metric-table.audiences .switch[data-value='No'] .selection {
  background: var(--text-gray);
}

.acsr-metric-table.audiences .acsr-table-row.campaign td {
  background-color: rgba(35, 74, 94, 0.15);
  font-weight: 500;
}

.acsr-metric-table.audiences .acsr-table-row.adset td {
  background-color: rgba(35, 74, 94, 0.05);
}

.acsr-metric-table.audiences .acsr-table-row.hover-effect td {
  background-color: rgba(35, 74, 94, 0.2);
}

.acsr-metric-cell .audience-list {
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.acsr-metric-cell .edit-button {
  display: none;
  transition: opacity 0.3s ease;
}

.acsr-metric-cell:hover .edit-button {
  display: inline-block;
}

tr[data-type='funnel'] .acsr-label-cell:hover {
  background-color: rgba(221, 19, 19, 0.05);
}

.acsr-label-cell[data-clickable='true'] {
  cursor: pointer;
  color: #234a5e;
}

.acsr-label-cell[data-clickable='true']:hover {
  text-decoration: underline;
}

.acsr-tables-wrapper.dragging {
  cursor: grabbing;
  scroll-behavior: auto;
}

.no-data-text {
  text-align: center;
  padding: 1em;
  color: #666;
}

.acsr-metric-table.audiences .acsr-metric-cell {
  width: 22em;
  min-width: 18em;
  max-width: 36em;

  position: relative;
}

.acsr-metric-table.audiences .audience-cell {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.acsr-metric-table.audiences .audience-cell > div:first-child {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 3em;
  padding-left: 3em;
}

.acsr-metric-table.audiences .edit-button {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease;
  padding: 0;
  margin: 0;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.acsr-metric-table.audiences .acsr-metric-cell:hover .edit-button {
  opacity: 1;
}

.acsr-metric-table.audiences .switch-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.acsr-metric-table.audiences th:first-child,
.acsr-metric-table.audiences td:first-child {
  width: 10em;
  min-width: 10em;
  max-width: 10em;
}

.acsr-metric-table.audiences th .tooltip-container {
  height: 1.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.6em;
  font-weight: bold;
  line-height: 1.2;
}

.acsr-table-container .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}
