.creative-mode-selection {
  max-width: 1200px; /* 1200px */
  margin: 1em 20px;
  padding: 0;
}

.creative-mode-selection h2 {
  font-size: 1.5em; /* 24px */
  margin-bottom: 2em;
  color: #1a1a1a;
  font-weight: 500;
}

.creative-mode-selection .creative-modes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5em;
}

.creative-mode-selection .mode-card {
  background: #fff;
  border-radius: 1em; /* 12px */
  padding: 2em;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #dfeaf2; /* 1px */
  min-height: 12.5em; /* 200px */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

/* AI Creative Lab kartı için özel stil */
.creative-mode-selection .mode-card:first-child {
  grid-row: 1 / span 2;
  position: relative;
  min-height: 26.25em; /* 420px */
}

/* Background image ve overlay */
.creative-mode-selection .mode-card:first-child::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../../shared/assets/images/ai-ads/ai-creative-lab-bg.png');
  background-size: cover;
  background-position: center;
  opacity: 0.4;
  z-index: 0;
  transition: opacity 0.3s ease;
}

.creative-mode-selection .mode-card:first-child:hover::before {
  opacity: 1;
}

/* Kartın içeriğini overlay'in üzerine getirmek için */
.creative-mode-selection .mode-card:first-child > * {
  position: relative;
  z-index: 1;
}

.creative-mode-selection .mode-icon {
  width: 4.5em; /* 70px */
  height: 4.5em; /* 70px */
  margin-bottom: 1em;
  padding: 0.5em; /* 8px */
  border-radius: 1em;
}

.creative-mode-selection .mode-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 0.5em;
}

.creative-mode-selection .mode-card h3 {
  font-size: 1.25em; /* 15px */
  margin-bottom: 0.75em;
  padding: 0 0.4em;
  color: #1a1a1a;
  font-weight: 500;
  background: none;
  text-align: left;
}

.creative-mode-selection .mode-card p {
  font-size: 1em; /* 14px */
  color: #666;
  line-height: 1.5;
  margin: 0;
  padding: 0 0.5em;
}

.creative-mode-selection .mode-card:hover {
  transform: translateY(-0.125em); /* -2px */
  border: 1px solid #a2c4dd;
}

/* Responsive Tasarım */
@media (max-width: 992px) {
  .creative-mode-selection .creative-modes {
    grid-template-columns: 1fr;
  }

  .creative-mode-selection .mode-card:first-child {
    min-height: 18.75em; /* 300px */
  }

  .creative-mode-selection .mode-card {
    min-height: 11.25em; /* 180px */
  }
}

@media (max-width: 768px) {
  .creative-mode-selection {
    padding: 0 1em;
  }

  .creative-mode-selection h2 {
    font-size: 1.25em; /* 20px */
    text-align: left;
  }

  .creative-mode-selection .mode-card {
    padding: 1.5em;
  }
}
