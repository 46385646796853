.no-data .row {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.no-data .dot {
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background: var(--main-green);
  box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.15);
  transition: all 0.2s ease;
}
