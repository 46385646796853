.stripe-card-modal{
    padding: 10em;
}
.stripe-card-modal .header-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4em;
    margin: 0;
}

.stripe-card-modal img{
   max-width: 60px;
   max-height: 30px;
}

.stripe-card-modal .header-row p{
     font-weight: bold;
     color: #5F5F5F;
}

