.alarms-calendar-container {
  height: 100%;
}

.alarms-calendar-container .layout {
  height: 100%;
  display: flex;
  gap: 1em;
}

.alarms-calendar-container .layout .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
}

.alarms-calendar-wrapper {
  width: 100%;
  height: 95%;
}

.alarms-calendar-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.alarms-calendar-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.alarms-calendar-wrapper::-webkit-scrollbar-thumb {
  background: var(--transparent-gray);
}

.alarms-calendar-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.25);
}

.alarms-calendar-wrapper .date-changer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  margin: 0.9em;
  font-size: 1.2em;
  font-weight: bold;
}

.alarms-calendar-wrapper .date-changer .dates-bar {
  display: flex;
  gap: 5em;
  font-size: 1.5em;
}

.alarms-calendar {
  display: flex;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding: 0 1em 1em;
  align-items: start;
  justify-content: space-evenly;
}

.scroll-ending-box {
  padding: 1em;
  align-items: center;
  justify-content: center;
  display: flex;
}

.alarms-calendar .alarms-date-column {
  display: flex;
  flex-direction: column;
  width: 30%;
}

@media (max-width: 970px) {
  .alarms-calendar .alarms-date-column {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  .alarms-calendar {
    justify-content: space-around;
  }
}
@media (max-width: 700px) {
  .alarms-calendar {
    font-size: smaller;
    padding: 0em !important;
  }
  .alarms-calendar-wrapper .date-changer {
    font-size: 1em !important;
  }
}
@media (max-width: 675px) {
  .alarms-calendar .alarms-date-column {
    display: flex;
    flex-direction: column;
    width: 85%;
  }
  .alarms-calendar {
    justify-content: space-around;
  }
}
