.card-header .customer-segmentation-score-icon {
	background: white;
	color: black;
	display: flex;
	border-radius: 10em;
	width: 25px;
	height: 25px;
	font-size: 0.8em;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.segmentation-info-popup table {
	font-size: 0.8em;
	text-align: left;
	margin-top: 0.5em;
}

.segmentation-info-popup table td,
.segmentation-info-popup table th {
	border: 1px solid rgb(159, 159, 159);
	padding: 5px 10px;
}