.ad-creatives-list {
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1em;
  /* transform: scale(0.5) translate(-50%, -50%) */
  max-height: 30vh;
  overflow: scroll;
  padding: 0.5em;
  max-width: 800px;
}

.ad-creatives-list .single-creative-cont {
  flex: 1 1 auto;
  border-radius: 0.5em;
  overflow: hidden;
  padding: 0.3em 1em;
  /* transform: scale(.5) translate(-50%, -50%); */
  box-shadow: 0 0 1px 1px #ccc;
  transition: 0.3s;
  border: none;
  margin-bottom: 0.7em;
  cursor: pointer;
  position: relative;
}

.ad-creatives-list .single-creative-cont > .row {
  justify-content: space-between;
  flex-wrap: nowrap;
}

.ad-creatives-list .single-creative-cont .ad-creative-cont {
  transform: scale(0.5) translate(-50%, -50%);
}

.ad-creatives-list .single-creative-cont:hover {
  box-shadow: 0 1px 5px 1px #ccc;
  transform: translateY(-2px);
}

.ad-creatives-list .single-creative-cont.active {
  border: 2px solid var(--main-green);
}

.ad-creatives-list .single-creative-cont .creative-control-bar {
  padding: 0.5em;
  background: #f1f1f1;
}

.ad-creatives-list .single-creative-cont .creative-control-bar > .row {
  justify-content: space-between;
}

.ad-creatives-list .single-creative-cont .ad-name {
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 0 50%;
  overflow: hidden;
}

.ad-creatives-list .single-creative-cont .info-row {
  display: flex;
  align-items: center;
  gap: 1em;
  flex: 1 1 30%;
}

.ad-creatives-list .single-creative-cont .creative-info {
  font-size: 1em;
  color: var(--main-green);
  white-space: nowrap;
}

.ad-creatives-list .single-creative-cont .creative-info .value {
  font-weight: 600;
}

.ad-creatives-list .single-creative-cont .helper-text {
  color: #676767;
  font-size: 0.8em;
}

.ad-creatives-list .single-creative-cont.active .active-indicator {
  display: inline-block;
  color: var(--main-green);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.ad-creatives-list .single-creative-cont:not(.active) .active-indicator {
  display: none;
}

.ad-creatives-list .single-creative-cont .buttons-row {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.ad-creatives-list .single-creative-cont.active .buttons-row {
}

.ad-creatives-list .single-creative-cont .buttons-row .button {
  font-size: 0.8em;
  padding: 2px 8px;
  background: var(--button-gradient);
}

.ad-creatives-list .see-more {
  background: transparent;
  color: var(--text-blue);
  font-weight: 500;
  margin: 0 auto;
}

.ad-creatives-list .single-creative-cont .edit-floating-button {
  overflow: hidden;
  background: rgba(4, 4, 4, 0.493);
  backdrop-filter: blur(4px);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 0.5em 0.5em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  font-weight: 500;
  margin: 0 auto;
  padding: 5px 2em;
  opacity: 0;
  transition: 0.3s;

  cursor: default;
  color: #bebebe;
}

.ad-creatives-list .single-creative-cont .edit-floating-button.editable {
  color: var(--main-white);
  cursor: pointer;
}

.ad-creatives-list
  .single-creative-cont:not(.is-edit-open).active:hover
  .edit-floating-button {
  opacity: 1;
}

.ad-creatives-list .single-creative-cont .edit-creative-form {
  position: relative;
  z-index: 10;
}

.ad-creatives-list .single-creative-cont .edit-creative-form {
  position: relative;
  z-index: 10;
}

.ad-creatives-list .single-creative-cont .edit-creative-form .control-row {
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--main-gray);
  margin: 0;
  margin-top: 0.3em;
  padding: 0.2em 0;
  position: relative;
  z-index: 10;
}

.ad-creatives-list
  .single-creative-cont
  .edit-creative-form
  .control-row
  .close-edit-button:hover {
  transform: scale(1.05);
}

.ad-creatives-list .single-creative-cont .edit-creative-form h4 {
  margin: 0;
}

.ad-creatives-list .single-creative-cont .edit-creative-form .row {
  margin-top: 0.5em;
}

.ad-creatives-list .single-creative-cont .edit-creative-form label {
  font-size: 0.85em;
}

.ad-creatives-list
  .single-creative-cont
  .edit-creative-form
  .form-control
  input,
.ad-creatives-list
  .single-creative-cont
  .edit-creative-form
  .form-control
  textarea {
  font-size: 0.75em;
  cursor: text;
  scrollbar-width: none;
}

.ad-creatives-list .single-creative-cont .edit-creative-form {
  /* font-size: .75em; */
}

.ad-creatives-list .tooltip-container {
  width: 100%;
}

.ad-creatives-list .tooltip-container .option {
  width: 100%;
}
