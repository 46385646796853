.button {
  font: inherit;
  padding: 0.4rem 1rem;
  border: none;
  border-radius: 6px;
  background: var(--text-blue);
  color: var(--main-white);
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  cursor: pointer !important;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: var(--text-blue-hover);
  border-color: var(--text-blue-hover);
}

.button--green-gradient {
  background: linear-gradient(
    32deg,
    var(--primary-green),
    var(--second-green) 42%
  );
  font-weight: 600;
}

.button--green-gradient:hover,
.button--green-gradient:hover {
  background: var(--main-green-gradient-hover);
}

.button--cancel {
  background-color: #f0f0f0;
  color: #7f7f7f;
  box-shadow: none !important;
}

.button--cancel:hover {
  background-color: #e0e0e0;
}

.button--inverse {
  background: transparent;
  color: #ff0055;
}

.button--inverse:hover,
.button--inverse:active {
  color: var(--main-white);
  background: #ff0055;
}

.button--danger {
  background: #d60a0a;
  border-color: #d60a0a;
}

.button--danger:hover,
.button--danger:active {
  background: #830000;
  border-color: #830000;
}

.button--info {
  background: #ffd900;
  border-color: #ffd900;
}

.button--transparent {
  background: none;
}

.button--info:hover,
.button--info:active {
  background: #f34343;
  border-color: #f34343;
}

.button:not(.button--loading):disabled,
.button:not(.button--loading):hover:disabled,
.button:not(.button--loading):active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed !important;
}

.button--success {
  background: var(--second-green);
  border-color: var(--second-green);
}

.button--success:hover,
.button--success:active {
  background: var(--second-green-hover);
  border-color: var(--second-green-hover);
}

.button--loading,
.button--loading:hover,
.button--loading:active {
  opacity: 0.5;
  cursor: default !important;
}

.button--loading .icon {
  animation-name: loading-spin;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.button--text {
  background: none;
  border: none;
  color: var(--text-blue);
  display: inline;
  padding: 0 0.2em;
}

.button--text:hover,
.button--text:active {
  background: none;
  border: none;
  color: var(--text-blue-hover);
}

.button--small {
  font-size: 0.8rem;
  padding: 0.2em 1em;
}

.button--big {
  font-size: 1.3rem;
  padding: 0.5em 1em;
}

.button--attention-drawer {
  background: linear-gradient(
    55deg,
    var(--main-green),
    10%,
    #ffffff,
    90%,
    var(--main-green)
  );
  color: #5b5b5e;
  border: 1px solid #c0c0c0;
  font-weight: 600;
}

.button--attention-drawer:hover {
  background: linear-gradient(
    55deg,
    var(--main-green),
    20%,
    #ffffff,
    80%,
    var(--main-green)
  );
  border: 1px solid #9d9d9d;
}

.action-button {
  box-shadow: 0 2px 5px 0 #ccc;
}

.action-button.red {
  background-color: var(--main-red) !important;
}

.performance-button {
  min-height: 2.5em;
  max-height: 4em;
}

.text-button {
  cursor: pointer;
  font-size: 0.8em;
}

.text-button:hover {
  color: rgb(174, 57, 57);
}

.text-button.green-hover:hover {
  color: var(--main-green);
}

.admin-spec-button {
  font-size: 0.8em;
}

.button.button--disabled {
  opacity: 0.4;
}

@media (max-width: 1200px) {
  .button {
    font-size: 0.9em;
    padding: 0.2rem 0.7rem;
  }

  .button--text {
    font-size: 0.9em;
  }
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Gradient Background Button */
.button--gradient-background {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  color: #ffffff;
  background-image: linear-gradient(
    83deg,
    #01b04a -14.97%,
    #2edbff 50%,
    #01b04a 84.97%,
    #2edbff 184.06%
  ) !important;
  background-size: 300% 100% !important;
  background-position: 0% center !important;
  transition:
    all 0.3s ease,
    background-position 0.5s ease !important;
  box-shadow: 0px 1px 6px 0px rgba(46, 219, 255, 0.3);
}

.button--gradient-background:hover,
.button--gradient-background:active {
  background-image: linear-gradient(
    83deg,
    #01b04a -14.97%,
    #2edbff 50%,
    #01b04a 84.97%,
    #2edbff 184.06%
  ) !important;
  background-position: 102% center !important;
  border-color: transparent !important;
  box-shadow: 0px 8px 24px rgba(46, 219, 255, 0.3);
  border: none !important;
}

.button--gradient-background:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  box-shadow: none;
}

/* Gradient Border Button */
.button--gradient-border {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 8px;
  background: white !important;
  color: #1a1a1a;
  border: none;
  cursor: pointer;
  position: relative;
  isolation: isolate;
  transition: all 0.3s ease;
  white-space: nowrap;
  z-index: 1;
}

.button--gradient-border::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(90deg, #01b04a 0%, #2edbff 50%, #01b04a 100%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  background-size: 200% 100%;
  transition: all 0.3s ease;
  z-index: -1;
}

.button--gradient-border:hover,
.button--gradient-border:active {
  background: white !important;
  border-color: transparent !important;
}

.button--gradient-border:hover::before {
  animation: gradientRotate 0.5s linear infinite;
}

.button--gradient-border:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button--gradient-border:disabled::before {
  background: #cccccc;
  animation: none;
}

/* Loading State */
.button--gradient-background.loading,
.button--gradient-border.loading {
  color: transparent;
  pointer-events: none;
}

.button--gradient-background.loading::after,
.button--gradient-border.loading::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 0.8s linear infinite;
}

.button--gradient-background.loading::after {
  border-color: #ffffff;
  border-top-color: transparent;
}

.button--gradient-border.loading::after {
  border-color: #1a1a1a;
  border-top-color: transparent;
}

/* Animations */
@keyframes gradientRotate {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .button--gradient-background,
  .button--gradient-border {
    width: 100%;
    justify-content: center;
  }
}
