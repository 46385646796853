.alarms-calendar-wrapper {
  width: 100%;
  height: 95%;
}

.alarms-calendar-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.alarms-calendar-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.alarms-calendar-wrapper::-webkit-scrollbar-thumb {
  background: var(--transparent-gray);
}

.alarms-calendar-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.25);
}

.alarms-calendar-wrapper .date-changer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  margin: 0.9em;
  font-size: 1.2em;
  font-weight: bold;
}

.alarms-calendar-wrapper .date-changer .dates-bar {
  display: flex;
  gap: 5em;
  font-size: 1.5em;
}

.alarms-calendar {
  display: flex;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding: 0 1em 1em;
  align-items: start;
  justify-content: space-evenly;
}

.scroll-ending-box {
  padding: 1em;
  align-items: center;
  justify-content: center;
  display: flex;
}

.meeting-card {
  display: flex;
  font-size: 0.9em;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
  margin: 1em;
  font-family: Arial, sans-serif;
  background-color: var(--main-white) !important;
  box-shadow: 0 4px 8px var(--transparent-gray);
  overflow: hidden;
  min-width: 260px;
}

.meeting-card.demo-zoom {
  background-color: #dcefff;
}
.meeting-card.signup-zoom {
  background-color: #ffe8bf;
}
.meeting-card.customer-zoom {
  background-color: #d7fae9;
}

.meeting-card .label {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  background-color: #76b537;
  color: var(--main-white);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 20px 0 0 20px;
}

.meeting-card .label.demo {
  background: linear-gradient(to bottom, #007bff, 40%, #7c7c7c);
}

.meeting-card .label.signup {
  background: linear-gradient(to bottom, #ffa500, 40%, #7c7c7c);
}

.meeting-card .label.customer {
  background: linear-gradient(to bottom, var(--second-green), 40%, #7c7c7c);
}

.meeting-card .content-wrapper {
  flex: 1;

  overflow: hidden;
}

.meeting-card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 8px 8px 8px 8px;
}

.meeting-card .header .header-labels {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meeting-card .title {
  font-weight: bold;
  font-size: 1em;
  height: 2em;
}

.meeting-card .checkmark {
  color: var(--dark-green);
  font-size: 24px;
}

.meeting-card .subtitle {
  font-style: italic;
  font-size: 0.8em;
  color: #a1a1a1;
  margin: 4px 8px 16px 0px;
  height: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.meeting-card .content-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 8px;
  border-radius: 4px;
  margin-bottom: 1em;
}

.meeting-card .content-details {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  overflow: hidden;
}

.meeting-card .company {
  font-size: 1.2em;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.meeting-card .company-website {
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.meeting-card .account-manager {
  margin-top: 0.5em;
  font-size: 0.95em;
  height: 1em;
}

.meeting-card .time {
  flex: 1 0 auto;
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  justify-content: end;
  align-self: end;
}

.meeting-card .status {
  margin-top: 16px;
  padding: 8px;
  margin-left: -8px;
  text-align: right;
  font-size: 0.9em;
  font-weight: bold;
}

.meeting-card .status.touch {
  color: #43900f;
}

.meeting-card .status.no-touch {
  color: rgb(24, 24, 24);
}

.meeting-card .status.fast-churn {
  color: rgb(179, 49, 49);
}

.meeting-card .status.onboarding {
  color: rgb(221 171 43);
}

.meeting-card .status.good {
  color: rgb(86 137 59);
}

.meeting-card .status.bad {
  color: rgba(120, 38, 26, 1);
}

.meeting-card .status.no-access {
  color: rgba(66, 105, 241, 1);
}

.meeting-card .status.lost {
  color: #f73c3c;
}

.meeting-card .status.won {
  color: #4caf50;
}

.meeting-card .status.meeting-done {
  color: rgb(3, 155, 161);
}

.meeting-card .status.rescheduling {
  color: rgb(80, 117, 138);
}

.meeting-card .status.not-applicable {
  background: rgb(24, 24, 24);
}

.meeting-card .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 1px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.meeting-card .circle:hover {
  background-color: #e4e4e4;
}

.meeting-card .mini-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
}

.meeting-card .go-to-buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 1.7em;
}

.meeting-card .go-to-buttons .buttons-divider {
  background: #8e8e8e;
  width: 4px;
}

.meeting-card .go-to-buttons button {
  border-radius: 0;
  background: #7c7c7c;
  width: 100%;
}

.meeting-card .go-to-buttons button:hover {
  background: #373737;
}

.meeting-card .go-to-buttons button .button-text {
  white-space: nowrap;
}
