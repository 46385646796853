.audience-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.audience-manager-page {
  flex: 1 1 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2em;

  .middle-container {
    flex: 1 1 75%;
  }
}

.audience-manager-page .result-card {
  box-shadow: var(--card-shadow);
  border-radius: 7px;
  background: var(--main-white);
  overflow: hidden;
  font-size: 0.8em;
  flex: 1 1 auto;
}

.audience-manager-page .result-card.second {
  display: flex;
  flex-direction: column;
}

.audience-manager-page .result-card.second .card-content {
  padding-left: 0;
}

.audience-manager-page .result-card.second .card-content .segment-list {
  padding-left: 1em;
}

.audience-manager-page .result-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  border-bottom: 1px solid var(--disabled-gray);
}

.audience-manager-page .result-card .sample-text {
  font-size: 0.9em;
  font-weight: 500;
  color: #d13434;
}

.audience-manager-page .result-card .card-header .icon {
  font-size: 1.3em;
}

.audience-manager-page .result-card .card-header h4 {
  margin: 0;
  font-weight: 600;
  font-size: 1.1em;
}

.audience-manager-page .result-card .card-header h4 span.green {
  color: var(--second-green);
}

.audience-manager-page .result-card .card-header .form-control {
  margin: 0;
}

.audience-manager-page .result-card .card-header .form-control label {
  display: none;
}

.audience-manager-page .result-card .card-header .form-control .select-cont {
  box-shadow: none;
}

.audience-manager-page
  .result-card
  .card-header
  .form-control
  .select-cont
  select {
  padding: 0;
  height: 2em;
  width: 100%;
}

.audience-manager-page .result-card .card-content {
  padding: 1em;
  flex: 1 1 90%;
  overflow: hidden;
}

.audience-manager-page .result-card.second .card-content {
  height: 90%;
  overflow-y: scroll;
}

.audience-manager-page .result-card .card-content > .row {
  flex-wrap: nowrap;
  justify-content: space-between;
}

@media (max-width: 1550px) {
  .audience-manager-page {
    gap: 1em;
  }

  .App .middle-container {
    min-width: 600px;
  }

  .audience-manager-page .result-card .card-header {
    font-size: 0.9em;
    padding: 0.3em 1em;
  }

  .audience-manager-page
    .result-card
    .card-header
    .form-control
    .select-cont
    select {
    padding: 5px;
    font-size: 0.9em;
    height: unset;
    width: 100%;
  }
}

.audience-manager .middle-container.column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 1em;
}

.audience-manager .segmentation-row {
  align-items: flex-start;
  height: 100%;
}

.audience-manager .save-audience-button {
  margin-top: 2em;
  float: right;
}

.audience-manager .save-audiences-button {
  margin-top: 2em;
}

.audience-settings-container {
  padding-left: 2em;
}

.audience-manager .filters-toggle {
  cursor: pointer;
}

.audience-manager .filters-toggle:hover {
  transform: scale(1.05);
}

.audience-manager .result-card .card-content {
  overflow: scroll;
}

.audience-manager .result-card .target-choices {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

.audience-manager .result-card .use-target-choice {
  background: var(--main-white);
  border-radius: 5px;
  margin-bottom: 5px;
  width: min-content;
  padding: 2px 8px;
  font-size: 0.8em;
  cursor: pointer;
}
.audience-manager .campaign-selector {
  margin: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}
.audience-manager .campaign-selector-header {
  font-size: 1em;
  font-weight: 600;
}

.audience-manager .campaign-dropdown {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 200px;
  background-color: white;
  cursor: pointer;
}

.audience-manager .campaign-dropdown:focus {
  outline: none;
  border-color: #4caf50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
}

.audience-manager .result-card .use-target-choice.green {
  background: var(--main-green);
  color: var(--main-white);
}

.audience-manager .result-card .use-target-choice.yellow {
  background: #d4ac0d;
  color: var(--main-white);
}

.audience-manager .result-card .use-target-choice.red {
  background: var(--main-red);
  color: var(--main-white);
}

.audience-manager .result-card .use-target-choice.gray {
  background: gray;
  color: var(--main-white);
}

@media (max-width: 1400px) {
  .audience-manager .filters-toggle {
    width: 20px;
  }
}
