.ai-caption-section {
  margin-left: calc(12ch + 1em);
  width: calc(100% - 12ch - 1em);
  display: flex;
  flex-direction: column;

  position: relative;
}

.ai-caption-section::before {
  content: '';
  position: absolute;
  inset: -1px;
  z-index: -1;
  background: linear-gradient(
    215deg,
    var(--main-green-dark),
    var(--main-green),
    #4caf50,
    #81c784
  );
  background-size: 200% 200%;
  animation: gradient-animation 6s ease infinite;
  opacity: 0.1;
  border-radius: 1em;
}

.generate-captions-button {
  display: flex;
  align-items: center;
  gap: 0.5em;
  background: var(--main-green);
  color: white;
  border: none;
  padding: 0.75em 1.5em;
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
  width: fit-content;
  position: relative;
  overflow: hidden;
}

.generate-captions-button::before {
  content: '';
  position: absolute;
  inset: -2px;
  background: linear-gradient(
    215deg,
    var(--main-green-dark),
    var(--main-green),
    #4caf50
  );
  background-size: 200% 200%;
  animation: gradient-animation 6s ease infinite;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.generate-captions-button .arrow {
  font-size: 1.2em;
}

.generate-captions-button:hover::before {
  opacity: 1;
}

.generate-captions-button:hover {
  background: var(--main-green-dark);
}

.ai-caption-picks-header {
  font-size: 1em;
  color: var(--main-green-dark);
  font-weight: 500;
}

.recommendation-cards {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
  position: relative;
}

.recommendation-card {
  padding: 1em;
  font-size: 0.9em;
  color: #333;
  cursor: pointer;
  flex: 1 1;
  background: white;
  position: relative;
  border-radius: 0.8em;
  background-clip: padding-box;
  border: none;
  isolation: isolate;
}

.recommendation-card::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 2px;
  background: conic-gradient(from 0deg, #00c2ff, var(--main-green), #00c2ff);
  border-radius: 0.8em;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0.4;
  transition: opacity 0.3s ease;
  animation: rotateBorderColors 4s linear infinite;
  pointer-events: none;
}

.recommendation-card::after {
  content: '';
  position: absolute;
  inset: 0;
  background: white;
  border-radius: 0.8em;
  z-index: -1;
}

.recommendation-card:hover::before,
.recommendation-card.selected::before {
  opacity: 1;
  filter: drop-shadow(0 0 10px rgba(0, 194, 255, 0.5));
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes rotateBorderColors {
  0% {
    background: conic-gradient(from 0deg, #00c2ff, var(--main-green), #00c2ff);
  }
  25% {
    background: conic-gradient(from 90deg, #00c2ff, var(--main-green), #00c2ff);
  }
  50% {
    background: conic-gradient(
      from 180deg,
      #00c2ff,
      var(--main-green),
      #00c2ff
    );
  }
  75% {
    background: conic-gradient(
      from 270deg,
      #00c2ff,
      var(--main-green),
      #00c2ff
    );
  }
  100% {
    background: conic-gradient(
      from 360deg,
      #00c2ff,
      var(--main-green),
      #00c2ff
    );
  }
}

.recommendation-card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.recommendation-card .title {
  font-weight: 500;
  color: #333;
  font-size: 1.1em;
}

.recommendation-card .regenerate-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: var(--main-green);
  transition: color 0.3s ease;
}

.recommendation-card .regenerate-button:hover {
  color: var(--main-green-hover);
}

@media (max-width: 468px) {
  .ai-caption-section {
    margin-left: 0;
    width: 100%;
  }
}

.ai-caption-section .header-container {
  display: flex;
  align-items: center;
  gap: 1em;
}

.gpt4-banner {
  background: linear-gradient(90deg, #00c2ff 0%, var(--main-green) 100%);
  color: white;
  padding: 0.25em 0.75em;
  border-radius: 1em;
  font-size: 0.8em;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.gpt4-banner .icon {
  font-size: 1.2em;
}

.personalization-info {
  font-size: 0.85em;
  color: #666;
  font-style: italic;
  margin-top: 0.5em;
  margin-bottom: 2em;
}

.recommendation-card .tone-info,
.tone-info .highlight {
  display: none;
}
