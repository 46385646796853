.lookalike-audience-config {
  font-size: .9em;  
  width: 500px;
}

.lookalike-audience-config h3 {
  font-weight: 500;
  font-size: 1.1em;
}

.lookalike-audience-config h3 b {
  font-size: 1.2em;
  color: var(--main-green);
}

.lookalike-audience-config table {
  width: 100%;
  border-collapse: collapse;
  margin: 2em auto;
}

.lookalike-audience-config th,
.lookalike-audience-config td {
  padding: 8px;
  text-align: left;
}

.lookalike-audience-config th.label {
  font-weight: bold;
}

/* Center align all columns except the first one */
.lookalike-audience-config td:not(:first-child),
.lookalike-audience-config th:not(:first-child) {
  text-align: center;
}

/* Alternating row colors */
.lookalike-audience-config tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

.lookalike-audience-config input[type="checkbox"] {
  height: 1em;
}