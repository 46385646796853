.model-logs {
  min-width: 500px;
  max-width: 1700px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.modal-container .model-logs .content-box {
  width: 100%;
  padding: 0 0.5em;
  margin: 0;
  height: 100%;
  flex: 1 1 80%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.model-logs .category-title {
  color: var(--second-green);
  font-weight: 600;
}

.model-logs select {
  display: block;
  padding: 3px 7px;
  border: none;
  background: rgb(236 236 236);
  border-radius: 5px;
  width: 100%;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}

.model-logs select:hover {
  transform: translateY(-1px);
  box-shadow: 0 1px 5px 0 #ccc;
}

.model-logs .item {
  margin: 0 auto 1em;
}
