.modalContent {
  padding: 0 0 1em 0 !important;
  width: 85vw;
  max-width: 1200px;
  min-height: 600px;
  max-height: 90vh;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.headerSection {
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding: 2em 1em;
  background: linear-gradient(to bottom, #ffffff, #f9f9f9);
}

.smallTitle {
  margin: 0 auto;
  letter-spacing: 3px;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
  background: linear-gradient(
    263deg,
    var(--second-green) 44.19%,
    var(--primary-green) 96.46%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5em;
}

.mainTitle {
  margin: 0 auto;
  font-size: 1.8em;
  font-weight: 700;
  color: var(--text-black);
}

.previewContainer {
  flex: 1;
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  overflow: hidden;
}

.preview {
  flex: 1;
  width: 100%;

  border-radius: 12px;

  overflow: hidden;
}

.publishButton {
  min-height: 48px;
  padding: 0 2rem;
  font-size: 1rem;
  font-weight: 600;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.cancelButton {
  min-height: 48px;
  background: none;
  border: none;
  padding: 0 2rem;
  color: #666;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.2s ease;
}

.cancelButton:hover {
  color: #333;
  background: none;
}

@media (max-width: 768px) {
  .modalContent {
    width: 95vw;
    min-height: 500px;
  }

  .mainTitle {
    font-size: 1.5em;
  }

  .smallTitle {
    font-size: 0.8em;
  }

  .previewContainer {
    padding: 1em;
  }

  .previewContainer button {
    min-height: 44px;
    padding: 0 1.5rem;
    font-size: 0.95rem;
  }

  .cancelButton {
    font-size: 0.9rem;
  }
}
