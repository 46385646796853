.support-ticket-card {
  font-size: 14px;
  width: 100%;
  border-radius: 10px;
  background: var(--main-white);
  border: 1px solid #dbdbdb;
}

.support-ticket-card p {
  margin: 0;
}

.support-ticket-card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-gray);
  background: rgb(238 238 238);
  font-weight: 600;
  padding: 0.7em 1em;
  font-size: 0.928571429em;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.support-ticket-card .card-content {
  display: flex;
  flex-direction: column;
  padding: 0.7em 1em;
}

.support-ticket-card .card-header .section,
.support-ticket-card .card-content .section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.support-ticket-card .card-content .section:last-child {
  margin-top: 0.75em;
}

.support-ticket-card .card-header .section .priority {
  position: relative;
  background: #ffffff20;
  color: #fff;
  font-weight: 600;
  padding: 0.25em 0.75em;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  min-width: 15ch;
}

.support-ticket-card.urgent .card-header .section .priority {
  background: #900f0f;
}

.support-ticket-card.high .card-header .section .priority {
  background: #b93907;
}

.support-ticket-card.normal .card-header .section .priority {
  background: #d36b41;
}

.support-ticket-card:not(.pending) .card-header .section .priority {
  opacity: 0.5;
}

.support-ticket-card .card-header .section .priority:hover {
  filter: brightness(0.9);
}

.support-ticket-card .card-header .section .priority select {
  position: absolute;
  opacity: 0;
  width: 100%;
  inset: 0;
  cursor: pointer;
}

.support-ticket-card .card-content .section .key-value {
  font-size: 1.12em;
}

.support-ticket-card .card-content .section .key-value .key {
  font-weight: 500;
}

.support-ticket-card .card-content .section .description {
  margin-top: 0.75em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.support-ticket-card .card-content .section .indicators {
  display: flex;
  align-items: center;
  gap: 1em;
}

.support-ticket-card .card-content .section .indicators .item {
  width: 1.75em;
  height: 1.75em;
  background: var(--disabled-gray);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.15em;
  transition: all 120ms;
}

.support-ticket-card .card-content .section .indicators .item:hover {
  background: #cecece;
}

.support-ticket-card .card-content .section .indicators .item:active {
  transform: scale(0.97);
}

.support-ticket-card .card-content .section .indicators .messages {
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: #666;
}

.support-ticket-card .card-content .section .indicators .messages .icon-container {
  position: relative;
  display: flex;
  align-items: center;
}

.support-ticket-card .card-content .section .indicators .messages .icon-container .icon {
  font-size: 1.2em;
}

.support-ticket-card .card-content .section .indicators .messages .icon-container .unread {
  position: absolute;
  top: -0.1em;
  right: -0.3em;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #ff4500;
  border: 1px solid var(--main-white);
}

.support-ticket-card .card-content .section .indicators .messages .count {
  font-size: 0.9em;
  font-weight: 600;
}

.support-ticket-card .card-content .section .status {
  padding: 0.2em 1em;
  border-radius: 1em;
  font-size: 0.9em;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  min-width: 13ch;
  text-align: center;
}

.support-ticket-card .card-content .section .status select {
  position: absolute;
  opacity: 0;
  width: 100%;
  inset: 0;
  cursor: pointer;
}

.support-ticket-card .card-content .section .status.in-progress {
  background: #feeac1;
  color: #523d21;
}

.support-ticket-card .card-content .section .status.postponed {
  background: #d1e1f0;
  color: #253444;
}

.support-ticket-card .card-content .section .status.completed {
  background: #daeada;
  color: #354839;
}

.support-ticket-card .card-content .section .status.pending {
  background: #f1eef2;
  color: #565355;
}

.support-ticket-card .card-content .section .status.ignored {
  background: #f0dada;
  color: #4f2f2f;
}

.support-ticket-card .card-content .section .performance-marketer {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  overflow: hidden;
}

.support-ticket-card .card-content .section .performance-marketer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}