.notification-popup {
  position: absolute;
  top: 1em;
  right: 1em;
  width: 450px;
  padding: 0.5em;
  border-radius: 0.25em;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(10px);
  color: #000;
  box-shadow:
    0 1px 10px 0 var(--transparent-gray),
    0 2px 15px 0 rgba(0, 0, 0, 0.05);
  z-index: 9999;
  cursor: pointer;

  .notification-popup-content {
    display: flex;
    align-items: center;
    gap: 0.5em;

    .icon {
      flex-shrink: 0;
      font-size: 1.25em;
      color: #2e2e2e;
    }

    .texts {
      display: flex;
      flex-direction: column;
      gap: 0.25em;

      .main-text {
        display: flex;
        align-items: center;
        gap: 0.25em;
        font-size: 1em;
        font-weight: 500;
        color: #2e2e2e;
      }

      .sub-text {
        font-size: 0.9em;
        color: #757575;
      }
    }
  }
}

.notification-popup.visible {
  animation: fade-in 0.25s forwards;
}

.notification-popup.hidden {
  animation: fade-out 4s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    display: none;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}
