.predefined-alarms-modal {
  background: var(--main-white);
  border-radius: 16px;
  padding: 24px;
  max-width: 400px;
  margin: 0 auto;
}

.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  font-size: 1.2em;
  font-weight: 600;
  color: #2c3e50;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.predefined-alarm-box {
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.predefined-alarm-box:hover {
  background: #ffffff;
  border-color: #cbd5e1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transform: translateY(-1px);
}

.alarm-title {
  font-size: 1em;
  font-weight: 500;
  color: #334155;
  margin-bottom: 4px;
}

.alarm-due-date {
  font-size: 0.85em;
  color: #64748b;
  margin-top: 4px;
}
