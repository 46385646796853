.alarm-modal.checkup-meeting-modal .modal-middle .property .property-desc {
	width: auto;
}


.alarm-modal.checkup-meeting-modal .modal-middle .property {
	width: 100%;
	display: inline grid;
	grid-template-columns: 0.8fr 1.2fr;
	align-items: center;
}

.alarm-modal.checkup-meeting-modal .modal-footer {
	justify-content: space-between;
}