/* Payment Required - Start */
.payment-required {
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;
  max-width: 700px;
  /* align-items: flex-end; */
  position: relative;
}

.payment-required .subscription-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  justify-content: left;
  color: var(--text-green);
  font-weight: bold;
  font-size: var(--font-24);
  text-align: left;
}
.payment-required .payment-modal-close-button {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: flex-end;
  width: 100%;
  cursor: pointer;
  color: #ccc;
  font-size: 1.2em;
}

.payment-required .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 50%;
  font-size: 0.9em;
  justify-content: center;
  text-align: left;
  color: #5f5f5f;
}

.payment-required .second-subscription-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: var(--text-green);
  font-weight: bold;
  font-size: var(--font-24);
  text-align: left;
  padding-top: 2em;
}
.payment-required .subscription-content {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  justify-content: center;
  color: #5f5f5f;
  font-weight: 600;
  font-size: var(--font-20);
  text-align: center;
  gap: 1em;
  padding-top: 1em;
}
.payment-required .guideline {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  justify-content: left;
  color: #5f5f5f;
  font-weight: 600;
  font-size: var(--font-20);
  text-align: center;
  padding: 8px 0 15px 0;
}

.payment-required .progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 0 auto;
  max-width: 500px;
}

.progress-container .bar-container {
  width: 100%;
  height: 6px;
  top: 14px;
  position: absolute;
  overflow: hidden;
  z-index: 1;
}

.bar-container .green-bar {
  width: 100%;
  height: 100%;
  animation: barFill 4s linear forwards;
  position: absolute;
  z-index: 2;
  background: linear-gradient(
    90deg,
    rgba(70, 182, 134, 1) 0%,
    rgba(139, 196, 93, 1) 100%
  );
}
.bar-container .grey-bar {
  width: 100%;
  height: 100%;
  /* background: #b4b4b4; */
  background: var(--disabled-gray);
  position: absolute;
  z-index: 1;
}

.progress-container .step-circles {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
  z-index: 1;
}

.step-circles .step {
  display: flex;
  align-items: center;

  flex-direction: column;
  gap: 0.5em;
}
.step-circles .step:first-child {
  transform: translateX(-30%);
}
.step-circles .step:nth-child(2) {
  transform: translateX(7%);
}
.step-circles .step:last-child {
  transform: translateX(42%);
}

.step-circles .step .circle {
  width: 30px;
  height: 30px;
  background-color: var(--disabled-gray);
  border-radius: 50%;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-white);
  font-weight: bold;
  box-shadow: #b5b1b1 0px 2px 0px 0px;
}
.step-circles .step .circle-green {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: var(--disabled-gray);
  border-radius: 50%;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-white);
  font-weight: bold;
  box-shadow: #b5b1b1 0px 2px 0px 0px;

  /* animation-duration: 1s; */
}
.step-circles .step .circle-green-1 {
  animation: circleFill 0.3s ease-in-out forwards;
}

.step-circles .step .circle-green-2 {
  animation: circleFill 0.3s ease-in-out forwards 1.2s;
}
.step-circles .step .circle-green-3 {
  animation: circleFill 0.3s ease-in-out forwards 3.9s;
}

.step-circles .step .step-text {
  font-size: var(--font-16);
  color: #5f5f5f;
}

@keyframes barFill {
  0% {
    width: 0;
  }

  30% {
    width: 50%;
  }
  70% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}
@keyframes circleFill {
  0% {
    width: 0;
    height: 0;
    background: linear-gradient(
      90deg,
      rgba(70, 182, 134, 1) 0%,
      rgba(139, 196, 93, 1) 100%
    );
  }
  100% {
    width: 30px;
    height: 30px;
    background: linear-gradient(
      90deg,
      rgba(70, 182, 134, 1) 0%,
      rgba(139, 196, 93, 1) 100%
    );
  }
}

.payment-required .subscription-content span {
  color: var(--text-green);
  font-size: var(--font-32);
}

.modal-container .subscription-footer {
  margin: 1em auto 0;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

.modal-container .subscription-footer .watch-video-button {
  padding: 0.2rem 1rem;
  width: 250px;
  height: 33px;
  background-color: rgb(255, 255, 255);
  border: 1.5px solid var(--text-green);
  color: var(--text-green);
  font-weight: bold;
  border-radius: 0.4em;
}
.modal-container .watch-video-button a:hover {
  text-decoration: underline rgb(255, 255, 255);
}
.modal-container .subscription-footer .action-button-subscribe {
  padding: 0.2rem 1rem;
  width: 250px;
  height: 33px;
  background-color: var(--text-green);
  font-weight: 600;
}

@media (max-width: 470px) {
  .payment-required .subscription-header {
    width: 50%;
    font-size: var(--font-18);
  }
  .payment-required .content {
    width: 100%;
    font-size: 0.7em;
  }
  .payment-required .second-subscription-header {
    font-size: var(--font-18);
    width: 100%;
  }
  .payment-required .subscription-content {
    width: 100%;
    font-size: var(--font-16);
  }
  .payment-required .subscription-content span {
    font-size: var(--font-16);
  }
  .modal-container .subscription-footer .watch-video-button {
    width: 250px;
    height: 40px;
    font-size: 0.6em;
  }
  .modal-container .subscription-footer .action-button-subscribe {
    width: 250px;
    height: 40px;
    font-size: 0.6em;
  }
  .step-circles .step .step-text {
    font-size: 12px;
  }
}
.are-you-sure.payment-required .close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (max-width: 750px) {
  .modal-container .subscription-footer {
    padding-top: 0em;
  }
}
@media (min-width: 470px) and (max-width: 635px) {
  .payment-required .subscription-header {
    width: 50%;
    font-size: var(--font-18);
  }
  .payment-required .content {
    width: 50%;
    font-size: 0.7em;
  }
  .payment-required .second-subscription-header {
    font-size: var(--font-16);
    width: 50%;
  }
  .payment-required .subscription-content {
    width: 60%;
    font-size: var(--font-16);
  }
  .payment-required .subscription-content span {
    font-size: var(--font-16);
  }
  .modal-container .subscription-footer .watch-video-button {
    width: 250px;
    height: 30px;
    font-size: 0.6em;
  }
  .modal-container .subscription-footer .action-button-subscribe {
    width: 250px;
    height: 30px;
    font-size: 0.6em;
  }
}

@media (max-width: 325px) {
  .payment-required .content {
    width: 100%;
    font-size: 0.7em;
  }

  .payment-required .second-subscription-header {
    font-size: var(--font-16);
    width: 100%;
  }
  .payment-required .subscription-content {
    width: 100%;
    font-size: var(--font-14);
  }
  .payment-required .subscription-content span {
    font-size: var(--font-14);
  }
  .modal-container .subscription-footer .watch-video-button {
    width: 250px;
    height: 45px;
    font-size: 0.6em;
  }
  .modal-container .subscription-footer .action-button-subscribe {
    width: 250px;
    height: 45px;
    font-size: 0.6em;
  }
  .modal-container .subscription-footer {
    padding-top: 0em;
  }
  .payment-required .subscription-content {
    font-size: 0.8em;
  }
}

/* Payment Required - End */
