.savedOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-content: center;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  .savedOverlay.fullsize {
    height: 100%;
  }
}

.savedOverlayContent {
  background: #ffffff;
  max-width: 1200px;
  border-radius: 12px;
  margin: 2rem auto;
  width: 90%;
  border: 1px solid var(--border-main);
}

.savedOverlayContentTopPart {
  border-bottom: 1px solid var(--border-main);
  width: 100%;
  padding: 2rem;
}

.savedOverlayContentSmallTitle {
  width: 90%;
  margin: 0 auto;
  letter-spacing: 5.6px;
  font-size: 1rem;
  font-weight: 500;
  background: var(--main-green-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.savedOverlayContentTitle {
  width: 90%;
  margin: 0.5rem auto 0;
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-black);
}

.previewYourProductText {
  width: 90%;
  margin: 1rem auto 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-black);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.previewYourProductIcon {
  color: var(--second-green);
  font-size: 1.2rem;
}

.lastStepOverlay,
.oneProductOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 2rem;
}

.previewContainer {
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--border-main);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.checkIconContainer {
  position: absolute;
  top: -12px;
  right: -12px;
  background: var(--second-green);
  border-radius: 50%;
  padding: 0.5rem;
  z-index: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.checkIcon {
  color: #ffffff;
  font-size: 1.5rem;
}

.savedOverlayContentBottomPart {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.overlayButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  background: var(--main-green-gradient);
  color: #ffffff;
  min-width: 200px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: var(--main-green-gradient-hover);
    transform: translateY(-2px);
  }
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1rem;
  padding: 1.5rem;
  width: 100%;
  max-height: 55vh;
  overflow-y: auto;
  margin: 0 auto;
}

.productItem {
  position: relative;
  justify-self: center;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--border-main);
  transition: all 0.2s ease;
  width: fit-content;
  min-width: 200px;
  min-height: 200px;
  height: auto;

  &:hover {
    transform: translateY(-2px);
    border-color: var(--second-green);

    .editButton {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

.editButton {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background: rgba(0, 0, 0, 0.9);
  }
}

.actionButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1.5rem;
}

.progressBar {
  width: 100%;
  height: 4px;
  background: #f1f3f5;
  border-radius: 2px;
  overflow: hidden;
  visibility: hidden;

  &.progressBarVisible {
    visibility: visible;
  }
}

.progressBarFill {
  height: 100%;
  background: var(--main-green-gradient);
  border-radius: 2px;
  transition: width 0.3s ease;
}

@media (max-width: 768px) {
  .savedOverlayContent {
    width: 95%;
    margin: 1rem auto;
  }

  .savedOverlayContentTopPart {
    padding: 1.5rem;
  }

  .savedOverlayContentTitle {
    font-size: 1.5rem;
  }

  .previewYourProductText {
    font-size: 1.2rem;
  }

  .overlayButtons {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
    min-width: 160px;
  }

  .productGrid {
    padding: 1rem;
    gap: 0.75rem;
  }
}
