.demo-meetings-container {
  height: 100%;
}

.demo-meetings-container .layout {
  height: 100%;
  display: flex;
  gap: 1em;
}

.demo-meetings-container .layout .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
}

.demo-meetings-container .layout .content .heading {
  margin: 0;
  padding: 0 0;
  flex: 1;
}

.demo-meetings-container .layout .content .top {
  margin: 0;
  padding: 0 16px;
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.demo-meetings-container .layout .content .top .create-new-button {
  text-wrap: nowrap;
  flex: 0 0 auto;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: rgb(96, 96, 96);
  border: solid 1.5px var(--main-green);
  gap: 0.2em;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;
}

.demo-meetings-container .layout .content .top .create-new-button:hover {
  background: var(--main-green);
  color: rgb(255, 255, 255);
}

.demo-meetings-container .layout .content .heading::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--main-green);
  margin-bottom: 16px;
}

.demo-meetings-container .layout .content .not-found-text {
  margin: 0;
  color: #c4c4c4;
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 8em;
  font-size: 1.5em;
  font-weight: 600;
}
