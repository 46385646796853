.modal-container.CAMPAIGN_CREATION {
  background: var(--main-white);
  box-shadow: 0px 2.172px 32.522px 0px rgba(0, 0, 0, 0.25);
  width: 90%;
  max-width: 900px;
  height: 55vh;
  overflow: hidden;
}

.campaign-creation {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em 1.5em !important;
  position: relative;
}

.campaign-creation h3,
.campaign-creation p {
  margin: 0;
}

.campaign-creation .close-modal {
  display: flex;
  width: 100%;
  height: 28px;
  position: relative;
  z-index: 6;
}

.campaign-creation .close-modal .cross {
  position: absolute;
  right: 0;
  width: 28px;
  height: 28px;
  opacity: 0.5;
  transition: all 150ms;
  cursor: pointer;
}

.campaign-creation .close-modal .cross:hover {
  opacity: 1;
}

.campaign-creation .close-modal .cross:before,
.close-modal .cross:after {
  position: absolute;
  left: 14px;
  content: ' ';
  height: 29px;
  width: 1px;
  background-color: #000;
}

.campaign-creation .close-modal .cross:before {
  transform: rotate(45deg);
}

.campaign-creation .close-modal .cross:after {
  transform: rotate(-45deg);
}

.campaign-creation .background {
  display: flex;
  justify-content: flex-end;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: absolute;
}

.campaign-creation .background .circles-container {
  position: relative;
}

.campaign-creation .background .circles-container .circle {
  width: 400px;
  height: 400px;
  border: 4px solid var(--second-green);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -130px;
  transform: translateY(-52%);
}

.campaign-creation .background .circles-container .circle.dashed {
  width: 430px;
  height: 430px;
  border: 1px dashed var(--second-green);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -145px;
  transform: translateY(-52%);
}

.campaign-creation .icons {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 6;
  display: flex;
}

.campaign-creation .icons .icon.instagram {
  position: relative;
  right: -13px;
  bottom: -15px;
}

.campaign-creation .icons .icon.instagram img {
  position: relative;
  z-index: 7;
}

.campaign-creation .icons .icon.instagram::before {
  content: '';
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--second-green);
  position: absolute;
  top: 0px;
  left: 3px;
  z-index: 1;
}

.campaign-creation .icons .icon.meta {
  position: relative;
  right: 3px;
  bottom: 0px;
}

.campaign-creation .icons .icon.meta img {
  position: relative;
  z-index: 9;
}

.campaign-creation .icons .icon.meta::before {
  content: '';
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: var(--second-green);
  position: absolute;
  top: 1px;
  left: 4px;
  z-index: 8;
}

.campaign-creation .content {
  display: flex;
  flex: 1;
  gap: 2.5em;
}

.campaign-creation .content .information-container,
.campaign-creation .content .preview-container {
  flex: 1;
}

.campaign-creation .content .preview-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.campaign-creation .content .preview-container .ad-creative-container {
  position: relative;
  padding: 348px 250px;
  margin-right: -250px;
  margin-bottom: -375px;
  border-radius: 4px;
  overflow: hidden;
  transform: scale(0.57) translate(-15%, -50%);
  transform: scale(0.55) translate(-15%, -55%);
  box-shadow: 0px 1.192px 11.024px 0px rgba(0, 0, 0, 0.25);
  background: rgb(223, 223, 223);
  background: linear-gradient(130deg, #f1f1f1, #cdcdcd);
  background-size: 200% 200%;
  -webkit-animation: gradientAnimation 2s ease-in-out infinite;
  -moz-animation: gradientAnimation 2s ease-in-out infinite;
  animation: gradientAnimation 2s ease-in-out infinite;
  z-index: 5;
}

.campaign-creation .content .preview-container .ad-creative-container iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.campaign-creation .content .information-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  z-index: 9;
}

.campaign-creation .content .information-container .bottom {
  flex: 1;
}

.campaign-creation .content .information-container .top {
  display: flex;
  flex-direction: column;
  gap: 1.25em;
}

.campaign-creation .content .information-container .top .title {
  font-size: 2em;
  line-height: 1.2;
  font-weight: 700;
  text-align: left;
}

.campaign-creation .content .information-container .top .title .gradient-text {
  background: linear-gradient(
    263deg,
    var(--second-green) 44.19%,
    var(--primary-green) 96.46%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.campaign-creation .content .information-container .top .description {
  color: #575757;
  line-height: 1.2;
}

.campaign-creation .content .information-container .bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.campaign-creation .content .information-container .bottom .campaign-details {
  border: 1px solid var(--second-green);
  border-radius: 6px;
  padding: 1em 0.75em;
}

.campaign-creation
  .content
  .information-container
  .bottom
  .campaign-details:hover {
  background: #46b68010;
}

.campaign-creation
  .content
  .information-container
  .bottom
  .campaign-details
  ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #575757;
  font-size: 1.1em;
}

.campaign-creation .content .information-container .bottom .button-container {
  display: flex;
  justify-content: space-between;
}

.campaign-creation
  .content
  .information-container
  .bottom
  .button-container
  .continue-btn {
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(126, 191, 89, 0.35);
  transition: all 200ms;
  font-weight: 500;
  border: 1px solid transparent;
  color: var(--main-white);
  background: var(--second-green);
}

.campaign-creation
  .content
  .information-container
  .bottom
  .button-container
  .continue-btn:hover {
  box-shadow: 0px 2px 11px 0px rgba(126, 191, 89, 0.6);
  transform: translateY(-1px);
}

.campaign-creation
  .content
  .information-container
  .bottom
  .button-container
  .continue-btn:active {
  transform: translateY(0);
}

@media (max-width: 1200px) {
  .campaign-creation .content .preview-container,
  .campaign-creation .background,
  .campaign-creation .icons {
    display: none;
  }
}

@media (max-width: 800px) {
  .modal-container.CAMPAIGN_CREATION {
    min-width: 450px;
  }
}
