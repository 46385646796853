.segment-item-row {
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.3em;
  gap: 5px;
  transition: 0.3s;
  /* min-width: 400px; */
}

.segment-item-row .segment-triangle {
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none !important;
  /* width: 12px;
  height: 15px;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  border-radius: 5px; */
}

.segment-item-row.detail-open .segment-triangle,
.segment-item-row:hover .segment-triangle {
  display: flex;
}

.segment-item-row:hover:not(.detail-open) .segment-triangle-shape {
  display: flex;
  fill: rgb(207, 207, 207);
}

.segment-item-row.detail-open .segment-triangle-shape {
  fill: var(--second-green);
}

.segment-item-row .segment-triangle svg g {
  width: 100%;
  height: 100%;
}

.segmentation.old .segment-list .segment-item-row {
  margin: 0 auto;
  width: 90%;
  max-width: 500px;
}

.segment-list .segment-item {
  border-radius: 0.5em;
  padding: 3px 2em;
  font-size: 0.9em;
  box-shadow: var(--card-shadow);
  background: var(--main-white);
  cursor: pointer;
  position: relative;
  flex: 0 1 90%;
}

.segment-list .segment-item * {
  transition: transform 0.3s;
}

.segment-list .segment-item:hover {
  transform: scale(1.01);
}

.segment-list .segment-item.selected {
  color: var(--main-white);
}

.segment-list .segment-item .header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.segment-list .segment-item .header-row .left-side {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2em;
}

.segment-list .segment-item .header-row .segment-name {
  font-weight: 500;
  flex: 1 1 100px;
  white-space: nowrap;
}

.segment-list .segment-item .header-row .button-cont {
  flex: 1 1 100px;
}

.segment-list .segment-item .segment-details-content {
  font-size: 0.8em;
  display: flex;
  align-items: flex-end;
  justify-items: end;
  gap: 3em;

  max-width: 300px;
}

.segment-list .segment-item .numbers {
  display: flex;
  align-items: baseline;

  gap: 0.5em;
}

.segment-list .segment-item .numbers .number {
  font-size: 1.2em;
  font-weight: 500;
}

.segment-list .segment-item button {
  background: rgb(0 0 0 / 58%);
  border: none;
  color: var(--main-white);
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.5em;
  border-radius: 6px;
  height: 2em;
  opacity: 1;
  cursor: pointer;
}

.segment-list .segment-item.selected button {
  background: rgba(0, 0, 0, 0.3);
}

.segment-list .segment-item button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.segment-list .segment-item.selected button:hover {
  background: rgba(0, 0, 0, 0.4);
}

@media (max-width: 1400px) {
  .segment-item-row {
    margin-bottom: 0.7em;
    font-size: 0.9em;
  }
}
