.custom-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.table-reset-filters {
  background: #e2e1e1;
  border: 1px solid #ddd;
  border-radius: 0.5em;
  margin: 10px 0px 5px 6px;
  font-size: 0.8em;
  padding: 2px;
  max-width: 80px;
}
.table-reset-filters:hover {
  background: #ddd;
  cursor: pointer;
}

.custom-table::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  background-color: #e5e3e3;
}
.custom-table::-webkit-scrollbar-thumb {
  background-color: #aba9a992;
  border-radius: 0.5em;
}
.custom-table th,
.custom-table td {
  text-align: left;
  padding: 1px;
  border: 1px solid #ddd;
}
.custom-table td {
  border: 1px solid #bbbbbb;
}

.custom-table-filter {
  border: none;
  padding: 3px;
  margin: 2px;
  text-align: center;
  background-color: #388f65a2;
  color: var(--main-white);
  border-radius: 5px;
  max-width: 140px;
  height: 18px;
}
.custom-table-filter:focus {
  border: 1px solid var(--main-white);
  outline: none;
  padding: 3px;
  margin: 3px;
  text-align: center;
  background-color: #388f65a2;
}

.custom-table tr:hover {
  background-color: #d3cfcf;
}

.custom-table thead th select {
  border: none;
  background-color: #388f65a2;
  border-radius: 5px;
  max-width: 140px;
  color: rgba(255, 255, 255, 0.766);
  outline: none;
}
.custom-table thead th select:focus {
  background-color: #ffffff75;
  color: rgba(57, 57, 57, 0.766);
}
.custom-table thead th input {
  border: none;
  background-color: #388f65a2;
  border-radius: 5px;
  max-width: 140px;
  color: rgba(255, 255, 255, 0.766);
  outline: none;
  height: 18px;
}
.custom-table thead th input::placeholder {
  color: rgba(255, 255, 255, 0.577);
}

.custom-table thead th input:focus {
  background-color: #23533c82;
  color: var(--main-white);
}

.custom-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: var(--second-green);
  color: var(--main-white);
  font-weight: 500;
  font-size: 0.9em;
}

.custom-table-pagination {
  display: flex;
  width: 95%;
  max-width: 900px;
  margin: 1em auto;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.7em;
  font-size: 0.8em;
}
