.cancel-subscription-modal {
  padding: 2em !important;
  gap: 1em;
  border-radius: 0.5em;
  position: relative;
  height: 100%;
  text-align: center;

  h2 {
    font-size: 1.8em;
  }
  .small-text {
    font-size: 0.8em;
    color: var(--text-gray);
    margin-top: -2%;
  }

  .close-button {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
    color: var(--text-gray);
    font-size: 1.4em;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 2em;
    flex-wrap: nowrap;
    margin: 1em 0;
  }
  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    font-size: 16px;
    margin-top: 1em;
  }
  .input-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    width: 100%;
    padding: 1em;
    border-radius: 0.5em;
    box-shadow: 0px 0px 10px 0px #0000001a;
  }
  .more-details {
    width: 100%;
    border-color: var(--main-white);
  }

  label {
    cursor: pointer;
  }
  input {
    cursor: pointer;
  }

  .button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
    width: 100%;
  }
  button {
    background-color: var(--second-green);
    padding: 0.8em 1.5em;
  }
  button:hover {
    background-color: var(--second-green);
    transform: translateY(-2px);
    box-shadow: 0px 0px 10px 0px #0000001a;
  }
}
