.savedOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-content: center;
  z-index: 3;
  background: #414141a7;
  backdrop-filter: blur(4px);
}

.savedOverlay.fullsize {
  height: 100%;
}

.savedOverlayContent {
  background: white;
  max-width: 1200px;
  border-radius: 1em;
  margin: 0 auto;
  width: 90%;
}

.savedOverlayContentTopPart {
  border-bottom: 1px solid #cacaca;
  width: 100%;
  padding: 2em 1em;
}

.savedOverlayContentSmallTitle {
  width: 90%;
  margin: 0 auto;
  letter-spacing: 5.6px;
  font-size: 1em;
  font-weight: 500;
  background: linear-gradient(
    263deg,
    var(--second-green) 44.19%,
    var(--primary-green) 96.46%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.savedOverlayContentTitle {
  width: 90%;
  margin: 0.5em auto 0;
  font-size: 2.125em;
  font-weight: 700;
  line-height: normal;
  color: var(--text-black);
}

.previewYourProductText {
  width: 90%;
  margin: 1em auto 2em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  color: var(--text-black);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.previewYourProductIcon {
  color: var(--main-green);
  font-size: 1.2em;
}

.lastStepOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.oneProductOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.previewContainer {
  position: relative;
  width: 400px;
  height: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.checkIconContainer {
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: rgba(0, 200, 0, 0.9);
  border-radius: 50%;
  padding: 8px;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.checkIcon {
  color: white;
  font-size: 35px;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
}

.previewContainer img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.savedOverlayContentBottomPart {
  display: flex;
  flex-direction: row;
  padding: 2em;
  align-items: center;
}

.overlayButtons {
  margin: 0.5em;
  font-size: 1.3em;
  display: flex;
  gap: 0.4em;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0.5em 1.2em;
  background: var(--main-green-gradient);
  color: white;
  width: 12em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.overlayButtons:hover {
  background: var(--main-green-gradient-hover);
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.continueEditingButton {
  margin: 1em auto 2em;
  display: block;
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 20vw));
  justify-content: center;
  gap: 10px;
  padding: 20px;
  width: 100%;
  max-height: 55vh;
  overflow-y: auto;
  margin: 0 auto;
}

.productItem {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: var(--card-shadow);
  border: 1px solid #e3e3e3;
}

.productItem:hover {
  transform: scale(1.03);
}

.productItem:hover .editButton {
  opacity: 1;
}

.productItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.editButton {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.editIcon {
  margin-left: 5px;
}

.noPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  font-weight: 500;
  color: var(--light-gray);
}

@media (min-width: 1400px) {
  .productGrid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
  }
}
