.collapsible-card {
  border-radius: 16px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
}

.collapsible-card .collapsible-card-header {
  border-radius: 16px;
  height: 2.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 1.75em;
}

.collapsible-card.open .collapsible-card-header {
  border-radius: 16px 16px 0 0;
}

.collapsible-card .save-button-container {
  margin-top: 1em;
}

.collapsible-card .save-button-container .save-button {
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 0.9em;
  padding: 0.5em 1.5em;
  border-radius: 0.5em;
  cursor: pointer;
  color: var(--main-white);
  transition: all 200ms;
}

.collapsible-card .save-button-container .save-button:hover {
  transform: translateY(-1px);
}

.collapsible-card .save-button-container .save-button:active {
  transform: translateY(0);
}

.collapsible-card .save-button-container .save-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.collapsible-card.emerald .collapsible-card-header,
.collapsible-card.emerald .save-button-container .save-button {
  background: linear-gradient(92deg, #237d6d 0%, #22b69b 100%);
}

.collapsible-card.sapphire .collapsible-card-header,
.collapsible-card.sapphire .save-button-container .save-button {
  background: linear-gradient(270deg, #1f82a5 0%, #1b6078 100%);
}

.collapsible-card.topaz .collapsible-card-header,
.collapsible-card.topaz .save-button-container .save-button {
  background: linear-gradient(270deg, #c59a1f 0%, #b48a13 100%);
}

.collapsible-card.turquoise .collapsible-card-header,
.collapsible-card.turquoise .save-button-container .save-button {
  background: linear-gradient(90deg, #227b85 0%, #31a1ae 100%);
}

.collapsible-card.ruby .collapsible-card-header,
.collapsible-card.ruby .save-button-container .save-button {
  background: linear-gradient(270deg, #882923 0%, #af4039 100%);
}

.collapsible-card.amethyst .collapsible-card-header,
.collapsible-card.amethyst .save-button-container .save-button {
  background: linear-gradient(90deg, #851597 0%, #5c0b69 100%);
}

.collapsible-card.sky .collapsible-card-header,
.collapsible-card.sky .save-button-container .save-button {
  background: linear-gradient(45deg, #2375c7 0%, #529eea 100%);
}

.collapsible-card .collapsible-card-header h1 {
  color: var(--main-white);
  font-size: 1em;
  font-style: normal;
  font-weight: 600;
  line-height: 0;
}

.collapsible-card .collapsible-card-header .toggle-button {
  display: flex;
  background-color: transparent;
  border: none;
  color: var(--main-white);
  font-size: 1.5em;
  cursor: pointer;
  padding: 0;
}

.collapsible-card .collapsible-card-content {
  display: flex;
  flex-direction: column;
  padding: 1.25em 1.75em;
}
@media (max-width: 615px) {
  .collapsible-card .collapsible-card-content {
    padding: 1.5em 1em 1.5em 1em;
  }
}
