.crc-container {
  padding: 0em 0em 1em 0em;
}

.crc-title {
  font-size: 1.5em;
  color: #1a1a1a;
  font-weight: 500;
  margin: 0 0 1em 0;
}

.crc-report-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  gap: 1em;
}

.crc-report-grid.two-rows {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.crc-report-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 20em));
  gap: 1em;
}

.crc-report-row.main-metrics {
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1200px) {
  .crc-report-row.main-metrics,
  .crc-report-row.secondary-metrics {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(15em, 20em));
  }
}

.crc-comparison-card {
  background: white;
  border-radius: 0.75em;
  padding: 1.25em 2.25em 1.25em 2.25em;
  border: 1px solid #dfeaf2;
  transition: all 0.3s ease;
}

.crc-comparison-card:hover {
  border: 1px solid #a2c4dd;
}

.crc-comparison-card h3 {
  margin: 0 0 1em 0;
  font-size: 1.2em;
  color: #1a1a1a;
  font-weight: 500;
}

.crc-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.crc-header-label {
  font-size: 1.1em;
  color: #666;
  font-weight: 500;
}

.crc-header-label.after {
  color: var(--card-color);
}

.crc-comparison-content {
  margin-bottom: 1em;
}

.crc-values-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.crc-value-row {
  margin-bottom: 0.8em;
}

.crc-value-group {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  width: 100%;
}

.crc-value {
  font-size: 1em;
  font-weight: 500;
  display: inline-flex;
  align-items: baseline;
  position: relative;
  cursor: help;
}

.crc-value.after {
  color: var(--card-color);
}

.crc-value.before {
  color: #666;
}

.crc-metric-label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #94959b;
  font-size: 0.9em;
}

.crc-currency {
  font-size: 0.9em;
  opacity: 0.8;
  margin-right: 0.1em;
}

.crc-chart-container {
  height: 80px;
  margin-top: 1em;
}

@media (max-width: 1200px) {
  .crc-report-grid {
    grid-template-columns: repeat(auto-fit, minmax(16em, 1fr));
  }
}

.crc-alternative-view {
  background: white;
  border-radius: 0.75em;
  padding: 1.25em;
  border: 1px solid #dfeaf2;
  transition: all 0.3s ease;
}

.crc-alternative-view:hover {
  border: 1px solid #a2c4dd;
}

.crc-alternative-header {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 24px;
}

.crc-metric-selector {
  margin-bottom: 0;
}

.crc-metric-dropdown {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background: transparent;
  width: 250px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.crc-metric-dropdown:hover {
  background: #f5f5f5;
  color: #333;
}

.crc-metric-dropdown:focus {
  outline: none;
  border-color: #e5e5e5;
  background: #f5f5f5;
  color: #333;
}

.crc-single-chart {
  position: relative;
  height: 400px;
}

.crc-alternative-chart {
  height: 100%;
  width: 100%;
}

.crc-back-button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  background: transparent;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 250px;
  justify-content: center;
}

.crc-back-button:hover {
  background: #f5f5f5;
  color: #333;
}

.crc-no-data-message {
  background: linear-gradient(
    to bottom,
    rgba(var(--main-green-rgb), 0.02),
    #ffffff
  );
  border-radius: 16px;
  padding: 48px;
  text-align: center;
  border: 1px solid rgba(var(--main-green-rgb), 0.1);
  box-shadow: 0 4px 24px rgba(var(--main-green-rgb), 0.05);
}

.crc-no-data-content {
  max-width: 600px;
  margin: 0 auto;
}

.crc-no-data-icon {
  color: var(--main-green);
  margin-bottom: 24px;
  opacity: 0.8;
}

.crc-no-data-message h2 {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 16px 0;
}

.crc-no-data-message p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  margin: 0 0 24px 0;
}

.crc-no-data-features {
  list-style: none;
  padding: 0;
  margin: 0 0 32px 0;
  display: grid;
  gap: 12px;
}

.crc-no-data-features li {
  font-size: 15px;
  color: #1a1a1a;
  padding: 12px 16px;
  background: rgba(var(--main-green-rgb), 0.04);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.crc-no-data-features li:hover {
  background: rgba(var(--main-green-rgb), 0.08);
  transform: translateY(-1px);
}

.crc-create-campaign-button {
  background: var(--main-green);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(var(--main-green-rgb), 0.2);
}

.crc-create-campaign-button:hover {
  background: var(--main-green-dark, var(--main-green));
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(var(--main-green-rgb), 0.3);
}

.crc-create-campaign-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(var(--main-green-rgb), 0.2);
}

.crc-header-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.crc-title-group {
  display: flex;
  align-items: center;
  gap: 12px;
}

.crc-title {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
}

.crc-comparison-dropdown {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 16px;
  padding-right: 24px;
}

.crc-comparison-dropdown:hover {
  color: var(--main-green);
}

.crc-comparison-dropdown:focus {
  color: var(--main-green);
  outline: none;
}

.crc-comparison-card.simple {
  cursor: pointer;
}

.crc-comparison-card.simple:hover {
  border: 1px solid #a2c4dd;
}

.crc-comparison-card.simple .crc-chart-container {
  display: none;
}

.crc-comparison-card.simple .crc-comparison-content {
  margin-bottom: 0;
}

.crc-comparison-card.simple .crc-value-row:last-child {
  margin-bottom: 0;
}

.crc-dual-line-chart {
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 16px;
}

.crc-dual-line-chart canvas {
  width: 100% !important;
  height: 100% !important;
}

.crc-toggle-button {
  background: none;
  border: none;
  color: var(--main-green);
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 auto;
  transition: all 0.2s ease;
}

.crc-toggle-button:hover {
  color: var(--main-green-dark, var(--main-green));
}

.crc-toggle-button svg {
  width: 16px;
  height: 16px;
  transition: transform 0.2s ease;
}

.crc-toggle-button.expanded svg {
  transform: rotate(180deg);
}

.crc-report-row.secondary-metrics {
  justify-content: center;
  grid-template-columns: repeat(2, 20em);
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.crc-report-row.secondary-metrics.expanded {
  max-height: 500px;
  opacity: 1;
  margin-top: 1em;
}

.crc-dual-charts-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5em;
  margin-top: 1em;
}

.crc-chart-wrapper.full-width {
  grid-column: 1 / -1;
}

.crc-chart-title {
  font-size: 1.2em;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 1em;
}

.crc-chart-wrapper {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1200px) {
  .crc-dual-charts-container {
    grid-template-columns: 1fr;
  }

  .crc-chart-wrapper.full-width {
    grid-column: auto;
  }
}

.crc-alternative-view.no-border {
  border: none;
  background: transparent;
  padding: 0;
}

.crc-chart-wrapper.chart-with-border {
  background: white;
  border-radius: 0.75em;
  padding: 1.25em;
  border: 1px solid #dfeaf2;
  transition: all 0.3s ease;
}

.crc-chart-wrapper.chart-with-border:hover {
  border: 1px solid #a2c4dd;
}

.crc-info-box {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  background: rgba(var(--main-green-rgb), 0.05);
  border: 1px solid rgba(var(--main-green-rgb), 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 24px;
}

.crc-info-icon {
  color: var(--main-green);
  font-size: 16px;
  line-height: 1.5;
}

.crc-info-text {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

.crc-info-highlight {
  color: #1a1a1a;
  font-weight: 500;
}

.crc-header-controls {
  margin-bottom: 16px;
}

.crc-title-group {
  display: flex;
  align-items: center;
  gap: 12px;
}

.crc-comparison-dropdown {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 16px;
  padding-right: 24px;
}

.crc-comparison-dropdown:hover {
  color: var(--main-green);
}

.crc-comparison-dropdown:focus {
  color: var(--main-green);
  outline: none;
}

.crc-chart-header {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 1em;
}

.crc-chart-header:has(.crc-chart-stats) {
  justify-content: space-between;
}

.crc-chart-stats {
  display: flex;
  align-items: center;
  gap: 16px;
}

.crc-stat-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.crc-stat-label {
  font-size: 12px;
  color: #666;
  font-weight: 500;
}

.crc-stat-value {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-green);
}

.crc-mini-info {
  position: relative;
  display: flex;
  align-items: center;
}

.crc-mini-info-icon {
  color: var(--main-green);
  font-size: 14px;
  cursor: help;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.crc-mini-info:hover .crc-mini-info-icon {
  opacity: 1;
}

.crc-mini-info-tooltip {
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid rgba(var(--main-green-rgb), 0.2);
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 12px;
  color: #666;
  width: 240px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 10;
}

.crc-mini-info-tooltip::before {
  content: '';
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 8px;
  height: 8px;
  background: white;
  border-left: 1px solid rgba(var(--main-green-rgb), 0.2);
  border-top: 1px solid rgba(var(--main-green-rgb), 0.2);
}

.crc-mini-info:hover .crc-mini-info-tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.crc-chart-title {
  font-size: 1.2em;
  font-weight: 500;
  color: #1a1a1a;
  margin: 0;
}

.crc-trend-metrics {
  display: flex;
  justify-content: center;
  gap: 48px;
  margin-bottom: 24px;
  padding: 16px;
  background: rgba(var(--main-green-rgb), 0.05);
  border-radius: 8px;
}

.crc-trend-metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.crc-trend-label {
  font-size: 14px;
  color: #666;
}

.crc-trend-value {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
}

.crc-trend-metric:first-child .crc-trend-value {
  color: #94959b;
}

.crc-trend-metric:last-child .crc-trend-value {
  color: var(--main-green);
}

.crc-trend-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.crc-trend-title {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
}

.crc-trend-range-dropdown {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
  border: 1px solid #dfeaf2;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 150px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.crc-trend-range-dropdown:hover {
  border-color: var(--main-green);
}

.crc-trend-range-dropdown:focus {
  outline: none;
  border-color: var(--main-green);
  box-shadow: 0 0 0 2px rgba(var(--main-green-rgb), 0.1);
}

.crc-trend-controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;

  margin-top: -3em;
}

.crc-trend-selectors {
  display: flex;
  gap: 12px;
}

.crc-trend-metric-dropdown {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
  border: 1px solid #dfeaf2;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 150px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.crc-trend-metric-dropdown:hover,
.crc-trend-range-dropdown:hover {
  border-color: var(--main-green);
}

.crc-trend-metric-dropdown:focus,
.crc-trend-range-dropdown:focus {
  outline: none;
  border-color: var(--main-green);
  box-shadow: 0 0 0 2px rgba(var(--main-green-rgb), 0.1);
}

.crc-trend-change {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
}

.crc-trend-change-value {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 12px;
  border-radius: 16px;
  background-color: var(--gray-100);
}

.crc-trend-change-value.positive {
  color: var(--main-green);
  background-color: rgba(var(--main-green-rgb), 0.1);
}

.crc-trend-change-value.negative {
  color: var(--error-red);
  background-color: rgba(var(--error-red-rgb), 0.1);
}

.crc-trend-label i {
  margin-right: 8px;
}

.crc-trend-change-value i {
  margin-right: 4px;
}

.crc-mini-info-icon i {
  color: var(--main-green);
  font-size: 16px;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.crc-mini-info:hover .crc-mini-info-icon i {
  opacity: 1;
}

.crc-trend-change-value.positive i {
  color: var(--main-green);
}

.crc-trend-change-value.negative i {
  color: var(--error-red);
}

.crc-period-bar {
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  margin-bottom: 0;
  padding: 1em 1em 0 1em;
  border-radius: 8px 8px 0 0;
  gap: 2px;
  background: white;
}

.crc-period-segment {
  flex: 1;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 1em;
  border-radius: 6px;
}

.crc-period-segment-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  font-size: 11px;
  font-weight: 500;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.crc-period-segment.previous::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #f8b72426 20%, transparent);
}

.crc-period-segment.recent::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(133, 202, 110, 0.15) 20%,
    transparent
  );
}

.crc-period-days {
  font-size: 10px;
  opacity: 0.8;
  font-weight: 400;
  text-transform: none;
  letter-spacing: normal;
  margin-top: 4px;
}

.crc-period-average {
  font-size: 18px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: normal;
  margin-top: 8px;
}

.crc-period-segment.previous .crc-period-average {
  color: #f8b724;
}

.crc-period-segment.recent .crc-period-average {
  color: var(--main-green);
}

.crc-period-change-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: white;
  padding: 6px 12px;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(var(--main-green-rgb), 0.2);
  display: flex;
  align-items: center;
  gap: 4px;
}

.crc-period-change-overlay.positive {
  color: var(--main-green);
  background-color: white;
  border-color: rgba(var(--main-green-rgb), 0.2);
}

.crc-period-change-overlay.negative {
  color: var(--error-red);
  background-color: white;
  border-color: rgba(var(--error-red-rgb), 0.2);
}

.crc-period-change-overlay i {
  font-size: 12px;
}

.crc-period-change-value {
  font-size: 18px;
  font-weight: 600;
}

.crc-comparison-select {
  position: relative;
  display: inline-block;
}

.crc-comparison-button {
  font-size: 18px;
  font-weight: 600;
  color: #33383d;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 24px;
  position: relative;
}

.crc-comparison-button:hover {
  color: var(--main-green);
}

.crc-comparison-button:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: transform 0.2s ease;
}

.crc-comparison-button.open:after {
  transform: translateY(-50%) rotate(180deg);
}

.crc-comparison-options {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 8px;
  min-width: 320px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-8px);
  transition: all 0.2s ease;
}

.crc-comparison-options.open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.crc-comparison-option {
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  overflow: hidden;
}

.crc-comparison-option:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: var(--main-green);
  opacity: 0;
  transition: all 0.2s ease;
}

.crc-comparison-option:hover {
  background: rgba(var(--main-green-rgb), 0.04);
}

.crc-comparison-option:hover:before {
  opacity: 1;
}

.crc-comparison-option.selected {
  background: rgba(var(--main-green-rgb), 0.08);
}

.crc-comparison-option.selected:before {
  opacity: 1;
}

.crc-option-title {
  font-size: 16px;
  font-weight: 500;
  color: #1a1a1a;
  transition: all 0.2s ease;
}

.crc-comparison-option:hover .crc-option-title {
  color: var(--main-green);
  transform: translateX(4px);
}

.crc-option-description {
  font-size: 13px;
  color: #666;
  line-height: 1.4;
  transition: all 0.2s ease;
}

.crc-comparison-option:hover .crc-option-description {
  transform: translateX(4px);
}

.crc-single-chart {
  background: white;
  border-radius: 0 0 8px 8px;
  padding: 1em;
}

.crc-value-tooltip {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid rgba(var(--main-green-rgb), 0.2);
  border-radius: 6px;
  padding: 6px 10px;
  font-size: 12px;
  color: #1a1a1a;
  white-space: nowrap;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 10;
}

.crc-value-tooltip::before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 8px;
  height: 8px;
  background: white;
  border-right: 1px solid rgba(var(--main-green-rgb), 0.2);
  border-bottom: 1px solid rgba(var(--main-green-rgb), 0.2);
}

.crc-value:hover .crc-value-tooltip {
  opacity: 1;
  visibility: visible;
}

.crc-trend-selector-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.crc-trend-selector-label {
  font-size: 12px;
  color: #666;
  font-weight: 500;
  padding-left: 4px;
}

.crc-trend-selectors {
  display: flex;
  gap: 12px;
}

.results.three-columns {
  display: grid;
  grid-template-areas:
    'before before before'
    'trend ai-ads share';
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  gap: 0.7em;
  margin-top: 0em;
  width: 100%;
}
.results.three-columns .column {
  display: flex;
  flex-direction: column;
  padding: 1em 0.5em;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #f9f9f9;
  border: 1px solid rgba(var(--main-green-rgb), 0.1);
  height: 100%;
}
/* Grid area atamaları */
.results.three-columns .column.before-after {
  grid-area: before;
}

.results.three-columns .column:not(.before-after):nth-of-type(2) {
  grid-area: trend;
  align-items: center;
}

.results.three-columns .column:not(.before-after):nth-of-type(3) {
  grid-area: ai-ads;
  align-items: center;
}

.results.three-columns .column:not(.before-after):nth-of-type(4) {
  grid-area: share;
  align-items: center;
}
.results.three-columns .column.before-after {
  grid-area: before;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em 0.5em;
  gap: 0em;
}

.results.three-columns .column.before-after h4 {
  width: 100%;
}

.results.three-columns .column.before-after .items-wrapper {
  display: flex;
  gap: 1em;
  justify-content: space-around;
  width: 100%;
  margin-top: 0.8em;
}

.results.three-columns .column:hover {
  border-color: rgba(var(--main-green-rgb), 0.3);
  box-shadow: 0 2px 8px rgba(var(--main-green-rgb), 0.1);
  transform: translateY(-1px);
}

.results.three-columns .column h4 {
  font-size: 12px;
  color: #666;
  margin: 0 0 0em 0;
  font-weight: 600;
  align-items: flex-start;
  text-align: center;
}
.results.three-columns .column .roas-text {
  font-size: 11px;
  color: #666;

  font-weight: 300;
  align-items: flex-start;
  text-align: center;
  margin-top: -1.4em;
}

.results.three-columns .column .item {
  display: flex;
  flex-direction: column;
  gap: 0.1em;

  align-items: center;
}

.results.three-columns .column .item .value {
  font-size: 18px;
  font-weight: 400;
}

.results.three-columns .column .item .value .meta,
.results.three-columns .column .item .trend-value {
  font-size: 18px;
  font-weight: 500;
}

.results.three-columns .column .item .value .currency {
  font-size: 0.85em;
  margin-right: 0.1em;
  opacity: 0.8;
}

.results.three-columns .column .item .description {
  font-size: 0.85em;
  color: #666;
}

.results.three-columns .column .item .trend-value.better {
  color: var(--main-green);
}

.results.three-columns .column .item .trend-value.worse {
  color: #f73c3c;
}

.results.three-columns .column .item .trend-value.others {
  color: #94959b;
  font-weight: 500;
}

.results.three-columns .column .item .vertical-comparison {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.2em;
  align-items: center;
}

.results.three-columns .column .item .vertical-comparison > span {
  font-size: 0.9em;
  white-space: nowrap;
}

.results.three-columns .column .item .vertical-comparison .arrow {
  color: #666;
  font-size: 0.8em;
  margin: 0;
  line-height: 1;
}

.results.three-columns .items-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  width: 100%;
  justify-content: space-around;
}
@media (max-width: 768px) {
  .crc-trend-controls {
    margin-top: 0em;
  }
}
@media (max-width: 468px) {
  .results.three-columns {
    grid-template-areas:
      'before'
      'trend'
      'ai-ads'
      'share';
    grid-template-columns: 1fr;
  }

  .results.three-columns .column.before-after {
    flex-direction: column;
    align-items: flex-start;
  }

  .results.three-columns .column.before-after .items-wrapper {
    flex-direction: column;
    gap: 1em;
    width: 100%;
    align-items: center;
  }
  .ai-ads .ai-ads-home-grid .history {
    max-width: 360px;
  }
  .crc-trend-controls {
    flex-direction: column;
  }
  .crc-trend-range-dropdown,
  .crc-trend-metric-dropdown {
    min-width: 110px;
    max-width: 110px;
  }
}
