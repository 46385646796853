.enhencer-remarketing-audience .fluid-card-header {
    box-shadow: unset;
    background: var(--main-turquoise);
  }
  
  .enhencer-remarketing-audience .fluid-card-content {
    padding: 1.5em 4em 1.5em 0;
    border-radius: 0 0 10px 10px;
    height: 20em;
  }
  
  .enhencer-remarketing-audience .fluid-card-content .row {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  .enhencer-remarketing-audience .fluid-card-content .segmentation-row {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  
  .enhencer-remarketing-audience
    .fluid-card-content
    .segmentation-row
    .segment-list {
    padding-left: 4em;
  }