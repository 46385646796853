/* Demo Session - Start */
.before-you-cancel-modal {
  padding: 2em !important;
  gap: 1em;
  border-radius: 0.5em;
  position: relative;
  height: 100%;

  h2 {
    font-size: 1.8em;
  }

  button {
    background-color: var(--second-green);
    color: var(--main-white);
    border: none;
    padding: 1em 1.5em;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 0.8em;
    font-weight: 600;
    transition: all 0.3s;
    width: 30%;
  }
  button:hover {
    background-color: #3a9e6e;
  }
  .column {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    gap: 2em;
    align-items: center;
  }
  .boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    width: 100%;
  }
  .box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1em;
    gap: 1em;
    border-radius: 0.5em;

    box-shadow: 0px 0px 10px 0px #0000001a;
  }
  .box .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1em;
    flex-wrap: nowrap;
  }
  .box .row img {
    max-width: 50px;
  }
  .box p {
    font-size: 0.9em;
    color: #878787;
    font-weight: 400;
  }
  .box p span {
    font-weight: 600;
  }

  .close-button {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
    color: var(--text-gray);
    font-size: 1.4em;
  }

  .who-trust-us {
    width: 130%;
    max-width: 800px;
    margin: 3em 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 0.9em;
    justify-content: center;
    text-align: center;
    color: #9099a2;
  }
  .buttons-row {
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
    justify-content: center;
  }
  .gradient-button {
    background: linear-gradient(90deg, #74b751 0%, #57ba8a 100%);
    color: var(--main-white);
    border: none;
    padding: 0.8em 1em;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    transition: all 0.3s;
  }
  .gradient-button:hover,
  .white-button:hover {
    transform: translateY(-2px);
    box-shadow: 0px 0px 10px 0px #0000001a;
  }
  .white-button:hover {
    background-color: var(--main-white);
    transform: translateY(-2px);
    box-shadow: 0px 0px 5px 0px var(--second-green);
  }
  .white-button {
    background-color: var(--main-white);
    color: var(--second-green);
    border: 1px solid var(--second-green);
    padding: 0.8em 1em;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    transition: all 0.3s;
  }
}
