.under-maintenance {
  margin: 2em auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.under-maintenance .icon {
  font-size: 3em;
  display: block;
  color: var(--dark-blue);
}
