@keyframes rotateBorderColors {
  0% {
    background: conic-gradient(from 0deg, #00c2ff, var(--main-green), #00c2ff);
  }
  25% {
    background: conic-gradient(from 90deg, #00c2ff, var(--main-green), #00c2ff);
  }
  50% {
    background: conic-gradient(
      from 180deg,
      #00c2ff,
      var(--main-green),
      #00c2ff
    );
  }
  75% {
    background: conic-gradient(
      from 270deg,
      #00c2ff,
      var(--main-green),
      #00c2ff
    );
  }
  100% {
    background: conic-gradient(
      from 360deg,
      #00c2ff,
      var(--main-green),
      #00c2ff
    );
  }
}
@keyframes borderGlow {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
@keyframes breathe {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
@keyframes gradientFlow {
  0% {
    background-position: 0% center;
  }
  100% {
    background-position: 200% center;
  }
}
@keyframes subtleFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-button {
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 1em;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  white-space: nowrap;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: calc((100vw - var(--lg-navbar-width)) * 0.3);
  max-width: 400px;
  min-width: 360px;
  height: 100vh;
  transform: translate(100%, -50%) scale(0.5);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 9999;
  padding: 1rem;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-sidebar.open {
  transform: translate(0, 0) scale(1);
  opacity: 1;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-sidebar-inside {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 16px;
  background: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow:
    rgba(87, 186, 138, 0.15) 2px 2px,
    rgba(126, 191, 89, 0.1) 4px 4px,
    rgba(87, 186, 138, 0.08) 6px 6px,
    rgba(126, 191, 89, 0.05) 8px 8px,
    rgba(87, 186, 138, 0.03) 10px 10px;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-sidebar-inside::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 3px;
  background: conic-gradient(from 0deg, #00c2ff, var(--main-green), #00c2ff);
  border-radius: 16px;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: rotateBorderColors 4s linear infinite;
  pointer-events: none;
  filter: drop-shadow(0 0 10px rgba(0, 194, 255, 0.5));
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-button:hover {
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-button::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #00c2ff, var(--main-green));
  border-radius: 10px;
  z-index: -1;
  animation: borderGlow 3s ease infinite;
  transition: opacity 0.2s ease;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-button-content {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-button-title {
  color: var(--main-green);
  font-weight: 600;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-button-info {
  color: #666;
  font-size: 0.9em;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  background: rgba(0, 0, 0, 0.05);
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-close::before,
.enhencer-ai-agent-wrapper .enhencer-ai-agent-close::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 2px;
  background-color: #666;
  transition: background-color 0.2s ease;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-close::before {
  transform: rotate(45deg);
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-close::after {
  transform: rotate(-45deg);
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-close:hover {
  background: rgba(0, 0, 0, 0.08);
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content {
  flex: 1;
  overflow-y: auto;
  background: white;
  padding: 1em;
  padding-left: 1.5em;
  padding-top: 2.5em;
  padding-bottom: 8em;
  min-height: 0;
  box-sizing: border-box;
  height: calc(100vh - 100px);
  margin-right: 3px;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content > * {
  margin-bottom: 1em;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content > *:last-child {
  margin-bottom: 2em;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content h3 {
  color: var(--main-green);
  font-size: 0.9rem;
  margin: 1.5rem 0 0.75rem;
  text-align: left;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content h3:first-child {
  margin-top: 0;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content h4 {
  font-size: 1em;
  font-weight: 600;
  color: var(--main-green);
  margin: 1em auto 0.5em;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  background: white;
  min-height: 60px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 1em;
}

.enhencer-ai-agent-wrapper
  .enhencer-ai-agent-header
  .enhencer-ai-agent-button-title {
  font-weight: 600;
  font-size: 1.1rem;
  background: linear-gradient(90deg, #57ba8a 0%, #7ebf59 50%, #57ba8a 100%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientFlow 3s linear infinite;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-avatar {
  display: none;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-summary {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-questions {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #d5d5d5;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-questions h4 {
  color: var(--main-green);
  margin-bottom: 12px;
  font-size: 14px;
}

.enhencer-ai-agent-wrapper .question-cards {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.enhencer-ai-agent-wrapper .question-card {
  padding: 12px 16px;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #eee;
}

.enhencer-ai-agent-wrapper .question-card:hover {
  background: linear-gradient(90deg, #fcfcfc, #f5f5f5);
  border-color: var(--main-green);
  box-shadow: 0 4px 12px rgba(87, 186, 138, 0.15);
  transform: translateY(-2px);
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-conversation {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.question-bubble,
.answer-bubble,
.enhencer-ai-agent-wrapper .loading-bubble {
  padding: 12px 16px;
  border-radius: 16px;
  max-width: 100%;
}

.enhencer-ai-agent-wrapper .question-bubble {
  background-color: var(--main-green);
  color: white;
  align-self: flex-end;
  font-weight: 500;
}

.enhencer-ai-agent-wrapper .answer-bubble {
  background: linear-gradient(90deg, #fcfcfc, #f8f8f8, #fcfcfc);
  background-size: 200% 100%;
  animation: subtleFlow 3s ease infinite;
  align-self: flex-start;
  position: relative;
  padding: 16px 20px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.enhencer-ai-agent-wrapper .answer-bubble::before {
  content: '';
  position: absolute;
  top: -25px;
  left: -5px;
  width: 35px;
  height: 35px;
  background-image: url('../../shared/assets/images/ai-ads/ai-ads-logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.44);
}

.enhencer-ai-agent-wrapper .loading-bubble {
  display: flex;
  align-items: center;
  gap: 3px;
  background: #f0f0f0;
  padding: 10px 18px;
  border-radius: 8px;
  position: relative;
}

.enhencer-ai-agent-wrapper .loading-bubble.in-chat {
  padding: 20px 18px 14px 18px;
  border-radius: 16px;
  position: relative;
}

.enhencer-ai-agent-wrapper .loading-bubble.in-chat::before {
  content: '';
  position: absolute;
  top: -30px;
  left: -10px;
  width: 35px;
  height: 35px;
  background-image: url('../../shared/assets/images/ai-ads/ai-ads-logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.44);
}

.enhencer-ai-agent-wrapper .dot {
  width: 6px;
  height: 6px;
  background-color: var(--main-green);
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}

.enhencer-ai-agent-wrapper .dot:nth-child(2) {
  animation-delay: 0.3s;
}

.enhencer-ai-agent-wrapper .dot:nth-child(3) {
  animation-delay: 0.6s;
}

.enhencer-ai-agent-wrapper .back-button {
  cursor: pointer;
  color: var(--main-green);
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  font-size: 0.8rem;
}

.enhencer-ai-agent-wrapper .back-button:hover {
  background-color: #f5f5f5;
}

/* Add shimmer loading effect */

.enhencer-ai-agent-wrapper .loading-dots {
  position: relative;
  color: #222;
  font-weight: 500;
}

.enhencer-ai-agent-wrapper .loading-dots::after {
  content: 'Generating insights...';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent 25%,
    rgba(255, 255, 255, 0.95) 50%,
    transparent 75%
  );
  background-size: 200% 100%;
  animation: shimmer 2s infinite;
  -webkit-background-clip: text;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-button.disabled {
  opacity: 0.95;
  background: #f0f0f0;
  border: 1px solid #e0e0e0;
}

.enhencer-ai-agent-wrapper
  .enhencer-ai-agent-button.disabled
  .enhencer-ai-agent-button-info {
  color: #222;
}

.enhencer-ai-agent-wrapper
  .enhencer-ai-agent-button.disabled
  .enhencer-ai-agent-button-title {
  position: relative;
  color: #222;
  font-weight: 500;
}

.enhencer-ai-agent-wrapper
  .enhencer-ai-agent-button.disabled
  .enhencer-ai-agent-button-title::after {
  content: 'Enhencer AI Agent';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent 25%,
    rgba(255, 255, 255, 0.95) 50%,
    transparent 75%
  );
  background-size: 200% 100%;
  animation: shimmer 2s infinite;
  -webkit-background-clip: text;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-summary-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-summary-footer {
  font-size: 0.85em;
  color: #666;
  margin-top: 16px;
  font-style: italic;
  display: flex;
  justify-content: end;
  align-items: center;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-summary-footer-button {
  cursor: pointer;
  color: var(--main-green);
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content p {
  margin-bottom: 1rem;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content ul {
  padding-left: 1.2rem;
  margin: 0.5rem 0;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content li {
  margin: 0.5rem 0;
}

.enhencer-ai-agent-wrapper .recommendations-section {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-close:hover {
  background: rgba(0, 0, 0, 0.1);
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content::-webkit-scrollbar {
  width: 6px;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content::-webkit-scrollbar-track {
  background: transparent;
  margin: 1em 0;
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transition: background 0.2s ease;
}

.enhencer-ai-agent-wrapper
  .enhencer-ai-agent-content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

.enhencer-ai-agent-wrapper .enhencer-ai-agent-content {
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
  .enhencer-ai-agent-wrapper .enhencer-ai-agent-button-info {
    display: none;
  }
  .enhencer-ai-agent-wrapper .enhencer-ai-agent-button {
    position: absolute;
    top: 13px;
    right: 5px;
  }
}

@media (max-width: 468px) {
  .enhencer-ai-agent-wrapper .enhencer-ai-agent-button {
    padding: 8px;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 13px;
    right: 5px;
    background: none;
    box-shadow: none;
  }

  .enhencer-ai-agent-wrapper .enhencer-ai-agent-button::before {
    content: '';
    position: absolute;
    inset: 0;
    background: none;
    opacity: 0.3;
    z-index: -1;
    box-shadow: none;
  }

  .enhencer-ai-agent-wrapper .enhencer-ai-agent-button-content {
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .enhencer-ai-agent-wrapper .enhencer-ai-agent-button-title,
  .enhencer-ai-agent-wrapper .enhencer-ai-agent-button-info {
    display: none;
  }

  .enhencer-ai-agent-wrapper .enhencer-ai-agent-button-content::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    background-image: url('../../shared/assets/images/ai-ads/ai-ads-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .enhencer-ai-agent-wrapper .enhencer-ai-agent-sidebar {
    height: 100dvh !important;
  }
  .enhencer-ai-agent-wrapper .enhencer-ai-agent-content {
    height: calc(100vh - 205px);
    background: none;
  }
}
