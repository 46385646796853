.admin-tabs-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
}

.tab-buttons {
  display: flex;
  gap: 0.5rem;
  align-items: -webkit-center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
}

.tab-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: transparent;
  border: none;
  color: rgba(245, 245, 245, 0.7);
  cursor: pointer;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  font-size: 0.95rem;
  position: relative;
}

.tab-button:hover {
  color: white;
  background: rgba(255, 255, 255, 0.1);
}

.tab-button.active {
  color: white;
  position: relative;
  background: transparent;
}

.tab-button.active::before {
  content: '';
  position: absolute;
  inset: 0;

  border-radius: 0.5rem;
  z-index: -1;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.tab-icon {
  font-size: 1.1rem;
}

@media (max-width: 1180px) {
  .tab-buttons {
    gap: 0.2rem;
  }
  .tab-button {
    gap: 0.2rem;
    font-size: 0.8rem;
    padding: 0.75rem 0.8rem;
  }
}
@media (max-width: 960px) {
  .tab-buttons {
    gap: 1rem;
  }
  .tab-button span {
    display: none;
    padding: 1rem;
  }
}
@media (max-width: 768px) {
  .tab-buttons {
    justify-content: center;
    gap: 0rem;
  }

  .tab-button {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
}
