.productListHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.productListTitle {
  font-size: 1rem;
  font-weight: bold;
}

.manageProducts {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  background-color: #f8f8f8;
  color: #838383;
  padding: 0.5em 0.75em;
  border-radius: 0.75em;
  cursor: pointer;
  transition: all 100ms ease;
}

.manageProducts:hover {
  background-color: #f0f0f0;
  color: var(--text-gray);
}

.productListProducts {
  display: flex;
  flex-direction: row;
  gap: 5px;

  height: 100%;
  padding: 5px;
  width: 100%;
  align-items: center;
}

.productListProduct {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 100%;
  max-height: 100px;
  flex: 0 1 100px;
  aspect-ratio: 1 / 1;
}

.productListProductSelected {
  border: 2px solid var(--main-green);
}

.productListProduct:hover {
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
}

.savedBadge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--main-green);
  color: white;
  padding: 0.25em;
  font-size: 0.9em;
  border-radius: 0 0 0 0.75em;
}

.errorBadge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffc83e;
  color: white;
  padding: 0.25em;
  font-size: 0.9em;
  border-radius: 0 0 0 0.75em;
}

.productListProductImage {
  width: 100%;
  border-radius: 4px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productListProductImage img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.productNameTooltipContent {
  text-align: center;
}

.errorTooltipContent {
  font-size: 0.9em;
  margin-top: 0.5em;
  text-align: left;
  border: 1px solid #ffffff10;
  border-radius: 0.5em;
  padding: 0.5em;
  background-color: #ffc83e15;
}

.errorTooltipContent::before {
  content: 'Error: ';
  font-weight: bold;
}
