.toolbarBoxInsideItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 48px;
}

.toolbarBoxInsideItemVertical {
	flex-direction: column;
	gap: 10px;
	height: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 5px;
}

.toolbarBoxInsideItemTitle {
	font-size: 14px;
}

.toolbarBoxInsideItemContent {
	display: flex;
	flex-direction: row;
}

.toolbarBoxInsideItemVertical .toolbarBoxInsideItemContent {
	width: 100%;
}

.toolbarBoxInsideItemContentSelect {
	width: 100%;
	height: 30px;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 12px;
	margin-left: 10px;
	cursor: pointer;
	transition: background 0.1s;
}

.toolbarBoxInsideItemContentImage {
	width: 48px;
	height: 48px;
	object-fit: contain;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
}

.toolbarBoxInsideItemContentCheckbox {
	width: 20px;
	height: 20px;
	border: 1px solid #e0e0e0;
	border-radius: 10px;
	padding: 10px;
	color: #58BA8B;
	background: #58BA8B;
}


.toolbarBoxInsideItemContentColor {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
	transition: background 0.1s;
}

.toolbarBoxInsideItemContentColorBox {
	width: 20px;
	height: 20px;
	border: 1px solid #e0e0e0;
	border-radius: 2px;
	margin-left: 10px;
}

.toolbarBoxInsideItemContentColorInput {
	width: 70px;
	height: 30px;
	border: none;
	padding: 5px 10px;
	text-align: right;
	font-size: 12px;
	cursor: pointer;
	background: none;
	align-content: center;
	align-self: center;
}

.toolbarBoxInsideItemContentInput {
	width: 70px;
	height: 30px;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 12px;
}

.toolbarBoxInsideItemContentInputRightAligned {
	text-align: right;
}


.toolbarBoxInsideItemVertical .toolbarBoxInsideItemContentInput {
	width: 100%;
}

.toolbarBoxInsideItemContentInputText {
	font-size: 14px;
	align-self: center;
	margin-left: 6px;
	margin-right: 6px;
}

.toolbarBoxInsideItemContentButton {
	background: none;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 14px;
	color: #333;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	transition: background 0.1s;
}

.toolbarBoxInsideItemContentButton:hover,
.toolbarBoxInsideItemContentSelect:hover,
.toolbarBoxInsideItemContentColor:hover {
	background: #ececec;
}

.toolbarBoxInsideItemContentButtonIcon {
	margin-right: 4px;
}

.toolbarBoxInsideItemContentInputTextWithSuggestionsContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.suggestionChips {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin-top: 8px;
	margin-bottom: 16px;
}

.suggestionChip {
	background-color: #f0f0f0;
	border: none;
	border-radius: 16px;
	padding: 6px 12px;
	font-size: .7em;
	color: #333;
	cursor: pointer;
	transition: all 0.3s ease;
	outline: none;
}

.suggestionChip:hover {
	background-color: #e0e0e0;
}

.suggestionChip:active {
	background-color: #d0d0d0;
	transform: scale(0.98);
}

.suggestionChip.disabled {
	background-color: #e6e6e6;
	color: gray;
	cursor: default;
}