.ai-ads-campaign.campaign {
  cursor: pointer;
  box-shadow: #00000040 0 0 2px 0;
  opacity: 0.9;
  border-radius: 0.7em;
  overflow: hidden;
  transition: all 200ms;
  max-width: 24em;
  min-width: 24em;
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 2px 8px,
    rgba(17, 17, 26, 0.05) 0px 4px 16px;
}

.ai-ads-campaign.campaign .fluid-card-content {
  padding: 1em;
}

.ai-ads-campaign.campaign .campaign-content-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: relative;
}

.ai-ads-campaign.campaign .info-area .column {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.ai-ads-campaign.campaign
  .campaign-content-area
  .info-area
  .campaign-created-at {
  font-size: 0.9em;
}

.ai-ads-campaign.campaign .campaign-content-area .info-area {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  min-height: 10em;
  max-height: 10em;
  width: 70%;
  color: #013a56 !important;
  justify-content: space-around;
}

.ai-ads-campaign.campaign .fluid-card-header {
  width: 100%;
  text-align: left;
  align-items: flex-start;
  border-radius: 0;
  color: #013a56 !important;
  padding: 0em;
  margin-bottom: 0.2em;
  background: var(--main-white);
}

.ai-ads-campaign.campaign .campaign-content-area .image-area {
  width: 30%;
  height: 100%;
  position: relative;
}

.ai-ads-campaign.campaign .campaign-content-area .image-area .adcr h3 {
  display: none;
}

.ai-ads-campaign.campaign .campaign-content-area .image-area .ad-creatives-box {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

.ai-ads-campaign.campaign .campaign-content-area .image-area .ad-creative-cont {
  transform: scale(0.195) translate(-130%, -216%);
  pointer-events: none;
  z-index: 10;
}

.ai-ads-campaign.campaign:hover {
  opacity: 1;
  box-shadow: #00000020 0 0 6px 0;
}

/* Header */
.ai-ads-campaign.campaign .fluid-card-header .header-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25em;
}

.ai-ads-campaign.campaign .fluid-card-header .header-wrapper .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ai-ads-campaign.campaign .fluid-card-header .header-wrapper .row .label {
  font-size: 1.15em;
  font-weight: 500;
}

.ai-ads-campaign.campaign .fluid-card-header .header-wrapper .website-url {
  font-size: 0.9em;
  color: #8d8d8d;
}

.ai-ads-campaign.campaign .fluid-card-header .header-wrapper .row .status {
  background: #d1d1d1;
  width: 0.85em;
  height: 0.85em;
  border-radius: 50%;
  flex-shrink: 0;
}

.ai-ads-campaign.campaign .status-row {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.ai-ads-campaign.campaign .status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
}

.ai-ads-campaign.campaign .status.active {
  background-color: #4caf50;
}

.ai-ads-campaign.campaign .status-text {
  font-size: 0.8em;
  margin-left: 0px;
  color: #ccc;
}

.ai-ads-campaign.campaign .status.active + .status-text {
  color: #4caf50;
}

.ai-ads-campaign.campaign
  .fluid-card-header
  .header-wrapper
  .row
  .status.active {
  background: var(--second-green);
}

/* Content */
.ai-ads-campaign.campaign .fluid-card-content .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.ai-ads-campaign.campaign .fluid-card-content .content-wrapper span {
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.ai-ads-campaign.campaign .fluid-card-content .content-wrapper span.bold {
  font-weight: 500;
}

@media (max-width: 768px) {
  .ai-ads-campaign.campaign
    .campaign-content-area
    .image-area
    .ad-creative-cont {
    transform: scale(0.17) translate(-150%, -255%);
    z-index: 10;
  }

  .ai-ads-campaign.campaign {
    height: 140px;
  }
}
