.ad-health-checkup:has(.loading) {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ahcu-header {
  position: fixed;
  /* left: 3em;
  top: 1em; */
  z-index: 10;
  justify-content: space-between;
  width: 100%;
  padding: 0 2em;
}
.basic-user.ad-health-checkup .ahcu-header {
  width: calc(100% - var(--locked-navbar-width));
}

.ahcu-header .row {
  justify-content: flex-start !important;
  gap: 0.5em;
  line-height: 2em;
}

.ahcu-header .ahcu-logo {
  max-width: 150px;
}

.ahcu-header > div {
  font-size: 1.5em;
  color: #767676;
  font-weight: 500;
  transform: translateY(3px);
  width: 100%;
}

.ahcu-header .dot {
  font-size: 2em;
  font-weight: bold;
}

.ad-health-checkup {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  .content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 85%;
    max-width: 1400px;
    margin: 0 auto;
    gap: 0;
    padding: 7em 0;
  }
  .content-box.meta-revenue-main {
    gap: 2em;
  }

  .content .did-you-know-paragraph {
    min-height: 12ch;
    max-width: 710px;
    width: 90vw;
  }

  .content-box.lets-dive {
    align-items: flex-start;
  }

  .content-box.start-your-journey-main {
    gap: 2em;
    flex-direction: row;
  }

  .gradient-text {
    background: linear-gradient(
      90deg,
      var(--second-green) 0%,
      var(--primary-green) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .big-text {
    font-size: 3.1em;
    color: #33383d;
    font-weight: 700;
  }

  .second-big-text {
    font-size: 2.8em;
    color: #33383d;
    font-weight: 600;
  }

  .secondary-text {
    font-size: 1.4em;
    color: #0d2330;
    font-weight: 600;
  }

  .small-text {
    font-size: 1.2em;
    color: #0d2330;
  }

  .smaller-text {
    font-size: 0.8em;
    font-weight: 500;
    color: #9099a2;
  }

  .ai-card .secondary-text,
  .ai-card .smaller-text {
    color: var(--main-white);
  }

  .close-button {
    position: absolute;
    top: 1em;
    right: 1em;
    font-size: 1.5em;
    color: #33383dbc;
    z-index: 1;
    height: 1em;
    width: 1em;
    align-self: self-end;
  }

  .close-button:hover {
    cursor: pointer;
  }

  .navigation-row {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1em;
    bottom: 4%;
    left: var(--locked-navbar-width);
    width: 20%;
    z-index: 1;
  }

  .navigation-dot {
    flex: 1 1 auto;
    width: 5%;
    height: 7px;
    border-radius: 10px;
    background: #d9d9d9c2;
  }

  .navigation-dot:hover {
    cursor: pointer;
  }

  .navigation-dot.active {
    /* background-color: #8c8c8c; */
    background-color: #46b680c2;
  }

  .navigation-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 0;
    bottom: 5em;
    right: 3em;
    gap: 0.5em;
  }

  .navigation-buttons:hover {
    cursor: pointer;
  }

  .navigation img {
    width: 60px;
  }

  .currency {
    font-size: 0.7em;
    margin-right: 2px;
  }

  @media (max-width: 1500px) {
    .navigation-row {
      width: 30%;
    }
  }

  @media (max-width: 900px) {
    .big-text {
      font-size: 2.3em;
    }

    .second-big-text {
      font-size: 2em;
    }

    .secondary-text {
      font-size: 1.3em;
    }

    .navigation-row {
      width: 40%;
    }
  }
  @media (max-width: 768px) {
    .ahcu-header {
      padding: 0 4em;
    }
  }

  @media (max-width: 700px) {
    .big-text {
      font-size: 1.5em;
    }

    .secondary-text {
      font-size: 1.2em;
    }

    .small-text {
      font-size: 1em;
    }

    .smaller-text {
      font-size: 0.6em;
    }

    .second-big-text img {
      max-width: 40px;
    }

    .content-box.start-your-journey-main {
      flex-direction: column;
      gap: 1em;
    }
  }

  @media (max-width: 500px) {
    .navigation-row {
      width: 80%;
    }

    .navigation-buttons {
      bottom: 4em;
      right: 2em;
    }

    .navigation-buttons img {
      width: 50px;
    }
  }
}

/* ahcu waiting */
.ad-health-checkup-waiting-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 0.5em !important;
  /* border-radius: 7px; */
  /* background: linear-gradient(320deg, #a8a8a636 15.87%, #69696950 48.67%, #F9F8F6 64.17%, #d4d4d4c5 75.79%, #7f7f7f33 88.5%); */
  width: 100%;
  height: 100%;

  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      z-index: 4;
    }

    .logo {
      max-width: 200px;
    }

    h1 {
      margin-bottom: 0;
      font-size: 1.6em;
      font-weight: 600;
      text-align: center;
      background: var(--main-green-gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .title {
      font-size: 1.5em;
      font-weight: 600;
      color: var(--main-green);
    }

    .description {
      font-size: 1em;
      color: var(--main-green);
    }

    h2 {
      font-size: 1.5em;
      font-weight: 600;
      color: #33383d;
      margin-top: 3.5em;
    }

    p {
      font-size: 1em;
      color: #767676;
      font-weight: 500;
    }
    h3 {
      font-size: 1em;
      color: #767676;
      font-weight: 500;
    }

    span {
      background: linear-gradient(
        90deg,
        var(--second-green) 0%,
        var(--primary-green) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 0em;
      padding: 0em;
    }

    .footer .logo {
      max-width: 200px;
    }

    .footer img {
      width: 80%;
    }

    .footer .decor {
      max-width: 700px;
    }

    .animation-part {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;
      margin-top: 5em;
    }

    .left {
      background-image: url('https://cdn.enhencer.com/admin-assets/images/ad-health-checkup/left-lines.png');
      background-size: cover;
      width: 100%;
      height: 110px;
      transform: translateX(50px);
      position: absolute;
      background-size: 250px 130px;
      right: 62%;
      background-position: right;
      background-repeat: no-repeat;
      animation: expandLines 6s linear infinite;
    }

    .right {
      background-image: url('https://cdn.enhencer.com/admin-assets/images/ad-health-checkup/right-lines.png');
      position: absolute;
      left: 57%;
      width: 100%;
      height: 110px;
      transform: translateX(50px);
      background-size: 230px 117px;
      background-position: left;
      background-repeat: no-repeat;
      animation: expandLines 6s linear infinite;
    }

    .line.left,
    .line.right {
      width: 100%;
      max-width: 250px;
      z-index: 1;
    }

    .pluses {
      position: relative;
      display: flex;
      flex-direction: row;
      min-width: 130px;
      height: 1px;
    }

    .ellipse-right {
      position: absolute;
      z-index: 2;
      transform-origin: center;
      /* animation: animateEllipse 10s linear infinite; */
      max-width: 500px;

      background-image: url('https://cdn.enhencer.com/admin-assets/images/ad-health-checkup/ellipse.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 216px;
      height: 140px;
      top: -91px;
      left: -37%;
      animation: animateEllipse 6s linear infinite;
      /* animation-delay: 2s; */
    }

    .plus-1,
    .plus-2,
    .plus-3 {
      position: absolute;
      animation: rotate 6s linear infinite;
      z-index: 3;
    }

    .plus-1 {
      top: -70px;
      max-width: 100px;
      left: 10%;
    }

    .plus-2 {
      top: 30px;
      left: 60%;
      max-width: 60px;
      animation: rotate 8s linear infinite;
    }

    .plus-3 {
      transform: translateY(20%) translateX(-170%);
      top: 20px;
      /* left: -70px; */
      max-width: 40px;
      animation: rotate 7s linear infinite;
    }

    .empty {
      height: 100px;
      animation: emptyContainer 10s linear infinite;
    }
  }
}

/* ahcu error */
.ad-health-checkup-error-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 0.5em !important;
  width: 100%;
  height: 100%;

  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 70ch;

    .content {
      z-index: 4;
    }

    h1 {
      margin-bottom: 0;
      font-size: 1.6em;
      font-weight: 600;
      text-align: center;
      background: var(--main-green-gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h3 {
      font-size: 1em;
      color: #767676;
      font-weight: 500;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes expandLines {
  0% {
    max-width: 0px;
  }

  20% {
    max-width: 80px;
  }

  40% {
    max-width: 160px;
  }

  60% {
    max-width: 240px;
  }

  80% {
    max-width: 240px;
  }

  100% {
    max-width: 240px;
  }
}

@keyframes animateEllipse {
  0% {
    transform: scaleX(1);
  }

  20% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(2);
  }

  60% {
    transform: scaleX(2.5);
  }

  80% {
    transform: scaleX(3);
  }

  100% {
    transform: scaleX(3.5);
  }
}
