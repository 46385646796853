.top-ads-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

.top-ads-list {
  .column {
    gap: 4em;
  }
  .grey-line {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .svg-path-grey {
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    animation: dashLineSingle 2s linear forwards;
  }

  .svg-path-red {
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    animation: dashLineSingle 2s linear forwards;
  }

  .red-line {
    position: absolute;
    bottom: 0;
    right: -2%;
    width: 110%;
  }
  .red-line svg,
  .grey-line svg {
    width: 100%;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2em;
    width: 100%;
  }

  .detail-card {
    display: flex;
    flex-direction: column;
    box-shadow: var(--main-box-shadow);
    position: relative;
    width: 80%;
    background-color: var(--main-white);
  }
  .detail-card .order {
    font-size: 15em;
    font-weight: 600;

    opacity: 0.3;
    position: absolute;
    top: -15%;
    left: -25%;
    z-index: 10;
  }
  .inefficient .detail-card .order.red-text {
    color: #fac2c2;
    opacity: 1;
  }

  .image-part {
    padding: 1em 0;
  }

  .image-part img {
    width: 100%;
  }
  .content-part {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em;
    background-color: #fcfcfc;
  }

  .content-part.gradient-background {
    background: linear-gradient(
      90deg,
      var(--second-green) 0%,
      var(--primary-green) 100%
    );
  }
  .inefficient .content-part.red-background {
    background: #e54343;
  }
  .content-part.gradient-background .secondary-text {
    color: var(--main-white) !important;
  }
  .inefficient .content-part.red-background .secondary-text {
    color: var(--main-white) !important;
  }
  .content-part .row {
    justify-content: space-between !important;
  }
  .secondary-text {
    color: #9099a2 !important;
  }
  .secondary-text.smaller {
    font-size: 1em;
    white-space: nowrap;
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 1400px) {
    .detail-card {
      width: 100%;
    }
  }
  @media (max-width: 1000px) {
    .detail-card .order {
      font-size: 10em;
    }
    .secondary-text {
      font-size: 0.8em !important;
    }
  }
  @media (max-width: 650px) {
    .detail-card .order {
      font-size: 6em;

      left: -10%;
      top: -5%;
    }
    .secondary-text {
      font-size: 0.8em !important;
    }
    .grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}
