.generativeTemplatesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 2em;
  overflow: hidden scroll;
}

.searchBarContainer {
  width: 100%;
  margin-bottom: 0em;
  font-size: 0.9em;
  max-width: 500px;
}

.templateItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border: 1px solid #ccc;
  padding: 1em;
  border-radius: 1em;
}

.templateName {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0 0 0.5em 0;
}

.templateImageContainer {
  flex: 1 1 auto;
  position: relative;
}

.templateImageOverlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.378);
  transition: opacity 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  flex-direction: column;
}

.templateImageContainer:hover .templateImageOverlay {
  opacity: 1;
}

.templateImageOverlay button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.templateImageOverlay button:hover {
  color: rgb(220, 250, 255);
}

.templateImages {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1em;
  width: 100%;
}

.templateImage {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.templateImage:hover {
  transform: scale(1.05);
}

.templateImagePrompt {
  font-size: 0.7em;
  color: var(--text-gray);
  font-style: italic;
}
