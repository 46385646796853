.card {
  position: relative;
  margin: 0;
  box-shadow: var(--card-shadow);
  border-radius: 0.5em;
  padding: 1rem;
  overflow: hidden;
  background: var(--main-white);
}

.revenue-graph-main .card {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  padding: 0;
  height: 100%;
  width: 100%;
}
.revenue-graph-main .card canvas {
  max-width: 100% !important;
  max-height: 100%;
}
