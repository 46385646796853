.ticket-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .go-back {
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 0.75em;
    color: #a6a6a6;
    cursor: pointer;
    transition: all 75ms;

    .text {
      font-weight: 600;
      font-size: 0.8em;
    }

    &:hover {
      color: #6b6b6b;
    }
  }
}

.profile-content-wrapper:has(.ticket-details-content) {
  display: flex;
  flex-direction: column;

  .profile-content {
    flex: 1;
  }
}

.ticket-details-content {
  display: flex;
  gap: 1em;
  height: 100%;
  max-height: calc(100vh - var(--topbar-height) - 10em);

  .chatbox {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;

    .messages {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1.5em;
      padding: 2em;
      overflow-y: auto;
      background-color: var(--main-white);
      box-shadow:
        rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      border-radius: 1em;
      min-height: 80%;
      max-height: 675px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &:hover::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--transparent-gray);
      }

      .message {
        display: flex;
        gap: 0.5em;

        .avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2em;
          height: 2em;
          border-radius: 50%;
          background-color: var(--second-green);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }

          .name-first-letter {
            color: var(--main-white);
            font-size: 1.5em;
            font-weight: 600;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 0.5em;
          border-radius: 0.5em;
          padding: 0.75em;
          background-color: #f6f7f9;
          max-width: 400px;
          position: relative;

          .text {
            font-size: 0.9em;
          }

          .time {
            font-size: 0.8em;
            color: #a6a6a6;
          }
        }

        &.received {
          justify-content: flex-start;

          .avatar {
            align-self: flex-start;
          }

          .content {
            top: 0.25em;
            background-color: #f6f7f9;
            text-align: left;
            border-top-left-radius: 0;
          }
        }

        &.sent {
          justify-content: flex-end;

          .avatar {
            order: 2;
            align-self: flex-end;
          }

          .content {
            top: -0.25em;
            background-color: #eaf1ff;
            border-bottom-right-radius: 0;

            .time {
              text-align: right;
            }
          }
        }
      }
    }

    .add-message {
      display: flex;
      height: 4em;

      button {
        flex: 1;
        border-radius: 1em;
        background-color: #6b6b6b;
        color: var(--main-white);
        font-weight: 600;
        transition: all 75ms;

        &:hover {
          background-color: #4b4b4b;
        }

        &:disabled {
          opacity: 0.5;
          &:hover {
            background-color: #6b6b6b;
            color: var(--main-white);
          }
        }
      }
    }

    .input {
      display: flex;
      gap: 0.5em;
      padding: 0.75em;
      background-color: var(--main-white);
      box-shadow:
        rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      border-radius: 1em;
      height: 4em;

      textarea {
        flex: 1;
        border: none;
        border-radius: 5px;
        background-color: var(--main-white);
        resize: none;
        font-size: 0.9em;
        font-family: inherit;

        &:focus {
          outline: none;
        }
      }

      button {
        padding: 0.5em 2em;
        border: none;
        border-radius: 1em;
        background-color: #6b6b6b;
        color: var(--main-white);
        font-weight: 600;
        transition: all 75ms;

        &:hover {
          background-color: #4b4b4b;
        }

        &:disabled {
          opacity: 0.5;
          &:hover {
            background-color: #6b6b6b;
            color: var(--main-white);
          }
        }
      }
    }
  }

  .information {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 4em;
    background-color: var(--main-white);
    box-shadow:
      rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 1em;
    padding: 2em;
    overflow: auto;

    .details {
      display: flex;
      flex-direction: column;
      gap: 1.5em;

      .header {
        font-size: 1.2em;
        margin: 0;
        color: #6b6b6b;

        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: var(--main-gray);
          margin-top: 0.4em;
        }
      }

      .section.grouped {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .group {
          display: flex;
          flex-direction: column;
          gap: 0.25em;
          max-width: 10ch;

          .value {
            margin: 0;
            padding: 0.2em 0em;
          }
        }
      }

      .section.your-marketing-expert {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5em;

        .avatar {
          width: 64px;
          height: 64px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .texts {
          display: flex;
          flex-direction: column;
          gap: 0.1em;

          .name {
            font-size: 1.1em;
            font-weight: 600;
          }

          .email {
            font-size: 0.9em;
            color: #6b6b6b;
          }
        }
      }

      .section {
        display: flex;
        flex-direction: column;
        gap: 0.25em;

        .title {
          font-size: 1.05em;
        }

        .value {
          font-size: 0.9em;
          line-height: 1.25;
          color: #6b6b6b;
        }

        .value.description {
          word-break: break-all;
        }

        .value:has(.screenshot) {
          width: 120px;
          height: 120px;

          .screenshot {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .value.status {
          padding: 0.2em 1em;
          border-radius: 1em;
          font-weight: 600;
          position: relative;
          overflow: hidden;
          min-width: 12ch;
          text-align: center;

          &.in-progress {
            background: #feeac1;
            color: #523d21;
          }

          &.postponed {
            background: #d1e1f0;
            color: #253444;
          }

          &.completed {
            background: #daeada;
            color: #354839;
          }

          &.pending {
            background: #f1eef2;
            color: #565355;
          }

          &.closed {
            background: #f0dada;
            color: #4f2f2f;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &:hover::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--transparent-gray);
    }
  }
}

/* Custom styling for the "react-medium-image-zoom" component */
[data-rmiz-btn-unzoom] {
  display: none;
}

[data-rmiz-modal-overlay='hidden'] {
  background-color: rgba(0, 0, 0, 0);
}

[data-rmiz-modal-overlay='visible'] {
  background-color: rgba(0, 0, 0, 0.8);
}
