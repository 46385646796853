.crc-no-campaigns {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background: rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(2px);
  border-radius: 0.75em;
  padding: 2em;
  overflow: hidden;
  z-index: 2;
}

.crc-no-campaigns-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  filter: blur(4px);
  pointer-events: none;
  background-image: linear-gradient(to bottom right, rgba(var(--main-green-rgb), 0.1), rgba(var(--main-green-rgb), 0.05));
}

.crc-no-campaigns-graph {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 200px;
  opacity: 0.1;
  pointer-events: none;
}

.crc-no-campaigns-graph path {
  stroke: var(--main-green);
  stroke-width: 2;
  fill: none;
}

.crc-no-campaigns-content {
  text-align: center;
  top: 50px;
  max-width: 450px;
  position: relative;
  z-index: 11;
  background: white;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid #dfeaf2;
  transition: all 0.6s ease;
}

.crc-no-campaigns-content:hover {
  border: 1px solid #a2c4dd;
}

.empty-state-icon-container {
  margin-bottom: 8px;
}

.empty-state-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.crc-no-campaigns-title {
  font-size: 22px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
}

.crc-no-campaigns-description {
  font-size: 14px;
  color: #666;
  line-height: 1.3;
  margin-bottom: 16px;
}

.crc-create-campaign-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: var(--main-green);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
}

.crc-create-campaign-button:hover {
  background: var(--main-green-dark, var(--main-green));
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(var(--main-green-rgb), 0.2);
}

.crc-create-campaign-button:active {
  transform: translateY(0);
}

.button-icon {
  font-size: 14px;
}

.crc-preview-graph-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  filter: blur(1px);
  pointer-events: none;
  padding: 2em;
}

.crc-preview-graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.crc-preview-graph-title {
  font-size: 1.2em;
  font-weight: 500;
  color: #1a1a1a;
}

.crc-preview-graph-content {
  height: 300px;
  position: relative;
  margin-top: 2em;
}

.crc-preview-graph-content svg {
  width: 100%;
  height: 100%;
}

.crc-preview-stats {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.crc-preview-stat {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-green);
}

.crc-preview-stat-label {
  font-size: 12px;
  color: #666;
}

.crc-no-campaigns-icon-wrapper {
  margin-bottom: 16px;
}

.crc-no-campaigns-launcher-icon {
  width: 90px;
  height: 90px;
  object-fit: contain;
}

.crc-no-campaigns-waiting-icon {
  font-size: 50px;
  color: var(--main-green);
  opacity: 0.8;
} 