.notification-badge {
  position: absolute;
  top: -6px;
  right: -6px;
  background: rgb(251, 50, 50);
  border-radius: 30px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 #53535353;
  font-weight: 500;
}

.notification-badge.yellow {
  background: rgb(255, 222, 37);
}

.notification-badge.green {
  background: rgb(255, 222, 37);
}

.notification-badge.blue {
  background: var(--main-blue)
}