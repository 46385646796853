.modalContent {
  padding: 0 !important;
  width: 85vw;
  max-width: 600px;
  min-height: 300px;
  text-align: center;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.headerSection {
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding: 2em 1.5em;
  background: linear-gradient(to bottom, #ffffff, #f9f9f9);
}

.mainTitle {
  margin: 0 auto;
  font-size: 1.8em;
  font-weight: 700;
  color: var(--text-black);
  background: linear-gradient(45deg, #2c2c2c, #1a1a1a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.infoText {
  margin: 1rem auto 0;
  color: #666;
  font-size: 1.05rem;
  line-height: 1.5;
  max-width: 480px;
}

.progressContainer {
  flex: 1;
  padding: 3rem 2.5rem 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Özel progress bar stilleri */
:global(.customProgress.progress-bar) {
  border-radius: 12px;
  background: #f3f4f6;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

:global(.customProgress .progress) {
  background: linear-gradient(
    90deg,
    var(--second-green) 0%,
    var(--primary-green) 100%
  );
  box-shadow: 0 2px 4px rgba(16, 185, 129, 0.15);
  animation: shimmer 3s infinite linear;
}

.progressDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 0 0.25rem;
}

.percentage {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--second-green);
}

.processingInfo {
  font-size: 0.85rem;
  color: #6b7280;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

:global(.customProgress .progress) {
  background: linear-gradient(
    90deg,
    var(--second-green) 0%,
    var(--primary-green) 50%,
    var(--second-green) 100%
  );
  background-size: 1000px 100%;
}

@media (max-width: 768px) {
  .modalContent {
    width: 95vw;
    min-height: 280px;
  }

  .headerSection {
    padding: 1.5em 1em;
  }

  .mainTitle {
    font-size: 1.5em;
  }

  .infoText {
    font-size: 0.95rem;
    margin-top: 0.75rem;
  }

  .progressContainer {
    padding: 1.5rem;
  }

  .percentage {
    font-size: 1.1rem;
  }
}
