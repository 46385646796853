.checkbox {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.checkbox.right {
  flex-direction: row-reverse;
}

.checkbox input[type='checkbox'] {
  appearance: none;
  background-color: #95919138;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.3em;
  height: 1.3em;
  border: 0.01px solid #0000003b;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

.checkbox input[type='checkbox']:checked {
  background: var(--dark-green);
  color: var(--main-white);
}

.checkbox input[type='checkbox']::before {
  content: '✓';
  width: 0.65em;
  height: 0.65em;
  transform: translateY(0.4em) scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

.checkbox input[type='checkbox']:hover {
  background-color: #d4d2d238;
}

.checkbox input[type='checkbox']:checked:hover {
  background: #255e43;
}

.checkbox input[type='checkbox']:checked::before {
  transform: translateY(-0.4em) scale(1);
}

.checkbox.right input[type='checkbox'] {
  appearance: none;
  background-color: #95919138;
  font: inherit;
  color: currentColor;
  width: 1.3em;
  height: 1.3em;
  border: 0.01px solid #0000003b;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.checkbox.right input[type='checkbox']::before {
  content: 'X';
  width: 0.65em;
  height: 0.65em;
  transform: translateY(0.4em) scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

.checkbox.right input[type='checkbox']:hover {
  background-color: #d4d2d238;
}

.checkbox.right input[type='checkbox']:checked::before {
  transform: translateY(-0.4em) scale(1);
}
