.overlay-panel {
  position: fixed;
  bottom: 0em;
  transform: translate(-1em, 5px);
  background-color: var(--main-white);
  padding: 1em;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px -5px 15px rgba(15, 15, 15, 0.115);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  max-width: 8.5em;
  max-height: 4em;
}

.summary-details {
  display: flex;
  padding: 1em 0em 2em 0em;
  gap: 1.5em;
}

.overlay-panel:hover,
.overlay-panel.pinned {
  max-height: 80%;
  height: auto;
  max-width: 60%;
  width: 100%;
}

.overlay-panel:hover .pin-toggle {
  display: flex;
}

.overlay-panel.pinned .pin-toggle {
  display: flex;
}

.pin-toggle {
  display: none;
  position: fixed;
  bottom: 1em;
  left: 1em;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  transition:
    opacity 500ms,
    background-color 150ms,
    border 150ms;
}

.pin-toggle.pinned {
  border: 2px solid var(--dark-green);
}

.pin-toggle:hover {
  background-color: #f3f3f3;
}

.summary-details-column {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  /* width: fit-content; */
}

.summary-details-row {
  display: flex;
  flex-direction: row;
  padding: 0px 0px;
  gap: 0.5em;
  align-items: center;
}

.summary-details-row .data {
  display: flex;
  align-items: center;
}

.summary-details-row .data .tooltip-container {
  display: flex;
  justify-content: flex-end;
}

.overlay-panel .summary-title {
  font-size: 1.3em;
  font-weight: bold;
  color: var(--main-green);
}

.summary-group-container {
  padding: 10px;
  margin: 5px 0px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.summary-group-data {
  flex: 1 1 auto;
}

.summary-group-data .title {
  font-size: 0.7em;
  font-weight: bold;
  color: #949494;
}

.summary-group-data .bigtitle {
  font-size: 1em;
  color: #000000;
}

.summary-group-data .data {
  font-size: 1.2em;
  font-weight: bold;
  flex: 0 0 8em;
}

.summary-group-data .data>div {
  flex: 0 0 50%;
  text-align: right;
}

.summary-details-row .helper-text {
  font-size: 0.8em;
  color: #676767;
  font-weight: 500;
}

.summary-details-column .helper-text {
  font-size: 0.8em;
  color: #676767;
  font-weight: 500;
}

.summary-group-data .description {
  align-self: center;
  font-size: 12px;
  font-weight: bold;
  color: #949494;
  flex: 1 1 70%;
  white-space: nowrap;
}

.summary-group-data .bigdescription {
  align-self: center;
  font-size: 0.6em;
  font-weight: bold;
  color: #949494;
}

.summary-divider {
  display: block;
  width: 100%;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
  height: 1px;
  background-color: #ccc;
}

@media (max-width: 768px) {
  .overlay-panel {
    max-width: 90px;
    font-size: x-small;
  }

  .admin-panel-container .filter-container .content-wrapper .filters {
    height: 80%;
  }

  .admin-panel-container .filter-container .content-wrapper {
    height: 105%;
  }
}

.controls-container {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.overlay-panel .subscription-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.overlay-panel .subscription-header .title {
  font-size: 0.7em;
  font-weight: bold;
  color: #949494;
}

.overlay-panel .subscription-header .switch {
  font-size: 0.6em;
}