.activity-logs-container {
  padding: 20px;
}

.activity-logs-container .layout {
  background: white;
  border-radius: 8px;
  padding: 20px;
}

.activity-logs-container .top {
  margin-bottom: 20px;
}

.activity-logs-container .filters {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.activity-logs-container .filter-section {
  width: 300px;
}

.activity-logs-container .date-filters {
  display: flex;
  gap: 20px;
}

.activity-logs-container .date-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.activity-logs-container .date-input-wrapper label {
  font-size: 14px;
  color: #666;
}

.activity-logs-container .user-select,
.activity-logs-container .date-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
}

.activity-logs-container .user-select {
  width: 100%;
}

.activity-logs-container .date-input {
  width: 150px;
}

.activity-logs-container .user-select:focus,
.activity-logs-container .date-input:focus {
  outline: none;
  border-color: #007bff;
}

.activity-logs-container .activity-logs-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.activity-logs-container .activity-log-row {
  display: grid;
  grid-template-columns: 120px 220px 220px 1fr;
  gap: 24px;
  padding: 16px 20px;
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  align-items: center;
  transition: all 0.2s ease;
}

.activity-logs-container .activity-log-row:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
}

.activity-logs-container .timestamp {
  color: #666;
  font-size: 14px;
  white-space: nowrap;
}

.activity-logs-container .target-user-id {
  color: var(--main-green);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activity-logs-container .action {
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activity-logs-container .details {
  color: #666;
  font-size: 14px;
  line-height: 1.4;
}

.activity-logs-container .loading,
.activity-logs-container .not-found-text {
  text-align: center;
  color: #666;
  margin: 40px 0;
}

.activity-logs-container .target-user-filter {
  width: 250px;
}

.activity-logs-container .input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.activity-logs-container .input-wrapper label {
  font-size: 14px;
  color: #666;
}

.activity-logs-container .target-user-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  width: 100%;
}

.activity-logs-container .target-user-input:focus {
  outline: none;
  border-color: var(--main-green);
}

.activity-logs-container .search-button {
  padding: 8px 24px;
  background-color: var(--main-green);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  height: 38px;
  align-self: flex-end;
}

.activity-logs-container .search-button:hover {
  background-color: var(--main-green-hover);
}

.activity-logs-container .search-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Add column headers */
.activity-logs-container .activity-logs-header {
  display: grid;
  grid-template-columns: 120px 220px 220px 1fr;
  gap: 24px;
  padding: 0 20px;
  margin-bottom: 8px;
  color: #666;
  font-size: 13px;
  font-weight: 500;
}

.activity-logs-container .load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 20px 0;
}

.activity-logs-container .load-more-button {
  padding: 8px 24px;
  background-color: white;
  color: var(--main-green);
  border: 1px solid var(--main-green);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.activity-logs-container .load-more-button:hover {
  background-color: var(--main-green);
  color: white;
}

.activity-logs-container .load-more-button:disabled {
  background-color: #f5f5f5;
  border-color: #ddd;
  color: #999;
  cursor: not-allowed;
}

.activity-logs-container .datetime-inputs {
  display: flex;
  gap: 8px;
}

.activity-logs-container .time-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
  width: 120px;
}

.activity-logs-container .time-input:focus {
  outline: none;
  border-color: var(--main-green);
} 