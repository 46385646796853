/* Layout Start */
.subscription-card .inputs-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1.25em 3em;
}

.subscription-card .inputs-wrapper.single-line {
  grid-template-rows: 1fr;
}

.subscription-card .inputs-wrapper .input-cell {
  display: flex;
  align-items: center;
}

.subscription-card .inputs-wrapper .input-cell span {
  max-width: 40%;
}

.subscription-card .inputs-wrapper .input-cell span,
.subscription-card .inputs-wrapper .input-cell .form-control {
  flex: 1;
}

.subscription-card .inputs-wrapper .input-cell .half-inputs {
  flex: 1;
  display: flex;
  gap: 0.5em;
}

.subscription-card .inputs-wrapper .input-cell .half-inputs .form-control {
  flex: 1;
}

/* Layout End */

.subscription-card .inputs-wrapper .input-cell span {
  color: #000;
  font-size: 0.8em;
}

/* Resets Start */
.subscription-card .input-cell .form-control {
  display: flex;
  margin: 0;
  width: 100%;
  height: 1.8em;
}

.subscription-card .input-cell .form-control .select-cont,
.subscription-card .input-cell .form-control input {
  box-shadow: unset;
  height: 100%;
}

.subscription-card
  .inputs-wrapper
  .input-cell
  .half-inputs
  .form-control
  select {
  height: 100%;
}

.subscription-card
  .inputs-wrapper
  .input-cell
  .half-inputs
  .form-control
  select,
.subscription-card
  .inputs-wrapper
  .input-cell
  .half-inputs
  .form-control
  input {
  font-size: 0.75em;
}

.subscription-card
  .inputs-wrapper
  .input-cell
  .half-inputs
  .form-control
  select
  option {
  font-size: 1em;
  color: #333;
}

.subscription-card .input-cell .form-control select {
  width: 100%;
  height: 100%;
  padding: 0.5em;
  font-size: 0.75em;
  border-radius: 5px;
  background: var(--transparent-gray);
  color: #333;
  outline: none;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}

.subscription-card .input-cell .form-control select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

.subscription-card .input-cell .form-control select::-ms-expand {
  display: none;
}

.subscription-card .input-cell .form-control select::before {
  content: '\25BC';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
  pointer-events: none;
}

.subscription-card .input-cell .form-control select option {
  font-size: 1em;
  color: #333;
}

.subscription-card .collapsible-card-content .input-cell input {
  flex: 1;
  border-radius: 5px;
  border: 0;
  background: var(--transparent-gray);
}

.subscription-card .collapsible-card-content .usage-charges .title {
  margin-top: 1.5em;
  font-size: 0.9em;
  font-weight: 600;
  color: var(--main-green);
}

.subscription-card .collapsible-card-content .usage-charges table {
  font-size: 0.8em;
  text-align: left;
  margin-top: 0.5em;
}

.subscription-card .collapsible-card-content .usage-charges table td,
.subscription-card .collapsible-card-content .usage-charges table th {
  border: 1px solid rgb(159, 159, 159);
  padding: 5px 10px;
}

.subscription-card
  .collapsible-card-content
  .usage-charges
  table
  .billing-cell {
  display: flex;
  align-items: center;
  gap: 5px;
}

.subscription-card
  .collapsible-card-content
  .usage-charges
  table
  .billing-cell.billed {
  color: var(--main-green);
}

.subscription-card .collapsible-card-content .usage-charges table .icon {
  display: none;
  color: var(--main-green);
}

.subscription-card
  .collapsible-card-content
  .usage-charges
  table
  .billed
  .icon {
  display: inline-block;
}

.subscription-card .collapsible-card-content .date-picker-cont select {
  padding: unset;
}

.subscription-card .collapsible-card-content .date-picker-cont input {
  height: 1.8em;
}
@media (max-width: 824px) {
  .subscription-card .inputs-wrapper {
    grid-template-columns: none;
  }
}
/* Resets End */
