/* Switch - Start */

.switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: stretch;
  height: 2em;
  min-width: 150px;
  background: var(--main-white);
  border-radius: 10em;
  box-shadow: inset 0 0 5px #d8d8d8;
  text-transform: capitalize;
}

.switch .left-side,
.switch .right-side {
  flex: 0 0 50%;
  cursor: pointer;
  text-align: center;
  z-index: 1;
}

.switch.left .left-side {
  color: var(--main-white);
}

.switch.right .right-side {
  color: var(--main-white);
}

.switch .selection {
  background: var(--main-green-gradient);
  width: calc(50% - 2px);
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  z-index: 0;
  border-radius: 10em;
  transition: 0.3s;
  box-shadow: 0 0 10px rgba(95, 185, 125, 0.3);
}

.switch.right .selection {
  left: 50%;
}

.switch.disabled {
  pointer-events: none;
  background: rgb(183, 183, 183);
  opacity: 0.6;
}

.switch.disabled .right-side,
.switch.disabled .left-side {
  color: var(--disabled-gray) !important;
}

.switch.disabled .selection {
  background: rgb(150, 150, 150);
  box-shadow: none;
}

.switch.off-on-switch.left .selection {
  background: #b6b6b6;
  box-shadow: 0 0 10px rgba(224, 224, 224, 0.3);
}

.switch.off-on-switch.right .selection {
  background: var(--main-green-gradient);
  box-shadow: 0 0 10px rgba(95, 185, 125, 0.3);
}

.switch.no-yes-switch.left .selection {
  background: #b6b6b6;
  box-shadow: 0 0 10px rgba(224, 224, 224, 0.3);
}

.switch.no-yes-switch.right .selection {
  background: var(--main-green-gradient);
  box-shadow: 0 0 10px rgba(95, 185, 125, 0.3);
}

/* TAB - start */

.tab-switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: stretch;
  height: 2em;
  min-width: 185px;
  background: none;
  color: rgb(99, 99, 99);
}

.tab-switch .left-side,
.tab-switch .right-side {
  flex: 0 0 50%;
  cursor: pointer;
  text-align: center;
  z-index: 1;
}

.tab-switch.left .left-side,
.tab-switch.right .right-side {
  color: var(--main-green);
  font-weight: 500;
}

.tab-switch .selection {
  background: none;
  border-bottom: 2px solid var(--main-green);
  width: calc(50% - 2px);
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  z-index: 0;
  transition: 0.3s;
}

.tab-switch.right .selection {
  left: 50%;
}

/* TAB - end */

/* Size Variants - Only apply when size prop is explicitly set */
.switch.switch-small {
  height: 26px !important; /* Kesin değer veriyoruz */
  min-width: 80px !important;
  font-size: 12px !important;
}

.switch.switch-small .selection {
  width: calc(50% - 2px);
}

/* Diğer switchlerin default boyutlarını korumak için */
.switch:not(.switch-small) {
  height: 2em;
  min-width: 150px;
  font-size: 1em;
}
