.result-card .segment {
  flex: 1 1 50%;
  padding: 0;
  box-shadow: 2px 0 5px 0 #ccc;
  font-size: 0.8em;
  border-radius: 0.5em;
  max-height: 100%;
  overflow-y: scroll;
}

.result-card .segment .header {
  width: 100%;
  padding: 0.5em 5%;
  border-radius: 0.5em 0.5em 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--disabled-gray);
}

.result-card .segment .header .segment-name {
  font-size: 1.2em;
  font-weight: 500;
}

.result-card .segment .header .conversion-container,
.result-card .segment .footer .conversion-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 0.5em;
  font-size: 0.9em;
}

.result-card .segment .header .conversion-container .number,
.result-card .segment .footer .conversion-container .number {
  font-size: 1.5em;
}

.result-card .segment .cont-box {
  margin: 1em auto;
  width: 90%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.result-card .segment .visitors-who {
  font-weight: 400;
  font-size: 1.1em;
  color: var(--text-gray);
}

.result-card .segment .visitors-who b {
  font-weight: 600;
  /* color: rgb(58, 58, 58) */
}

.result-card .segment .rules {
  position: relative;
}

.result-card .segment .rules .vertical-line {
  position: absolute;
  height: 100%;
  width: 1px;
  background: var(--disabled-gray);
  left: 4px;
}

.result-card .segment .ruleset-row {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 1em;
  color: var(--text-gray);
  margin: 1.5em 0;
}

.result-card .segment .ruleset-row .bullet {
  height: 15px;
  width: 15px;
  background: var(--main-white);
  border-radius: 5px;
  transform: rotate(45deg) translate(0px, 4px);
}

.result-card .segment .footer {
  justify-content: space-between;
  width: 90%;
}

@media (max-width: 1400px) {
  .result-card .segment {
    font-size: 0.7em;
  }

  .result-card .segment .header {
    width: 100%;
    padding: 0.5em 5%;
    border-bottom: 1px solid var(--disabled-gray);
  }

  .result-card .segment .ruleset-row .bullet {
    height: 12px;
    width: 12px;
    border-radius: 4px;
  }

  .result-card .segment .rules .vertical-line {
    width: 1px;
    left: 3px;
  }
}
