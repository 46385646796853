.achievement-bars-container {
  position: relative;
  width: 100%;
  padding-right: 2em;
}

.achievement-bar-container {
  transition: all 0.5s ease;
}

.achievement-bar-container.shrink {
  transform: scale(0.5);
  opacity: 0.7;
  margin-bottom: 20px;
}

/* Level 2 için elit renkler */
.achievement-bar-container .level-2 .achievement-fill {
  background: linear-gradient(90deg, #3792b8, #4eab72, #12445a);
}

.achievement-bar-container .level-2 .checkpoint.active svg {
  filter: drop-shadow(0 0 5px #4eab72);
}

.achievement-bar-container .level-2 .star-path-active {
  stroke: #10b981;
}

@keyframes levelTransition {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.achievement-bar-container .level-2 {
  animation: levelTransition 0.5s ease forwards;
}

/* Level 2 için parıltı efektleri */
.achievement-bar-container .level-2 .checkpoint.active::after {
  background: radial-gradient(
    circle,
    rgba(16, 185, 129, 0.2) 0%,
    rgba(5, 150, 105, 0.1) 30%,
    transparent 70%
  );
}

@keyframes starGlowLevel2 {
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 5px #10b981);
  }
  50% {
    transform: scale(1.2);
    filter: drop-shadow(0 0 10px #10b981) brightness(1.2);
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 5px #10b981);
  }
}

.achievement-bar-container .level-2 .checkpoint.active svg {
  animation: starGlowLevel2 1.5s ease-in-out infinite;
}

/* Mevcut stiller aynı kalacak */

.achievement-bar-container {
  width: 100%;
  padding: 60px 0;
  position: relative;
  padding-right: 1em;
}

.achievement-bar-container .achievement-markers {
  width: 100%;
  position: absolute;
  height: 110px;
  top: 0;
}

.achievement-bar-container .marker {
  position: absolute;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: 500;
  color: #94959b;
  display: flex; /* Ekleyin */
  flex-direction: column; /* Ekleyin */
  align-items: center; /* Ekleyin */
  text-align: center; /* Ekleyin */
}

.achievement-bar-container .marker:nth-child(odd) {
  top: 0;
}

.achievement-bar-container .marker:nth-child(even) {
  bottom: -20px;
}

.achievement-bar-container .achievement-bar-wrapper {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #e5e7eb;
  border-radius: 10px;
  overflow: visible;
}

.achievement-bar-container .achievement-fill {
  position: relative;
  height: 100%;
  background: linear-gradient(90deg, #12445a, #3792b8, #4eab72);
  border-radius: 10px;
  z-index: 1;
}

.achievement-bar-container .checkpoints {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.achievement-bar-container .checkpoint {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.achievement-bar-container .checkpoint svg {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
}

.achievement-bar-container .checkpoint.active svg {
  animation: starGlow 1.5s ease-in-out infinite;
  filter: drop-shadow(0 0 5px #3792b8);
}

@keyframes starGlow {
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 5px #10b981);
  }
  50% {
    transform: scale(1.2);
    filter: drop-shadow(0 0 10px #10b981) brightness(1.2);
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 5px #10b981);
  }
}

.checkpoints .checkpoint:nth-child(1) {
  left: 10%;
}
.checkpoints .checkpoint:nth-child(2) {
  left: 25%;
}
.checkpoints .checkpoint:nth-child(3) {
  left: 50%;
}
.checkpoints .checkpoint:nth-child(4) {
  left: 100%;
}

.checkpoint.active::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    circle,
    rgba(16, 185, 129, 0.2) 0%,
    rgba(5, 150, 105, 0.1) 30%,
    transparent 70%
  );
  pointer-events: none;
  z-index: 1;
}

.star-path-active {
  stroke-dasharray: 100;
  stroke-dashoffset: 0;
  transition: all 0.3s ease;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.achievement-bar-container {
  position: absolute;
  width: 100%;
  transition: all 0.5s ease;
}

.achievement-bar-container.fade-out {
  animation: fadeOutUp 0.8s ease forwards;
}

.achievement-bar-container.level-2 {
  animation: fadeInUp 0.8s ease forwards;
}

.achievement-bar-container .achievement-icon {
  transition: all 0.3s ease;
  opacity: 0.5;
}

.achievement-bar-container .achievement-icon.active {
  opacity: 1;
  filter: drop-shadow(0 0 5px #3792b8);
}

.achievement-bar-container .level-2 .achievement-icon.active {
  filter: drop-shadow(0 0 5px #4eab72);
}

.achievement-bar-container .marker-value {
  color: #1a1a1a;
}

.achievement-bar-container .marker-label {
  color: #94959b;
  font-weight: 400;
}
/* Mevcut media query'yi güncelleyin veya yenisini ekleyin */
@media (max-width: 768px) {
  .achievement-bars-container {
    padding: 0 16px;
    margin-top: 24px;
  }

  .achievement-bar-container {
    padding: 32px 0;
    position: relative;
  }

  .achievement-bar-container .achievement-markers {
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;
    position: absolute;
    top: -30px;
    padding: 0 12px;
  }

  .achievement-bar-container .marker {
    position: relative;
    transform: none;
    left: auto !important;
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .achievement-bar-container .achievement-icon {
    width: 35px !important;
    height: 35px !important;
  }

  .achievement-bar-container .checkpoints {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    position: absolute;
    width: 100%;
    top: 50%;
  }

  .achievement-bar-container .checkpoint {
    position: relative;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
  }

  .achievement-bar-container .checkpoints .checkpoint:nth-child(1) {
    left: 0;
  }
  .achievement-bar-container .checkpoints .checkpoint:nth-child(2) {
    left: 33.33%;
  }
  .achievement-bar-container .checkpoints .checkpoint:nth-child(3) {
    left: 66.66%;
  }
  .achievement-bar-container .checkpoints .checkpoint:nth-child(4) {
    left: 100%;
    transform: translate(-100%, -50%);
  }
}
