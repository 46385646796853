.account-manager-box {
  position: relative;
}

.account-manager-box .sized-box {
  height: 5px;
  width: 100%;
}

.account-manager-box .accordion-part {
  /* display: none; */
  max-height: 0;
  position: absolute;
  transition: 0.3s;
  right: 0;
  z-index: 10;
  border-radius: 5px;
  overflow: visible;
  z-index: 9999;
  min-width: 250px;
  max-width: 600px;
}

.account-manager-box .accordion-part.open {
  /* display: inline-block; */
  max-height: 200px;
}

.account-manager-box .accordion-part .content {
  max-height: 0;
  overflow: hidden;
  padding: 0.5em 1.5em 1em;
  border-radius: 5px;
  font-size: 0.85em;

  color: var(--text-gray);
}

.account-manager-box .accordion-part.open .content {
  max-height: 100%;
  box-shadow: 0 1px 5px 0 #01394f;
  background: linear-gradient(to right top, #01394f, 80%, #016750);
  color: var(--main-white);
  margin-top: 12px;
}

.account-manager-box .accordion-part.open h3 {
  margin: 0.5em 0 1em;
  color: var(--main-white);
}

.account-manager-box .accordion-part .content .main-row {
  padding: 6px 10px;
  border-radius: 8px;
  gap: 10px;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 1em;
}

.account-manager-box .accordion-part .content .main-row a {
  color: rgb(178, 218, 253);
}

.account-manager-box .accordion-part.open .content img {
  border-radius: 100%;
  max-width: 50px;
}

.account-manager-box .accordion-part .content .actions-row .button {
  flex: 1 1 auto;
  padding: 8px 5px;
  background-color: var(--transparent-black);
}

.account-manager-box .accordion-part .content .actions-row .button:hover {
  background-color: var(--transparent-black-hover);
}

.account-manager-box .accordion-part .content .buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
}

.get-support-icon {
  display: block;
  margin-left: auto;
}

.get-support-icon {
  display: none;
}

@media (max-width: 768px) {
  .account-manager-box {}

  .get-support-icon {
    display: block;
    margin-left: auto;
  }

  .account-manager-box .accordion-part {
    /* display: none; */
    max-height: 0;
    position: relative;
    transition: 0s;
    right: 0;
    z-index: 10;
    border-radius: 5px;
    overflow: visible;
    z-index: 0;
    width: 80vw;
    min-width: none;
    max-width: none;

    margin: auto;
  }

  .account-manager-box .accordion-part .content .main-row {
    padding: 3px 5px;
  }

  .account-manager-box .accordion-part .content .actions-row .button {
    flex: 1 1 auto;
    padding: 12px 5px;
    background-color: var(--transparent-black);
  }

  .account-manager-box .accordion-part .content .buttons {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
  }

  .account-manager-box .accordion-part.open .content {
    margin-bottom: 10px;
    border-radius: 12px;
    box-shadow: 0 1px 5px 0 var(--transparent-black);
    margin-top: 5px;
    background: none;
    color: var(--main-white);
  }

  .account-manager-box .accordion-part.open {
    /* display: inline-block; */
    max-height: 250px;
    min-width: 100%;
  }

  .account-manager-box .accordion-part.open h3 {
    color: var(--second-green);
  }
}