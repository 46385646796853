.stroke-input-root .stroke-input:-webkit-autofill,
.stroke-input-root .stroke-input:-webkit-autofill:hover,
.stroke-input-root .stroke-input:-webkit-autofill:focus,
.stroke-input-root .stroke-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: white !important;
}

/* Firefox için */
.stroke-input-root .stroke-input:autofill,
.stroke-input-root .stroke-input:autofill:hover,
.stroke-input-root .stroke-input:autofill:focus,
.stroke-input-root .stroke-input:autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: white !important;
}
.password-input-wrapper {
  display: flex !important;
  align-items: center !important;
}

.password-toggle-btn {
  position: absolute !important;
  right: 10px !important;
  font-size: 1.2em !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  padding: 0 !important;
  color: #666 !important;
}

.password-toggle-btn:focus {
  outline: none !important;
}

.stroke-input-root .stroke-input[type='password'] {
  padding-right: 40px !important;
}
.stroke-input-root {
  min-height: 4.5em !important;
}
.stroke-input-root .form-control {
  margin: 1em 0 !important;
  position: relative !important;
}

.stroke-input-root .form-control label,
.stroke-input-root .form-control .stroke-input,
.stroke-input-root .form-control .stroke-textarea {
  display: block !important;
}

.stroke-input-root .stroke-input-label {
  margin-bottom: 0.5em !important;
  font-size: 1em !important;
  position: absolute !important;
  top: -0.9em !important;
  left: 1em !important;
  background: linear-gradient(to bottom, transparent 60%, white 40%) !important;
  border-radius: 1em !important;
  padding: 0.1em 0.5em !important;
  transition: all 0.3s !important;
  color: #050e13 !important;
  font-weight: 300 !important;
}

.stroke-input-root .required-asterisk {
  color: #e54343 !important;
  vertical-align: text-bottom !important;
  margin-left: 0.25em !important;
}

.stroke-input-root .stroke-input,
.stroke-input-root .stroke-textarea,
.stroke-input-root .stroke-select {
  width: 100% !important;
  height: 3em !important;
  border: 1.63px solid #ccc !important;
  outline: none !important;
  border-radius: 0.8em !important;
  box-shadow: none !important;
  padding: 0.5em 1em !important;
  font: inherit !important;
  background: #fff !important;
  transition: all 0.3s !important;
}

.stroke-input-root .stroke-textarea {
  resize: none !important;
  height: auto !important;
  min-height: 6em !important;
  width: calc(100% - 2.5em) !important;
}

.stroke-input-root .stroke-input:focus,
.stroke-input-root .stroke-textarea:focus,
.stroke-input-root .stroke-select:focus {
  outline: none !important;
  background: #fff !important;
  border-color: #abb1b8 !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 10px 0px !important;
}

.stroke-input-root .stroke-input::placeholder,
.stroke-input-root .stroke-textarea::placeholder {
  color: #999 !important;
  font-style: italic !important;
}

.stroke-input-root .form-control--invalid .stroke-input-label,
.stroke-input-root .form-control--invalid .error-text {
  color: #e54343 !important;
}

.stroke-input-root .form-control--invalid .stroke-input,
.stroke-input-root .form-control--invalid .stroke-textarea,
.stroke-input-root .form-control--invalid .stroke-select {
  border-color: #e54343 !important;
  background: #fff !important;
  box-shadow: none !important;
}

.stroke-input-root .error-text-container {
  height: 1.2em !important;
  margin-top: 0.25em !important;
}

.stroke-input-root .error-text {
  font-size: 0.8em !important;
  color: #333 !important;
  height: 1.2em !important;
  line-height: 1.2em !important;
}

.stroke-input-root .error-text.error {
  color: #e54343 !important;
}

.stroke-input-root .error-text.success {
  display: none !important;
}

.stroke-input-root .error-placeholder {
  height: 1.2em !important;
  margin-top: 0.25em !important;
}

.stroke-input-root .form-control--invalid .error-text {
  color: #e54343 !important;
}

.stroke-input-root .select-cont {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  border-radius: 0.5em !important;
  box-shadow: none !important;
  padding: 0 !important;
  cursor: pointer !important;
}

.stroke-input-root .stroke-select {
  width: calc(100% - 0em) !important;
  margin: 0 auto !important;
  box-shadow: none !important;
  cursor: pointer !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  padding-right: 2em !important;
  width: -webkit-fill-available !important;
}

.stroke-input-root .select-cont::after {
  content: '\25BE' !important;
  position: absolute !important;
  right: 0.75em !important;
  transform: translateY(60%) !important;
  pointer-events: none !important;
}

.stroke-input-root .stroke-input.standart,
.stroke-input-root .stroke-textarea.standart,
.stroke-input-root .stroke-select.standart {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: 0 0.0625em 0.25em 0 #ccc !important;
  border-radius: 0.3125em !important;
  font-size: 0.9em !important;
  padding: 0.3125em 0.625em !important;
}

.stroke-input-root .stroke-input.standart:focus,
.stroke-input-root .stroke-textarea.standart:focus,
.stroke-input-root .stroke-select.standart:focus {
  box-shadow: 0 0.125em 0.375em 0 #ccc !important;
}

/* Left Title Styles */
.stroke-input-root.left-title {
  display: flex;
  align-items: flex-start;
  gap: 0.75em !important;
  width: 100% !important;
  min-height: auto !important;
}

.stroke-input-root.left-title .form-control {
  display: flex;
  gap: 0.75em !important;
  margin: 0 !important;
  width: 100% !important;
}

.stroke-input-root.left-title .stroke-input-label {
  position: relative !important;
  top: 0.75em !important;
  left: 0 !important;
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1em !important;
  min-width: 7.5em !important;
  flex-shrink: 0 !important;
  border-radius: 0 !important;
  font-weight: normal !important;
}

.stroke-input-root.left-title .input-container {
  display: flex !important;
  flex-direction: column !important;
  flex: 1 !important;
  gap: 0.25em !important;
}

.stroke-input-root.left-title .error-text,
.stroke-input-root.left-title .error-placeholder {
  height: 1.25em !important;
  margin: 0 !important;
  font-size: 0.875em !important;
}
.stroke-input-root.left-title .form-control {
  margin-bottom: 0.5em !important;
}

.stroke-input-root.left-title .form-control.no-error-line {
  margin-bottom: 0 !important;
}

@media (max-width: 468px) {
  .stroke-input-root.left-title {
    display: block !important;
  }

  .stroke-input-root.left-title .form-control {
    display: block !important;
    margin: 1em 0 !important;
  }

  .stroke-input-root.left-title .stroke-input-label {
    position: absolute !important;
    top: -0.9em !important;
    left: 1em !important;
    min-width: auto !important;
    background: linear-gradient(
      to bottom,
      transparent 60%,
      white 40%
    ) !important;
    border-radius: 1em !important;
    padding: 0.1em 0.5em !important;
    z-index: 1 !important;
  }

  .stroke-input-root.left-title .input-container {
    width: 100% !important;
  }

  .stroke-input-root.left-title .daily-budget-wrapper {
    margin-top: 0 !important;
    padding-top: 0.5em !important;
  }

  .stroke-input-root.left-title .daily-budget-wrapper .stroke-input {
    margin-top: 0 !important;
  }
}

.stroke-input-root .currency-symbol {
  position: absolute !important;
  left: 1em !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: #666 !important;
  z-index: 1 !important;
  pointer-events: none !important;
  line-height: normal !important;
}

.stroke-input-root .stroke-input.with-currency {
  padding-left: 2.5em !important;
}

/* Daily Budget input için özel container stili */
.stroke-input-root .daily-budget-wrapper {
  position: relative !important;
  width: 100% !important;
}

.countries-select {
  position: relative;
  width: 100%;
}

.selected-countries {
  cursor: pointer;
  padding: 10px;

  border-radius: 4px;
}

.countries-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
}

.countries-dropdown input {
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #ccc;
}

.country-item {
  padding: 8px;
  cursor: pointer;
}

.country-item:hover {
  background: #f5f5f5;
}

.country-item.selected {
  background: #e0e0e0;
}

/* Age input styling */
.age-input-wrapper {
  display: flex !important;
  align-items: center !important;
  gap: 0.75em !important;
  width: 100% !important;
}

.age-input-wrapper input {
  flex: 1 !important;
  min-width: 0 !important;
}

.age-input-wrapper .dash {
  margin: 0 0.5em !important;
  font-size: 1.3em !important;
  color: #666 !important;
}

/* Mobil görünüm için özel düzenleme */
@media (max-width: 468px) {
  .stroke-input-root.left-title .daily-budget-wrapper {
    padding-top: 0em !important;
  }
  .stroke-input-root .stroke-textarea {
    width: 100% !important;
  }
  .stroke-input-root .stroke-input-label {
    font-size: 0.9em !important;
  }

  .stroke-input-root .error-text {
    font-size: 0.7em !important;
  }
}
