.opening-text-first {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 85%;
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  gap: 3em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .text {
    font-size: 2.5em;
    color: #33383d;
  }
  .images-row {
    display: flex;
    flex-direction: column;

    width: 100%;

    .images {
      display: flex;
      flex-direction: row;
      width: 100%;
      position: relative;
      align-items: center;
      justify-content: center;
      filter: brightness(0.8);
    }
    .images img {
      width: 110%;
    }
    .image-1 {
      transform: translateX(150%) translateY(30%) scale(0.5);
      /* opacity: 0.5; */
    }
    .image-2 {
      /* transform: translateX(30%) translateY(15%); */
      transform: scale(0.5);
      /* opacity: 0.5; */
    }

    .image-3 {
      transform: translateX(-10%) translateY(-10%);
    }
    .image-4 {
      transform: translateX(-100%) translateY(45%);
    }
    .image-5 {
      transform: translateX(-300%) translateY(-50%);
    }

    .white-big-text {
      position: absolute;
      font-size: 5em;
      font-weight: 600;
      transform: translateX(80%) translateY(90%);
      color: var(--main-white);
      text-align: center;
      line-height: 0.6em;
      opacity: 0;
    }
    .white-big-text span {
      font-size: 0.4em;
      margin-top: -5em;
    }
    @media (max-width: 1400px) {
      .white-big-text {
        font-size: 5em;
      }
    }

    @media (max-width: 1000px) {
      .images img {
        width: 150%;
      }
      .white-big-text {
        font-size: 5em;
        transform: translateX(80%) translateY(80%);
      }
      .white-big-text span {
        font-size: 0.3em;
        margin-top: -3em;
      }
    }
  }
}
