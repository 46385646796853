/* Log In - Start */

.login-main-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background: var(--main-white);
  margin: 0 auto;
  flex-direction: row;
  gap: 10%;
}

.login-cont {
  max-width: 450px;
  width: 90%;
  padding: 5em 0;
  margin: 0 auto;
}

.login-main-cont .login-cont {
  margin: 0em;
}

.login-cont .logo {
  max-width: 230px;
  margin: 1em auto;
}

.login-cont h1 {
  font-weight: 500;
  color: var(--text-gray);
  text-align: center;
}

.login-cont form {
  width: 100%;
  max-width: 450px;
  margin-top: 2em;
}

.login-cont form > div:nth-child(2) {
  margin: 0;
}

.login-cont form .input-cont {
  width: 100%;
  height: 3em;
  border: none;
  outline: none;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px 0 #ccc;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;

  padding: 0 1em;
}

.login-cont form .input-cont:nth-child(2) {
  margin: 0;
}

.login-cont form .input-cont .icon {
  color: var(--text-blue);
  font-size: 1.1em;
  min-width: 5%;
}

.login-cont form input {
  border: none;
  outline: none;
  /* padding: 0.2em 0; */
  flex: 1 1 100%;
  height: 3em;
}

.login-cont form .input-cont select {
  height: 3em;
  border: none;
  outline: none;
  padding: 0.2em 0;
  flex: 1 1 80%;
  width: 80%;
}

.login-cont form select {
  height: 2.5em;
}

.login-cont .error-text {
  color: rgb(209, 3, 3);
  font-size: 0.8em;
  margin: 0.5em auto;
}

.login-cont .button-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-cont .link-button {
  margin: 1em auto;
  height: 2em;
}

.login-cont form > div.cont {
  margin-top: 0;
}

.login-cont .action-button {
  width: 100%;
  height: 2.5em;
  border: none;
  border-radius: 0.5em;
  font-size: 1.1em;
  padding: 0 1em;
  margin: 0 auto;
}

.login-cont .or-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin: 0.5em 0;
}

.login-cont .or-row .left-line {
  height: 1px;
  flex: 1 1 50%;
  background: linear-gradient(90deg, var(--main-white), #373a3c);
}

.login-cont .or-row .text {
  margin: 0 1em;
}

.login-cont .or-row .right-line {
  height: 1px;
  flex: 1 1 50%;
  background: linear-gradient(270deg, var(--main-white), #373a3c);
}

.login-cont .log-in-with-google,
.login-cont .log-in-with-shopify {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  color: var(--text-gray);
  background: var(--main-white);
  font-size: 1em;
  margin: 1em auto;
  width: 100%;
}

.login-cont .log-in-with-google {
  margin-top: 0;
}

.login-cont .log-in-with-google:hover,
.login-cont .log-in-with-shopify:hover {
  background: rgb(245, 245, 245);
}

.login-cont .log-in-with-google img {
  width: 1.5em;
}

.login-cont .log-in-with-shopify img {
  width: 4.5em;
}

.login-cont .no-account-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.7em auto 1em !important;
}

.login-cont .no-account-row .link-button {
  margin: 0;
  font-size: 1em;
  height: auto;
  margin-left: 0.5em;
}

.stored-accounts {
  display: flex;
  flex-direction: column;
  gap: 1em;
  /* margin:0 auto; */
  align-items: flex-start;
  justify-content: center;
  max-width: 350px;
  color: var(--light-gray);
}

.stored-accounts .row {
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.stored-accounts .logout-icon {
  cursor: pointer;
  color: var(--disabled-gray);
}

.stored-accounts h2 {
  font-size: 1.2em;
  font-weight: 600;
}

.accounts {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  font-size: 0.9em;
}

.accounts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  width: 100%;
  max-height: calc(90vh - 2em);
  overflow: scroll;
}

.stored-account {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 0.5em;
  background: var(--background-gray);
  /* box-shadow: 0 2px 5px 0 #ccc; */
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.stored-account .account-box {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5em;
}

.stored-account:hover {
  background: #dadde385;
  cursor: pointer;
}

.stored-account .remove-user-row {
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  height: 1em;
}

.stored-account .remove-user-row .icon {
  margin-top: 0.5em;
  margin-right: 0.5em;
  color: var(--disabled-gray);
}

.stored-account .remove-user-row .icon:hover {
  color: var(--text-gray);
  cursor: pointer;
}

.stored-account .account-name {
  font-size: 3em;
  font-weight: 500;
  opacity: 0.7;
  background: var(--main-green-gradient);
  /* background-clip: text; */
  -webkit-background-clip: text;
  color: transparent;
}

.stored-account .email-bar {
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30%;
  background-color: #7c8284a1;
  color: var(--background-gray);
}

.modal-container .login-with-shopify .img-cont {
  width: 90%;
  max-width: 100px;
  margin: 0 auto;
}

.modal-container .login-with-shopify img {
  width: 100%;
}

.modal-container .login-with-shopify .action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 0.5em 2em !important;
}

.modal-container .login-with-shopify .action-button .icon {
  font-size: 1.3em;
}

.modal-container .login-with-shopify h5 {
  margin: 1em auto 0.5em;
  font-weight: 500;
  font-size: 1.1em;
}

.modal-container .login-with-shopify .input-cont {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 0.5em;
  box-shadow: 0 0px 3px 0px #e5e5e5;
  padding: 0.5em 1em;
  margin: 0 auto 1em;
  font-size: 1em;
  color: rgb(94 94 94);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-container .login-with-shopify .input-cont input {
  border: none;
  outline: none;
  font-size: 1em;
  width: 50%;
  flex: 1 1 auto;
}

.modal-container .login-with-shopify .input-cont span {
  flex: 0 1 auto;
}

.modal-container .custom-subscription .amount-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  /* border: 1px solid gray;
  border-radius: 5px; */
  /* padding: 5px 1em; */
}

.modal-container .custom-subscription .amount-container input,
.modal-container .custom-subscription .amount-container select {
  border: 1px solid gray;
  outline: none;
  padding: 5px 1em;
  border-radius: 5px;
}

.modal-container .custom-subscription input::-webkit-outer-spin-button,
.modal-container .custom-subscription input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-container .custom-subscription input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

/* Log In - End */

/* Sign Up - Start */

.full-container.sign-up {
  display: flex;
}

.sign-up-info {
  flex: 1;
  position: relative;
  max-width: 85vh;
  align-self: center;
}

.sign-up-info img {
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.sign-up-info .background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: auto;
}

.sign-up-cont {
  flex: 1;
  overflow: scroll;
  margin: 0;
  width: 100%;
  max-width: unset;
  padding: 0;
  align-content: center;
}

.sign-up-cont form .input-cont {
  height: 3em;
  gap: 1em;
  padding: 0 1em;
}

.sign-up-cont form input {
  height: 2.5em;
}

.sign-up-cont form .input-cont select {
  height: 2.5em;
  padding: 0.2em 0;
}

.sign-up-cont form select {
  height: 2.5em;
}

.sign-up-cont .action-button {
  height: 3em;
  font-size: 1em;
  padding: 0 1em;
  background: var(--main-green-gradient);
  font-weight: 600;
}

.sign-up-cont .action-button:hover {
  background: var(--main-green-gradient-hover);
}

.sign-up-form-cont {
  max-width: 600px;
  width: 90%;
  margin: 2em auto;
}

.sign-up-form-cont .intro-headline-container {
  height: 10em;
}

.sign-up-cont h1 {
  margin-bottom: 1em;
  text-align: left;
}

.sign-up-cont .small-text {
  margin: 0.5em auto 1em !important;
  color: var(--text-gray);
  font-size: 0.9em;
}

.mobile-logo-cont {
  display: none;
}

.sign-up-cont .no-account-row {
  font-size: 0.8em;
  margin-top: 10px;
}

@keyframes bounce {
  0%,
  100% {
    transform: translate(-50%, -50%) translateY(0);
  }

  50% {
    transform: translate(-50%, -50%) translateY(-20px);
  }
}

@media (max-width: 1200px) {
  .sign-up-info {
    display: none;
  }

  .sign-up-form-cont {
    max-width: 500px;
    width: 90%;
    margin: 2em auto;
  }
  .stored-account .account-box {
    width: 120px;
    height: 120px;
    padding-top: 1em;
  }
}

@media (max-width: 1000px) {
  .login-main-cont {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    overflow: scroll;
  }

  .login-main-cont .stored-accounts {
    width: 90%;
  }
}

@media (max-width: 468px) {
  .sign-up-form-cont .intro-headline-container {
    height: 8em;
  }
}

/* Sign Up - End */
