.ad-health-checkup-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* max-width: 1400px; */
  margin: 0 auto;
  position: relative;
  z-index: 0;
}

.opening-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  gap: 3em;
  left: 05%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .text {
    font-size: 2.5em;
    color: #33383d;
  }
}
.report-header .left-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
  width: 50%;
}
.report-header .right-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  width: 50%;
}

.report-header {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: 100vh;
  gap: 3em;
  /* opacity: 0; */
  margin: 0 auto;

  .logo img {
    width: 40%;
  }

  .big-text span {
    color: var(--second-green);
  }
  .line {
    position: absolute;
    z-index: -1;
    max-width: 100%;
  }

  .line-1 {
    top: -50%;
    right: -20%;
    width: 100%;
  }
  .line-2 {
    bottom: -50%;
    right: -20%;
    width: 100%;
  }

  .gradient-line-1,
  .gradient-line-2 {
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
  }

  .gradient-line-1 {
    animation: dashLine 8s linear forwards;
  }

  .gradient-line-2 {
    animation: dashLineReverse 8s linear forwards;
  }

  .rectangle-1 img {
    width: 100%;
  }
  .rectangle-1 {
    z-index: 1;
  }
  .rectangle-2 {
    position: absolute;
    transform: translate(90%, 90%);
    z-index: 2;
  }
  .rectangle-3 {
    position: absolute;
    transform: translate(90%, -90%);
    z-index: 2;
  }
  .rectangle-4 {
    position: absolute;
    transform: translate(-90%, 90%);
    z-index: 2;
  }
  .rectangle-5 {
    position: absolute;
    transform: translate(-140%, -140%);
    z-index: 0;
  }
  .arrow img {
    width: 3em;
  }
  .arrow:hover {
    cursor: pointer;
  }
}
