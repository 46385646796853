:root {
  --transition-timing: 300ms;
  --transition-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.new-navbar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: linear-gradient(to top, #011520 0%, #13364a 100%);
  transition: width var(--transition-timing) cubic-bezier(0.4, 0, 0.2, 1);
  overflow: visible;
  z-index: 8000;
  color: white;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  width: var(--lg-navbar-width);
  font-size: 1em;
  border-radius: 0 1.5em 1.5em 0;
}

.navbar-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  z-index: 80;
}
.new-navbar.collapsed .navbar-toggle {
  display: none;
}

.lock-button {
  width: 25px;
  height: 50px;
  background: rgba(137, 137, 137, 0.265);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  padding: 7px;
  border-radius: 0 1.5em 0px 1.5em;
  border-top: 1px solid rgba(137, 137, 137, 0.265);
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.272);
}

.lock-button.locked svg {
  color: rgba(
    255,
    255,
    255,
    0.821
  ); /* Navbar'ın arka plan rengi, gerekirse ayarlayın */
}
.lock-button:hover {
  background-color: var(--main-green);
}

.lock-button svg {
  color: rgba(177, 177, 177, 0.697);
  font-size: 0.9em;
}
.new-navbar.collapsed {
  width: var(--locked-navbar-width);
}

.new-navbar.collapsed .nav-item-content {
  margin: 0;
}

.navbar-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1em 0px 1em 0;

  overflow: hidden;
}

.nav-item-left {
  padding: 0 0 0 0.8em;
  width: 100%;
  background: none;
  border-radius: 0.5em;
}
@keyframes pulse {
  0% {
    color: rgb(255, 255, 255);
  }
  50% {
    color: rgb(255, 0, 0);
  }
  100% {
    color: rgb(255, 255, 255);
  }
}

.nav-item-badge {
  margin-right: 1em;
  font-size: 1.2em;

  text-decoration: none;
  animation: pulse 2s infinite;
}

.new-navbar.collapsed .nav-item-badge {
  display: none;
}
.new-navbar.collapsed .nav-item-left {
  background: none;
  padding: 0px;
  justify-content: center;
}
.nav-item * {
  text-decoration: none !important;
}
.new-navbar * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navbar-logo {
  position: relative;
  text-align: center;
  height: 60px;
  overflow: hidden;
  padding-bottom: 60px;
  margin-right: 10px;
  cursor: pointer;
}
.new-navbar.collapsed .navbar-logo {
  margin-right: 0;
}
.navbar-logo img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.navbar-logo .full-logo {
  width: 80%;
  max-width: 160px;
  opacity: 1;
}

.navbar-logo .e-logo {
  width: 60px;
  height: 60px;
  opacity: 0;
}

.new-navbar.collapsed .navbar-logo .full-logo {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5);
}

.new-navbar.collapsed .navbar-logo .e-logo {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.1);
}

.summary-report-btn {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 12px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
  width: 90%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  transition: all 0.3s ease;
}

.navbar-menu {
  flex-grow: 1;
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
}

.nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.3s ease;
  text-decoration: none;
}

.nav-item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.nav-item.disabled .nav-item-content {
  pointer-events: auto;
  cursor: not-allowed;
}

.new-navbar.collapsed .nav-item.disabled .nav-item-content {
  margin: 0.5em 1em;
}

.nav-item-content.pulsating {
  animation: nav-pulse 2s infinite;
}

@keyframes nav-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(139, 196, 93, 0.7);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(139, 196, 93, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(139, 196, 93, 0);
  }
}
.new-navbar.collapsed .nav-item.active .nav-item-content {
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.7);
  background: linear-gradient(
    to bottom,
    rgba(76, 175, 80, 0.2),
    rgba(76, 175, 80, 0.1)
  );
}

.new-navbar.collapsed .nav-item-content svg {
  color: rgba(180, 180, 180, 0.749);
}

.new-navbar.collapsed .nav-item-content:hover svg {
  color: rgb(255, 255, 255);
}

.new-navbar.collapsed .nav-item.active .nav-item-content svg {
  color: white;
}

.nav-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.9em;
  height: 3.5em;
  border-radius: 12px;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-right: 1em;
  margin-left: 1em;
  position: relative;
}

.nav-item.active .nav-item-content::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, var(--main-green), #085a9c);
  border-radius: 14px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.new-navbar:not(.collapsed) .nav-item.active .nav-item-content::before {
  opacity: 1;
}

.new-navbar:not(.collapsed)
  .nav-item:not(.full-funnel-campaigns).active
  .nav-item-content {
  background-color: #13364a;
}

.nav-item-left {
  display: flex;
  align-items: center;
  color: white;
}

.nav-item-right svg {
  transition: transform 0.3s ease;
  margin-left: 8px;
  scale: 0.8;
  min-height: 25px;
}

.nav-item svg {
  font-size: 1.2em;
  min-width: 1.2em;
  margin-right: 13px;
}

.nav-item span {
  white-space: nowrap;
  opacity: 1;
  transition:
    opacity 0.3s ease,
    width 0.3s ease;
  font-weight: 400;
}
.basic-user .full-funnel-campaigns .sub-item-group {
  padding: 5px 0px;
}
.basic-user .full-funnel-campaigns .campaign-item {
  padding-left: 10px;
}
.basic-user .full-funnel-campaigns .sub-items {
  padding: 0 1em;
}
.sub-items {
  margin-left: 0px;
  font-size: 13px;

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding-right: 15px;
}

.sub-items.open {
  max-height: 500px;
}

.sub-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  color: #ccc;
}

.sub-item-count {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.8em;
}

.create-campaign-btn {
  background-color: #2c3e50;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
}

.navbar-footer {
  margin-top: auto;
  padding-bottom: 10px;
  text-align: -webkit-center;
  z-index: 80;
}

.toggle-circle {
  width: 25px;
  height: 40px;
  background-color: #4caf4fe8;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.navbar.collapsed .navbar-toggle .toggle-circle svg {
  transform: rotate(180deg);
}

.navbar.expanded .navbar-toggle .toggle-circle svg {
  transform: rotate(0deg);
}

.chevron-expanded,
.chevron-collapsed {
  color: white;
  font-size: 18px;
}

.new-navbar.collapsed .sub-items,
.new-navbar.collapsed .navbar-footer span {
  display: none;
}

.new-navbar.collapsed .nav-item span {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

.new-navbar.collapsed .nav-item {
  align-items: center;
  padding: 8px;
}

.new-navbar.collapsed .nav-item-content {
  justify-content: center;
  width: 3.2em;
  height: 3.2em;

  padding: 0px;
  border-radius: 0.5em;
}

.new-navbar.collapsed .nav-item svg {
  margin-right: 0;
}

.new-navbar.collapsed .navbar-content {
  padding: 1.2em 0;
}

.new-navbar.collapsed .nav-item-content:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease;
}

.summary-report-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.create-campaign-btn:hover {
  background-color: #34495e;
}

.navbar-content::-webkit-scrollbar {
  width: 5px;
}

.navbar-content::-webkit-scrollbar-track {
  background: transparent;
}

.navbar-content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.navbar-content::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.5);
  transition: background-color 0.3s ease;
}

.nav-item.active .nav-item-content,
.nav-item-content:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease;
}

.support-text {
  font-size: 14px;
  color: #ccc;
  margin-top: 10px;
}

.nav-item .sub-items {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  scrollbar-width: none;
}
.nav-item.open {
  background-color: #13364a;
  border-radius: 12px;
  margin: 0.5em 1em;
}

.nav-item.open .sub-items {
  max-height: 650px;
  overflow-y: auto;
}

.nav-item .sub-item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: var(--main-white);
  text-decoration: none;
  cursor: pointer;
}

.nav-item .sub-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-item .sub-item svg {
  margin-right: 10px;
}

.nav-item-right svg {
  transition: transform 0.3s ease;
}

.navbar-footer .navbar-line {
  width: 80%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-footer .nav-item {
  margin-bottom: 5px;
}

.navbar-footer .support-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.new-navbar.collapsed .navbar-footer .nav-item-content span {
  display: none;
}

.navbar-footer .profile-menu {
  position: relative;
}

.navbar-footer.profile-menu.expanded {
  width: 100%;
}

.navbar-footer .profile-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background: none;
  border: none;
  width: 100%;
}

.navbar-footer .profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.navbar-footer .profile-letter {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #4caf50;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 10px;
  margin-right: 16px;
}

.new-navbar.collapsed .navbar-footer .profile-letter {
  margin-right: 0;
}

.navbar-footer .user-name {
  flex-grow: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  font-weight: 600;
  text-transform: capitalize;
}
.navbar-footer .user-email {
  flex-grow: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9em;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 2px;
  text-transform: lowercase;
}
.navbar-footer .nav-item-content,
.navbar-footer .nav-item-content:hover {
  text-decoration: none;
}
.navbar-footer .nav-item a,
.navbar-footer .nav-item a:hover {
  text-decoration: none;
}

@media (max-width: 1470px), (max-height: 1030px) {
  .new-navbar {
    font-size: 0.8em;
  }
  .nav-item-content {
    height: 3em;
  }

  .nav-item-content {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .nav-item.open {
    max-height: 100%;
  }
  .nav-item.open .sub-items {
    max-height: 100%;
    overflow-y: auto;
  }
}

.nav-item:not(.full-funnel-campaigns) .nav-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.9em;

  border-radius: 12px;
  margin-bottom: 0.5em;
  margin-top: 0.5em;

  position: relative;
}

@media (max-width: 1470px), (max-height: 1030px) {
  .nav-item:not(.full-funnel-campaigns) .nav-item-content {
    height: 3em;
  }
  .basic-user .full-funnel-campaigns .campaign-item {
    padding-left: 10px;
  }
}

/* Hover state styles */

/* Lock button on AdHealthCheckup page */
.new-navbar.ahcu-mode .navbar-toggle {
  display: none;
}

/* Mobile hamburger menu */
.mobile-menu-button {
  display: none;
  position: fixed;
  top: 15px;
  left: 1rem;
  z-index: 9002;
  background: none;
  border: none;
  color: var(--text-gray);
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
}

.mobile-menu-button:hover {
  color: var(--main-green);
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
  }

  .new-navbar {
    display: none;
    position: fixed;
    width: var(--locked-navbar-width) !important;
    height: 100vh;
    left: 0;
    transition: all 0.3s ease;
    border-radius: 0;
  }

  .new-navbar.expanded,
  .new-navbar.mobile-open {
    display: block;
    width: 100vw !important;
    background: linear-gradient(to top, #011520 0%, #13364a 100%);
    z-index: 8000;
  }

  .new-navbar.collapsed {
    width: var(--locked-navbar-width) !important;
  }

  .new-navbar .navbar-content {
    padding-top: 4rem;
  }

  /* Hide lock button on mobile */
  .new-navbar .navbar-toggle {
    display: none;
  }

  /* Main content styles */
  .App.basic-user .main-cont {
    margin-left: 0 !important;
    width: 100% !important;
  }

  body.navbar-locked .basic-user .main-cont {
    margin-left: 0 !important;
    width: 100% !important;
  }

  /* Mobile navbar content styles */
  .new-navbar.mobile-open,
  .new-navbar.expanded {
    display: flex;
    flex-direction: column;
  }

  .new-navbar.mobile-open .navbar-content,
  .new-navbar.expanded .navbar-content {
    padding: 0;
    flex: 1;
    overflow-y: auto;
  }

  /* Navbar footer sticky */
  .new-navbar.mobile-open .navbar-footer,
  .new-navbar.expanded .navbar-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1rem;

    z-index: 9999;
  }

  /* Navbar menu styles */
  .new-navbar.mobile-open .navbar-menu,
  .new-navbar.expanded .navbar-menu {
    flex: 1;
    overflow-y: auto;
    min-height: 350px;
  }

  /* Logo düzenlemesi */
  .new-navbar.mobile-open .navbar-logo,
  .new-navbar.expanded .navbar-logo {
    margin: 0;
    position: sticky;
    top: 0;
    width: 100vw !important;
    left: 0;
    margin: 0 auto;

    z-index: 8000;
  }

  /* Hide scrollbar */
  .new-navbar.mobile-open .navbar-content::-webkit-scrollbar,
  .new-navbar.expanded .navbar-content::-webkit-scrollbar {
    display: none;
  }

  .new-navbar.mobile-open .navbar-content,
  .new-navbar.expanded .navbar-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  /* iOS navbar fix */
  @supports (-webkit-touch-callout: none) {
    .new-navbar.mobile-open .navbar-footer,
    .new-navbar.expanded .navbar-footer {
      padding-bottom: calc(1rem + env(safe-area-inset-bottom));
      margin-bottom: env(safe-area-inset-bottom);
    }
  }

  /* Navbar line'ların mobil görünümü */
  .new-navbar.collapsed .navbar-footer .navbar-line {
    display: none;
  }

  .new-navbar.mobile-open .navbar-footer .navbar-line,
  .new-navbar.expanded .navbar-footer .navbar-line {
    display: block;
  }

  .new-navbar.mobile-open .nav-item-content span,
  .new-navbar.expanded .nav-item-content span {
    display: inline-block !important;
    opacity: 1 !important;
    visibility: visible !important;
  }

  .new-navbar.mobile-open .nav-item-left,
  .new-navbar.expanded .nav-item-left {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
  }
  .navbar-profile-menu.open {
    border-radius: 1.5em;
  }
  /* Account manager card mobil stili */
  .navbar-account-manager-card {
    transition: all 0.3s ease;
    margin-top: 1rem;
  }

  /* Profile menu açıkken account manager card'ı göster */
  .new-navbar.mobile-open .navbar-account-manager-card {
    display: block;
    opacity: 1;
  }

  /* Profile menu kapalıyken account manager card'ı gizle */
  .new-navbar.collapsed .navbar-account-manager-card {
    display: none;
    opacity: 0;
  }
}

/* AHCU modu için geçiş ayarları */
.new-navbar.ahcu-mode {
  transition: all 0.3s ease;
}

.new-navbar.ahcu-mode.expanded {
  width: var(--lg-navbar-width);
  transition: all 0.3s ease;
}

.new-navbar.ahcu-mode.collapsed {
  width: var(--locked-navbar-width);
  transition: all 0.3s ease;
}

/* Ana içerik alanı geçişleri için */
.basic-user .main-cont {
  transition: all 0.3s ease !important;
}

.nav-item-content img {
  width: 1.4em;
  height: 1.4em;
  scale: 1.5;
  filter: brightness(0) invert(1);
  margin-right: 13px;
}
.new-navbar.collapsed .nav-item-content img {
  margin-right: 0;
}

.new-navbar.collapsed .nav-item.full-funnel-campaigns.disabled {
  margin-right: -0.5em;
}

/* Full funnel campaigns için özel stil */
.full-funnel-campaigns .nav-item-content img {
  width: 1.4em;
  height: 1.4em;
  filter: brightness(0) invert(1);
  scale: 1;
}

/* Collapsed durumunda ikonların renk stili */
.new-navbar.collapsed .nav-item-content img {
  filter: brightness(0) invert(0.6);
}

.new-navbar.collapsed .nav-item.active .nav-item-content img {
  filter: brightness(0) invert(1);
}

@media (max-width: 468px) {
  .nav-item-content img {
    margin-right: 8px;
  }
}

/* Facebook Ads menü öğesi için özel stiller */
.nav-item.full-funnel-campaigns .nav-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.9em;

  border-radius: 12px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  position: relative;
  transition: all var(--transition-timing) var(--transition-function);
}

/* Facebook Ads aktif olduğunda */
.nav-item.full-funnel-campaigns.active .nav-item-content {
  background: rgba(76, 175, 80, 0.1);

  border-radius: 12px;
}

/* Sidebar açıkken Facebook Ads butonu */
.nav-item.full-funnel-campaigns.sidebar-open .nav-item-content {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(to right, #011520 0%, #13364a 100%);
}
.nav-item.active.full-funnel-campaigns.sidebar-open .nav-item-content {
  border: 0px solid rgba(255, 255, 255, 0.2);
}

.navbar-container {
  transition: all var(--transition-timing) var(--transition-function);
}

/* Sidebar açıkken navbar'ın border-radius'unu kaldır */
.nav-item.full-funnel-campaigns.sidebar-open + .new-navbar,
.new-navbar:has(.nav-item.full-funnel-campaigns.sidebar-open) {
  border-radius: 0;
}

/* Sidebar açıkken collapsed navbar'ın border-radius'unu kaldır */
.new-navbar.collapsed:has(.nav-item.full-funnel-campaigns.sidebar-open) {
  border-radius: 0;
}

.sidebar-container {
  position: absolute;
  top: 0;
  height: 100vh;
  transition: left var(--transition-timing) cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 7000;
  left: var(--locked-navbar-width);
}

/* Navbar locked durumunda */
body.navbar-locked .sidebar-container {
  left: var(--lg-navbar-width);
}

/* Navbar hover durumunda */
.navbar-container.hovered:not(.locked) + .sidebar-container {
  left: var(--lg-navbar-width);
  z-index: 6999;
}

/* Navbar hover bittiğinde */
.navbar-container:not(.hovered):not(.locked) + .sidebar-container {
  left: var(--locked-navbar-width);
}

@media (max-width: 1470px), (max-height: 1030px) {
  .navbar-container:not(.hovered):not(.locked) + .sidebar-container {
    left: calc(var(--locked-navbar-width) - 1em);
  }
  .navbar-container.hovered:not(.locked) + .sidebar-container {
    left: calc(var(--lg-navbar-width) - 3.6em);
    z-index: 6999;
  }
  .new-navbar.collapsed .nav-item.full-funnel-campaigns.disabled {
    margin-right: -1em;
  }
}

/* Sidebar açıkken navbar'ın border-radius'unu kaldır */
.nav-item.full-funnel-campaigns.sidebar-open + .new-navbar,
.new-navbar:has(.nav-item.full-funnel-campaigns.sidebar-open) {
  border-radius: 0;
}

/* Sidebar açıkken lock-button */
.new-navbar:has(.nav-item.full-funnel-campaigns.sidebar-open) .lock-button {
  border-radius: 0 0em 0px 1.5em;
}
