.currentCreativeCard {
  flex: 1;
  border: 0.0625em solid #dfeaf2;
  background: #fff;
  border-radius: 0.75em;
  padding: 0.75em 1em;
  max-width: 31.25em;
}

.preview {
  width: 3.875em;
  height: 3.875em;
  border-radius: 0.3125em;
  border: 0.0625em solid #cbcbcb;
  background-color: #dfeaf2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.creativeCardTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.creativeCardDetailsBox {
  display: flex;
  flex-direction: column;
  gap: 0.3125em;
}

.creativeCardDetilsBoxTexts {
  display: flex;
  flex-direction: column;
  gap: 0.1875em;
}

.creativeIcon {
  width: 1em;
}

.creativeName {
  display: flex;
  align-items: center;
  gap: 0.1875em;
  font-size: 0.875em;
  font-weight: 500;
  color: #1a1b1c;
}

.creativeDate {
  font-size: 0.75em;
  color: #7d7d7d;
}

.creativeCardDetailsBoxActions {
  display: flex;
  gap: 0.625em;
}

.launchAdButton {
  color: #fff;
  border-radius: 0.4em;
  padding: 0.6em 1.3em;
  font-size: 0.625em;
}

.editButton {
  background: #fff;
  color: #abb1b8;
  border-radius: 0.4em;
  border: 0.1em solid #4cd995;
  padding: 0.6em 1.3em;
  font-size: 0.625em;
}

.editButton:hover {
  background: #f7f7f7;
  border: 0.0625em solid #4cd995;
}

.creativeCardDivider {
  width: 100%;
  height: 0.0625em;
  background-color: #dfeaf2;
  margin: 0.625em 0;
}

.previews {
  display: flex;
  gap: 0.3125em;
}

.previewSmall {
  width: 1.875em;
  height: 1.875em;
  border-radius: 0.3125em;
  border: 0.0625em solid #cbcbcb;
  background-color: #dfeaf2;
  overflow: hidden;
}

.previewImageSmall {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
  transition: opacity 0.25s ease;
}

.previewImageSmall:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .draftCreativeCard {
    font-size: 0.85em;
    flex: 1;
    width: 100%;
    max-width: 31.089em;
  }
}
