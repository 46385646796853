/* Segments colors - start */

.segment-text-color-one {
    color: #62B446
}

.segment-color-one {
    background: linear-gradient(to right, #62B446 50%, #94D47F 100%) !important;
}

.segment-color-one .segment-triangle-shape {
    fill: #62B446 !important
}

.color-one {
    background: #2D4B56;
    border-color: #2D4B56;
}

.segment-text-color-two {
    color: #59AD53
}

.segment-color-two {
    background: linear-gradient(to right, #59AD53 50%, #84CC7F 100%) !important;
}

.segment-color-two .segment-triangle-shape {
    fill: #59AD53 !important
}

.color-two {
    background: #59AD53;
    border-color: #59AD53;
}

.segment-text-color-three {
    color: #50A65F
}

.segment-color-three {
    background: linear-gradient(to right, #50A65F 50%, #76CF83 100%) !important;
}

.segment-color-three .segment-triangle-shape {
    fill: #50A65F !important
}

.color-three {
    background: #50A65F;
    border-color: #50A65F;
}

.segment-text-color-four {
    color: #469E6C
}

.segment-color-four .segment-triangle-shape {
    fill: #469E6C !important
}

.segment-color-four {
    background: linear-gradient(to right, #469E6C 50%, #76C799 100%) !important;
}

.color-four {
    background: #469E6C;
    border-color: #469E6C;
}

.segment-text-color-five {
    color: #3C9778
}

.segment-color-five {
    background: linear-gradient(to right, #3C9778 50%, #65C2A2 100%) !important;
}

.segment-color-five .segment-triangle-shape {
    fill: #3C9778 !important
}

.color-five {
    background: #3C9778;
    border-color: #3C9778;
}

.segment-text-color-six {
    color: #339084
}

.segment-color-six {
    background: linear-gradient(to right, #339084 50%, #54BAAD 100%) !important;
}

.segment-color-six .segment-triangle-shape {
    fill: #339084 !important
}

.color-six {
    background: #339084;
    border-color: #339084;
}

.segment-text-color-seven {
    color: #298990
}

.segment-color-seven {
    background: linear-gradient(to right, #298990 50%, #5DBCC2 100%) !important;
}

.segment-color-seven .segment-triangle-shape {
    fill: #298990 !important
}

.color-seven {
    background: #298990;
    border-color: #298990;
}

.segment-text-color-eight {
    color: #1F819D
}

.segment-color-eight {
    background: linear-gradient(to right, #1F819D 50%, #42A5C2 100%) !important;
}

.segment-color-eight .segment-triangle-shape {
    fill: #1F819D !important
}

.color-eight {
    background: #1F819D;
    border-color: #1F819D;
}

.segment-text-color-nine {
    color: #167AA9
}

.segment-color-nine {
    background: linear-gradient(to right, #167AA9 50%, #3B9ECC 100%) !important;
}

.segment-color-nine .segment-triangle-shape {
    fill: #167AA9 !important
}

.color-nine {
    background: #167AA9;
    border-color: #167AA9;
}

.segment-text-color-ten {
    color: #0C73B5
}

.segment-color-ten {
    background: linear-gradient(to right, #0C73B5 50%, #3D9CD9 100%) !important;
}

.segment-color-ten .segment-triangle-shape {
    fill: #0C73B5 !important
}

.color-ten {
    background: #0C73B5;
    border-color: #0C73B5;
}

/* Segments colors - end */



.segment-list {
    flex: 1 1 50%;
    height: 100%;
    overflow-y: scroll
}

.segmentation.old .segment-list {
    width: 100%;
    margin: 0 auto;
}

.segment-list .segments-grid {
    width: 100%;
    overflow-y: scroll;
    padding: 1em;
    justify-content: center;
}


.segment-color-vertical-one {
    background: #62B446;
    background: -moz-linear-gradient(bottom, #62B446 50%, #94D47F 100%) !important;
    background: -webkit-linear-gradient(bottom, #62B446 50%, #94D47F 100%) !important;
    background: linear-gradient(to bottom, #62B446 50%, #94D47F 100%) !important;
}
.segment-color-vertical-two {
    background: #59AD53;
    background: -moz-linear-gradient(bottom, #59AD53 50%, #84CC7F 100%) !important;
    background: -webkit-linear-gradient(bottom, #59AD53 50%, #84CC7F 100%) !important;
    background: linear-gradient(to bottom, #59AD53 50%, #84CC7F 100%) !important;
}
.segment-color-vertical-three {
    background: #50A65F;
    background: -moz-linear-gradient(bottom, #50A65F 50%, #76CF83 100%) !important;
    background: -webkit-linear-gradient(bottom, #50A65F 50%, #76CF83 100%) !important;
    background: linear-gradient(to bottom, #50A65F 50%, #76CF83 100%) !important;
}
.segment-color-vertical-four {
    background: #469E6C;
    background: -moz-linear-gradient(bottom, #469E6C 50%, #76C799 100%) !important;
    background: -webkit-linear-gradient(bottom, #469E6C 50%, #76C799 100%) !important;
    background: linear-gradient(to bottom, #469E6C 50%, #76C799 100%) !important;
}
.segment-color-vertical-five {
    background: #3C9778;
    background: -moz-linear-gradient(bottom, #3C9778 50%, #65C2A2 100%) !important;
    background: -webkit-linear-gradient(bottom, #3C9778 50%, #65C2A2 100%) !important;
    background: linear-gradient(to bottom, #3C9778 50%, #65C2A2 100%) !important;
}
.segment-color-vertical-six {
    background: #339084;
    background: -moz-linear-gradient(bottom, #339084 50%, #54BAAD 100%) !important;
    background: -webkit-linear-gradient(bottom, #339084 50%, #54BAAD 100%) !important;
    background: linear-gradient(to bottom, #339084 50%, #54BAAD 100%) !important;
}
.segment-color-vertical-seven {
    background: #298990;
    background: -moz-linear-gradient(bottom, #298990 50%, #5DBCC2 100%) !important;
    background: -webkit-linear-gradient(bottom, #298990 50%, #5DBCC2 100%) !important;
    background: linear-gradient(to bottom, #298990 50%, #5DBCC2 100%) !important;
}
.segment-color-vertical-eight {
    background: #1F819D;
    background: -moz-linear-gradient(bottom, #1F819D 50%, #42A5C2 100%) !important;
    background: -webkit-linear-gradient(bottom, #1F819D 50%, #42A5C2 100%) !important;
    background: linear-gradient(to bottom, #1F819D 50%, #42A5C2 100%) !important;
}
.segment-color-vertical-nine {
    background: #167AA9;
    background: -moz-linear-gradient(bottom, #167AA9 50%, #3B9ECC 100%) !important;
    background: -webkit-linear-gradient(bottom, #167AA9 50%, #3B9ECC 100%) !important;
    background: linear-gradient(to bottom, #167AA9 50%, #3B9ECC 100%) !important;
}
.segment-color-vertical-ten {
    background: #0C73B5;
    background: -moz-linear-gradient(bottom, #0C73B5 50%, #3D9CD9 100%) !important;
    background: -webkit-linear-gradient(bottom, #0C73B5 50%, #3D9CD9 100%) !important;
    background: linear-gradient(to bottom, #0C73B5 50%, #3D9CD9 100%) !important;
}