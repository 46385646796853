.sms-verification {
  text-align: center;
}

.sms-verification h3 {
  all: unset;
  font-size: 1.15em;
  font-weight: 600;
  color: var(--text-black);
}

.sms-verification .verify-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.sms-verification .verify-form p {
  all: unset;
  margin-top: 0.5em;
  font-size: 0.9em;
  color: #a5a5a5;
}

.sms-verification .verify-form .verification-input {
  display: flex;
  justify-content: space-between;
}

.sms-verification .verify-form .verification-input input {
  all: unset;
  outline: none;
  border: 1px solid #e5e7ec;
  border-radius: 0.5em;
  background-color: var(--background-gray);
  color: var(--text-black);
  padding: 0;
  font-size: 1.35em;
  max-width: 2.5em;
  height: 2.5em;
}

.sms-verification .verify-form .verification-input input:focus {
  border: 1px solid var(--text-black);
}

.sms-verification .verify-form .verify-button {
  border: none;
  outline: none;
  padding: 0.75em 1em;
  border-radius: 0.5em;
  font-weight: 600;
  color: var(--main-white);
  background: var(--dark-blue);
  cursor: pointer;
  transition: all 0.3s;
}

.sms-verification .verify-form .verify-button:disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.sms-verification .verify-form .verify-button:hover {
  background: #1d7080;
}

.sms-verification .verify-form .resend-code {
  font-size: 0.8em;
  color: var(--text-blue);
  cursor: pointer;
  transition: all 0.3s;
  width: fit-content;
  margin: auto;
}

.sms-verification .verify-form .resend-code:hover {
  color: var(--text-blue-hover);
}

@media (max-width: 1000px) {
  .sms-verification .verify-form .verification-input input {
    font-size: 1em;
    max-width: 2.3em;
    height: 2.3em;
  }
}
