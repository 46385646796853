.verify-events-container{
    display:flex;
    flex-direction: column;


 p {
    font-size: .9em;
    color: gray;
    margin: 1em auto;
}

 .step-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.5em auto;
    padding: 0.5em 1em;
    border-radius: 1em;
    background: var(--background-gray);
    transition: 0.3s;
}

 .step-card .step-header{
    display: flex;
    justify-content: space-between;
    font-size: var(--font-16); 
}

 .step-card .step-content{
    display: none;
    flex-direction: column;
    margin-top: 1em;

}

 .step-card .step-content.open{
    display: flex;
}

}
