.admin-panel-container {
  height: 100%;
}
.container-performance {
  height: 100%;
  display: flex;
  gap: 1em;
}

.admin-panel-container .container-performance .not-found-text {
  margin: 0;
  color: #c4c4c4;
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 8em;
  font-size: 1.5em;
  font-weight: 600;
}

.go-admin {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-top: 0.3em;
  margin-bottom: 0.9em;
}

.go-admin :hover {
  cursor: pointer;
  background-color: rgb(157, 212, 48);
  border-radius: 1em;
}

.go-admin button {
  border-radius: 1em;
  width: 12em;
  height: 2.5em;
  color: var(--main-white);
  background-color: yellowgreen;
  border: 0;
  font-weight: 600;
}
@media (max-width: 768px) {
  .container-performance {
    gap: 0.5em;
  }
}
