.audience-manager .result-card .card-content .audience-card {
  overflow: hidden;
  flex: 1 1 50%;
  max-width: 500px;
  background: var(--main-white);
}

.audience-manager .result-card .card-content .audience-card .header {
  background: var(--second-green);
  color: var(--main-white);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 0.9em;
  /* box-shadow: 0 5px 8px -3px #ccc; */
  border-radius: 0.5em;
}

.audience-manager .result-card .card-content .audience-card.b-audience .header {
  background: var(--dark-blue);
}

.audience-manager .result-card .card-content .audience-card .content {
  padding: 1.5em 0 0;
}

.audience-manager .result-card .card-content .audience-card .content > .row {
  flex-wrap: nowrap;
}

.audience-manager
  .result-card
  .card-content
  .audience-card
  .content
  .chart-container {
  flex: 1 1 80%;
  width: 75%;
  margin-bottom: 1em;
}

.audience-manager
  .result-card
  .card-content
  .audience-card
  .content
  .switch-container {
  max-width: 150px;
  font-size: 0.8em;
  margin: 0 auto;
}

.audience-manager .result-card .card-content .audience-card .content .stats {
  margin-top: 20px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
  padding: 0 1em;
}

.audience-manager
  .result-card
  .card-content
  .audience-card
  .content
  .stats
  > div {
  cursor: pointer;
}

.audience-manager
  .result-card
  .card-content
  .audience-card
  .content
  .stats
  > div:hover {
  font-weight: 500;
}

.audience-manager
  .result-card
  .card-content
  .enhencer-audience
  .content
  .stats
  .selected {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--second-green);
}

.audience-manager
  .result-card
  .card-content
  .enhencer-audience
  .content
  .stats {
  text-align: right;
}

.audience-manager
  .result-card
  .card-content
  .b-audience
  .content
  .stats
  .selected {
  font-size: 1.2em;
  font-weight: 500;
  color: var(--dark-blue);
}

.audience-manager
  .result-card
  .card-content
  .audience-card
  .content
  .switch-container
  .switch {
  margin: 0 auto;
}

.audience-manager .middle-column {
  font-size: 0.8em;
  margin-top: 6em;
  height: 162px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 0 1 130px;
}

.audience-manager .middle-column p {
  margin: 0;
  cursor: pointer;
  text-align: center;

  background: linear-gradient(90deg, var(--second-green), var(--dark-blue));
  color: var(--main-white);
  border-radius: 0.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audience-manager .middle-column p:not(:last-child):hover,
.audience-manager .middle-column p:not(:last-child).active {
  box-shadow: 0 1px 5px 2px #919191;
  font-weight: 500;
}

@media (max-width: 1400px) {
  .audience-manager .result-card .card-content .audience-card .header {
    padding: 2px 5px;
    font-size: 0.8em;
  }

  .audience-manager .result-card .card-content .audience-card .content {
    padding: 0.5em;
  }

  .audience-manager .result-card .card-content .content .stats {
    font-size: 0.9em;
    justify-content: space-evenly;
  }

  .audience-manager .result-card .card-content .content .stats .selected {
    font-size: 1.1em;
  }

  .audience-manager .middle-column {
    font-size: 0.7em;
    margin-top: 5em;
  }
}

@media (max-height: 1000px) {
  .audience-manager .result-card .card-content .audience-card .content .stats {
    height: 120px;
  }

  .audience-manager .middle-column {
    height: 128px;
  }
}
