.editorContainer {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #f9f9f9;
}
:global(.basic-user) .editorContainer {
  width: calc(100vw - var(--locked-navbar-width));
}
.leftToolbar,
.rightToolbar {
  flex-shrink: 0;
  background-color: #ffffff;
  overflow: auto;
  width: 300px;
  border: 0 solid var(--border-main);
  transition: all 0.2s ease;

  /* Modern scrollbar */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }

  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

.leftToolbar {
  border-right-width: 1px;
}

.rightToolbar {
  border-left-width: 1px;
}

.contentArea {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1rem;
  gap: 1rem;
}