.hover-card-container .hover-card-trigger {
  position: relative;
}

.hover-card-container .hover-card-trigger::after {
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  width: 20px;
  height: 100%;
}

.hover-card-container .hover-card-content {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
}