.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(0 0 0 / 50%);
  z-index: 8300;
}

.backdrop.blur {
  backdrop-filter: blur(4px);
}
