.Toastify__toast {
  font-size: 0.9em;
  background: rgb(234, 18, 18) !important;
  text-align: left;
  font-weight: 600;
  border-radius: 1em !important;
}

.Toastify__toast--warning {
  background: var(--main-yellow) !important;
}

.Toastify__toast--success {
  background: #2fb403 !important;
}

.Toastify__toast--info {
  background: var(--main-blue) !important;
}

.Toastify__toast a {
  color: var(--main-white);
  text-decoration: underline;
}

.Toastify__toast a:hover {
  color: rgb(203, 248, 255);
  text-decoration: none;
}

.Toastify__toast-body {
  width: 100%;
}