.acsr-report-card-container {
  cursor: pointer;
  position: relative;
  max-width: 15em;
  flex: 1 1;
}
h3 {
  text-align: -webkit-center;
  text-align: center;
}

.acsr-report-card-container .acsr-report-card {
  position: relative;
  background-color: #ffffff;
  border-radius: 2em;
  cursor: pointer;
  min-height: 15em;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0.25em 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.acsr-report-card-container .acsr-report-card:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0 0.25em 0.75em;
  scale: 1.01;
  transition: all 0.1s ease-in-out;
}

.acsr-report-card-container .acsr-report-card h3 {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 0.6em;
  font-weight: 600;
}

.acsr-report-card-container .acsr-subtitle-text {
  font-weight: 400;
  font-size: 1em;
  color: #234d62;
}

.acsr-report-card-container .acsr-subtitle-value {
  font-weight: 600;
  font-size: 1.5em;
  color: #234d62;
}

.acsr-report-card-container .acsr-subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.6em;
  font-size: 0.9em;
  color: #666;
  margin-top: 0.3em;
}

.acsr-report-card-container .acsr-report-card .acsr-subtitle-value .x-symbol {
  margin-right: 0.2em;
}
.acsr-report-card-container .acsr-card-content {
  padding: 1em 2em;
}
.acsr-report-card-container .acsr-line {
  border-top: 0.125em solid #e0e0e0;
}
.acsr-report-card-container .acsr-chart-tooltip {
  width: 100%;
  height: 100%;
}

.acsr-report-card-container .acsr-chart {
  width: 100%;
  height: 100%;
  position: relative;
}
.acsr-report-card-container .acsr-active-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1em;
  background-color: inherit;
}

.acsr-report-card-container.active .acsr-report-card {
  box-shadow: 0 0 0.6em rgba(76, 175, 80, 0.3);
}

.acsr-report-card-container .acsr-subtitle-label {
  display: flex;
  align-items: center;
  gap: 0.4em;
  cursor: help;
}

.acsr-report-card-container .info-icon {
  font-size: 1.1em;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  color: #666;
}

.acsr-report-card-container .info-icon:hover {
  opacity: 0.8;
}

.acsr-report-card-container .acsr-subtitle-label:hover .info-icon {
  opacity: 0.8;
}

.acsr-report-card-container .tooltip-container {
  display: flex;
  align-items: center;
}
