.campaign-creatives-section {
  display: flex;
  flex-direction: column;
  height: fit-content;
  background: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
  padding: 0px;
}

.campaign-creatives-section .header-row {
  display: flex;
  gap: 0.5em;
  flex-wrap: nowrap;
  background: none;
  justify-content: space-between;
  border-radius: 5px;
  padding: 3px 0.5em;
}

.campaign-creatives-section .header-row h3 {
  background: none;
  padding: 0;
  margin: 0;
  line-height: 1.5em;
}

.campaign-creatives-section .creative-action-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  background: #45b67f;
  border-radius: 5px;
  color: var(--main-white);
  padding: 0.1em 1em;
  cursor: pointer;
  font-size: 1em;
  height: 2em;
  font-weight: 500;
}

.campaign-creatives-section .creative-action-button.primary {
  color: var(--main-white);
  background: #45b67f;
}

.campaign-creatives-section .creative-action-button.primary:hover {
  background: #2e835a;
}

.campaign-creatives-section .creative-action-button.secondary {
  color: var(--text-gray);
  background: #eee;
}

.campaign-creatives-section .creative-action-button.secondary:hover {
  background: #d2d2d2;
}

.campaign-creatives-section .creative-action-button.disabled {
  color: var(--disabled-gray);
  background: #b2b2b2;
  cursor: default;
}

.campaign-creatives-section .creative-action-button.disabled:hover {
  background: #b2b2b2;
}

.campaign-creatives-section .editor-header {
  margin-top: 1em;
  font-size: 1em;
  font-weight: 600;
  padding: 0 0.5em;
}

.campaign-creatives-section .helper-text {
  color: gray;
  margin: 0;
  font-size: 0.9em;
}

.campaign-creatives-section .campaign-creatives-list {
  display: flex;
  gap: 0.5em;
  flex-wrap: nowrap;
  padding: 0.5em;
}

.campaign-creatives-list .campaign-creative-chip {
  padding: 0.1em 1em;
  font-size: 0.95em;
  font-weight: 500;
  background: #45b67f9a;
  color: var(--main-white);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* white-space: nowrap; */
}

.campaign-creatives-list .campaign-creative-chip:hover {
  background: #45b67fd7;
}

.campaign-creatives-list .campaign-creative-chip.active {
  background: #45b67f;
  transform: scale(1.05);
}

.campaign-creatives-list .creative-action-button span {
  white-space: nowrap;
}

.creatives-tabs {
  display: flex;
  align-items: flex-end;
  background: #eef0f2;
  padding: 8px 8px 0;
  height: 44px;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #dadce0;
}

.creative-tab {
  position: relative;
  padding: 12px 24px;
  background: #eaeaea;
  border-radius: 8px 8px 0 0;
  font-size: 0.9em;
  color: #5f6368;
  cursor: pointer;
  transition: background 0.2s ease;
  border: 1px solid transparent;
  margin-right: 5px;
  min-width: 100px;
  text-align: center;
  user-select: none;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 40px;
  font-weight: 500;
}

.creative-tab:hover {
  background: #e5e5e5;
}

.creative-tab.active {
  background: #f9f9f9;
  color: #202124;
  border: 1px solid #dadce0;
  border-bottom: 1px solid #f9f9f9;
  z-index: 2;
  font-weight: 600;
  margin-bottom: -1px;
  padding-bottom: 13px;
  border-radius: 8px 8px 0 0;
  position: relative;
  transition:
    background 0.2s ease,
    color 0.2s ease;
}

/* Alt köşeleri dışa doğru kıvrımlı yapmak için pseudo elementler */
.creative-tab.active::before,
.creative-tab.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  width: 8px;
  height: 8px;
  z-index: 1;
  background: none;
}

.creative-tab.active::before {
  left: -9px;
  transform: translateY(-1px);
  background: none;
  border-bottom-right-radius: 8px;
  border-right: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  box-shadow: 2px 2px 0 #f9f9f9;
}

.creative-tab.active::after {
  right: -9px;
  background: none;
  transform: translateY(-1px);
  border-bottom-left-radius: 8px;
  border-left: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  box-shadow: -2px 2px 0 #f9f9f9;
}

.creative-tab.add {
  min-width: 100px;
  width: auto;
  height: 30px;
  background: transparent;
  border: none;
  color: #5f6368;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  place-self: center;
  transform: translateY(-2px);
  gap: 4px;
  padding: 5px 24px;
}

.creative-tab.add:hover {
  background: #eaeaea;

  color: #202124;
}

.creative-tab.add .plus-icon {
  font-size: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign-content {
  flex: 1;
  background: #fff;
  padding: 0;
  position: relative;

  margin-top: -1px;
  z-index: 1;
  border-radius: 0 0 8px 8px;
}

.campaign-sections:has([data-tab='Creative'].active) .campaign-content {
  border-radius: 0 0 8px 8px;
}

.campaign-sections:has([data-tab='Targeting'].active) .campaign-content {
  border: none;
  box-shadow: none;
  border-radius: 8px;
  background: #f9f9f9;
  margin-top: 1.5em;
  padding: 20px;
}

.creative-maker-form {
  padding: 20px;
}

.input-container-row {
  margin-bottom: 2em;
}

.form-control input,
.form-control textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
}

.campaign-creatives-section .custom-dropdown {
  border: 1px solid #ccc;
  border-radius: 0.8em;
  background: #fff;
  transition: all 0.2s ease;
}

.campaign-creatives-section .custom-dropdown:hover {
  border-color: #bdc1c6;
}

.campaign-creatives-section .custom-dropdown.active {
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.1);
}

.campaign-creatives-section .campaign-content-wrapper {
  display: flex;
  gap: 24px;
  padding: 20px;
}

.campaign-creatives-section .main-content {
  flex: 1;
  min-width: 0;
}

.campaign-creatives-section .preview-panel {
  width: 340px;
  flex-shrink: 0;
}

@media (max-width: 1200px) {
  .campaign-creatives-section .campaign-content-wrapper {
    flex-direction: column;
  }

  .campaign-creatives-section .preview-panel {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
}
.campaign-creatives-section .creative-tab .remove-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.6em;
  color: #5f6368;
  cursor: pointer;
  padding: 2px;
  border-radius: 50%;
  transition: all 0.2s ease;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  color: #5f6368;
}

.campaign-creatives-section .creative-tab .remove-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #d93025;
}

.campaign-creatives-section .creative-tab:not(.active) .remove-icon {
  display: none;
}

.campaign-creatives-section .input-container-row.no-margin {
  margin-bottom: 0 !important;
}
