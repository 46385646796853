/* Layout Start */
.account-card .input-groups-wrapper {
  display: flex;
  gap: 3.25em;
  margin: 0.4em 0;
}

.account-card .input-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1.125em 2.375em;
}
.account-card .signup-date {
  font-size: 0.8em;
  margin-bottom: 1em;
}

.account-card .input-group:first-child {
  flex: 3;
}

.account-card .input-group:last-child {
  flex: 2;
}

.account-card .input-group .contract-start-date,
.account-card .input-group .contract-end-date {
  grid-row: 2;
  display: flex;
  align-items: center;
  gap: 1em;
}
/* Layout End */

/* Resets Start */
.account-card .input-cell {
  display: flex;
  align-items: center;
  position: relative;
}

.account-card .input-cell .form-control {
  margin: 0;
  width: 100%;
}

.account-card .input-cell .form-control input {
  box-shadow: unset;
}

.account-card .input-cell .form-control select {
  width: 100%;
}

.account-card .input-cell .form-control label {
  display: none;
}

.account-card .input-cell .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 1em;
  font-size: 0.75em;
}
.account-card .select-cont {
  box-shadow: none;
}

.account-card .contract-start-date input,
.account-card .contract-end-date input,
.account-card .input-cell input,
.account-card .input-cell select {
  height: 2.4em;
  max-width: 23em;
  border-radius: 5px;
  outline: none;
  border: 0;
  background: var(--transparent-gray);
  padding-left: 2.5em;
  font-size: 0.75em;
}

.account-card .contract-start-date input,
.account-card .contract-end-date input {
  padding-left: 0;
  max-width: 8em;
  text-align: center;
}
/* Resets End */

.account-card .input-group .contract-text {
  align-self: center;
  color: #000;
  font-size: 0.9em;
  font-weight: 600;
}

.account-card .input-group .contract-start-date span,
.account-card .input-group .contract-end-date span {
  color: #000;
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .account-card .input-groups-wrapper {
    display: block;
  }
}
