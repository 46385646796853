.tooltip-container {
  width: fit-content;
}

.tooltip-container>div:first-child {
  width: fit-content;
}

.tooltip {
  position: absolute;
  background-color: rgba(70, 70, 70, 0.9);
  color: var(--main-white);
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0 4px 30px var(--transparent-gray);
  backdrop-filter: blur(4px);
  font-size: 0.8em;
  z-index: 55555;
  text-wrap: initial;
  text-align: left;
  max-width: 32ch;
}

/* Small */
.tooltip.sm {
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 0.7em;
  max-width: 30ch;
}

/* Medium (Default) */
.tooltip.md {
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.8em;
  max-width: 32ch;
}

/* Large */
.tooltip.lg {
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.92em;
  max-width: 40ch;
}