.progress-bar {
  border-radius: 20px;
  box-shadow: inset 0px 0px 4px 0px #c2c2c2;
  position: relative;
  height: 12px;
  min-height: 12px;
}

.progress-bar .progress {
  background: #10b981;
  position: absolute;
  height: 10px;
  border-radius: 10px;
  left: 1px;
  top: 1px;
  transition: 0.3s;
}

@media (max-width: 1500px) {
  .progress-bar {
    height: 10px;
    min-height: 10px;
  }

  .progress-bar .progress {
    height: 8px;
    border-radius: 8px;
  }
}
