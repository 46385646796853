.slider-brand-rectangle-review {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 6em;

  .rectangle {
    background: linear-gradient(135deg, #5fb97d 0%, #74b751 100%);
    border-radius: 20px;
    padding: 1.5em;
    color: white;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 13em;
    min-height: 13em;
    height: 13em;
    display: flex;
    flex-direction: column;
    border: 1px solid #c4c4c4;
    opacity: 0.75;
  }

  .quotes-icon {
    position: absolute;
    top: 0.6em;
    left: 2em;
    width: 30px;
    height: 30px;
    opacity: 0.2;
    color: white;
  }

  .review-text {
    font-size: var(--font-20);
    line-height: 1.5;
    margin-bottom: 20px;
    padding-left: 15px;
    font-weight: 400;
    flex-grow: 1;
    overflow-y: auto;
    max-height: calc(100% - 60px);
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
    text-align: left;
  }

  .review-text::-webkit-scrollbar {
    width: 6px;
  }

  .review-text::-webkit-scrollbar-track {
    background: transparent;
  }

  .review-text::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
  }

  .brand-logo-container {
    position: absolute;
    left: 2.5em;
    bottom: 1.5em;
  }

  .brand-logo {
    max-width: 80px;
    height: auto;
  }

  .slider-dots {
    text-align: right;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .dot {
    height: 8px;
    width: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    transition: all 0.3s ease;
  }

  .dot.active {
    background-color: white;
    width: 20px;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    .slider-brand-rectangle-review {
      max-width: 90%;
    }

    .rectangle {
      padding: 20px;
    }

    .review-text {
      font-size: 16px;
    }

    .brand-logo {
      max-width: 60px;
    }
  }
}
