/* The following styles are for the expanded view */
.filter-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-width: 467px;
  max-width: 467px;
  max-height: calc(100vh - var(--topbar-height) - 2em);
  border: 2px solid #e5e5e5;
  border-radius: 11px;
  overflow: hidden;
  transition: all 300ms ease-in-out;

  .expand-button {
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .switch-container {
    align-items: center;
    padding: 1em 0;
  }

  .content-wrapper {
    padding: 0.5em 2em 12em 2.5em;
    font-size: 0.7em;
    height: 100%;
    transition: all 500ms ease-in-out;
  }

  .content-wrapper h2 {
    font-size: 1em;
  }

  .content-wrapper h3 {
    font-size: 1.3em;
    margin: 0;
  }

  .content-wrapper .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
    margin-top: 1.5em;
    margin-bottom: 1.2em;
  }

  .content-wrapper .header .button-title-wrapper {
    display: flex;
    align-items: center;
    gap: 0.15em;
    margin-left: -0.8em;
  }

  .content-wrapper .button-title-wrapper .collapse-button {
    opacity: 1;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
    transition:
      opacity 500ms,
      background-color 150ms;
  }

  .content-wrapper .button-title-wrapper .collapse-button:hover {
    background-color: #f3f3f3;
  }

  .content-wrapper .users-count {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0.25em 0.5em;
    margin-bottom: 1em;
    background: rgb(70, 182, 128);
    background: linear-gradient(
      90deg,
      rgba(70, 182, 128, 0.25) 0%,
      rgba(70, 182, 128, 0) 100%
    );
  }

  .content-wrapper .users-count h2 {
    margin: 0;
    padding: 0;
    color: var(--dark-green);
  }

  .content-wrapper .header .helpers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
    height: 28px;
  }

  .content-wrapper .header .helpers .buttons {
    display: flex;
    gap: 0.5em;
  }

  .content-wrapper .header .helpers > * {
    flex: 1;
    height: 100%;
  }

  .content-wrapper .header .helpers .search {
    position: relative;
  }

  .content-wrapper .header .helpers .search svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  .content-wrapper .header .helpers .search input {
    border-radius: 0.5em;

    display: flex;
    align-items: center;
    padding: 5px 0em 5px 2em;
    border: 1px solid #dedede;
    height: 28px;
  }

  .content-wrapper .header .helpers .search input:focus {
    box-shadow: 0 2px 6px 0 #ccc;
    outline: 1px solid #adadad;
    background-color: rgb(246, 248, 244);
  }

  .content-wrapper .header .helpers .update-button {
    cursor: pointer;
    font-weight: 550;
    background-color: var(--light-green);
    color: var(--main-white);
    box-shadow: 1px 1px #ebe5e5;
    border: 0;
    border-radius: 0.5em;
  }

  .content-wrapper .header .helpers .update-button:hover {
    font-weight: 550;
    background-color: #5fce4e;
  }

  .content-wrapper .header .helpers .update-button.disabled {
    background-color: #cdcdcd;
    pointer-events: none;
    color: #efefef;
  }

  .content-wrapper .header .helpers .reset-button {
    cursor: pointer;
    font-weight: 550;
    background-color: #f0c117;
    color: var(--main-white);
    box-shadow: 1px 1px #ebe5e5;
    border: 0;
    border-radius: 0.5em;
  }

  .content-wrapper .header .helpers .reset-button:hover {
    background-color: #ffd333;
  }

  .content-wrapper .divider {
    height: 6px;
    width: 100%;
    border-radius: 2px;
    background: var(--second-green);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  }

  .content-wrapper .filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em 2em;
    overflow: hidden scroll;
    max-height: 100%;
  }

  .content-wrapper .filters::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .filters:hover::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .content-wrapper .filters::-webkit-scrollbar-thumb {
    background: var(--transparent-gray);
  }

  .content-wrapper .filters::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.25);
  }
}

/* The following styles are for the collapsed view */

.filter-container.collapsed {
  min-width: 50px;
  max-width: 50px;
}

.filter-container.collapsed .content-wrapper {
  /* opacity: 0; */
  padding: 7em 0 0;
}

.filter-container.collapsed .expand-button {
  position: absolute;
  left: 50%;
  top: 1.5em;
  transform: translateX(-50%);
  opacity: 1;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  width: 36px;
  height: 36px;
  z-index: 10;
  cursor: pointer;
  transition:
    opacity 500ms,
    width 500ms,
    height 500ms,
    background-color 150ms;
}

.filter-container.collapsed .expand-button .badge {
  position: absolute;
  right: -5px;
  top: -5px;
  background: var(--dark-green);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: var(--main-white);
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

.filter-container.collapsed .expand-button:hover {
  background-color: #f3f3f3;
}

.filter-container.collapsed
  .content-wrapper
  .button-title-wrapper
  .collapse-button {
  opacity: 0;
}

.filter-container.collapsed .content-wrapper .header {
  display: none;
  transition:
    opacity 500ms,
    width 500ms,
    height 500ms,
    background-color 150ms;
}

.filter-container.collapsed .content-wrapper .users-count {
  background: none;
}

.filter-container.collapsed .content-wrapper h2 {
  text-align: center;
  text-wrap: none;
}
@media (max-width: 768px) {
  .filter-container.collapsed {
    min-width: 35px;
    max-width: 35px;
    margin-right: -10px;
  }
  .filter-container.collapsed .expand-button {
    max-width: 20px;
    scale: 0.9;
  }
  .filter-container {
    min-width: 97%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .filter-container .content-wrapper {
    padding: 0;
    font-size: 0.6em;
  }
  .filter-container .content-wrapper.header {
    padding: 0;
  }

  .filter-container .content-wrapper .header .button-title-wrapper {
    margin-left: 0;
    font-size: smaller;
    gap: 0;
  }

  .filter-container .content-wrapper .header .helpers .search input {
    width: 150px;
  }
}

@media (min-width: 768px) {
  .filter-container.alarmCalendar {
    width: 50%;
    max-width: 300px;
    min-width: auto;
  }

  .filter-container.alarmCalendar .content-wrapper .filters {
    display: flex;
    flex-direction: column;
  }

  .filter-container.alarmCalendar .content-wrapper .header .helpers .search {
    display: none;
  }
}
.filter-container.collapsed.alarmCalendar {
  max-width: 50px;
  min-width: 50px;
}
.filter-container.collapsed .filters {
  display: none;
}
.filter-container.collapsed .switch-container {
  display: none;
}
