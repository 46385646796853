.creative-maker-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;

  padding-left: 0em;
}

.creative-maker-form .head {
  justify-content: space-between;
}

.creative-maker-form h4 {
  margin: 0;
  color: #727276;
}

.creative-maker-form .head .actions-row {
  flex: 1 1 20%;
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: flex-end;
}

.enhenced-catalog-selection {
  padding: 1em;
  background: var(--main-green-gradient);
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.enhenced-catalog-selection:hover {
  background: var(--main-green-gradient-hover);
}

::placeholder {
  color: rgb(153, 153, 153);
  opacity: 1;
  /* Firefox */
}

.creative-maker-form .refresh-button {
  width: 2em;
  height: 2em;
  padding: 0;
  background: none;
  color: var(--text-gray);
}

.creative-maker-form .refresh-button:hover {
  background: none;
  color: var(--second-green);
}

.creative-maker-form .recommendations-container {
  margin-left: calc(12ch + 1em);
  display: flex;
  gap: 1em;
  width: calc(100% - 12ch - 1em);
}

.creative-maker-form .button.generate-ad-text-button {
  border: 2px solid #44859d;
  border-radius: 0.5em;
  padding: 0.5em;
  text-align: center;
  background: transparent;
  color: #44859d;
  flex: 1 1 10%;
}

.creative-maker-form .recommendations-list {
  flex: 1 1 90%;
}

.creative-maker-form .recommendation-chip {
  background: #e4faff;
  margin: 0.5em 0;
  max-width: 800px;
  width: 100%;
  color: #2c2c2c;
  padding: 0.5em 1em;
  border-radius: 1.5em;
  cursor: pointer;
}

.creative-maker-form .recommendation-chip:hover {
  background: #d9f4fa;
}
