.productsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 0.75rem;
  padding: 1em;
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: 450px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

:global(.adcr.modal-preview) .productsGrid {
  max-height: 390px;
}

.productContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  background: #ffffff;
  height: fit-content;
  aspect-ratio: 1;
  overflow: hidden;
}

.productContainer:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-color: var(--second-green);
}

.completedProductActions {
  opacity: 0;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

:global(.completeEnhencedModal) .productContainer:hover .completedProductActions {
  opacity: 1;
}

.productActionsButton {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #666;
}

.productActionsButton svg {
  width: 16px;
  height: 16px;
  color: inherit;
}

.productActionsButton:hover {
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: var(--second-green);
}

.productActionsMenuHidden {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 120px;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s ease;
  pointer-events: none;
}

.productActionsMenuVisible {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 120px;
  transition: all 0.2s ease;
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}

.productActionItem {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #333;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.productActionItem:first-child {
  border-radius: 8px 8px 0 0;
}

.productActionItem:last-child {
  border-radius: 0 0 8px 8px;
}

.productActionItem:hover {
  background: #f5f5f5;
  color: var(--second-green);
}

.productActionItem.danger {
  color: #dc3545;
}

.productActionItem.danger:hover {
  background: #fff5f5;
  color: #dc3545;
}

.productImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

:global(.completeEnhencedModal) .productsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1.25rem;
  padding: 1.5rem;
  overflow-y: auto;
  max-height: calc(80vh - 200px);
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

:global(.completeEnhencedModal) .productsGrid::-webkit-scrollbar {
  width: 6px;
}

:global(.completeEnhencedModal) .productsGrid::-webkit-scrollbar-track {
  background: transparent;
}

:global(.completeEnhencedModal) .productsGrid::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

:global(.completeEnhencedModal) .productContainer {
  position: relative;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  background: #f5f5f5;
  transition: all 0.3s ease;
}

:global(.completeEnhencedModal) .productContainer:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

:global(.completeEnhencedModal) .productImage {
  width: 100%;
  height: 100%;
  position: relative;
}

:global(.completeEnhencedModal) .productImage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(0, 0, 0, 0.02) 100%
  );
  z-index: 1;
}

:global(.completeEnhencedModal) .productImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

:global(.completeEnhencedModal) .productContainer:hover .productImage img {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  :global(.completeEnhencedModal) .productsGrid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
}
