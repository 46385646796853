.place-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: var(--main-white);
}

.connect-your-website .connect-text {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.connect-your-website .connect-text .check {
  font-size: 2em;
  color: var(--main-green);
}

.connect-your-website .actions-row {
  display: flex;
  align-items: center;
  gap: 2em;
}

.connect-your-website .actions-row a {
  display: flex;
  align-items: center;
  gap: 0.3em;
}

/* Connect Overview - Start */

.connect-options {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 2em;
  margin: 2em auto;
}

.connect-options .option-button {
  border-radius: 0.5em;
  box-shadow: 0 1px 5px 0 #ccc;
  border: none;
  background: var(--main-white);
  text-decoration: none;
  min-width: 150px;
  max-width: 300px;
  padding: 7px 10px;
  text-align: center;
  color: var(--text-blue);
  font-weight: 500;
  flex: 1 1 20%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connect-options .option-button.disabled {
  color: #8aa7b2;
  cursor: not-allowed;
}

.connect-options .option-button:hover {
  background: rgb(245, 245, 245);
}

.connect-options .option-button img {
  max-width: 1.5em;
  margin-right: 1em;
}

.connect-overview-container .data-overview-header {
  margin: 3em 0 2em;
}

.connect-overview-container h4 {
  font-weight: 600;
  font-size: 1.2em;
  color: #2782a2;
  margin: 0;
}

.date-input-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  /* margin: 1em 0 2em; */
  padding: 4px 8px;
  border-radius: 5px;
  box-shadow: 0px 5px 20px #00003e1a;
  box-shadow: 0 0 3px 0 #ccc;
  max-width: 175px;
  cursor: pointer;
}

.connect-overview-container .date-input-cont:hover,
.connect-overview-container .date-input-cont:hover input {
  background: rgb(241, 241, 241);
}

.connect-overview-container .date-input-cont .icon {
  font-size: 1.3em;
}

.date-input-cont input {
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.data-summary-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  font-size: 1em;
  gap: 3px;
}

.data-summary-container > div {
  overflow: hidden;
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  box-shadow: 0 0 2px 0 #ccc;
  padding: 1em 0;
}

.data-summary-container .step-name {
  color: rgb(56, 56, 56);
  font-size: 1.2em;
  font-weight: 600;
  /* padding-left: 0.5em; */
  width: 90%;
  margin: 0 auto;
}

.data-summary-container .conversion-badge {
  width: 90%;
  margin: 0 auto;
  height: 4em;
}

.data-summary-container .conversion-badge .number {
  font-size: 1.8em;
  font-weight: 500;
}

.data-summary-container .canvas-container {
  /* min-height: 200px;
  max-height: 220px;
  height: 100% */
  height: 210px;
}

.data-summary-container .funnel-canvas {
  width: 100%;
  height: 100%;
}

.data-summary-container .summary-badge {
  width: 90%;
  /* padding-left: 0.5em; */
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  gap: 0.5em;
}

.data-summary-container .summary-badge .number {
  font-size: 1.8em;
  font-weight: 500;
}

.connect-overview-container .card {
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: var(--card-shadow);
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.connect-overview-container .card .boxes-icon {
  color: var(--text-blue);
  font-size: 2em;
}

.connect-overview-container .important-info {
  font-size: 1.1em;
  margin-bottom: 0.5em;
  color: var(--text-blue);
  font-weight: 500;
}

.connect-overview-container .action-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em auto;
}

.connect-overview-container .action-container button {
  font-size: 1.1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0;
  min-width: 250px;
  position: relative;
}

.connect-overview-container .action-container button .icon {
  position: absolute;
  right: 1em;
  height: calc(100% - 1em);
}

/* Connect Overview - End */
