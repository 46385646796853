.special-users-container {
    height: 100%;
  
  
   .layout {
    height: 100%;
    display: flex;
    gap: 1em;
  }
  
   .layout .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
  }
  
   .layout .content .heading {
    margin: 0;
    padding: 0 0;
    flex: 1;
  }
  
   .layout .content .top {
    margin: 0;
    padding: 0 16px;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
  
   .layout .content .top .create-new-button {
    text-wrap: nowrap;
    flex: 0 0 auto;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: rgb(96, 96, 96);
    border: solid 1.5px var(--main-green);
    gap: 0.2em;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
   .layout .content .top .create-new-button:hover {
    background: var(--main-green);
    color: rgb(255, 255, 255);
  }
  
  .layout .content .heading::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--main-green);
    margin-bottom: 16px;
  }
  
  .layout .content .not-found-text {
    margin: 0;
    color: #c4c4c4;
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-bottom: 8em;
    font-size: 1.5em;
    font-weight: 600;
  }
  .demo-meetings-list-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  
  .demo-meetings-list-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  .demo-meetings-list-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  .demo-meetings-list-wrapper::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .demo-meetings-list-wrapper::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.25);
  }
  
  .demo-meetings-list {
    display: grid;
    flex-wrap: wrap;
    grid-gap: 1.5em;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    width: 100%;
    padding: 0 1em 1em;
    align-items: start;
  }
  
  .scroll-ending-box {
    padding: 1em;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}  
  