.are-you-sure .footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1.5rem;
}

.are-you-sure .footer .action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.8rem;
  border-radius: 0.8em;

  font-weight: 500;
  transition: all 0.2s ease;
  background: var(--main-white);
}

.are-you-sure .footer .action-button.no {
  color: var(--text-blue);
  border: 1px solid var(--text-blue);
}

.are-you-sure .footer .action-button.no:hover {
  background: var(--text-blue);
  color: var(--main-white);
}

.are-you-sure .footer .action-button.yes {
  color: #ff6b6b;
  border: 1px solid #ff6b6b;
}

.are-you-sure .footer .action-button.yes:hover {
  background: #ff6b6b;
  color: var(--main-white);
}

.are-you-sure .footer .action-button.yes:not(.danger) {
  color: var(--main-green);
  border: 1px solid var(--main-green);
}

.are-you-sure .footer .action-button.yes:not(.danger):hover {
  background: var(--main-green);
  color: var(--main-white);
}
