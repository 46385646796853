.acsr-container {
  background-color: #ffffff;
  border-radius: 0.5em;
  padding: 2em 0 1em 1em;
  overflow-y: auto;
  height: fit-content;
}

.acsr-report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.3em;
  flex-wrap: wrap;
}

.acsr-report-header h2 {
  font-size: 1em;
  color: #333;
  font-weight: normal;
  margin: 0;
  flex: 1 1 100%;
  margin-bottom: 1em;
}

.acsr-report-header h2 span {
  font-weight: 600;
  color: var(--main-green);
}

.acsr-filters {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

/* Etiket ve dropdown'ları gruplamak için yeni bir sınıf ekliyorum */
.filter-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  margin-right: 2em;
}

.acsr-filters .filter-label {
  display: block;
  color: var(--text-gray);
  font-size: 0.9em;
}

.acsr-filter {
  position: relative;
}

.acsr-filter select {
  appearance: none;
  box-shadow:
    rgba(50, 50, 105, 0.15) 0 0.25em 0.45em 0,
    rgba(0, 0, 0, 0.05) 0.125em 0.25em 0.25em 0.125em;
  border: none;
  padding: 0.5em 2em 0.5em 1em;
  border-radius: 1em;
  font-size: 1em;
  font-weight: 500;
  color: var(--main-green);
  cursor: pointer;
  background: white;
}

.acsr-filter select:focus-visible {
  outline: 0.1em solid var(--main-green);
}

.acsr-filter .facebook-date-picker .toggle-button {
  color: var(--main-green);
  border-radius: 1em;
  padding: 0.5em 2em 0.5em 1em;
  background-color: var(--main-white);
  font-size: 1em;
  font-weight: 500;
  box-shadow:
    rgba(50, 50, 105, 0.15) 0 0.25em 0.45em 0,
    rgba(0, 0, 0, 0.05) 0.125em 0.25em 0.25em 0.125em;
}

.acsr-filter .facebook-date-picker .toggle-button:focus-visible {
  outline: 0.1em solid var(--main-green);
}

.acsr-caret-icon {
  position: absolute;
  right: 0.6em;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #666;
  z-index: 5;
}

.acsr-report-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  margin-bottom: 3em;
}

.acsr-container .currency {
  font-size: 0.7em;
  position: relative;
}

.acsr-chart {
  height: 100%;
  /* padding: 1em 2em; */
  padding: 1em;
  min-height: 8em;
}

.acsr-container > h2 {
  font-size: 1em;
  color: #333;
  font-weight: normal;
  margin: 0;
  flex: 1 1 100%;
  margin-bottom: 1em;
}

.acsr-container > h2 span {
  font-weight: 600;
}

@media (max-width: 48em) {
  .acsr-report-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .acsr-filters {
    margin-top: 0.6em;
    width: 100%;
  }

  /* Responsive'de filter-group'ların davranışını düzenliyorum */
  .filter-group {
    margin-bottom: 1em;
    width: auto;
  }
}

.acsr-chart-warning {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #666;
  font-size: 0.8em;
  padding: 0 10px;
}

.acsr-table-tabs {
  display: flex;
  gap: 1em;
  margin: 2em;
}

.acsr-tab {
  padding: 0.8em 1.6em;
  border: none;
  border-radius: 0.5em;
  background: #f5f5f5;
  color: #666;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.acsr-tab:hover {
  background: #ebebeb;
}

.acsr-tab.active {
  background: #0585e0;
  color: white;
}

/* Hiyerarşik tablo stilleri */
.acsr-table-row {
  transition: background-color 0.2s ease;
}
.acsr-table-row:hover {
  background-color: #f8f9fa;
}

.acsr-table-row[data-type='campaign'] {
  background-color: #f8f9fa;
  border-top: 2px solid #dee2e6;
  font-weight: 600;
}

.acsr-table-row[data-type='campaign']:hover {
  background-color: #f1f3f5;
}

.acsr-table-row[data-type='adset'] {
  background-color: #ffffff;
  padding-left: 2em;
  border-left: 3px solid #e9ecef;
  margin-left: 1.5em;
}

.acsr-table-row[data-type='adset']:hover {
  background-color: #f8f9fa;
}

.acsr-table-row[data-type='adset'] td:first-child {
  position: relative;
  padding-left: 2.5em;
}

.acsr-table-row[data-type='adset'] td:first-child::before {
  content: '';
  position: absolute;
  left: 1em;
  top: 50%;
  width: 0.8em;
  height: 2px;
  background-color: #ced4da;
}

/* Campaign ve Adset label stilleri */
.acsr-table-row[data-type='campaign'] .label-cell {
  color: #495057;
}

.acsr-table-row[data-type='adset'] .label-cell {
  color: #6c757d;
  font-size: 0.95em;
}
