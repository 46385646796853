.use-ai-ads-now {
  .orbits {
    position: absolute;
    width: 100%;
    /* z-index: -5; */
  }
  .orbits img {
    opacity: 0.5;
  }
}
