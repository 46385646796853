.konvaAreaContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.konvaAreaContainerLoading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.konvaAreaPreview {
  display: block;
  position: relative;
  margin: 1em 0;
  border: 2px solid rgb(193, 193, 193);
}

.originalImageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.hidden {
  display: none;
}

.visible {
  display: flex;
}

.originalImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: white;
}

.toolbarContainer {
  position: relative;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #e0e0e0;
}
