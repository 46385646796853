.typewriter-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.typewriter-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.typewriter-text p {
  margin: 0.5em 0;
}

.typewriter-text code {
  background-color: #f4f4f4;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
}

.typewriter-text pre {
  background-color: #f4f4f4;
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
}

.enhencer-ai-agent-questions {
  margin-top: 1em;
}

.enhencer-ai-agent-questions h4 {
  margin-bottom: 0.5em;
  color: #666;
}

.question-cards {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.question-card {
  padding: 0.75em 1em;
  background-color: #f5f5f5;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.question-card:hover {
  background-color: #e8e8e8;
} 