.campaign-detail .fluid-card-header .header-wrapper .options .campaign-label-wrapper {
  display: flex;
  align-items: center;
  gap: 0.4em;
  padding: 0.1em 0.5em;
  border-radius: 4px;
  transition: all 100ms;
  font-weight: 600;
}
.campaign-detail .fluid-card-header .header-wrapper .options .campaign-label-wrapper.campaign-edit-mode {
  cursor: pointer;
}

.campaign-detail .fluid-card-header .header-wrapper .options .campaign-label-wrapper.campaign-edit-mode:hover {
  background: #c2c2c240;
}

.campaign-detail .fluid-card-header .header-wrapper .options .campaign-label-wrapper:has(.label-error) {
  background: rgb(209 3 3 / 8%);
  border: 1px solid rgb(209 3 3 / 12%);
}

.campaign-detail .fluid-card-header .header-wrapper .options .campaign-label-wrapper .label-error {
  font-size: 0.75em;
  font-weight: 400;
  color: rgb(209 3 3);
}

.fluid-card-header .header-wrapper .options .campaign-label {
  overflow: hidden;
  white-space: nowrap;
  max-width: 40ch;
  text-overflow: ellipsis;
  font-weight: 600;
}

.fluid-card-header .header-wrapper .options .label-input {
  all: unset;
  font: inherit;
  width: 40ch;
  cursor: text;
}


@media (max-width: 992px) {
  .fluid-card-header .header-wrapper .options .label-input {
    width: 20ch;
  }

  .fluid-card-header .header-wrapper .options .campaign-label {
    max-width: 20ch;
  }
}
