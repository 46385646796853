.pricing-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
  position: relative;
}

.pricing-card {
  align-items: baseline;
  background:
    linear-gradient(var(--main-white) 0 0) padding-box,
    var(--main-green-gradient) border-box;
  border: 2px solid #0000;
  border-radius: 1em;
  box-shadow: var(--card-shadow);
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  font-size: var(--font-18);
  max-width: 20em;
  position: relative;
  transition: 0.1s;
}

/* Ad Health Checkup card for Shopify */
.ad-health-checkup-card {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  min-height: 200px;
  background:
    linear-gradient(var(--main-white) 0 0) padding-box,
    var(--main-green-gradient) border-box;
}

.ad-health-checkup-card .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 40px;
}

.ad-health-checkup-card .left-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;
  justify-content: flex-start;
  height: auto;
}

.ad-health-checkup-card .left-section .plan-name-row {
  margin-bottom: 0;
}

.ad-health-checkup-card .left-section .plan-name-row h2 {
  height: auto;
  margin: 0;
}

.ad-health-checkup-card .left-section .price-container {
  margin: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ad-health-checkup-card .left-section .custom-price {
  margin: 0;
  font-size: 2.5em;
  height: auto;
}

.ad-health-checkup-card .left-section .price-container button {
  margin: 0;
}

.ad-health-checkup-card .left-section .custom-price .name {
  padding-top: 0;
  transform: none;
}

.ad-health-checkup-card .features-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.ad-health-checkup-card .promotion-text-top {
  font-size: 0.9em;
  margin-top: 20px;
}

.ad-health-checkup-card .features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 20px;
  height: auto;
}

.ad-health-checkup-card .features li {
  margin: 0;
  white-space: nowrap;
  font-size: 0.9em;
}

.ad-health-checkup-card .features li .icon {
  flex-shrink: 0;
}

/* Enterprise kartı butonunu yeşil yapmak için */
.pricing-card .price-container .calendly-inline-widget,
.pricing-card .price-container .PopupButton {
  background: var(--main-green-gradient);
  color: var(--main-white);
  border-radius: 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.2em 0.5em;
  font-weight: 600;
  cursor: pointer;
}

.pricing-card .price-container .calendly-inline-widget:hover,
.pricing-card .price-container .PopupButton:hover {
  background: var(--main-green-gradient-hover);
}

.pricing-card.inverted {
  background: var(--main-green-gradient);
}

.pricing-card .content {
  padding: 2em 1em 1em 1em;
  width: 100%;
}

.pricing-card .left-side {
  flex: 1 1 40%;
  z-index: 2;
}

.pricing-card .mobile-title,
.pricing-card .price-container.xs {
  display: none;
}

.pricing-card .right-side {
  flex: 1 1 60%;
  z-index: 2;
}

.calculator-box {
  max-width: 700px;
  text-align: left;
}

.not-visible {
  opacity: 0;
}

.most-popular-container {
  /* background: linear-gradient(87.8deg, #2980a4 0%, #8bc260 100%); */
  background-color: white;
  border: 2.5px solid var(--main-green);
  width: 80%;
  height: 2.8em;
  color: var(--main-grey);
  font-weight: 500;
  font-size: 0.8em;
  border-radius: 1.5em;
  position: absolute;
  transform: translate(13%, -55%);
}
.most-popular-container img {
  filter: brightness(0.4);
  max-width: 20px;
}

.plan-name-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.save-container {
  background: var(--second-green);
  width: 100%;
  height: 4em;
  color: var(--main-white);
  text-align: center;
  font-weight: 500;
  font-size: 0.8em;
  transform: translateX(20%) translateY(-90%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 1em;
  border-bottom-left-radius: 1em;
}

.biger-text {
  font-size: 1.1em;
  font-weight: 600;
}

.most-popular-container .row {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.most-popular-container h3 {
  font-weight: 500;
  margin: 0;
}

.pageview-container {
  color: var(--text-black);
  text-align: left;
  font-weight: 500;
  font-size: 0.9em;
  height: 8em;
  margin-top: 2em;
}

.pricing-card.inverted .pageview-container {
  color: var(--main-white);
}
æ .pageview-number {
  font-size: 1.2em;
}

.pageview-text {
  color: var(--main-white);
}

.pricing-card .promotion-text {
  font-size: 0.9em;
}
.black-friday-sale {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  /* position: absolute;
  top: 42%;
  left: 3%; */
}
.black-friday-sale .black-friday-sale-text {
  background: var(--main-green-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: 0.9em;
  font-style: italic;
}
#sliderContainer {
  display: none;
}

.sized-box.one {
  flex: 0 0 5;
}

.price-container {
  margin: 0 auto;
  width: 100%;
  max-width: 750px;
  height: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.price-container .old-price {
  color: #9099a2;
  font-size: 0.9em;
}

.strike-through {
  text-decoration: line-through;
}

.price-container .price {
  color: var(--text-black);
  font-size: 1.8em;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0;
  text-align: left;
}

.price-container .custom-price {
  color: var(--text-black);
  font-size: 3em;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin: 0 0 0.5em;
  text-align: left;
}

.pricing-card.inverted .price-container .price,
.pricing-card.inverted .price-container .custom-price {
  color: var(--main-white);
}

.price-container .custom-price span {
  font-size: 0.5em;
  font-weight: 500;
  padding-top: 0.5em;
  transform: translateY(0.6em);
}

.price-container .custom-price .icon {
  font-size: 0.8em;
}

.price-container .per-month {
  font-size: 0.4em;
  color: #485460;
  margin: 1.3em auto 0;
}

.pricing-card.inverted .price-container .per-month {
  color: var(--main-white);
}

.price-container .additional-fee {
  font-size: 0.75em;
  color: var(--text-gray);
  margin: 0.2em auto 1em;
}

.no-discount-price .outer {
  text-decoration: line-through 3px;
  color: red;
}

.no-discount-price .inner {
  color: var(--main-white);
}

.price-container button,
.price-container a {
  background: var(--main-green-gradient);
  border: none;
  border-radius: 0.4em;
  padding: 1.2em 0.5em;
  color: var(--main-white);
  font-size: 0.9em;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1em;
  margin: 0.3em auto 1em;
  max-height: 2em;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none !important;
}

.price-container button:hover,
.price-container a:hover {
  background: var(--main-green-gradient-hover);
}

.custom-plan-card .price-container {
  height: unset;
}

.custom-plan-card .price-container button,
.custom-plan-card .price-container a {
  width: min-content;
  margin: 0.5em 0 0;
  padding: 0.5em 2em;
  font-size: 1.1em;
  text-decoration: none;
}

.pricing-card.inverted .price-container button,
.pricing-card.inverted .price-container a {
  background: var(--main-white);
  color: var(--main-green);
}

.pricing-card.inverted .price-container button:hover,
.pricing-card.inverted .price-container a:hover {
  background: rgb(242, 242, 242);
}

.pricing-card.inverted .price-container a .icon {
  max-width: 1em;
}

.price-container .active-plan-button {
  background: linear-gradient(87.8deg, #2980a4 10%, #8bc260 160%);
  color: var(--main-white);
  border: var(--main-green) 1px solid;
  cursor: default;
}

.price-container .active-plan-button:hover {
  background: linear-gradient(87.8deg, #2980a4 10%, #8bc260 150%);
  color: var(--main-white);
  border: var(--main-green) 1px solid;
}

.price-container .your-plan-text {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.price-container .your-plan-text .icon {
  font-size: 1.1em;
}
.price-container .ahchu-icon {
  max-width: 15px;
}

.pricing-card h2 {
  color: var(--text-black);
  font-weight: 700;
  font-size: 1.5em;
  text-align: left;
  width: 100%;
  margin-bottom: 0.5em;
  margin-top: 0;
  height: 3em;
}

.pricing-card.inverted h2 {
  color: var(--main-white);
}

.features {
  padding: 0;
  height: 15em;
}

.features.Growth li:nth-child(1),
.features.Pro li:nth-child(1),
.features.Enterprise li:nth-child(1),
.features.Starter li:nth-child(1) {
  font-weight: 700;
}

.features li {
  display: flex;
  align-items: center;
  gap: 0.5em;
  line-height: 1.2em;
  margin: 1em 0;
  transition: 0s;
  font-size: 0.8em;
}

.pricing-card.inverted .features li {
  color: var(--main-white);
}

.features li.passive {
  color: #c2c2c2;
}

.features li .icon {
  color: var(--main-green);
  font-size: 1em;
  width: 1em;
  flex: 0 0 1em;
}

.features li.passive .icon {
  opacity: 0 !important;
}

.pricing-card.inverted li .icon {
  color: var(--main-white);
}
@media (max-width: 1700px) {
  .ad-health-checkup-card {
    width: 100%;
  }
}
@media (max-width: 1500px) {
  .pricing-card {
    font-size: 0.9em;
  }
}

@media (max-width: 1400px) {
  .ad-health-checkup-card .features {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1100px) {
  .ad-health-checkup-card {
    flex-direction: column;
  }

  .ad-health-checkup-card .content {
    flex-direction: column;
    gap: 30px;
  }

  .ad-health-checkup-card .left-section {
    align-items: flex-start;
    text-align: left;
  }

  .ad-health-checkup-card .left-section .plan-name-row h2 {
    text-align: left;
  }

  .ad-health-checkup-card .left-section .price-container {
    align-items: flex-start;
  }

  .ad-health-checkup-card .features {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .pricing-card {
    font-size: 0.8em;
  }
}

@media (max-width: 900px) {
  .pricing-card {
    flex-direction: column;
    width: 100%;
    max-width: unset;
    font-size: 0.95em;
  }

  /* .pricing-card.no-border-small {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }

  .pricing-card.no-border-medium {
    border-radius: 1em;
  }

  .pricing-card.no-border-large {
    border-bottom-right-radius: 1em;
    border-bottom-left-radius: 1em;
  }

   */

  .pricing-card .lines {
    opacity: 0.3;
  }

  .break {
    display: none;
  }

  .pricing-card .price-container.xs {
    display: block;
  }

  .pricing-card .mobile-title {
    display: block;
    margin-bottom: 1em;
  }

  .pricing-card .calculator-box .pageview-number {
    margin: 0;
  }

  .pricing-card .calculator-box .pageview-text {
    font-size: 1.2em;
  }

  .price-container button {
    justify-content: center;
    font-size: 0.9em;
  }
}

@media (max-width: 485px) {
  .price-container {
    flex-direction: column;
    gap: 0.5em;
  }
  .pricing-card .features {
    height: 14em;
  }

  .price-container .price {
    margin-bottom: 0.5em;
  }

  .price-container button {
    flex: 1 1 auto;
    min-width: 180px;
  }

  .price-container {
    margin-top: 3em;
  }
}

@media (max-width: 768px) {
  .ad-health-checkup-card .left-section {
    align-items: center;
    text-align: center;
  }

  .ad-health-checkup-card .left-section .plan-name-row h2 {
    text-align: center;
  }

  .ad-health-checkup-card .left-section .price-container {
    align-items: center;
  }

  .ad-health-checkup-card .features {
    grid-template-columns: 1fr;
  }

  .ad-health-checkup-card .features li {
    white-space: normal;
  }
}
