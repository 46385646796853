.publish-button {
  border: none;
  outline: none;
  padding: 0.375em 1.25em !important;
  border-radius: 0.8em;
  background: white !important;
  color: var(--main-green) !important;
  font-weight: 500;
  font-family: 'Poppins';
  font-size: 0.9em;
  cursor: pointer;
  transition: all 100ms;
  display: flex;
  align-items: center;
  border: 1px solid var(--main-green);
}

.publish-button:hover {
  background: var(--main-green) !important;
  color: white !important;
  border: 1px solid var(--main-green);
}

.publish-button:disabled {
  background: #f9f9f9 !important;
  color: var(--main-green) !important;

  opacity: 0.5;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  border-color: var(--main-green) !important;
  cursor: not-allowed;
}

.publish-button .fa-cloud-upload-alt {
  font-size: 22px;
}
