.modal-container.CAMPAIGN_DEACTIVATION {
  background: var(--main-white);
  box-shadow: 0px 2.172px 32.522px 0px rgba(0, 0, 0, 0.25);
  width: 90%;
  max-width: 900px;
  height: 56vh;
  overflow: hidden;
}

.campaign-deactivation {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em 1.5em !important;
  position: relative;
}

.campaign-deactivation h3,
.campaign-deactivation p {
  margin: 0;
}

.campaign-deactivation .close-modal {
  display: flex;
  width: 100%;
  height: 28px;
  position: relative;
}

.campaign-deactivation .close-modal .cross {
  position: absolute;
  right: 0;
  width: 28px;
  height: 28px;
  opacity: 0.5;
  transition: all 150ms;
  cursor: pointer;
}

.campaign-deactivation .close-modal .cross:hover {
  opacity: 1;
}

.campaign-deactivation .close-modal .cross:before,
.close-modal .cross:after {
  position: absolute;
  left: 14px;
  content: ' ';
  height: 29px;
  width: 1px;
  background-color: #000;
}

.campaign-deactivation .close-modal .cross:before {
  transform: rotate(45deg);
}

.campaign-deactivation .close-modal .cross:after {
  transform: rotate(-45deg);
}

.campaign-deactivation .content {
  display: flex;
  flex: 1;
  gap: 2.5em;
}

.campaign-deactivation .content .information-container,
.campaign-deactivation .content .preview-container {
  flex: 1;
}

.campaign-deactivation .content .preview-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.campaign-deactivation .content .preview-container .ad-creative-container {
  position: relative;
  padding: 348px 250px;
  margin-right: -250px;
  margin-bottom: -375px;
  border-radius: 4px;
  overflow: hidden;
  /* transform: scale(0.6) translate(-10%, -45%); */
  transform: scale(0.55) translate(-15%, -55%);
  box-shadow: 0px 1.192px 11.024px 0px rgba(0, 0, 0, 0.25);
  background: rgb(223, 223, 223);
  background: linear-gradient(130deg, #f1f1f1, #cdcdcd);
  background-size: 200% 200%;
  -webkit-animation: gradientAnimation 2s ease-in-out infinite;
  -moz-animation: gradientAnimation 2s ease-in-out infinite;
  animation: gradientAnimation 2s ease-in-out infinite;
  z-index: 5;
}

.campaign-deactivation
  .content
  .preview-container
  .ad-creative-container
  iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.campaign-deactivation .content .information-container {
  display: flex;
  flex-direction: column;
}

.campaign-deactivation .content .information-container .top,
.campaign-deactivation .content .information-container .bottom {
  flex: 1;
}

.campaign-deactivation .content .information-container .top {
  display: flex;
  flex-direction: column;
  gap: 1.25em;
}

.campaign-deactivation .content .information-container .top .title {
  font-size: 2em;
  line-height: 1.2;
  font-weight: 700;
}

.campaign-deactivation
  .content
  .information-container
  .top
  .title
  .gradient-text {
  background: linear-gradient(
    263deg,
    var(--second-green) 44.19%,
    var(--primary-green) 96.46%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.campaign-deactivation .content .information-container .top .description {
  color: #575757;
  line-height: 1.2;
}

.campaign-deactivation .content .information-container .bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4em;
}

.campaign-deactivation .content .information-container .bottom .milestones {
  display: flex;
  justify-content: center;
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border {
  position: relative;
  width: 85%;
  height: 8px;
  border: 1px dashed #47b781;
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .inner-line {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 2px;
  width: 100%;
  background: #47b781;
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .circle {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.875em;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 2;
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .ai-ad-creation {
  right: 100%;
  transform: translate(50%, -50%);
  background: #f4f4f4;
  color: #575757;
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .improved-campaign-sales {
  left: 100%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    263deg,
    var(--second-green) 44.19%,
    var(--primary-green) 96.46%
  );
  color: var(--main-white);
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .ai-ad-creation-text {
  font-weight: 500;
  text-align: center;
  font-size: 0.7em;
  line-height: 1.25;
  position: absolute;
  top: 100%;
  right: 100%;
  transform: translate(50%, 100%);
  color: #000;
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .improved-campaign-sales-text {
  width: 100px;
  font-weight: 500;
  text-align: center;
  font-size: 0.7em;
  line-height: 1.25;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, 100%);
  background: linear-gradient(
    263deg,
    var(--second-green) 44.19%,
    var(--primary-green) 96.46%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.8em;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid var(--text-green);
  width: 100px;
  padding: 0.75em;
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .audience-optimization {
  top: 50%;
  left: 18%;
  transform: translateY(1em);
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .audience-optimization::before {
  content: '';
  display: block;
  position: absolute;
  width: 2px;
  height: 1em;
  background: var(--second-green);
  top: 0;
  transform: translateY(-1em);
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .ai-ad-optimization {
  bottom: 50%;
  right: 18%;
  transform: translateY(-1em);
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .milestones
  .main-border
  .ai-ad-optimization::after {
  content: '';
  display: block;
  position: absolute;
  width: 2px;
  height: 1em;
  background: var(--second-green);
  bottom: 0;
  transform: translateY(1em);
}

.campaign-deactivation .content .information-container .bottom .buttons {
  display: flex;
  gap: 1.25em;
}

.campaign-deactivation .content .information-container .bottom .buttons button {
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(126, 191, 89, 0.35);
  transition: all 200ms;
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .buttons
  button:hover {
  box-shadow: 0px 2px 11px 0px rgba(126, 191, 89, 0.6);
  transform: translateY(-1px);
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .buttons
  button:active {
  transform: translateY(0);
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .buttons
  .let-ai-optimize-btn {
  font-weight: 500;
  border: 1px solid transparent;
  color: var(--main-white);
  background: var(--second-green);
}

.campaign-deactivation
  .content
  .information-container
  .bottom
  .buttons
  .close-campaign-btn {
  font-weight: 500;
  border: 1px solid var(--second-green);
  color: var(--text-green);
  background: #fcfcfc;
}

.campaign-deactivation .preview-container .circle {
  position: absolute;
  border-radius: 50%;
  border: 1px dashed var(--second-green);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.campaign-deactivation .preview-container .circle.top {
  width: 144px;
  height: 144px;
  top: 10%;
  left: 5%;
}

.campaign-deactivation .preview-container .circle.top::after {
  content: '';
  display: block;
  background: transparent;
  border: 3px solid var(--second-green);
  width: 124px;
  height: 124px;
  border-radius: 50%;
}

.campaign-deactivation .preview-container .circle.bottom {
  width: 160px;
  height: 160px;
  bottom: 10%;
  right: 10%;
}

.campaign-deactivation .preview-container .circle.bottom::after {
  content: '';
  display: block;
  background: transparent;
  border: 3px solid var(--second-green);
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .campaign-deactivation .content .preview-container {
    display: none;
  }
}

@media (max-width: 800px) {
  .modal-container.CAMPAIGN_DEACTIVATION {
    min-width: 450px;
  }
}
