.crm-report {
  padding: 2em;
  padding-bottom: 4em;
  max-width: 1400px;
  margin: 0 auto;
}

.crm-report h1 {
  margin: 0;
}

.crm-report .row.control-row {
  margin: 0;
  z-index: 10;
  align-items: center;
}

.crm-report h6 {
  font-weight: 400;
  font-size: 0.8em;
  margin: 0;
}

.crm-report .row.trial-switch {
  margin-bottom: 0;
  width: 100%;
}

.crm-report .row.trial-switch .switch {
  max-width: 240px;
  font-size: 0.85em;
  margin-right: auto;
}

.crm-report .row.info-badges {
  margin-top: 1em;
}

.crm-report .action-button {
  max-width: 120px;
  font-size: 0.9em;
  height: 2em;
}

.crm-report .row {
  margin: 2em auto;
  justify-content: space-evenly;
}

.crm-report .row > * {
  flex: 1 1 15%;
  min-width: 40%;
}

.crm-report h4 {
  font-weight: 600;
  font-size: 1.2em;
  margin-top: 0.5em;
}

.crm-report table {
  margin-bottom: 0.5em;
}

.crm-report table b {
  font-weight: 600;
}

.crm-report .card {
  overflow-y: scroll;
}
main.scrolled .crm-report .control-row {
  background: var(--main-white);
  box-shadow: 0 0px 5px #05050575;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  margin: 0;
  padding: 5px 5%;
}

main.scrolled .crm-report h6 {
  display: none;
}

@media (max-width: 900px) {
  .crm-report {
    padding: 0;
  }

  .crm-report .row.wrappable {
    flex-direction: column;
    align-items: stretch;
  }

  .crm-report .row.wrappable .card {
    width: 100%;
  }

  .crm-report .table-cont {
    font-size: 0.8em;
  }

  .info-badge-card {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .crm-report {
    padding: 0em;
    width: -webkit-fill-available;
  }
  .info-badge-card {
    font-size: 0.7em;
  }
  .crm-report .row {
    margin: 1em auto;
    flex-direction: column;
    gap: 1em;
  }

  .crm-report .row > * {
    flex: 1 1 100%;
    width: 100%;
  }

  .crm-report .row.info-badges {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
  }

  .crm-report .row.info-badges > * {
    flex: 1 1 45%;
    min-width: 320px;
  }

  .crm-report .row.control-row {
    flex-direction: row;
    padding: 0.5em;
  }

  .crm-report .title-container {
    flex: 1;
  }

  .crm-report h1 {
    font-size: 1.5em;
  }

  .crm-report .card {
    padding: 0.5em;
    margin: 0;
    overflow-y: auto;
  }

  .crm-report .table-cont {
    font-size: 0.75em;
    overflow-x: auto;
  }

  main.scrolled .crm-report .control-row {
    padding: 5px 1em;
  }
}
