@keyframes memberUp {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.your-team {
  .your-team-page {
    z-index: 10;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2em 0em;
    width: 90%;
    margin: 0 auto;
    min-height: 100vh;
    align-items: center;
    max-width: 1650px;
  }

  .your-team-container {
    flex: 1;
    width: 40%;
    max-width: 600px;
    padding-right: 40px;
  }
  .your-team-container .intro-headline-container {
    height: 8em;
  }
  .your-team-description {
    color: #7d7d7d;
    text-align: left;
    z-index: 10;

    font-weight: 400;
    margin-top: 1.5em;
    margin-bottom: 1em;
    letter-spacing: -1px;
    font-size: var(--font-24);
  }

  .your-team-highlight {
    background: linear-gradient(to right, #46b680, #8bc45d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    display: block;
    font-size: var(--font-24);
    margin-bottom: 1.5em;
    font-weight: 600;
  }

  .your-team-members {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }

  .your-team-member {
    background: var(--main-green-gradient-transparent);

    border-radius: 10px;
    border: 1px solid #abb1b8;
    width: 160px;
    text-align: center;
    padding-bottom: 0px;
    overflow: hidden;
    animation: memberUp 0.8s ease-in;
  }

  .your-team-member-image {
    height: 150px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
  }

  .your-team-member-image img {
    width: 90%;
    height: 100%;
    object-fit: cover;
  }
  .your-team-member .your-team-member-info {
    background-color: white;
    text-align: left;
    padding: 0.7em 0em 0em 1em;
    position: relative;
    height: 5em;
    clip-path: polygon(
      100% 100%,
      0% 100%,
      0% 11.72%,
      2% 11.1%,
      4% 10.51%,
      6% 9.96%,
      8% 9.46%,
      10% 9.01%,
      12% 8.62%,
      14% 8.29%,
      16% 8.03%,
      18% 7.83%,
      20% 7.71%,
      22% 7.65%,
      24% 7.67%,
      26% 7.75%,
      28% 7.91%,
      30% 8.13%,
      32% 8.43%,
      34% 8.78%,
      36% 9.2%,
      38% 9.67%,
      40% 10.19%,
      42% 10.75%,
      44% 11.36%,
      46% 12%,
      48% 12.67%,
      50% 13.36%,
      52% 14.06%,
      54% 14.77%,
      56% 15.48%,
      58% 16.18%,
      60% 16.86%,
      62% 17.53%,
      64% 18.16%,
      66% 18.76%,
      68% 19.32%,
      70% 19.83%,
      72% 20.3%,
      74% 20.7%,
      76% 21.04%,
      78% 21.32%,
      80% 21.54%,
      82% 21.68%,
      84% 21.75%,
      86% 21.76%,
      88% 21.69%,
      90% 21.55%,
      92% 21.34%,
      94% 21.07%,
      96% 20.73%,
      98% 20.33%,
      100% 19.87%
    );
    margin-top: -30px;
  }

  .your-team-member-name {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: var(--font-16);
    color: #33383d;
  }

  .your-team-member-role {
    font-size: var(--font-14);
    color: #7d7d7d;
    font-weight: 500;
  }

  .your-team-calendly {
    flex: 1;
    max-width: 60%;
    position: relative;
    height: 700px;
  }

  .background-image {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    object-fit: fill;
    z-index: -1;
    margin-right: -4em;
    margin-top: -2em;
    filter: grayscale(100%);
    max-height: 95vh;
    inset-area: center;
  }

  .calendly-inline-widget {
    width: 100%;
    min-width: 1000px;
    height: 700px;
    z-index: 10;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translateX(-75px) translateY(0px);
    padding-left: 0;
  }
  .cont {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    padding: 2em 0em;

    margin: 0 auto;
  }
  .action-button {
    height: 3em;
    font-size: 1em;
    padding: 0 1em;
    background: var(--main-green-gradient);
    font-weight: 600;
  }
  .action-button:hover {
    background: var(--main-green-gradient-hover);
  }

  @media (max-width: 1460px) {
    .calendly-inline-widget {
      transform: scale(0.92) translateX(-120px) translateY(0px);
    }
  }

  @media (max-width: 1300px) {
    .your-team-page {
      flex-direction: column;
      align-items: center;
    }

    .your-team-container,
    .your-team-calendly {
      max-width: 100%;
      width: 100%;
      padding-right: 0;
    }

    .your-team-calendly {
      margin-top: 40px;
    }

    .calendly-inline-widget {
      height: 685px !important;
      transform: none;
      transform-origin: none;
      padding-left: 0;
    }
  }

  @media (max-width: 1100px) {
    .calendly-inline-widget {
      height: 1100px !important;
      margin: 0 auto;
      min-width: 700px;
    }
  }

  @media (max-width: 850px) {
    .calendly-inline-widget {
      height: 1000px !important;
      margin: 0 auto;
      min-width: 600px;
    }
    .background-image {
      display: none;
    }
  }
  @media (max-width: 750px) {
    .your-team-calendly {
      margin-top: 2em;
    }
  }

  @media (max-width: 650px) {
    .calendly-inline-widget {
      min-width: 400px !important;
      transform: none;
    }
  }

  @media (max-width: 630px) {
    .your-team-page {
      padding: 20px;
    }
    .your-team-member {
      padding-bottom: 0;
    }
    .your-team-description,
    .your-team-highlight {
      text-align: left;
    }

    .your-team-members {
      flex-direction: column;
      align-items: center;
    }

    .your-team-member {
      width: 100%;
      max-width: 200px;
    }
  }

  @media (max-width: 470px) {
    .calendly-inline-widget {
      min-width: 350px;
      overflow: hidden !important;
      transform: none;
      height: 1032px !important;
    }
    .cont {
      justify-content: center !important;
      align-items: center !important;
    }
  }

  @media (max-width: 420px) {
    .calendly-inline-widget {
      min-width: 320px !important;
      overflow: hidden;
    }
  }
}
