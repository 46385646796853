.see-all-alarms-modal .alarm-part-wrapper {
  margin-top: 1em;
}

.see-all-alarms-modal .alarm-part-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  height: auto;
  padding: 1em 0.5em;
  max-height: 400px;
  overflow: scroll;
}

.alarm-part-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  height: 120px;
}

.alarm-part-wrapper .see-all-button {
  /* display: block;
  position: absolute; */
  align-self: center;
  bottom: 5px;
}

.see-all-alarms-modal .one-alarm-container,
.one-alarm-container {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  font-size: 0.8em;
  align-items: center;
  background: #f7f7f7;
  padding: 0.3em 1em;
  border-radius: 10px;
  cursor: pointer;
}
.see-all-alarms-modal .one-alarm-container,
.one-alarm-container .attached-users div {
  opacity: 0.9;
}
.see-all-alarms-modal .one-alarm-container,
.one-alarm-container .attached-users div:hover {
  opacity: 1;
}

.see-all-alarms-modal .one-alarm-container:hover,
.one-alarm-container:hover {
  background: rgb(245, 245, 245);
}

.see-all-alarms-modal .one-alarm-container.new,
.one-alarm-container.new {
  justify-content: center;
}

.see-all-alarms-modal .one-alarm-container.new .new-title,
.one-alarm-container.new .new-title {
  font-weight: 600;
  color: #2a895b;
}

.see-all-alarms-modal .one-alarm-container .due-date,
.one-alarm-container .due-date {
  font-weight: 600;
  color: #016b92;
  flex: 0 0 auto;
}

.see-all-alarms-modal .one-alarm-container .due-date.over,
.one-alarm-container .due-date.over {
  font-weight: bold;
  color: #f73c3c;
}

.see-all-alarms-modal .one-alarm-container .text-bar,
.one-alarm-container .text-bar {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  overflow-x: hidden;
}

.see-all-alarms-modal .one-alarm-container .title,
.one-alarm-container .title {
  flex: 0 1 auto;
  text-wrap: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.see-all-alarms-modal .one-alarm-container .note,
.one-alarm-container .note {
  flex: 1 1 auto;
  text-wrap: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: #808080;
  font-style: italic;
}

/* Modals */
.alarm-modal {
  width: 500px;
  border-radius: 16px;
  padding: 24px;
}

.alarm-modal .modal-title {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 24px;
}

.alarm-modal .modal-title .alarm-name {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--text-gray);
}
.alarm-modal .modal-title button {
  background: transparent;
  border: none;
  color: #050e13;
  font-weight: 500;
  font-size: 1em;
}
.alarm-modal .modal-title .customer-name {
  color: #16a34a;
  font-weight: 500;
}
.alarm-modal .attached-users {
  padding-left: 0;
}

.alarm-modal .property {
  margin: 0;
  align-items: center;
}

.alarm-modal .property-desc {
  font-weight: 300;
  color: #050e13;

  font-size: 1em;
}

.alarm-modal .due-date-selector {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 16px 0;
}

.alarm-modal .due-date-selector > div {
  display: flex;
  align-items: center;
}

.alarm-modal .due-date-selector .selectable,
.alarm-modal .due-date-selector input {
  border: 1.63px solid #ccc;
  border-radius: 0.8em;
  padding: 6px 12px;
  font-size: 0.95em;
  color: #334155;
  background: #fff;
  transition: all 0.2s ease;
  min-width: 140px;
  height: 36px;
  line-height: 1;
  box-shadow: none;
}

.alarm-modal .due-date-selector .selectable:hover,
.alarm-modal .due-date-selector input:hover {
  border-color: #cbd5e1;
  background: #f8fafc;
}

.alarm-modal .due-date-selector .selectable:focus,
.alarm-modal .due-date-selector input:focus {
  border-color: var(--main-green);
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background: #ffffff;
  outline: none;
}

.alarm-modal .due-date-selector button {
  background: var(--main-green);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 36px;
  line-height: 1;
}

.alarm-modal .due-date-selector button:hover {
  background: var(--main-green-hover);
}

.alarm-modal .due-date-selector .separator {
  color: #94a3b8;
  font-weight: 500;
  margin: 0 4px;
}

.alarm-modal button {
  border-radius: 8px;
  transition: all 0.2s ease;
  padding: 0.5em 1em;
}

.alarm-modal button.action-button.yes {
  background: var(--main-green);
  color: white;
  font-weight: 500;
}

.alarm-modal button.action-button.yes:hover {
  background: var(--main-green-hover);
}

.alarm-modal .attach-meeting-link-button {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--main-green);
  background: transparent;
  margin: 0;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
  border: 1px dashed var(--main-green);
}

.alarm-modal .attach-meeting-link-button:hover {
  transition: all 0.2s ease;
  background: var(--main-green-hover);
  border: 1px dashed var(--main-green);
  color: white;
}

.alarm-modal .modal-footer {
  margin-top: 24px;
  padding-top: 20px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

/* People selector styling */
.alarm-modal .people-selector {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: 0;
}

.alarm-modal .people-selector button {
  border: 1px solid #e2e8f0;
  background: #f8fafc;
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 0.9em;
  color: #64748b;
}

.alarm-modal .people-selector button:hover {
  background: var(--main-green-hover);
  border-color: var(--main-green);
}

.alarm-modal .people-selector button.selected {
  background: var(--main-green);
  color: white;
}

.alarm-modal .form-control {
  position: relative;
}

.alarm-modal .remove-predefined-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #64748b;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 1;
}

.alarm-modal .remove-predefined-button:hover {
  background: #f1f5f9;
  color: #ef4444;
}

.alarm-modal .form-control .stroke-textarea {
  width: 100% !important;
}

.alarm-modal .stroke-input-root {
  min-height: 4em !important;
}

.alarm-modal .modal-middle {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

@media (max-width: 768px) {
  .alarm-modal {
    width: 400px;
    padding: 16px;
  }
  .alarm-modal .modal-title {
    font-size: 0.9em;
  }
}
