.checklist-list-long .selected {
  order: -1;
}

.checklist-list-long li {
  transition: all 1s ease;
}

.configure-facebook {
  height: 100%;
  flex-direction: column;
  margin-top: 1em;
  gap: 1em;
}

.configure-facebook .facebook-logged-in .row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.configure-facebook .facebook-login-cont a .icon {
  font-size: 0.9em;
}

.configure-facebook .connect-text {
  font-size: 1em;
}

.configure-facebook .fb-login-button {
  font-size: 1.1em;
}

.configure-facebook .facebook-logged-in p {
  margin: 0 0 5px;
}

.configure-facebook .facebook-logged-in img {
  max-width: 80px;
  border-radius: 50px;
  border: 2px solid rgb(0, 102, 166);
}

.configure-facebook .facebook-logged-in .button {
  margin: 0;
  padding: 2px 5px;
}

.configure-facebook .ad-account-select {
  margin: 0;
  width: 100%;
  max-height: 16em;
  overflow-y: auto;
  padding: 0 5px;
  max-width: 1300px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.configure-facebook .ad-account-select::-webkit-scrollbar {
  display: none;
}

.configure-facebook .ad-account-select ul {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  width: 100%;
  gap: 0.5em;
  margin-top: 10px;
}

.configure-facebook .ad-account-select li {
  border: 1px solid #dfeaf2;
  border-radius: 0.8em;
  padding: 0.5em 1em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.2s ease;
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.configure-facebook .ad-account-select li:hover {
  background: #f8fafc;
  border-color: #dfeaf2;
}

.configure-facebook .ad-account-select li.selected {
  background: #c3e2d334;
  border: 1px solid var(--second-green);
  position: relative;
}

.configure-facebook .ad-account-select li.selected::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(180deg, #10b981 0%, #059669 100%);
}

.configure-facebook .ad-account-select li .name {
  color: #1e293b;
  font-weight: 500;
  font-size: 1em;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.configure-facebook .ad-account-select li .id {
  font-size: 0.7em;
  color: #64748b;
}

.configure-facebook .ad-account-select li .dot {
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background: var(--main-green);
  box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.15);
  transition: all 0.2s ease;
}

.configure-facebook .ad-account-select li .dot.red {
  background: var(--main-red);
  box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.15);
}

.configure-facebook .ad-account-select li .dot.yellow {
  background: var(--main-yellow);
  box-shadow: 0 0 0 3px rgba(234, 179, 8, 0.15);
}

.configure-facebook .ad-account-select li:hover .dot {
  transform: scale(1.1);
}

.configure-facebook .ad-account-select li.selected .name,
.configure-facebook .ad-account-select li.selected .icon {
  font-weight: 600;
}

.configure-facebook .ad-account-select li.selected .name {
  color: var(--second-green);
}

.configure-facebook .ad-account-select li.not-owned {
  background: var(--main-gray);
  cursor: not-allowed;
  opacity: 1;
  border-color: #dfeaf2;
}

.configure-facebook .ad-account-select li.not-owned .name {
  color: #7f7f7f;
}

.configure-facebook .ad-account-select li.not-owned:hover {
  background: var(--main-gray);
  border-color: #e2e8f0;
  transform: none;
}

.configure-facebook .ad-account-select li.not-owned .id {
  color: #919191;
}

.configure-facebook .ad-account-select li:hover {
  background: rgb(245, 245, 245);
}

.configure-facebook .ad-account-select li.selected {
  background: #c3e2d334;
  box-shadow: none;
  border: 1px solid var(--second-green);
  position: relative;
  overflow: hidden;
}

.configure-facebook .ad-account-select li.selected::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: var(--second-green);
}

.configure-facebook .ad-account-select li.selected .name,
.configure-facebook .ad-account-select li.selected .icon {
  font-weight: 600;
}

.configure-facebook .ad-account-select li.selected .name {
  color: var(--second-green);
}

.configure-facebook .ad-account-select li.selected .id {
  color: var(--button-gradient);
}

.configure-facebook .ad-account-select li.selected:hover {
  background: #c3e2d350;
}

.configure-facebook .ad-account-select li.inactive {
  cursor: not-allowed;
  background: var(--main-gray);
}

.configure-facebook .ad-account-select li .row {
  display: flex;
  align-items: center;
  gap: 1em;
  flex: 1;
}

.configure-facebook .ad-account-select li .dot {
  width: 0.7em;
  height: 0.7em;
  border-radius: 20px;
  background: var(--main-green);
}

.configure-facebook .ad-account-select li .dot.red {
  background: var(--main-red);
}

.configure-facebook .ad-account-select li .dot.yellow {
  background: var(--main-yellow);
}

.configure-facebook .ad-account-select li .name {
  color: rgb(0, 78, 130);
  font-weight: 500;
  font-size: 1em;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.configure-facebook .ad-account-select li .id {
  font-size: 0.7em;
  color: gray;
}

.configure-facebook .ad-account-select li .icon {
  display: none;
}

.configure-facebook .ad-account-select li.selected .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--main-green);
  color: white;
  font-size: 10px;
  margin-left: 8px;
  box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.15);
  transition: all 0.2s ease;
}

.configure-facebook .ad-account-select li.selected:hover .icon {
  transform: scale(1.1);
}

.configure-facebook .ad-account-select li .info-text {
  color: #919191;
}

.configure-facebook .ad-accounts-card .card-header {
  padding: 0;
}

.configure-facebook .ad-accounts-card .card-header > .row {
  padding: 0.5em 1em;
}

.configure-facebook .result-card {
  flex: 1 1 auto;
}

.configure-facebook .result-card .card-header p {
  margin: 0 5px 1em;
}

.configure-facebook .search-bar {
  margin: 0px 5px 0.7em;
  background: #f8fafc;
  border: 1px solid #dfeaf2;
  border-radius: 0.8em;
  font-size: 0.9em;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  max-width: 250px;
  gap: 1em;
  transition: all 0.2s ease;
}

.configure-facebook .search-bar:focus-within {
  border-color: #10b981;
  box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.08);
}

.configure-facebook .search-bar .search-icon {
  font-size: 1.2em;
  color: #64748b;
}

.configure-facebook .search-bar input {
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 1em;
  flex: 1 1 90%;
}

.configure-facebook .search-bar .clear-icon {
  cursor: pointer;
  font-size: 1.1em;
}

.configure-facebook .search-bar .clear-icon:hover {
  color: #059669;
  transform: scale(1.1);
  transition: all 0.2s ease;
}

.configure-facebook .search-bar input::placeholder {
  color: #94a3b8;
  font-weight: 400;
}

.ad-accounts .no-account-text {
  color: #9d9d9d;
  font-size: 1em;
  margin: 10px 0;
}

.configure-facebook .ad-account-select li .tos-warning {
  display: flex;
  gap: 0.5em;
  font-size: 0.9em;
  flex: 0 1 45%;
}

.configure-facebook .ad-account-select li .not-owned-text {
  font-size: 0.9em;
  flex: 0 1 45%;
}
