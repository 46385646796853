.templatesGridContainer {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  padding: 1rem;
  overflow: hidden;
  background: #ffffff;
  border-radius: 12px;

  border: 1px solid var(--border-main);
}

.templatesGridHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-main);
}

.enhencedCatalogTemplatesGridHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enhencedCatalogTemplatesGridHeaderRowTitle {
  font-weight: 600;
  color: var(--text-black);
}

.templatesGridTitle {
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  color: var(--text-black);
  transition: color 0.2s ease;

  &.active {
    color: var(--second-green);
  }

  &:hover {
    color: var(--second-green-hover);
  }
}

.templatesGridTitleDisabled {
  cursor: not-allowed;
  color: #666;
}

.templatesGridCloseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.2rem;
  color: #666;
  transition: all 0.2s ease;
  padding: 0.5rem;
  border-radius: 6px;
  
  &:hover {
    color: var(--text-black);
    background: #f5f5f5;
  }
}

.templatesGridTitleDisabled {
  font-size: .9em;
  cursor: not-allowed;
  color: #666;

  &:hover {
    color: #666;
  }
}

.templatesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(205px, 1fr));
  gap: 1.25rem;
  overflow-y: auto;
  height: 100%;
  padding: 0.5rem 0;
  justify-items: center;
}

.templateItem {
  cursor: pointer;
  border: 1px solid var(--border-main);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease;
  min-height: 230px;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 0.75rem;

  &:hover {
    transform: translateY(-2px);

    border-color: var(--second-green);
  }

  img {
    width: 100%;
    flex-grow: 1;
    object-fit: cover;
    border-radius: 6px;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.03);
  }

  p {
    margin: 0.75rem 0 0;
    text-align: center;
    font-size: 0.9rem;
    color: var(--text-black);
    font-weight: 500;
  }

  @media (max-width: 1024px) {
    min-height: 200px;
    padding: 0.5rem;

    p {
      font-size: 0.85rem;
      margin-top: 0.5rem;
    }
  }
}
