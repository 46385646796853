.intro-phones-container {
  position: relative;
  flex: 1;
  height: 37.5em;
  width: 18.8em;

  .intro-lights {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
    height: auto;
    z-index: 6;
    mix-blend-mode: screen;
    pointer-events: none;
  }
  .intro-shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 150%;
    height: auto;
    z-index: 6;

    pointer-events: none;
  }
  .intro-phones-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .intro-phones-image.intro-left-phone {
    z-index: 1;
  }

  .intro-phones-image.intro-right-phone {
    z-index: 3;
    transform: translateX(5px) translateY(-5px);
  }

  .intro-screens-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .intro-screen-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .intro-left-phone-screen,
  .intro-right-phone-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .intro-left-phone-screen {
    z-index: 2;
    mask-image: url('../../../shared/assets/images/intro/white-left-screen.svg');
    mask-size: 100% 100%;
    -webkit-mask-image: url('../../../shared/assets/images/intro/white-left-screen.svg');
    -webkit-mask-size: 100% 100%;
  }

  .intro-right-phone-screen {
    z-index: 4;
    mask-image: url('../../../shared/assets/images/intro/white-right-screen.svg');
    mask-size: 100% 100%;
    -webkit-mask-image: url('../../../shared/assets/images/intro/white-right-screen.svg');
    -webkit-mask-size: 100% 100%;
  }

  .intro-screen-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  @media (max-width: 1000px) {
    .intro-lights,
    .intro-shadow {
      display: none;
    }
  }
}

@media (max-width: 1000px) {
  .intro-phones-container {
    width: 100%;
    max-width: 18.8em;
    height: 37.5em;
    margin: 0 auto;
  }
}

@media (max-width: 468px) {
  .intro-phones-container {
    height: 31.3em;
    width: 15.6em;
  }
}
