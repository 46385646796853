.task-reminder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  box-shadow: 0 6px 15px 5px rgba(0, 0, 0, 0.2);
  background: var(--main-white);
  border-radius: 20px;
  padding: 15px;
}
.task-reminder-container::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid green;
}

.task-reminder-container .task-reminder-buttons {
  display: flex;
  align-items: center;
}

.task-reminder-option {
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.task-reminder-option:hover {
  background-color: var(--main-gray);
}

.task-reminder-add-note {
  margin-top: 0.5em;
  cursor: pointer;
  color: var(--dark-green);
}
