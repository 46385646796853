.edit-subscription form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 600px;
}

.edit-subscription form.wide {
    max-width: 800px;
}

.edit-subscription form .gen-box,
.edit-subscription form .dates-box {
    max-width: 350px;
    min-width: 200px;
}

.edit-subscription label {
    margin-bottom: 0.5rem;
    font-size: 0.9em;
}

.edit-subscription .select-cont {
    padding: 4px 8px
}

.edit-subscription select,
.edit-subscription input {
    padding: 0 1em 0 0;
    height: auto !important;
    min-height: 2em;
    background: none;
}

.edit-subscription input#price {
    padding: 4px 8px
}

.edit-subscription .date-input-cont {
    margin: 0 0 1em;
    max-width: 350px;
    box-shadow: 0 2px 5px 0 #ccc;
    border-radius: 0.5em;
}

.date-input-cont .icon {
    font-size: 1em;
}

.edit-subscription .form-control:first-child {
    margin-top: 0;
}

.edit-subscription button.small {
    height: min-content;
    padding: 3px 5px;
    font-size: 0.9em;
    margin: 0.5em 0
}