.ticket-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 1em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  background-color: var(--main-white);
  cursor: pointer;
  transition: all 0.3s;

  .ticket-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ticket-card-header-left {
      flex: 1;

      .ticket-card-title {
        margin: 0;
        font-size: 1.2em;
        font-weight: 500;
        color: #333;
        white-space: nowrap;
        max-width: 27ch;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ticket-card-header-right {
      .ticket-card-date {
        font-size: 0.85em;
        color: #666;
      }
    }
  }

  .ticket-card-body {
    .ticket-card-description {
      margin: 0;
      font-size: 0.9em;
      line-height: 1.3;
      color: #666;
      word-break: break-all;
    }
  }

  .ticket-card-footer {
    display: flex;
    align-items: center;
    gap: 1em;
    font-size: 0.85em;

    .ticket-card-status {
      padding: 0.2em 1em;
      border-radius: 1em;
      font-weight: 600;
      position: relative;
      overflow: hidden;
      min-width: 10ch;
      text-align: center;

      &.in-progress {
        background: #feeac1;
        color: #523d21;
      }

      &.postponed {
        background: #d1e1f0;
        color: #253444;
      }

      &.completed {
        background: #daeada;
        color: #354839;
      }

      &.pending {
        background: #f1eef2;
        color: #565355;
      }

      &.closed {
        background: #f0dada;
        color: #4f2f2f;
      }
    }

    .ticket-card-messages {
      display: flex;
      align-items: center;
      gap: 0.5em;
      color: #666;

      .icon-container {
        position: relative;
        display: flex;
        align-items: center;

        .icon {
          font-size: 1.2em;
        }

        .unread {
          position: absolute;
          top: -0.1em;
          right: -0.3em;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background: #ff4500;
          border: 1px solid var(--main-white);
        }
      }

      .count {
        font-size: 0.9em;
        font-weight: 600;
      }
    }
  }

  &:hover {
    box-shadow:
      rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      var(--transparent-gray) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
}
