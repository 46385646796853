.desktop-is-better {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon-container {
    font-size: 50px;
    color: white;
  }
  .close-icon-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
 
  p {
    font-size: 18px;
    color: white;
    font-weight: 600;
    text-align: center;
  }
}
