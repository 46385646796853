.full-funnel-campaigns .sub-items {
  padding: 0 0.7em;
  font-size: 0.8em;
}

.full-funnel-campaigns .create-campaign-btn {
  width: fit-content;
  padding: 0.7em;
  margin: 0.4em auto;
  border: none;
  border-radius: 0.8em;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  transition: background-color 0.3s ease;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 3em;
  background-color: #4b4a4a4a;
}
.full-funnel-campaigns .nav-item-right svg {
  transition: transform 0.3s ease;
  font-size: 0.9em;
}

.full-funnel-campaigns .create-campaign-btn {
  border: none;
  background: none;
  color: white;
  background: #75757525;
  margin-top: 0.8em;
}
.full-funnel-campaigns .create-campaign-btn svg {
  font-size: 1em;
}

.full-funnel-campaigns .view-all-campaigns-btn {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.full-funnel-campaigns .create-campaign-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.full-funnel-campaigns .sub-item-group {
  padding: 5px;
}

.full-funnel-campaigns .sub-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5em;
  font-size: 1em;
}
.full-funnel-campaigns .campaign-group {
  max-height: 240px;
  overflow-y: auto;
}
/* Chrome için mevcut stiller - kesinlikle değişmeyecek */
.full-funnel-campaigns .campaign-group::-webkit-scrollbar {
  width: 2px;
}

.full-funnel-campaigns .campaign-group::-webkit-scrollbar-track {
  background: #585858;
  border-radius: 0.3em;
}

.full-funnel-campaigns .campaign-group::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.3em;
}

.full-funnel-campaigns .campaign-group::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Sadece Firefox için özel stil */
@-moz-document url-prefix() {
  .full-funnel-campaigns .campaign-group {
    scrollbar-width: none; /* önce varsayılan scrollbar'ı gizle */
    position: relative;
  }

  .full-funnel-campaigns .campaign-group::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background: #585858;
    pointer-events: none;
  }
}

.full-funnel-campaigns .campaign-item {
  padding: 10px 8px;
  margin-top: 5px;
  margin-bottom: 6px;
  cursor: pointer;
  border-radius: 0.8em;
  transition: background-color 0.3s ease;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  font-weight: 400;
  background-color: #75757525;
}
.full-funnel-campaigns .campaign-label {
  max-width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-weight: bold;
  padding: 0.1em 0;
}
.full-funnel-campaigns .indicator {
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  background-color: #ccc;
  margin-left: 0.7em;
}

.indicator.active {
  background-color: #4caf50;
}
.full-funnel-campaigns .campaign-item:hover,
.full-funnel-campaigns .campaign-item.active {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 1470px), (max-height: 1030px) {
  .full-funnel-campaigns .campaign-group {
    max-height: 150px;
    overflow-y: auto;
  }
  .full-funnel-campaigns .sub-items {
    font-size: 0.8em;
  }
}

.nav-item.active.open {
  position: relative;
  background-color: #13364a;
  border-radius: 12px;
  padding: 2px;
  margin: calc(0.5em - 4px) calc(1em - 4px);
}

.nav-item.active.open::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: calc(-100% - 2px);
  background: linear-gradient(45deg, var(--main-green), #085a9c);
  border-radius: 14px;
  z-index: 0;
  opacity: 1;
}
.nav-item.full-funnel-campaigns.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  width: 100%;
  background: none;
  margin-right: 1em;
}
.nav-item.full-funnel-campaigns.disabled .nav-item-content:hover {
  background: none;
}
.nav-item.full-funnel-campaigns.disabled .nav-item-content .nav-item-right {
  display: none;
}
.nav-item.active.open .sub-items {
  position: relative;
  z-index: 1;
  margin: 0;
  padding-bottom: 0.5em;
  background-color: #13364a;
  border-radius: 10px;
  margin-top: 0.2em;
  overflow-y: auto;
}

.nav-item.active.open .sub-item-group,
.nav-item.active.open .campaign-group {
  background-color: #13364a;
}

.nav-item.active.open .nav-item-content {
  margin: 0;
}

.nav-item.active.open .create-campaign-btn {
  margin: 1em auto;
  width: auto;
}

.nav-item.active.open .sub-item-group {
}
.nav-item.full-funnel-campaigns {
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
}

.nav-item.full-funnel-campaigns .nav-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.9em;
  height: 3.5em;
  border-radius: 12px;
  margin: 0.5em 1em;
  position: relative;
  background-color: transparent;
}

.nav-item.full-funnel-campaigns.active:not(.open) .nav-item-content {
  position: relative;
  background-color: #13364a;
}

.nav-item.full-funnel-campaigns.active:not(.open) .nav-item-content::before {
  content: '';
  position: absolute;
  inset: -2px;
  border: 2px solid transparent;
  border-radius: 14px;
  background: linear-gradient(45deg, var(--main-green), #085a9c) border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.nav-item.full-funnel-campaigns.active .nav-item-content,
.nav-item.full-funnel-campaigns .nav-item-content:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 1470px), (max-height: 1030px) {
  .nav-item.full-funnel-campaigns .nav-item-content {
    height: 3em;
  }
}

.nav-item-left {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  pointer-events: none;
}

.nav-item-left svg,
.nav-item-left span {
  pointer-events: none;
}

.nav-item-right {
  pointer-events: auto;
}

/* new styles */

.nav-item.full-funnel-campaigns .nav-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.9em;
  height: 3.5em;
  border-radius: 12px;
  margin: 0.5em 1em;
  position: relative;
  background-color: transparent;
}

.nav-item.full-funnel-campaigns.active:not(.open) .nav-item-content {
  position: relative;
  background-color: #13364a;
}

.nav-item.full-funnel-campaigns.active:not(.open) .nav-item-content::before {
  content: '';
  position: absolute;
  inset: -2px;
  border: 2px solid transparent;
  border-radius: 14px;
  background: linear-gradient(45deg, var(--main-green), #085a9c) border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.nav-item.full-funnel-campaigns.active.open {
  background-color: #13364a;
  border-radius: 14px;
  padding: 0px;
}

.nav-item.full-funnel-campaigns.active.open .nav-item-content {
  margin: 0;
  border-radius: 12px 12px 0 0;
}

.nav-item.full-funnel-campaigns.active.open .sub-items {
  background-color: #13364a;
  padding: 0.5em;
  border-radius: 0 0 12px 12px;
}

.nav-item.full-funnel-campaigns.active.open .campaign-group {
  background-color: #13364a;
}

.nav-item.full-funnel-campaigns.active.open .create-campaign-btn {
  margin: 1em auto;
  width: auto;
}

@media (max-width: 768px) {
  .nav-item.full-funnel-campaigns {
    display: block;
  }

  .nav-item.full-funnel-campaigns .nav-item-content {
    margin: 0.5em 1em;
    border-radius: 12px;
  }

  .nav-item.full-funnel-campaigns.sidebar-open .nav-item-content {
    border-radius: 12px;
    margin: 0.5em 1em;
    border: none;
  }
}

/* Mevcut stiller korunacak, aşağıdaki stiller eklenecek */

.nav-item.full-funnel-campaigns {
  position: relative;
}

.nav-item-right {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.nav-item-right svg {
  font-size: 0.8em;
  transition: transform 0.3s ease;
}

.nav-item.dropdown-open .nav-item-right svg {
  transform: rotate(180deg);
}

/* Mobile Dropdown Styles */
.mobile-dropdown {
  position: relative;
  background: #13364a;
  border-radius: 0 0 12px 12px;
  margin: 0 1em;
  padding: 0.5em;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.nav-item.dropdown-open .mobile-dropdown {
  max-height: 500px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0.8em;
  border: none;
  background: none;
  color: white;
  font-size: 0.9em;
  text-align: left;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.dropdown-item.overview-btn {
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 0.5em;
}

.dropdown-item.create-btn {
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 1em;
}

.dropdown-section {
  margin-bottom: 1em;
}

.section-header {
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.7);
  padding: 0.5em 0.8em;
  margin-bottom: 0.5em;
}

.campaign-item {
  padding-left: 1.5em;
}

.campaign-item.active {
  background: rgba(76, 175, 80, 0.2);
  border: 1px solid rgba(76, 175, 80, 0.3);
}

@media (max-width: 768px) {
  .nav-item.full-funnel-campaigns {
    display: block;
  }

  .nav-item.full-funnel-campaigns .nav-item-content {
    margin: 0.5em 1em;
    border-radius: 12px;
  }

  .nav-item.dropdown-open .nav-item-content {
    border-radius: 12px 12px 0 0;
    background-color: #13364a;
  }

  .nav-item.dropdown-open .mobile-dropdown {
    max-height: 500px;
    opacity: 1;
    visibility: visible;
  }
}

/* Temel buton stilleri */
.nav-item.full-funnel-campaigns .nav-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.9em;
  height: 3.5em;
  border-radius: 12px;
  margin: 0.5em 1em;
  position: relative;
  background-color: transparent;
  transition: all 0.3s ease;
}

.nav-item.full-funnel-campaigns.active:not(.open) .nav-item-content {
  position: relative;
  background-color: #13364a;
}

.nav-item.full-funnel-campaigns.active:not(.open) .nav-item-content::before {
  content: '';
  position: absolute;
  inset: -2px;
  border: 2px solid transparent;
  border-radius: 14px;
  background: linear-gradient(45deg, var(--main-green), #085a9c) border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.nav-item.full-funnel-campaigns.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  width: 100%;
  background: none;
  margin-right: 1em;
}

.nav-item.full-funnel-campaigns.disabled .nav-item-content:hover {
  background: none;
}

.nav-item-left {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  pointer-events: none;
}

.nav-item-left svg,
.nav-item-left span {
  pointer-events: none;
}
