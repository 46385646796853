.App .config-page {
  margin: 0 auto 2em;

  padding: 2em 0 2em 2em;
}

.App.basic-user .config-page {
  margin: 0em auto 2em;
}

.config-page-content {
  padding: 0 2em;
  max-width: 1400px;
  margin: 0 auto;
}

.App .config-page h1,
.App.basic-user .config-page h1 {
  display: none;
}

.config-page .process-status-box {
  margin: 0.5em auto 1em !important;
}

.config-page .process-status-box .process-text {
  font-size: 1em !important;
  color: var(--text-gray);
  width: 100%;
  text-align: start !important;
  margin-bottom: 0.5em;
}

.config-page .process-status-box .process-text .emphesize {
  font-size: 1.1em;
  font-weight: 500;
  color: rgb(37, 37, 37);
}

.config-page .config-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  margin-top: 3em;
}

.config-page .config-completed {
  text-align: center;

  background: white;
  margin: 2em 0em 0em 0em !important;
  padding: 2em 0em 0em 0em;
}

.config-page .config-completed .button {
  text-align: center;
  margin: 1em auto;
  max-width: 18.125em;
  /* 290px */
  font-weight: 600;
  height: 3em;
  background: linear-gradient(
    32deg,
    var(--primary-green),
    var(--second-green) 42%
  );
  font-size: var(--font-18);
}

.config-page .config-completed .button:hover {
  transition: 0.3s;
  background: linear-gradient(
    32deg,
    var(--primary-green),
    var(--second-green) 80%
  );
}

.config-page .need-assistance {
  position: fixed;
  bottom: 1em;
  right: 1em;
  background: var(--main-white);
  box-shadow: var(--card-shadow);
  z-index: 100;
  padding: 0.5em 1em;
  border-radius: 0.7em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
  cursor: pointer;
}

.config-page .need-assistance.is-extended {
  gap: 1em;
  padding: 1em;
  cursor: default;
}

.config-page .need-assistance .icon {
  width: 2em;
  font-size: 1.5em;
}

.config-page .need-assistance .text {
  margin-bottom: 0.5em;
}

.config-page .need-assistance button {
  background: var(--main-green-gradient);
  font-weight: 600;
  font-size: 0.9em;
}

.config-page .need-assistance button:hover {
  background: var(--main-green-gradient-hover);
}

.config-page .need-assistance .right-side {
  max-width: 0;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  transition: 0.3s;
}

.config-page .need-assistance.is-extended .right-side {
  max-width: 20em;
  /* 320px */
}

.config-page .need-assistance.is-extended .close-icon {
  position: absolute;
  top: -0.5em;
  left: -0.5em;
  font-size: 1.5em;
  cursor: pointer;
}

.config-page .breadcrumb-container {
  padding: 0 0 0 2em;
  background: white;
  z-index: 1;
  margin: -2em -2em 0;
  /* Üst padding'i dengelemek için negatif margin */
}

.config-page .breadcrumb {
  margin: 0;

  font-weight: 600;
  color: #33383d;
}

.config-page .breadcrumb-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 1em 0;
}

.basic-user .breadcrumb-sticky-wrapper {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  margin: -2em -2em 0;
  padding: 2em 2em 0;
}

.breadcrumb-sticky-wrapper {
  z-index: 0;
}

/* Progress bar ve completed message için sticky container */
.process-status-container {
  position: sticky;
  top: var(--breadcrumb-height);
  background: white;
  z-index: 9;
  padding: 1em 0 0.5em 0;
  margin: -2em 0 0 0;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 8px 8px -8px;
  left: 50%;
  transform: translateX(0%);
  margin-left: calc(-100vw - var(--lg-navbar-width) + 50%);
  margin-right: calc(-100vw - var(--lg-navbar-width) + 50%);
}

.inner-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2em;
  width: 100%;
}

/* iOS için safe-area desteği */
@supports (-webkit-touch-callout: none) {
  .process-status-container {
    top: calc(var(--breadcrumb-height) + env(safe-area-inset-top, 0px));
  }
}

.process-status-container .inner-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2em;
  width: 100%;
}

@media (max-width: 1700px) {
  .process-status-container .inner-wrapper {
    max-width: 100%;
    padding: 0 0.5em;
  }

  .process-status-container {
    padding: 0.5em 0 0.5em 0;
    margin: -1.5em 0 0 0;
  }

  .config-page .config-completed {
    margin: 1em 0em 0em 0em !important;
  }

  .process-status-container .inner-wrapper {
    padding: 0 1em;
  }

  .config-page .config-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em !important;
    margin-top: 3em;
  }
}

@media (max-width: 1500px) {
  .App.basic-user .config-page {
    font-size: 0.85em;
  }
}

@media (max-width: 1200px) {
  .config-page .config-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .App .config-page {
    padding: 0em;
    margin: 0em !important;
  }

  .process-status-container {
    padding: 1em;
    margin: -1em -1em;
  }

  .config-page .config-completed {
    margin: 0.5em auto 1em;
  }
  .config-page-content {
    padding: 2em;
  }
}
