.dev-zone .edit-job-box .repeat-input {
    max-width: 45px;
}

.dev-zone .edit-job-box .row.text-edit .repeat-input:last-of-type {
    margin-left: 1.5em;
}

.dev-zone .edit-job-box .row.text-edit {
    margin: 0;
    gap: 5px;
}