.select-category-desc {
  font-size: 1.2em;
  color: rgb(117, 117, 117);
  font-weight: 500;
  padding: 2em 0em;
  margin: 0 auto;
  max-width: 1700px;
}
.flow-select-category-container {
  height: 100vh;
  align-content: center;
  padding: 2em;
}
.select-category-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1em;
  max-width: 1700px;
  margin: 0 auto;
}
.select-category-container .header-row {
  display: flex;
  flex-direction: row;

  justify-content: right;
  width: 100%;
}

.select-category-container .header-row h2 {
  margin: 0;
  font-weight: 600;
  font-size: 1em;
  color: #9099a2;
}
.select-category-desc .intro-headline-container {
  height: 4em !important;
}

.select-category-container .category-part {
  width: 100%;
  background: #f3f3f3;
  padding: 1.5em;
  border-radius: 2em;
  border: 1px solid #abb1b8;
  box-shadow: var(--main-box-shadow);
}
.category-part .category-part-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-gap: 1em;
  width: 100%;
}

.category-part-grid .category-part-row-item .category-name-overlay {
  position: absolute;
  z-index: 10;
  background: rgb(255, 255, 255);
  color: #7d7d7d;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  align-items: start;
  font-size: 1em;
  font-weight: 500;
  padding: 1em 0.5em;
  transition: all 0.3s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.category-part-grid
  .category-part-row-item
  .category-name-overlay
  .category-name-overlay-text {
  width: 80%;
  position: relative;
  hyphens: auto;
  display: flex;
  align-items: center;
  text-align: left;
  min-height: 2.5em;
}
.category-part-grid .category-row-item-wrapper {
  width: 140px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  overflow: hidden;
}
.category-part-grid .category-row-item-wrapper:hover {
  border: 3px solid var(--main-green);
  transition: all 0.05s ease-in-out;
}
.category-skeleton {
  width: 100%;
  height: 100%;
  border-radius: 1em;
}

.category-part-grid .category-row-item-wrapper.selected {
  background: var(--main-green);
  border: none;
}

.category-part-grid .category-part-row-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  justify-content: center;
  border-radius: 1em;
  width: 100%;
  height: 100%;
}
/* .category-part-grid .category-part-row-item.selected {
 border: 1px solid var(--main-green);
} */

.category-part-grid .category-part-row-item .apparal-category-image {
  border-radius: 1em;
}
.category-part-grid .category-part-row-item .category-image {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 1em;
}

.category-part-grid .category-part-row-item.selected .category-name-overlay {
  background: none;
}
.category-part-grid .category-part-row-item .category-image-overlay {
  display: none;
}
.category-part-grid .category-part-row-item.selected .category-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-image: var(--main-green-gradient);
  color: #ffffff !important;
  min-height: 5em;
  display: flex;
  border-radius: 1em;
  transition: all 0.5s ease-in-out;
  border: 3px solid var(--main-green);
}

.category-part-grid .category-part-row-item.selected .category-image-overlay,
.category-part-grid .category-part-row-item.selected .category-name-overlay,
.category-part-grid .category-part-row-item.selected .category-name-overlay * {
  color: #ffffff !important;
}
.category-part-grid
  .category-part-row-item.selected
  .category-name-overlay
  .icon {
  filter: brightness(0) invert(1);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}
.category-part-grid .category-part-row-item .check-icon {
  position: absolute;
  z-index: 10;
  max-width: 60px;
  right: 1%;
  top: 1%;
}

.select-category-container button.save-button {
  padding: 0.5em 1em;
  background: var(--main-green-gradient);
  color: var(--main-white);
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
  height: 3em;
}

.select-category-container button.save-button:hover {
  background: var(--main-green-gradient-hover);
}

@media (max-width: 832px) {
  .category-part .category-part-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(135px, 2fr));
    grid-gap: 1em;
    width: 100%;
  }
  .select-category-desc .intro-headline-container {
    height: 6em !important;
  }
}
@media (max-width: 481px) {
  .category-part .category-part-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(135px, 2fr));
    grid-gap: 1em;
    width: 100%;
  }
  .select-category-container .header-row {
    justify-content: center;
  }
  .select-category-container button.save-button {
    padding: 0.5em 0.5em;
  }
  .select-category-desc .intro-headline-container {
    height: 7em !important;
  }
  .category-part-grid .category-row-item-wrapper {
    width: 120px;
    height: 140px;
  }
  .category-part .category-part-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  .flow-select-category-container {
    overflow-y: scroll;
  }
  .category-part-grid .category-part-row-item .category-name-overlay {
    font-size: 0.8em;
  }
}
