.sidebar-content-grid {
  display: flex;
  flex-direction: column;
}

.sidebar-content-grid .recommended-subscription-section {
  display: flex;
  flex-direction: column;
}

/* Sadece 1240px altında grid düzeni */
@media (max-width: 1240px) {
  .sidebar-content-grid {
    display: grid;
    width: 100%;
    gap: 2em;
  }

  .sidebar-content-grid .success-section {
    grid-column: 1;
  }

  .sidebar-content-grid .recommended-subscription-section {
    grid-column: 2;
    justify-content: space-between;
  }
}

/* Mobile görünüm */
@media (max-width: 630px) {
  .sidebar-content-grid {
    grid-template-columns: 1fr;
  }

  .sidebar-content-grid .success-section,
  .sidebar-content-grid .recommended-subscription-section {
    grid-column: 1;
  }
}
