.posts-modal {
  overflow-y: scroll;
  max-height: 80vh;
}

.posts-modal .post-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: .5em;
  margin-bottom: 10px;
}

.posts-modal .post-item img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  background: white;
  border: 1px solid #ededed;
}

.posts-modal .post-item .post-message {
  max-width: 400px;
  white-space: pre-wrap;
}