.admin-panel-customer-card {
  font-size: 14px;
  width: 100%;
  border-radius: 11px;
  background: var(--main-white);
  border: 2px solid #dedede;
  overflow: hidden;
  max-height: min-content;
}

.admin-panel-customer-card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--main-white);
  padding: 0.7em 1em;
  font-size: 0.928571429em;
  gap: 0.5em;
  background: linear-gradient(
    90deg,
    rgba(103, 103, 103, 1) 0%,
    rgba(103, 103, 103, 0.8) 100%
  );
}

.admin-panel-customer-card.onboarding .card-header {
  background: linear-gradient(
    90deg,
    rgb(221, 171, 43) 0%,
    rgb(235 196 112) 100%
  );
}

.admin-panel-customer-card.good .card-header {
  background: linear-gradient(
    90deg,
    rgb(86, 137, 59) 0%,
    rgb(118, 183, 84) 100%
  );
}

.admin-panel-customer-card.bad .card-header {
  background: linear-gradient(
    90deg,
    rgba(120, 38, 26, 1) 0%,
    rgb(175, 63, 46) 100%
  );
}

.admin-panel-customer-card.no-access .card-header {
  background: linear-gradient(
    90deg,
    rgba(66, 105, 241, 1) 0%,
    rgb(87 111 194) 100%
  );
}

.admin-panel-customer-card.no-touch .card-header {
  background: linear-gradient(90deg, rgb(24, 24, 24) 0%, rgb(34, 34, 34) 100%);
}

.admin-panel-customer-card .card-header .row {
  flex: 1 0 auto;
  justify-content: flex-end;
}

.admin-panel-customer-card .card-header .company {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-weight: 500;
  overflow: hidden;
}

.admin-panel-customer-card .card-header .company .username-bar {
  text-overflow: ellipsis;
  overflow-x: clip;
  white-space: nowrap;
  flex: 1 1 auto;
}

.admin-panel-customer-card .card-header .icon {
  font-size: 1.5em;
  min-width: 20px;
  transition: all 0.2s ease;
}

.admin-panel-customer-card .card-header .more {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
}

.admin-panel-customer-card .card-header .more .icon {
  cursor: pointer;
  position: relative;
  transform-origin: center;
}

.admin-panel-customer-card .card-header .more .icon:hover {
  transform: scale(1.1);
  color: #ffffff;
}

.admin-panel-customer-card .card-header .more .icon:active {
  transform: scale(0.9);
  animation: iconPulse 0.3s ease;
}

@keyframes iconPulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.admin-panel-customer-card .card-header .icon.has-ticket-badge,
.admin-panel-customer-card .card-header .icon.has-tickets-resolved-badge {
  border-radius: 10em;
  padding: 3px;
  background: var(--main-white);
  transform: scale(1.2);
}

.admin-panel-customer-card .card-header .icon.has-ticket-badge {
  background: #fd3737;
  border: 1px solidvar(--main-white);
  color: var(--main-white);
}

.admin-panel-customer-card .card-header .icon.has-ticket-badge:hover {
  background: var(--main-white);
  color: #fd3737;
}

.admin-panel-customer-card .card-header .icon.has-tickets-resolved-badge {
  color: #2a895b;
}

.admin-panel-customer-card .card-header .icon.has-tickets-resolved-badge:hover {
  color: #139556;
}

.admin-panel-customer-card .card-header .icon.has-ticket-badge:active,
.admin-panel-customer-card
  .card-header
  .icon.has-tickets-resolved-badge:active {
  animation: badgeIconPulse 0.3s ease;
}

@keyframes badgeIconPulse {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1.2);
  }
}

.admin-panel-customer-card .card-header .days {
  display: flex;
  align-items: center;
  gap: 0.8em;
}

.admin-panel-customer-card .card-header .days .day {
  display: flex;
  align-items: center;
  gap: 0.3em;
  font-size: 0.9em;
}

.admin-panel-customer-card .card-header .days .day .icon {
  font-size: 1.1em;
  transition: transform 0.2s ease;
}

.admin-panel-customer-card .card-header .days .day:hover .icon {
  transform: scale(1.1);
}

.admin-panel-customer-card .card-header .days .day:active .icon {
  transform: scale(0.9);
  animation: iconPulse 0.3s ease;
}

.admin-panel-customer-card .card-content {
  display: flex;
  flex-direction: column;
}

.admin-panel-customer-card .card-content .section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  position: relative;
  font-size: 15px;
}

.admin-panel-customer-card .card-content .section:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: #e5e5e5;

  z-index: 3;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status:has(.status-text),
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status:has(.status-text) {
  color: #444849;
  font-weight: 600;
  cursor: pointer;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status:has(.status-text):hover,
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status:has(.status-text):hover {
  background: #44484910;
  border-radius: 2px;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status:has(.status-text.disabled),
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status:has(.status-text.disabled) {
  color: #5b5e5f;
  font-weight: 600;
  cursor: not-allowed;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status
  .status-dropdown,
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status
  .status-dropdown {
  display: flex;
  gap: 0.25em;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status
  .status-dropdown
  select,
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status
  .status-dropdown
  select {
  width: 100px;
  border: 1px solid #dedede;
  background: transparent;
  border-radius: 2px;
  font-size: 1em;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status
  .status-dropdown
  select:focus,
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status
  .status-dropdown
  select:focus {
  outline: 1px solid #dedede;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status
  .status-dropdown
  .save-btn,
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status
  .status-dropdown
  .save-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 2px;
  background-color: #1f82a5;
  color: var(--main-white);
  font-weight: 600;
  transition: all 100ms;
  outline: 1px solid #dedede;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status
  .status-dropdown
  .save-btn:hover,
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status
  .status-dropdown
  .save-btn:hover {
  background-color: #1b6078;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status
  .status-dropdown
  .save-btn
  .icon,
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status
  .status-dropdown
  .save-btn
  .icon {
  font-size: 0.9em;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status
  .status-dropdown
  .cancel-btn,
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status
  .status-dropdown
  .cancel-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 2px;
  background-color: #d6d6d6;
  color: var(--main-white);
  font-weight: 600;
  transition: all 100ms;
  outline: 1px solid #dedede;
}

.admin-panel-customer-card
  .card-content
  .section.status
  .account-status
  .status-dropdown
  .cancel-btn:hover,
.admin-panel-customer-card
  .card-content
  .section.status
  .performance-status
  .status-dropdown
  .cancel-btn:hover {
  background-color: #b6b6b6;
}

.admin-panel-customer-card.onboarding
  .card-content
  .section.status
  .performance-status:has(.status-text) {
  color: #f1b623;
}

.admin-panel-customer-card.onboarding
  .card-content
  .section.status
  .performance-status:has(.status-text):hover {
  background: #f1b62310;
}

.admin-panel-customer-card.good
  .card-content
  .section.status
  .performance-status:has(.status-text) {
  color: #6ba14c;
}

.admin-panel-customer-card.good
  .card-content
  .section.status
  .performance-status:has(.status-text):hover {
  background: #6ba14c10;
}

.admin-panel-customer-card.bad
  .card-content
  .section.status
  .performance-status:has(.status-text) {
  color: #a85a55;
}

.admin-panel-customer-card.bad
  .card-content
  .section.status
  .performance-status:has(.status-text):hover {
  background: #a85a5510;
}

.admin-panel-customer-card.no-access
  .card-content
  .section.status
  .performance-status:has(.status-text) {
  color: #4269f1;
}

.admin-panel-customer-card.no-access
  .card-content
  .section.status
  .performance-status:has(.status-text):hover {
  background: #4269f110;
}

.admin-panel-customer-card .card-content .section.process {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.admin-panel-customer-card .card-content .section.process .overview {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
}

.admin-panel-customer-card .card-content .section.process .overview .item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
}

.admin-panel-customer-card .card-content .section.process .overview .item .day {
  color: var(--second-green);
  font-size: 0.785714286em;
  font-weight: 600;
  white-space: nowrap;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .overview
  .item
  .description {
  color: #6a6b6c;
  font-size: 0.571428571em;
}

.admin-panel-customer-card .card-content .section.process .overview .item .ne {
  color: var(--text-gray);
}

.admin-panel-customer-card .card-content .section.process .comparison {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .heading
  .large {
  font-size: 0.8em;
  display: flex;
  align-items: center;
}
.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .before-ai-ads {
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}
.admin-panel-customer-card
  .card-content
  .section.process
  .overview
  .right-side-column {
  display: flex;
  align-items: center;
  gap: 0rem;
  margin-left: auto;
}

.admin-panel-customer-card .card-content .section.process .comparison .green {
  color: #2a895b;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .heading
  .large
  img {
  max-width: 1.5em;
  margin-right: 0.5em;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .heading
  .small {
  color: #444849;
  font-size: 0.7em;
}

.admin-panel-customer-card .card-content .section.process .comparison .results {
  justify-content: space-between;
  width: 100%;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .results
  .item
  .value
  .meta {
  color: #6a6b6c;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .results
  .item
  .value
  .meta.down {
  color: #f84444;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .results
  .item
  .value
  .meta.up {
  color: var(--main-green);
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .results
  .item
  .value
  .meta
  .currency {
  color: inherit;
  font-size: 0.8em;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .results
  .item
  .value
  .meta
  .slash {
  color: inherit;
  font-size: 0.6em;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .results
  .item
  .value
  .before {
  color: #444849;
  font-size: 0.75em;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .results
  .item
  .value
  .before
  .currency {
  font-size: 0.8em;
  font-weight: 600;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .results
  .item
  .description {
  color: #757878;
  font-size: 12px;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .report-refresh-button {
  display: none;
  position: absolute;
  right: 0.6em;
  bottom: 0.6em;
  background: var(--main-green);
  color: white;
  font-size: 0.8em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-items: center;
  justify-content: center;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .report-refresh-button.loading {
  display: flex;
}

.admin-panel-customer-card .card-content .section.process .report-check {
  display: flex;
  align-items: center;
  gap: 0.5em;
  width: 100%;
}

.admin-panel-customer-card .card-content .section.process .report-check .icon {
  font-size: 1.2em;
  color: #f84444;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .report-check
  .description {
  font-size: 0.642857143em;
  color: #757878;
  margin: 0;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .report-check
  .description
  .highlight {
  font-size: 0.785714286;
  color: #f84444;
}

.admin-panel-customer-card .card-content .section.process .no-report {
  min-height: 4.5em;
  color: rgb(149, 149, 149);
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.admin-panel-customer-card .card-content .section.process .no-report img {
  max-height: 1em;
  margin-right: 0.5em;
}

.admin-panel-customer-card .card-content .section.contact {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.admin-panel-customer-card .card-content .section.contact .inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.admin-panel-customer-card .card-content .section.contact .inputs .line {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
}

.admin-panel-customer-card .card-content .section.contact .inputs .line > * {
  display: flex;
}

.admin-panel-customer-card .card-content .section.contact .inputs .line .input {
  display: flex;

  font-size: 0.9em;
  align-items: center;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input
  label {
  font-size: 0.9em;
  margin-right: 0.5em;
}

.admin-panel-customer-card .card-content .section.contact .inputs .line .input {
  background: #f9f9f9;
  padding: 0.2em 0em 0.2em 0.5em;
  border-radius: 6px;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input
  input {
  text-align: center;
  font-weight: 400;
  width: 36px;
  border: none;
  border-radius: 6px;
  background: white;
  padding: 0.2em;
  margin-right: 0.5em;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox {
  text-align: center;
  font-weight: 400;
  font-size: 0.9em;
  display: flex;
  gap: 0.3em;
  align-items: center;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox
  label {
  font-size: 0.9em;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input
  .save-btn,
.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox
  .save-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 2px;
  background-color: #1f82a5;
  color: var(--main-white);
  font-weight: 600;
  transition: all 100ms;
  height: 1.5em;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input
  .save-btn:disabled,
.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox
  .save-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input
  .save-btn:hover,
.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox
  .save-btn:hover {
  background-color: #1b6078;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input
  .save-btn
  .icon,
.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input-checkbox
  .save-btn
  .icon {
  font-size: 0.9em;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .description {
  color: #444849;
  font-size: 0.875em;
  font-weight: 300;
  margin: 0;
  display: flex;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
  display: none;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .inputs
  .line
  .input
  input:focus {
  outline: 1px solid #c3bdbd;
}

.admin-panel-customer-card .card-content .section.contact .customer-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1em;
  flex-wrap: nowrap;
  min-height: 1.2em;
  margin-top: 1em;
}

.admin-panel-customer-card .card-content .section.contact .customer-info .phone,
.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .email {
  display: flex;
  align-items: center;
  color: #444849;
  font-weight: 300;
  font-size: 0.875em;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .phone
  .helper-text,
.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .email
  .helper-text {
  font-size: 0.9em;
  color: rgb(81, 81, 81);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .phone
  .clock-icon {
  width: 1.1em;
  transform: translateY(2px);
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .phone:not(.not-available) {
  cursor: pointer;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .phone:not(.not-available):hover,
.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .email:not(.not-available):hover {
  color: #303233;
  font-weight: 400;
  text-decoration: none;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .phone
  .icon {
  display: none;
  margin-left: 5px;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .email
  .icon {
  display: none;
  margin-right: 5px;
}

.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .phone:not(.not-available):hover
  .icon,
.admin-panel-customer-card
  .card-content
  .section.contact
  .customer-info
  .email:not(.not-available):hover
  .icon {
  display: inline-block;
}

.admin-panel-customer-card .card-content .section.account-details {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.admin-panel-customer-card .card-content .section.account-details .line {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.admin-panel-customer-card .card-content .section.account-details .line .item {
  display: flex;
  align-items: center;
  gap: 0.1em;
  color: #6a6b6c;
  font-size: 0.9em;
  font-weight: 300;
}

.admin-panel-customer-card
  .card-content
  .section.account-details
  .line
  .item
  .pre-desc {
  color: #6a6b6c;
  font-weight: 300;
  margin-right: 2px;
}

.admin-panel-customer-card
  .card-content
  .section.account-details
  .line
  .item
  .value {
  color: var(--main-green);
  font-weight: 600;
  margin-right: 2px;
}

.admin-panel-customer-card
  .card-content
  .section.account-details
  .line
  .item
  .value.not-set {
  color: #f73c3c;
}

.admin-panel-customer-card
  .card-content
  .section.account-details
  .line
  .item
  .description {
  margin: 0;
}

.admin-panel-customer-card
  .card-content
  .section.account-details
  .source-type:has(.name-text) {
  color: #6a6b6c;
  font-weight: bold;
  font-size: 1.1em;
  cursor: pointer;
}

.admin-panel-customer-card
  .card-content
  .section.account-details
  .performance-manager:has(.name-text),
.admin-panel-customer-card
  .card-content
  .section.account-details
  .account-manager:has(.name-text) {
  color: rgb(52, 52, 52);
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.admin-panel-customer-card
  .card-content
  .section.account-details
  .performance-manager:has(.name-text.not-set),
.admin-panel-customer-card
  .card-content
  .section.account-details
  .account-manager:has(.name-text.not-set) {
  color: #f73c3c;
  font-weight: 600;
  cursor: pointer;
}

.admin-panel-customer-card
  .card-content
  .section.account-details
  .performance-manager:has(.name-text):hover,
.admin-panel-customer-card
  .card-content
  .section.account-details
  .account-manager:has(.name-text):hover {
  background: #44484910;
  border-radius: 2px;
}

.admin-panel-customer-card
  .card-content
  .section.account-details
  .performance-manager:has(.name-text.disabled),
.admin-panel-customer-card
  .card-content
  .section.account-details
  .account-manager:has(.name-text.disabled) {
  color: #5b5e5f;
  font-weight: 600;
  cursor: not-allowed;
}

.admin-panel-customer-card .card-content .section.account-details .currency {
  color: #5b5e5f;
  font-weight: 500;
  font-size: 1em;
}

.admin-panel-customer-card .card-content .section.account-details .price {
  color: #5b5e5f;
  font-weight: 500;
  font-size: 1.1em;
}

.admin-panel-customer-card .card-content .section .options-dropdown {
  display: flex;
  gap: 0.25em;
}

.admin-panel-customer-card .card-content .section .options-dropdown select {
  width: 100px;
  border: 1px solid #dedede;
  background: transparent;
  border-radius: 2px;
  font-size: 1em;
}

.admin-panel-customer-card
  .card-content
  .section
  .options-dropdown
  select:focus {
  outline: 1px solid #dedede;
}

.admin-panel-customer-card .card-content .section .options-dropdown .save-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 2px;
  background-color: #1f82a5;
  color: var(--main-white);
  font-weight: 600;
  transition: all 100ms;
  outline: 1px solid #dedede;
}

.admin-panel-customer-card
  .card-content
  .section
  .options-dropdown
  .save-btn:hover {
  background-color: #1b6078;
}

.admin-panel-customer-card
  .card-content
  .section
  .options-dropdown
  .save-btn
  .icon {
  font-size: 0.9em;
}

.admin-panel-customer-card
  .card-content
  .section
  .options-dropdown
  .cancel-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 2px;
  background-color: #d6d6d6;
  color: var(--main-white);
  font-weight: 600;
  transition: all 100ms;
  outline: 1px solid #dedede;
}

.admin-panel-customer-card
  .card-content
  .section
  .options-dropdown
  .cancel-btn:hover {
  background-color: #b6b6b6;
}

.admin-panel-customer-card .card-content .section .item .check-icon,
.admin-panel-customer-card .card-content .section .item .times-icon {
  font-size: 1.2em;
  transform: translateY(2px);
}

.admin-panel-customer-card .card-content .section .item .check-icon {
  color: var(--main-green);
}

.admin-panel-customer-card .card-content .section .item .times-icon {
  color: #f73c3c;
}

.comparison.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.8em;
  background: rgba(var(--main-green-rgb), 0.02);
  border-radius: 8px;
  border: 1px solid rgba(var(--main-green-rgb), 0.1);
}

.comparison.empty-state .heading {
  margin-bottom: 1em;
}

.empty-message {
  color: #666;
  font-size: 14px;
  text-align: center;
}

.results.three-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5em;
  margin-top: 1em;
}

.results.three-columns .column {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  padding: 1em 0em;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.results.three-columns .column:hover {
  background: rgba(0, 0, 0, 0.03);
}

.results.three-columns .column h4 {
  font-size: 0.9em;
  color: #666;
  margin: 0 0 0.5em 0;
  font-weight: 600;
  min-height: 2.4em;
  display: flex;
  align-items: center;
}

.results.three-columns .column .item {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}

.results.three-columns .column .item .value {
  font-size: 1.2em;
  font-weight: 500;
}

.results.three-columns .column .item .value .meta,
.results.three-columns .column .item .trend-value {
  font-size: 1em;
  font-weight: 600;
}

.results.three-columns .column .item .value .currency {
  font-size: 0.85em;
  margin-right: 0.1em;
  opacity: 0.8;
}

.results.three-columns .column .item .description {
  font-size: 0.85em;
  color: #666;
}

.results.three-columns .column .item .value .arrow {
  margin: 0 0.3em;
  color: #666;
  font-weight: normal;
  font-size: 0.85em;
  transform: rotate(-90deg);
  display: inline-block;
}

.results.three-columns .column .item .trend-value {
  font-weight: 600;
}

.results.three-columns .column .item .trend-value.better {
  color: var(--main-green);
}

.results.three-columns .column .item .trend-value.worse {
  color: #f73c3c;
}

.results.three-columns .column .item .trend-value.others {
  color: #94959b;
  font-weight: 500;
}

.results.three-columns .column .item .vertical-comparison {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2em;
}

.results.three-columns .column .item .vertical-comparison > span {
  font-size: 0.9em;
}

.results.three-columns .column .item .vertical-comparison .arrow {
  color: #666;
  font-size: 0.8em;
  margin: 0;
  line-height: 1;
}

.results.three-columns .column .item .vertical-comparison .currency {
  font-size: 0.8em;
  margin-right: 0.1em;
  opacity: 0.8;
}

.results.three-columns .column.before-after .items-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;
  width: 100%;
}

.results.three-columns .column.before-after .item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.results.three-columns .column.before-after {
  position: relative;
}

.results.three-columns .column.before-after:hover .report-refresh-button {
  opacity: 1;
  visibility: visible;
}

.report-refresh-button {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
}

.results.three-columns .column.before-after .value {
  font-size: 1.1em;
  font-weight: 500;
}

.results.three-columns .column.before-after .description {
  font-size: 0.9em;
  color: #666;
  margin-top: 0.3em;
}

.admin-panel-customer-card .card-content .section .title-container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 13px;
  color: #666;
}

.admin-panel-customer-card .card-content .section .title-container .days {
  color: #2a895b;
  font-weight: 500;
}

.admin-panel-customer-card .card-content .section .title-container .separator {
  color: #666;
  opacity: 0.7;
}

.results.three-columns .column.before-after .item {
  cursor: help;
}

.results.three-columns .column.before-after:hover {
  background-color: #f9f9f9;
}

.admin-panel-customer-card
  .card-content
  .section.process:hover
  .comparison
  .corner-refresh-button {
  opacity: 1;
  visibility: visible;
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .corner-refresh-button {
  position: absolute;
  top: 6.5em;
  right: 2em;
  background: var(--main-green);
  color: white;
  font-size: 0.7em;
  padding: 0.5em 1em;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
  cursor: pointer;
  z-index: 1;
}

.contact-metrics {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.5rem;
}

.metric-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.metric-icon {
  font-size: 1em;
  color: var(--text-color);
  opacity: 0.8;
}

.metric-item .customer-card-input {
  margin: 0;
  padding: 0;
}

.metric-item .customer-card-input input {
  width: 40px;
  text-align: center;
}

.results.three-columns .column .item .trend-value span::after,
.results.three-columns .column .item .meta span::after {
  content: attr(data-symbol);
  font-size: 13px;
  margin-left: 1px;
}

.results.three-columns .column .item .trend-value span,
.results.three-columns .column .item .meta span {
  display: inline-flex;
  align-items: baseline;
}

@keyframes hoverSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .corner-refresh-button:hover
  svg {
  animation: hoverSpin 0.3s ease-out;
}

@keyframes loadingSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.admin-panel-customer-card
  .card-content
  .section.process
  .comparison
  .corner-refresh-button.loading
  svg {
  animation: loadingSpin 1s linear infinite;
}

.empty-state-refresh-button {
  margin-top: 1em;
  background: var(--main-green) !important;
  color: white !important;
  border: none !important;
  padding: 0.5em 1em !important;
  border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
  gap: 0.5em !important;
  justify-self: center;
}

.empty-state-refresh-button:hover {
  background: var(--main-green-dark, var(--main-green)) !important;
}

.empty-state-refresh-button svg {
  font-size: 1em;
}

.empty-state-refresh-button:hover svg {
  animation: hoverSpin 0.3s ease-out;
}

.empty-state-refresh-button.loading svg {
  animation: loadingSpin 1s linear infinite;
}

.right-side-column {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}

.right-side-column .item {
  display: flex;
  align-items: center;
}

.day-indicator {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.3rem 0.6rem;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.2s ease;
}

.day-indicator:hover {
  background: rgba(255, 255, 255, 0.15);
}

.status-icon {
  font-size: 0.8rem;
  transition: transform 0.2s ease;
  font-weight: 600;
  color: gray;
}

.day-text {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--second-green);
}

.no-data-icon {
  font-size: 0.8rem;
  opacity: 0.7;
  color: var(--second-gray);
}

.connection-status {
  display: flex;
  align-items: center;
}

.status-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  font-size: 0.7rem;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: default;
}

.status-badge .status-icon {
  font-size: 0.9rem;
  transition: transform 0.2s ease;
}

.status-badge.success {
  background: rgba(51, 167, 111, 0.05);
  color: var(--main-green);
}
.status-badge.success svg {
  color: var(--main-green);
}

.status-badge.error {
  background: rgba(247, 60, 60, 0.106);
  color: #f73c3c;
}

.status-badge.error svg {
  color: #f73c3c;
}

.status-badge .status-text {
  color: inherit;
  font-weight: 500;
}

.status-badge:active .status-icon {
  animation: iconPulse 0.3s ease;
}

.admin-panel-customer-card .visitor-stats {
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  padding: 0.37em 0.8em;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.admin-panel-customer-card .visitor-stats:hover {
  filter: brightness(0.95);
}

.admin-panel-customer-card .visitor-stats.positive {
  background: rgba(51, 167, 111, 0.05);
}

.admin-panel-customer-card .visitor-stats.negative {
  background: rgba(247, 60, 60, 0.106);
}

.admin-panel-customer-card .visitor-stats .status-icon {
  font-size: 1em;
  color: var(--text-color);
}

.admin-panel-customer-card .visitor-stats .current-value {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--text-color);
}

.admin-panel-customer-card .visitor-stats .percentage {
  font-size: 0.9em;
  font-weight: 500;
}

.admin-panel-customer-card .visitor-stats .percentage.positive {
  color: var(--second-green);
}

.admin-panel-customer-card .visitor-stats .percentage.negative {
  color: var(--second-red);
}

.admin-panel-customer-card .visitor-stats .period-indicator {
  font-size: 0.9em;
  font-weight: 400;
  color: var(--text-color);
  opacity: 0.7;
}

.section-switch {
  position: absolute;
  bottom: -12px;
  right: 0;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.results.three-columns .column:hover .section-switch {
  opacity: 1;
  visibility: visible;
}

.section-switch .switch {
  min-width: 50px !important;
  height: 1.5em !important;
  font-size: 0.9em !important;
  transform: scale(0.85);
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-switch .switch .selection {
  height: calc(100% - 4px) !important;
  top: 2px !important;
}

.section-switch .switch .left-side,
.section-switch .switch .right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding-top: 1px;
}

/* Style for the OFF state */
.section-switch .switch.right .selection {
  background: #808080 !important;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.3) !important;
}

/* Adjust the color of the text when selected */
.section-switch .switch.left .left-side {
  color: white !important;
}

.section-switch .switch.right .right-side {
  color: white !important;
}

.results.three-columns .column {
  position: relative;
  padding-bottom: 2em; 
}
.results.three-columns .column.before-after .heading .section-switch {
  bottom: -15px;
  right: 0;
}
