.personal-info-content {
  display: flex;
  justify-content: flex-start;
  gap: 5em;
}

.personal-info-content .col {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  justify-content: space-between;
}

.personal-info-content .col.form {
  flex: 1.05;
}

.personal-info-content .form-control input {
  box-shadow: 0px 4px 16.1px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #f1f2f3;
  background: var(--main-white);
  border-radius: 8px;
}

.personal-info-content .form-control {
  margin: 1em 0 1.5em 0;
}

.personal-info-content .form-control input:focus {
  outline: 1px solid #c9d4df;
}

.personal-info-content .form-control label {
  color: #9099a2;
  font-weight: 500;
  font-size: 0.8em;
}

.personal-info-content h4 {
  color: #6b6b6b;
  font-weight: 600;
  font-size: 1.2em;
}

.personal-info-content .col .profile-image-area {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: fit-content;
  margin-bottom: 1em;
}

.personal-info-content .col .profile-image-area .upload-new-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.personal-info-content .col .profile-image-area .image-upload-area {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.personal-info-content .col .profile-image-area .image-upload-area #picInput,
.personal-info-content
  .col
  .profile-image-area
  .image-upload-area
  .change-pic-button,
.personal-info-content
  .col
  .profile-image-area
  .image-upload-area
  div:has(img[alt='vector']) {
  display: none;
}

.personal-info-content .col .profile-image-area .image-upload-area .p-pic-cont {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}

.personal-info-content
  .col
  .profile-image-area
  .image-upload-area
  .p-pic-cont
  .p-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.personal-info-content
  .col
  .profile-image-area
  .image-upload-area
  .placeholder {
  width: 90;
  height: 90px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
  padding: 2em;
  cursor: pointer;
}

.personal-info-content
  .col
  .profile-image-area
  .image-upload-area:has(.p-pic)
  .placeholder {
  display: none;
}

.personal-info-content
  .col
  .profile-image-area
  .image-upload-area:has(.error-text:empty)
  .error-text {
  display: none;
}

.personal-info-content
  .col
  .profile-image-area
  .image-upload-area
  .placeholder
  .icon {
  width: 100%;
  height: 100%;
  opacity: 0.25;
  transition: all 100ms;
}

.personal-info-content
  .col
  .profile-image-area
  .image-upload-area
  .placeholder:hover
  .icon {
  opacity: 0.5;
}

.personal-info-content .col .profile-image-area .title {
  margin: 0;
  margin-top: 16px;

  color: #9099a2;
  font-weight: 500;
  font-size: 0.8em;
}

.personal-info-content .col .profile-image-area .change-profile-photo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  color: var(--text-green);
  font-weight: 500;
  padding: 0.35em 0.5em;
  border-radius: 6px;
  font-size: 0.9em;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  transition: all 100ms;
}

.personal-info-content .col .profile-image-area .change-profile-photo:hover {
  border: 1px solid var(--text-green);
  background: #f5ffef;
}

.profile-content .save-button {
  background: var(--text-green);
}

.personal-info-content .col .profile-image-area .save-button:hover {
  background: #87c961;
}

.personal-info-content .col.form .save-button {
  display: none;
}

@media (max-width: 1000px) {
  .personal-info-content {
    flex-direction: column-reverse;
    gap: 0em;
  }

  .personal-info-content .col .profile-image-area .title {
    display: none;
  }

  .personal-info-content .col .profile-image-area .image-upload-area {
    flex-direction: row;
    align-items: center;
  }

  .personal-info-content .col .profile-image-area .change-profile-photo {
    font-size: 0.92em;
  }

  .personal-info-content
    .col
    .profile-image-area
    .image-upload-area
    .p-pic-cont,
  .personal-info-content
    .col
    .profile-image-area
    .image-upload-area
    .placeholder {
    width: 100px;
    height: 100px;
  }

  .personal-info-content .col.form .save-button {
    display: flex;
    background: var(--text-green);
    padding: 0.4rem 1rem;
  }

  .personal-info-content .col.form .save-button:hover {
    background: #87c961;
  }

  .personal-info-content .col .profile-image-area .save-button {
    display: none;
  }

  .personal-info-content .col.form .form-control {
    margin: 1.5em 0;
  }
}
@media (max-width: 435px) {
  .profile-content .save-button {
    width: 100%;
    margin-top: 20px;
    padding: 0.4rem 1rem;
  }
}
