.cancel-button {
  border: none;
  outline: none;
  padding: 0.375em 1.25em;
  border-radius: 5px;
  background: #1f7896;
  color: var(--main-white);
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 0.9em;
  cursor: pointer;
  transition: all 100ms;
}

.cancel-button:hover {
  background: #2987a6;
}
