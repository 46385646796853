.sidebar-container {
  position: absolute;
  top: 0;
  height: 100vh;
  transition: left var(--transition-timing) cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 7000;
  pointer-events: none;
}

/* Sidebar position according to navbar width */
body.navbar-locked .sidebar-container {
  left: var(--lg-navbar-width);
}

body:not(.navbar-locked) .sidebar-container {
  left: var(--locked-navbar-width);
}

/* For AHCU mode */
body .basic-user.ad-health-checkup .sidebar-container {
  left: 0;
}

.sidebar-container:has(.full-funnel-sidebar.open) {
  pointer-events: auto;
}

.full-funnel-sidebar {
  position: relative;
  width: var(--lg-navbar-width);
  height: 100%;
  background: linear-gradient(to top, #011520 0%, #13364a 100%);
  color: white;
  display: flex;
  flex-direction: column;
  border-radius: 0 1.5em 1.5em 0;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100%);
  transition:
    transform var(--transition-timing) cubic-bezier(0.4, 0, 0.2, 1),
    opacity var(--transition-timing) cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, opacity;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  pointer-events: auto;
}

.full-funnel-sidebar.open {
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* For clickability of sidebar content */
.full-funnel-sidebar .sidebar-content {
  cursor: pointer;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox için */
  -ms-overflow-style: none; /* IE ve Edge için */
}

/* Webkit (Chrome, Safari) için scroll çubuğunu gizle */
.full-funnel-sidebar .sidebar-content::-webkit-scrollbar {
  width: 0;
  display: none;
}

/* Make sidebar header non-clickable */
.full-funnel-sidebar .sidebar-header {
  pointer-events: none;
}

.full-funnel-sidebar .sidebar-header .close-button {
  pointer-events: auto;
}

/* To match the height with Facebook Ads button */
.full-funnel-sidebar.open::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);

  height: 3.5em;
  background: rgba(255, 255, 255, 0.2);
}

.full-funnel-sidebar .sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2em 1em 1.2em 1.5em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.full-funnel-sidebar .sidebar-header h2 {
  margin: 0;
  font-size: 1.1em;
  font-weight: 600;
  color: var(--main-white);
}

.full-funnel-sidebar .close-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  transition: all 0.3s ease;
}

.full-funnel-sidebar .close-button:hover {
  color: var(--main-white);
  background: rgba(255, 255, 255, 0.1);
}

.sidebar-content {
  flex: 1;
  overflow-y: auto;
  padding: 1.2em 1em 1.2em 1.5em;
}

.full-funnel-sidebar .overview-btn {
  width: 100%;
  padding: 0.8em;
  border: none;
  border-radius: 0.8em;
  background: rgba(255, 255, 255, 0.1);
  color: var(--main-white);
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin-bottom: 0.8em;
  margin-top: 0.8em;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
}

.full-funnel-sidebar .overview-btn:hover {
  background: rgba(255, 255, 255, 0.15);
}

.full-funnel-sidebar .overview-btn.active {
  background-color: #13364a;
  color: var(--main-white);
  border: none;
}

.full-funnel-sidebar .overview-btn.active::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, var(--main-green), #085a9c);
  border-radius: 1em;
  z-index: -1;
  opacity: 1;
}

.full-funnel-sidebar .overview-btn svg {
  font-size: 1em;
  transition: color 0.3s ease;
}

.full-funnel-sidebar .overview-btn.active svg {
  color: var(--main-white);
}

.full-funnel-sidebar .create-campaign-btn {
  width: 100%;
  padding: 0.8em;
  border: none;
  border-radius: 0.8em;
  background: var(--main-green-gradient);
  color: var(--main-white);
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7em;
  margin-bottom: 1.2em;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.full-funnel-sidebar .create-campaign-btn:hover {
  background: var(--main-green-gradient-hover);
}

.full-funnel-sidebar .create-campaign-btn svg {
  font-size: 1.1em;
  transition: transform 0.3s ease;
}

.full-funnel-sidebar .create-campaign-btn.active {
  background: #13364a;
  color: var(--main-white);
  border: none;
}
.full-funnel-sidebar .create-campaign-btn.active::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, var(--main-green), #085a9c);
  border-radius: 1em;
  z-index: -1;
  opacity: 1;
}

.full-funnel-sidebar .sub-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9em;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.8em;
  padding: 0 0.5em;
}

.full-funnel-sidebar .campaign-group {
  display: flex;
  flex-direction: column;
}

.full-funnel-sidebar .campaign-item {
  display: flex;
  align-items: flex-start;
  padding: 0.8em 1em;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.8em;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 0.8em;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  min-height: 3em;
  align-items: center;
}

.full-funnel-sidebar .campaign-info {
  flex: 1;
  min-width: 0;
  padding-right: 1.5em;
  width: calc(100% - 2em);
}

.full-funnel-sidebar .campaign-item:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.full-funnel-sidebar .campaign-item.active {
  background: rgba(76, 175, 80, 0.2);
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.full-funnel-sidebar .campaign-label {
  color: var(--main-white);
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  line-height: 1.3;
  font-size: 0.9em;
  width: 100%;
}

.full-funnel-sidebar .indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  flex-shrink: 0;
  position: absolute;
  right: 1em;
}

.full-funnel-sidebar .indicator.active {
  background: var(--main-green);
}

/* Lower sidebar z-index when navbar is hovered */
.navbar-container.hovered + .sidebar-container .full-funnel-sidebar {
  z-index: 6999;
}

/* Responsive settings */
@media (max-width: 1470px), (max-height: 1030px) {
  .full-funnel-sidebar {
    font-size: 0.85em;
  }

  body.navbar-locked .sidebar-container {
    left: 14.4em;
  }

  body:not(.navbar-locked) .sidebar-container {
    left: 4em;
  }

  .sidebar-header,
  .sidebar-content {
    padding: 1em 0.8em 1em 1.2em;
  }
}
/* Responsive settings */
@media (max-width: 1470px), (max-height: 1030px) {
  body.navbar-locked .sidebar-container {
    left: 14.4em;
  }

  body:not(.navbar-locked) .sidebar-container {
    left: 4em;
  }
}
@media (max-width: 1470px), (max-height: 1030px) {
  .full-funnel-sidebar {
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {
  .full-funnel-sidebar {
    display: none;
  }
}

@media (max-width: 768px) {
  /* Hide sidebar on mobile */
  .sidebar-container {
    display: none;
  }
}

/* Show FullFunnelCampaigns on mobile view */
@media (max-width: 768px) {
  .nav-item.full-funnel-campaigns {
    display: block;
  }

  .nav-item.full-funnel-campaigns .nav-item-content {
    margin: 0.5em 1em;
    border-radius: 12px;
  }

  /* Keep border-radius even when sidebar is open */
  .nav-item.full-funnel-campaigns.sidebar-open .nav-item-content {
    border-radius: 12px;
    margin: 0.5em 1em;
    border: none;
  }
}

.full-funnel-sidebar .sub-item-group {
  margin-bottom: 1.5em;
  margin-top: 2em;
}
