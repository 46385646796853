.summary-card {
  border-radius: 0.75em;
}
.summary-card .summary-card-header {
  margin-bottom: 1.5em;
}

.summary-card .summary-card-header h3 {
  font-size: 1.5em;
  font-weight: 500;
  color: #1a1a1a;
  margin: 0 0 0.75em 0;
}

.summary-card .summary-card-content {
  display: flex;
  gap: 1em;
}

.summary-card .card-item {
  flex: 1;
  background: white;
  border: 1px solid #dfeaf2;
  border-radius: 0.75em;
  padding: 1.25em;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.summary-card button.card-item {
  text-align: left;
  font-size: 1em !important;
  font-weight: 500 !important;
}

.summary-card .card-item:hover {
  border: 1px solid #a2c4dd;
}

.summary-card .platform-connections {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.summary-card .platform {
  display: flex;
  align-items: center;
  gap: 0.75em;
}

.summary-card .platform-logo {
  height: 3em;
}

.summary-card .platform-info {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.summary-card .platform-name {
  font-size: 1em;
  font-weight: 500;
  color: #1a1a1a;
}

.summary-card .status {
  display: flex;
  align-items: center;
  gap: 0.25em;
  font-size: 0.9em;
  width: 100%;
  background-color: white;
}

.summary-card .status-label {
  color: #94959b;
}

.summary-card .status-value.connected {
  color: #22c55e;
}

.summary-card .status-value.not-connected {
  color: #ef4444;
}

.summary-card .status.not-connected {
  & > span:first-child {
    color: #94959b;
  }

  & > span:last-child {
    color: #ef4444;
  }
}
.summary-card .card-icon {
  width: 3.5em;
  height: 3.5em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 0.75em;
}

.summary-card .card-text h4 {
  margin: 0;
  font-size: 1em !important;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 0.25em;
}

.summary-card .card-text p {
  margin: 0;
  font-size: 1em !important;
  color: #666;
  line-height: 1.4;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .summary-card .summary-card-content {
    flex-wrap: wrap;
  }

  .summary-card.card-item {
    flex: 1 1 calc(50% - 0.5em);
  }
}

@media (max-width: 768px) {
  .summary-card {
    padding: 1em;
  }

  .summary-card .summary-card-content {
    flex-direction: column;
  }

  .summary-card .card-item {
    flex: 1 1 100%;
  }

  .summary-card .card-icon {
    width: 2em;
    height: 2em;
    margin-bottom: 1.5em;
  }
}

.summary-card .process-bar-container {
  margin-top: 2em;
  margin-bottom: 2em;
  padding-right: 0em;
}

.summary-card .calendly-button-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.summary-card .calendly-popup-button {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
