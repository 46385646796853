.ai-ads {
  display: flex;
  position: fixed;
  top: var(--topbar-height);
  left: 0;
  width: 100vw;
  height: calc(103vh - var(--topbar-height));
  background-color: white;
  flex-direction: column;
}
.basic-user .ai-ads {
  position: relative;
  width: 100%;
  top: 0;
  height: 100vh;
  background-color: white;
  flex-direction: column;
}
.ai-ads-content-wrapper {
  flex: 3;
  padding: 2em;
  overflow-x: hidden;
  font-size: 0.9em;
}
/* .ai-ads-content-wrapper:not(.basic-user) {
  padding-left: 4em;
} */
.basic-user .ai-ads-content-wrapper {
  padding: 0em 0em 0em 2em;
  overflow-x: hidden;
}
.basic-user .ai-ads-content-wrapper::-webkit-scrollbar {
  width: 0px;
}

.ai-ads-sidebar-wrapper {
  flex: 1;
  background: var(--main-white);
  font-size: 0.9em;
  flex: 1 1 auto;
  max-width: 400px;
}
.basic-user .ai-ads-sidebar-wrapper {
  max-width: 25em;
}

.ai-ads .ai-ads-sidebar-wrapper .sidebar-items {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  gap: 0.75em;
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: 2em 1em;
}

.ai-ads .ai-ads-sidebar-wrapper .progress-tracker {
  max-width: 350px;
  margin-top: 2em;
}

.ai-ads .ai-ads-content-wrapper .breadcrumb {
}
.basic-user .ai-ads .ai-ads-content-wrapper .breadcrumb {
  margin: 0em 0em 0em 0em;
}

@media (min-width: 930px) {
  .ai-ads .ai-ads-home-grid {
    padding-bottom: 2em;
  }
}

@media (max-width: 1240px) {
  .ai-ads {
    display: block;
  }
  .ai-ads-sidebar-wrapper {
    background: none;
    box-shadow: none;
    min-width: 100vw;
  }
  .ai-ads-content-wrapper {
    flex: 3;
    padding: 2em 1em 0em 1em;
    font-size: 0.9em;
  }

  .ai-ads .ai-ads-sidebar-wrapper .sidebar-items {
    flex-direction: row;
    width: 100%;
    justify-content: start;
    gap: 2em;
    place-items: start;
    padding-left: 1em;
    padding-top: 0;
  }
  .basic-user .ai-ads .ai-ads-sidebar-wrapper .sidebar-items {
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 2em;
    place-items: start;
    padding-left: 2em;
    padding-top: 0;
  }

  .success-stories-slider {
    margin-top: 0em !important;
    height: 100%;
    align-items: center;
  }

  .tutorial-slider {
    height: 100%;
    align-items: center;
  }

  .tutorial-slider .content {
    height: 324px !important;
    max-height: 340px;
    width: 100%;
  }

  .ai-ads {
    flex-direction: column !important;
  }

  .ai-ads .ai-ads-home-grid {
  }
}
@media (max-width: 1500px), (max-height: 1000px) {
  .ai-ads-content-wrapper {
    font-size: 0.75em;
  }
  .ai-ads-sidebar-wrapper {
    font-size: 0.75em;
  }
  .ai-ads-sidebar-wrapper:not(.basic-user) {
    font-size: 0.75em;
  }
}

@media (max-width: 785px) {
  .ai-ads .ai-ads-sidebar-wrapper .sidebar-items {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 2em;
  }
}

@media (max-width: 768px) {
  .ai-ads-content-wrapper {
    font-size: 0.75em;
  }
}

@media (max-width: 400px) {
  .ai-ads-sidebar-wrapper {
    scale: 0.9;
  }

  .ai-ads .ai-ads-sidebar-wrapper .sidebar-items {
    gap: 1em;
  }

  .ai-ads .ai-ads-sidebar-wrapper {
    margin-top: -60px;
  }
}

.ai-ads-breadcrumb {
  position: sticky;
  top: 0;
  padding: 1em 2em 0;
  background: white;
  z-index: 100;
}

.ai-ads-main-content {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.ai-ads-content-wrapper {
  flex: 3;
  padding: 2em;
  overflow-x: hidden;
}

.ai-ads-sidebar-wrapper {
  flex: 1;
  background: var(--main-white);
  flex: 1 1 auto;
  max-width: 400px;
}

/* Responsive */
@media (max-width: 1240px) {
  .ai-ads-main-content {
    flex-direction: column;
  }

  .ai-ads-content-wrapper {
    width: 100%;
    padding: 2em 1em 0em 1em;
  }

  .ai-ads-sidebar-wrapper {
    width: 100%;
    max-width: none;
    min-width: 100%;
    background: none;
    box-shadow: none;
  }
}

.ai-ads .ai-ads-breadcrumb {
  padding: 0 0 0 2em;
  background: white;
  z-index: 1;
}

.ai-ads .ai-ads-breadcrumb .breadcrumb {
  margin: 0;
  padding: 0;
}

.ai-ads .ai-ads-breadcrumb .breadcrumb-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ai-ads .ai-ads-breadcrumb .breadcrumb-item :active {
  color: var(--text-gray);

  cursor: pointer;
}

.ai-ads .ai-ads-breadcrumb .breadcrumb-item:hover {
  color: var(--primary);
}

.ai-ads .ai-ads-breadcrumb .breadcrumb-separator {
  color: var(--text-gray);
  margin: 0 4px;
}
.ai-ads-breadcrumb-actions {
  width: 100%;
}
/* Breadcrumb styles */
.ai-ads-breadcrumb-wrapper {
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
}

.ai-ads-breadcrumb {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
  .ai-ads-breadcrumb-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    width: 100%;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
  .basic-user .ai-ads-content-wrapper {
    padding: 0em 0em 0em 0em;
  }
  .ai-ads .ai-ads-breadcrumb {
    padding: 0em 0em 0em 0em;
  }
  .ai-ads .ai-ads-breadcrumb .breadcrumb-wrapper {
    gap: 0px;
  }
  .ai-ads-breadcrumb {
    margin: 0;
  }

  .basic-user .ai-ads .ai-ads-sidebar-wrapper .sidebar-items {
    padding-top: 0em;
  }
  /* Safe-area for iOS */
  @supports (-webkit-touch-callout: none) {
    .basic-user .ai-ads {
      margin-top: calc(-1 * env(safe-area-inset-top));
      min-height: calc(100vh + env(safe-area-inset-top));
    }

    .ai-ads-breadcrumb-wrapper {
      top: env(safe-area-inset-top, 0);
      padding-top: env(safe-area-inset-top, 0);
    }

    .ai-ads-main-content {
      min-height: calc(100vh - env(safe-area-inset-top));
      padding-bottom: env(safe-area-inset-bottom, 0);
    }
  }
}
