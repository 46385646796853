.budget-comparison-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 85%;
  max-width: 1400px;
  margin: 0 auto;
  gap: 4em;
  padding: 7em 0;

  .column {
    gap: 0.2em;
    align-items: flex-start;
    width: 100%;
  }

  .row {
    justify-content: space-between !important;
  }
  .bar-cart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 100%;
  }
  .bar-cart-container canvas {
    max-width: 100% !important;
    max-height: 100%;
  }

  @media (max-width: 700px) {
    .bar-cart-container {
      width: 100%;
    }
  }
}
