.payment {
  position: relative;
}

.payment .package-details {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  font-size: 0.9em;
}

.payment .package-details .name {
  font-size: 1.1em;
  font-weight: 500;
}

.payment .package-details .price {
  font-size: 1.1em;
  font-weight: 500;
}

.payment .checkout-form h3 {
  width: 90%;
  max-width: 800px;
}

.payment .checkout-form .header-row {
  width: 90%;
  max-width: 800px;
  margin: 1em auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment .checkout-form .cancel-payment {
  font-size: 1.2em;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  color: var(--text-blue);
}

.payment .checkout-form .cancel-payment:hover {
  color: var(--text-blue-hover);
}

.payment .billing-form {
  margin: 0 auto 2em;
  width: 90%;
  max-width: 800px;
}

.payment .form-flex {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3em;
}

.payment .form-flex > div {
  flex: 1 1 50%;
  width: 100%;
}

.payment .billing-form label {
  font-weight: 500;
}

.payment .billing-form .row.phone-number {
  justify-content: space-between;
  gap: 1em;
}

.payment .billing-form .row.phone-number > div:first-child {
  flex: 1 1 30%;
}

.payment .billing-form .row.phone-number > div:last-child {
  flex: 1 1 60%;
}

/* .payment .billing-form .form-flex input {
    box-shadow: var(--card-shadow);
    padding: 0.7em;
    border-radius: 7px;
    width: 100%;
    border: none;
    outline: none;
    margin-bottom: 1em;
}

.payment .billing-form .error-text {
    color: rgb(209, 3, 3);
    font-size: 0.7em;
    margin: 0.5em auto
} */

.payment .agreement-line {
  display: flex;
  justify-content: center;
  gap: 0.5em;
  font-size: 1em;
  align-items: center;
  margin: 1em auto;
}

.payment .agreement-line button {
  background: none;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  color: var(--main-blue);
  font-size: 1em;
}

.payment .agreement-line button:hover {
  text-decoration: underline;
}

.payment .billing-form .action-button {
  margin: 2em auto;
  padding: 0.7em 1em;
  display: block;
  width: 100%;
  position: relative;
  max-width: 270px;
  padding-left: 0;
  font-size: 1em;
  font-weight: 500;
}

.payment .billing-form .action-button .icon {
  position: absolute;
  right: 1em;
  height: calc(100% - 1.5em);
}

.payment .billing-form .actions {
  width: 200px;
}

.payment-completed {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.subscription-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  gap: 1em;
  width: 100%;
  margin: 10vh auto;
}
.payment-completed .content{
  gap: 2em;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
}
.payment-completed .subscribed .plan-info-card .plan-column{
  justify-content: flex-start;
}
.payment-completed .smaller-text{
  text-decoration:  underline #909090;
  font-size: 0.8em;
  cursor: pointer;
}


.payment-completed .icon {
  color: var(--main-green);
  font-size: 2em;
}

.payment-completed .icon.red {
  color: var(--main-red);
}

.payment-completed .row {
  align-items: center;
  justify-content: center;
}

.payment-completed .row .spinner-cont {
  width: 2em;
  height: 2em;
  top: unset;
  left: unset;
  position: relative;
}

.payment-completed .row .spinner-cont .bar {
  width: 0.25em;
}

.payment-completed .button {
  font-size: 0.8em;
  text-align: center;
  margin: 1em auto;
  max-width: 300px;
  height: 3em;
  background: linear-gradient(
    32deg,
    var(--primary-green),
    var(--second-green) 42%
  );
  font-size: var(--font-18);
  font-weight: 500;
  box-shadow: 0 1px 3px 0 #ccc;
  padding: 5px 1em;
}

.payment-completed .button:hover {
  background: linear-gradient(
    32deg,
    var(--primary-green),
    var(--second-green) 52%
  );
}

.payment-completed .button.white {
  background: var(--main-white);
  color: #5dba77;
}

.payment-completed .button.white:hover {
  background: rgb(244, 244, 244);
}

.payment-completed .actions {
  margin-top: 1em;
}

.subscribed .update-card {
  margin-top: 0.5em;
  width: 100%;
}

.update-card .icon {
  color: var(--main-green);
  font-size: 1.5em;
}

.update-card .icon.red {
  color: var(--main-red);
}

.update-card .button {
  width: min-content;
  white-space: nowrap;
  padding: 2px 10px;
  font-size: 0.9em;
  margin-top: 1em;
}
