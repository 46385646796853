.custom-dropdown {
  flex: 1;
  position: relative;
  --dropdown-padding-amount: calc(0.5em + 2px);
}

.custom-dropdown .dropdown {
  display: flex;
  align-items: center;
  gap: 0.5em;
  position: relative;
  width: 100%;
  border: 1.63px solid #ccc;
  background: transparent;
  color: var(--text-black);
  padding: 0.4em var(--dropdown-padding-amount) calc(0.4em + 1px);
  border-radius: 0.8em;
  font-size: 0.875em;
}

.custom-dropdown .dropdown:focus {
  outline: 1px solid #dedede;
}

.custom-dropdown .dropdown::after {
  content: '';
  position: absolute;
  top: 50%;
  right: var(--dropdown-padding-amount);
  transform: rotate(45deg) translate(-50%, -50%) scale(0.8) skew(-5deg, -5deg);
  border: solid var(--text-black);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.custom-dropdown .dropdown > img {
  width: 28px;
  height: 28px;
  border-radius: 999px;
}

.custom-dropdown .dropdown > .text-box {
  display: flex;
  flex-direction: column;
}

.custom-dropdown .dropdown > .text-box > span.name,
.custom-dropdown .dropdown > .text-box > span.id {
  cursor: default;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.custom-dropdown .dropdown > .text-box > span.name {
  color: var(--text-black);
}

.custom-dropdown .dropdown > .text-box > span.id {
  font-size: 0.875em;
}

.custom-dropdown .options {
  z-index: 10;
  opacity: 0;
  position: absolute;
  background-color: var(--main-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 5px;
  box-shadow:
    var(--transparent-gray) 0px 8px 24px 4px,
    var(--transparent-gray) 0px 2px 2px 0px;
  font-size: 0.875em;
  padding: 0.4em var(--dropdown-padding-amount) calc(0.4em + 1px);
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition-duration: 0.28s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  pointer-events: none;
}

.custom-dropdown.open .options {
  max-height: 300px;
  opacity: 1;
  pointer-events: all;
}

.custom-dropdown.open .options-optionlist {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.custom-dropdown.open .options .option {
  display: flex;
  align-items: center;
  gap: 0.75em;
  padding: 0.25em 0.5em;
  border-radius: 5px;
}

.custom-dropdown.open .options .option:not(.disabled):focus,
.custom-dropdown.open .options .option:not(.disabled):hover {
  background: #f6f7f9;
  outline: none;
  cursor: pointer;
}

.custom-dropdown.open .options .option.selected {
  background: #edf2fa;
}

.custom-dropdown.open .options .option.disabled {
  cursor: not-allowed;
}

.custom-dropdown.open .options .option .check-icon {
  margin: 0 0.25em;
  visibility: hidden;
}

.custom-dropdown.open .options .option.selected .check-icon {
  visibility: visible;
}

.custom-dropdown .options .option .image-area img {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 999px;
}

.custom-dropdown .options .option .image-area {
  display: flex;
}

.custom-dropdown.open .options .option .text-box {
  flex: 1;
}

.custom-dropdown.open .options .option .text-box .title {
  color: var(--text-black);
}

.custom-dropdown.open .options .option .text-box .description {
  font-size: 0.875em;
}

.custom-dropdown .search-input {
  border: 1px solid #dedede;
  outline: none;
  color: var(--text-black);
  padding: 0.4em 0.25em;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 0.5em;
  font-size: 0.875em;
  flex: none;
  font-size: small;
  margin-top: 0.5em;
}

.custom-dropdown .search-input:focus {
  outline: 1px solid #dedede;
}
