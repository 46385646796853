.audience-error-box {
    padding: 0 2em;
    border-radius: .5em;
    margin: 0 auto;
    width: 100%;
    font-size: 1em;
}

.audience-error-box > .row {
    align-items: center;
    flex-wrap: nowrap;
    gap: 2em;
}

.audience-error-box .err-image {
    width: 10em;
}

.audience-error-box .err-message {
    color: gray;
    font-style: italic;
    display: block;
}

.audience-error-box .helper-text {
    margin: 1em 0 .5em;
    font-size: 0.9em;
    display: block;
}

.audience-creation .all-set {
    display: flex;
    align-items: center;
    gap: .5em;
}

.audience-creation .all-set .icon {
    color: var(--main-green);
    font-size: 2em;
}