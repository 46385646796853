.modal-container.AD_HEALTH_CHECKUP_MODAL {
  width: 50%;
  max-width: 1100px;
  position: relative;
  border-radius: 1em;
}

.ad-health-checkup-modal {
  border-radius: 1em;
  height: 100%;
}

.AD_HEALTH_CHECKUP_MODAL .close-button {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
  color: var(--text-gray);
  font-size: 1.8em;
  height: 1em;
}

.AD_HEALTH_CHECKUP_MODAL .close-button:hover {
  transform: translateY(-1px);
  color: var(--text-gray);
}

.AD_HEALTH_CHECKUP_MODAL .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 2em;
  width: 100%;
}
.AD_HEALTH_CHECKUP_MODAL .middle-text {
  width: 70%;
  font-size: var(--font-24);
  color: var(--text-gray);
  font-weight: 500;
  text-align: left;
  margin: 1em 0;
  display: flex;
  align-items: center;
  gap: 1em;
}

.AD_HEALTH_CHECKUP_MODAL .middle-text .icon {
  color: var(--main-green);
  font-size: 1.3em;
}

.modal-container.AD_HEALTH_CHECKUP_MODAL .subscription-header {
  width: 80%;
  max-width: 800px;
  margin: 0 auto 1em;
  color: var(--text-gray);
  font-weight: 600;
  font-size: var(--font-28);
  text-align: left;
}

.modal-container.AD_HEALTH_CHECKUP_MODAL .subscription-header .green {
  color: var(--text-green);
}

.modal-container .connect-page-button {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.modal-container .connect-page-button .button {
  background: var(--button-gradient);
  /* width: 30%; */
  color: var(--main-white);
  padding: 1em;
  border-radius: 7px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  flex-direction: row;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.modal-container .connect-page-button .button:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.modal-container .connect-page-button .button-row {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.modal-container .connect-page-button .button-row img {
  width: 1.5em;
}

.modal-container .connect-page-button .button-row .icon {
  font-size: 1.3em;
}

@media (max-width: 1200px) {
  .AD_HEALTH_CHECKUP_MODAL .middle-text {
    width: 100%;
  }
  .AD_HEALTH_CHECKUP_MODAL .middle-text .icon {
    font-size: 2em;
  }
}
